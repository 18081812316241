export default function SvgOrdAsc({ fill }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14.832" height="12.495" viewBox="0 0 14.832 12.495">
      <g id="noun-sort-1942229" transform="translate(0)">
        <path id="Tracciato_6070" data-name="Tracciato 6070" d="M131.8,95.89l-.562-.562v9.909a.586.586,0,0,1-1.171,0V95.328l-.562.562h0a.586.586,0,0,1-.828-.828l1.562-1.562h0a.585.585,0,0,1,.828,0l1.562,1.562h0a.586.586,0,0,1-.828.828Z" transform="translate(-128.509 -93.328)" fill="#255300" />
        <path id="Tracciato_6071" data-name="Tracciato 6071" d="M300.455,141.171h-8.2a.586.586,0,1,1,0-1.171h8.2a.586.586,0,0,1,0,1.171Z" transform="translate(-286.21 -130.239)" fill="#255300" />
        <path id="Tracciato_6072" data-name="Tracciato 6072" d="M298.894,222.841h-6.638a.586.586,0,0,1,0-1.171h6.638a.586.586,0,1,1,0,1.171Z" transform="translate(-286.21 -214.642)" fill="#255300" />
        <path id="Tracciato_6073" data-name="Tracciato 6073" d="M297.332,304.5h-5.076a.586.586,0,1,1,0-1.171h5.076a.586.586,0,1,1,0,1.171Z" transform="translate(-286.21 -299.035)" fill="#255300" />
      </g>
    </svg>
  );
}
