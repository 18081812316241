import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "./../../config/axiosConfig";
import { PURGE } from "redux-persist";
import { getAPIErrorMessage } from "../../common/utils";

export const login = createAsyncThunk("login/login", async ({ email, password }, thunkAPI) => {
  try {
    const response = await api.post("/api/login", { email, password });
    if (response.data.data.user.flg_reset_password) {
      return { user: response.data.data.user, tempToken: response.data.data.token, resetPassword: true };
    } else {
      return { user: response.data.data.user, token: response.data.data.token, loginSuccess: true };
    }
  } catch (error) {
    throw error.data ? error.data : error.message ? error.message : error;
  }
});

export const sendForgotPassword = createAsyncThunk("login/sendForgotPassword", async ({ email }, thunkAPI) => {
  try {
    await api.post("/api/resetPassword", { email });
    return true;
  } catch (error) {
    return thunkAPI.rejectWithValue({ message: getAPIErrorMessage(error) });
  }
});

export const setPasswordAsync = createAsyncThunk("login/setPassword", async ({ newPassword, confirmPassword }, thunkAPI) => {
  try {
    const state = thunkAPI.getState();
    const tempToken = state.login.tempToken;
    const config = {
      headers: {
        Authorization: "Bearer " + tempToken,
      },
    };
    await api.post("/api/password", { password: newPassword, password_confirm: confirmPassword }, config);
    return { resetPassword: false, loginSuccess: true, tempToken: null, token: tempToken };
  } catch (error) {
    return thunkAPI.rejectWithValue({ message: getAPIErrorMessage(error) });
  }
});

export const changeUserData = createAsyncThunk("login/changeUserData", async (params, thunkAPI) => {
  try {
    const state = thunkAPI.getState();
    const token = state.login.token;
    const config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    const response = await api.post("/api/cambiaDatiUtente", params, config);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue({ message: getAPIErrorMessage(error) });
  }
});

export const changePassword = createAsyncThunk("login/changePassword", async (data, thunkAPI) => {
  try {
    const state = thunkAPI.getState();
    const token = state.login.token;
    const config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    const response = await api.put("/api/password", data, config);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue({ message: getAPIErrorMessage(error) });
  }
});

const initialState = {
  user: null,
  isLoading: false,
  isLoadingForgot: false,
  isLoadingSetPsw: false,
  error: null,
  errorForgot: null,
  errorSetPsw: null,
  resetPassword: false,
  loginSuccess: false,
  resetSuccess: false,
  token: null,
  tempToken: null,
  changePwdSuccess: false,
  changeUserDataSuccess: false,
  errorChangeUserData: null,
  selectedSection: null,
};

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    setInitState: (state, action) => {
      for (let key in initialState) {
        state[key] = initialState[key];
      }
    },
    setLoginSuccess: (state, action) => {
      state.loginSuccess = action.payload;
    },
    setForgotSuccess: (state, action) => {
      state.resetSuccess = action.payload;
    },
    setDataCheck: (state, action) => {
      state.user = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    resetChangeUD: (state, action) => {
      state.changeUserDataSuccess = false;
      state.errorChangeUserData = false;
    },
    setChangePwdSuc: (state, action) => {
      state.changePwdSuccess = false;
      state.errorSetPsw = false;
    },
    setSelectedSection: (state, action) => {
      state.selectedSection = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload.user;
        if (action.payload.loginSuccess) {
          state.token = action.payload.token;
          state.loginSuccess = action.payload.loginSuccess;
        } else if (action.payload.resetPassword) {
          state.tempToken = action.payload.tempToken;
          state.resetPassword = action.payload.resetPassword;
        }
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message ? action.error.message : action.error;
      })
      .addCase(sendForgotPassword.pending, (state) => {
        state.isLoadingForgot = true;
        state.errorForgot = null;
      })
      .addCase(sendForgotPassword.fulfilled, (state, action) => {
        state.isLoadingForgot = false;
        state.resetSuccess = true;
      })
      .addCase(sendForgotPassword.rejected, (state, action) => {
        state.isLoadingForgot = false;
        state.errorForgot = action.payload.message;
      })
      .addCase(setPasswordAsync.pending, (state) => {
        state.isLoadingSetPsw = true;
        state.errorSetPsw = null;
      })
      .addCase(setPasswordAsync.fulfilled, (state, action) => {
        state.isLoadingSetPsw = false;
        state.resetPassword = action.payload.resetPassword;
        state.token = action.payload.token;
        state.tempToken = action.payload.tempToken;
        state.loginSuccess = action.payload.loginSuccess;
      })
      .addCase(setPasswordAsync.rejected, (state, action) => {
        state.isLoadingSetPsw = false;
        state.errorSetPsw = action.payload.message;
      })
      //#region changePassword
      .addCase(changeUserData.pending, (state, action) => {
        state.errorChangeUserData = null;
        state.isLoading = true;
      })
      .addCase(changeUserData.fulfilled, (state, action) => {
        state.user = action.payload.data;
        state.changeUserDataSuccess = true;
        state.isLoading = false;
      })
      .addCase(changeUserData.rejected, (state, action) => {
        state.isLoading = false;
        state.errorChangeUserData = action.payload.message || action.payload;
      })
      // #endregion
      //#region changePassword
      .addCase(changePassword.pending, (state, action) => {
        state.errorSetPsw = null;
        state.isLoadingSetPsw = true;
      })
      .addCase(changePassword.fulfilled, (state, action) => {
        state.user = action.payload.data;
        state.changePwdSuccess = true;
        state.isLoadingSetPsw = false;
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.isLoadingSetPsw = false;
        state.errorSetPsw = action.payload.message || action.payload;
      })
      // #endregion

      .addCase(PURGE, (state) => {
        // localStorage.clear();
        return initialState;
      });
  },
});

export const { setLoginSuccess, setForgotSuccess, setDataCheck, setToken, resetChangeUD, setChangePwdSuc, setSelectedSection, setInitState } = loginSlice.actions;

export default loginSlice.reducer;
