import React from "react";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useSelector } from "react-redux";
import TabContext from "@mui/lab/TabContext";
import { Delete } from "@mui/icons-material";
import { Box, Tab, Stack, Typography, TextField, FormControl, Grid, IconButton, Autocomplete } from "@mui/material";
import DraggableTab from "../DraggableTabs";
import { DragDropContext, Droppable } from "react-beautiful-dnd";

const PagineReferto = ({ selectedTab, setSelectedTab, refertoData, setRefertoData, readOnly = false }) => {
  const { listaTipoStrutturaReferti } = useSelector((state) => state.dashboardAdmin);

  const handleInputPaginaChange = (e, selectedPagePosition) => {
    const { name, value } = e.target;

    setRefertoData((prevData) => ({
      ...prevData,
      pagine: prevData.pagine.map((pagina, i) => {
        if (pagina.posizione === selectedPagePosition) {
          if (name === "tipo_struttura") {
            const tipoStrutturaObj = listaTipoStrutturaReferti.find((tipoStruttura) => tipoStruttura.codice === value);
            let elementi_pagina = [];
            if (tipoStrutturaObj) {
              if (pagina.tipo_struttura !== value) {
                for (const elementoStruttura of tipoStrutturaObj.elementi) {
                  elementi_pagina.push({ codice_elemento_struttura: elementoStruttura.codice, tipologia: elementoStruttura.tipologia, label: elementoStruttura.label, identificativo: "", titolo: "" });
                }
              } else {
                elementi_pagina = pagina.elementi;
              }
            } else {
              elementi_pagina = [];
            }

            return { ...pagina, tipo_struttura: value, elementi: elementi_pagina, tipo_struttura_obj: tipoStrutturaObj, [name]: value };
          } else {
            return { ...pagina, [name]: value };
          }
        } else {
          return pagina;
        }
      }),
    }));
  };

  const handleInputElementoPaginaChange = (e, selectedPagePosition, codiceElemento) => {
    const { name, value } = e.target;

    setRefertoData((prevData) => ({
      ...prevData,
      pagine: prevData.pagine.map((pagina) => {
        if (pagina.posizione === selectedPagePosition) {
          return {
            ...pagina,
            elementi: pagina.elementi.map((elemento, j) => {
              if (elemento.codice_elemento_struttura === codiceElemento) {
                return { ...elemento, [name]: value };
              } else {
                return elemento;
              }
            }),
          };
        } else {
          return pagina;
        }
      }),
    }));
  };

  const deletePagina = (selectedPagePosition) => {
    const newPages = refertoData.pagine.filter((pagina) => pagina.posizione.toString() !== selectedPagePosition);
    for (let i = 0; i < newPages.length; i++) {
      newPages[i].posizione = i + 1;
    }
    setRefertoData((prevData) => ({ ...prevData, pagine: newPages }));

    if (newPages.length > selectedPagePosition) {
      setSelectedTab(selectedPagePosition.toString());
    } else {
      setSelectedTab(newPages[newPages.length - 1]?.posizione.toString() || "");
    }
  };


  const _renderTabList = (droppableProvided) => (
    <TabList
      onChange={(e, newTab) => setSelectedTab(newTab)}
      variant="scrollable"
    >
      {
        refertoData?.pagine.map((pagina, index) => {
          const child = <Tab key={pagina.posizione} label={"Pagina " + pagina.posizione} value={pagina.posizione.toString()} />;

          return (
            <DraggableTab
              label={"Pagina " + pagina.posizione}
              value={pagina.posizione.toString()}
              index={pagina.posizione}
              key={pagina.posizione}
              child={child}
            />
          );
        })
      }
      {droppableProvided ? droppableProvided.placeholder : null}
    </TabList>

  );


  const _renderTabListWrappedInDroppable = () => (
    <DragDropContext onDragEnd={(e) => { e.source && e.destination && onDragEnd(e.source.index, e.destination.index) }}>
      <div style={{ display: "flex", overflow: "auto" }}>
        <Droppable droppableId="1" direction="horizontal">
          {(droppableProvided) => (
            <div
              ref={droppableProvided.innerRef}
              {...droppableProvided.droppableProps}
            >
              {_renderTabList(droppableProvided)}
            </div>
          )}
        </Droppable>
      </div>
    </DragDropContext>
  );

  const onDragEnd = (posizioneSource, posizioneEnd) => {

    const newPages = [...refertoData.pagine];
    const [removed] = newPages.splice((posizioneSource-1), 1);
    newPages.splice((posizioneEnd-1), 0, removed);

    for (let i = 0; i < newPages.length; i++) {
      newPages[i].posizione = i + 1;
    }

    setRefertoData((prevData) => ({ ...prevData, pagine: newPages }));
    setSelectedTab(posizioneEnd.toString());
  }



  return (
    <TabContext value={selectedTab || ""}>
      <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 1, mt: 2 }}>
        <Stack direction="column">{_renderTabListWrappedInDroppable()}</Stack>
      </Box>
      {refertoData?.pagine.map((pagina) => (
        <TabPanel value={pagina.posizione.toString()} index={pagina.posizione} key={pagina.posizione}>
          <Box sx={{ display: "flex", flex: 1, justifyContent: "end", position: 'relative', top: -20, right: -20 }}>
            {refertoData && !readOnly && (
              <IconButton color="error" onClick={() => deletePagina(selectedTab)}>
                <Delete />
              </IconButton>
            )}
          </Box>
          <Grid container spacing={2} columnSpacing={8}>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField label="Titolo Pagina" name="titolo" disabled={readOnly} value={pagina.titolo} onChange={(e) => handleInputPaginaChange(e, pagina.posizione)} />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <Autocomplete labelId="categoria-label" name="tipo_struttura" disabled={readOnly}
                  options={listaTipoStrutturaReferti} getOptionLabel={(option) => option.descrizione}
                  value={listaTipoStrutturaReferti.filter((v) => v.codice === pagina.tipo_struttura)[0] || null}
                  label="Struttura Pagina"
                  onChange={(e, newVal) => handleInputPaginaChange({ target: { name: 'tipo_struttura', value: newVal?.codice } }, pagina.posizione)}
                  renderInput={(params) => <TextField {...params} label="Struttura Pagina" />} />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField label="Testo Pagina" name="testo" disabled={readOnly} value={pagina.testo} onChange={(e) => handleInputPaginaChange(e, pagina.posizione)} multiline />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              {pagina.tipo_struttura === "" || pagina.tipo_struttura == null ? (
                <Typography variant="m" color="error" sx={{ ml: 2 }}>
                  Seleziona una struttura per la pagina
                </Typography>
              ) : (
                <Grid container spacing={2} sx={{ mt: 1 }}>
                  <Grid item xs={pagina.elementi.length > 0 ? 6 : 12} sx={{ textAlign: "center" }}>
                    <img src={pagina.tipo_struttura_obj?.thumbnailUrl} style={{ maxWidth: "100%", maxHeight: "500px", boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)" }} alt="thumbnail" />
                  </Grid>
                  {pagina.elementi.length > 0 && (
                    <Grid item xs={6}>
                      <Typography>Configurazione Elementi Pagina</Typography>
                      {pagina.elementi.map((elemento, indexElemento) => (
                        <Box key={indexElemento}>
                          <Stack direction="row" spacing={1} sx={{ mt: 3, mb: 2 }}>
                            <Typography variant="m">
                              {elemento.tipologia === "TAB" && "Elemento Tabella: "}
                              {elemento.tipologia === "BAR" && "Elemento Grafico Barre: "}
                              {elemento.tipologia === "STAR" && "Elemento Grafico Stella: "}
                            </Typography>
                            <Typography variant="m" color="primary.dark">
                              {elemento.label}
                            </Typography>
                          </Stack>

                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <FormControl fullWidth>
                                <TextField disabled={readOnly} label="Identificativo Excel" name="identificativo" value={elemento.identificativo} onChange={(e) => handleInputElementoPaginaChange(e, pagina.posizione, elemento.codice_elemento_struttura)} />
                              </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                              <FormControl fullWidth>
                                <TextField disabled={readOnly} label="Titolo" name="titolo" value={elemento.titolo} onChange={(e) => handleInputElementoPaginaChange(e, pagina.posizione, elemento.codice_elemento_struttura)} />
                              </FormControl>
                            </Grid>
                          </Grid>
                        </Box>
                      ))}
                    </Grid>
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>
        </TabPanel>
      ))}
    </TabContext>
  );
};

export default PagineReferto;
