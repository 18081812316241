import { Box, Button, Stack } from "@mui/material";
import { ReactComponent as SvgTommy } from "../assets/svg/SvgTommy.svg";
import { useEffect, useRef, useState } from "react";
import { NavigateBefore, NavigateNext } from "@mui/icons-material";

export default function Tommy({ content = false, autoplay = false, manualplay = true, boxStyle = {}, tommyHeight = '100px', tipPos, tipBgColor, tipStyle = {} } = {}) {
  const allowedPos = ["left-start", "right-start", "top", "bottom"];
  const validPos = allowedPos.includes(tipPos) ? tipPos : null;

  let boxMargin = {};
  let flexDirection = '';
  if (validPos == 'top') {
    flexDirection = 'column';
  } else if (validPos == 'bottom') {
    flexDirection = 'column-reverse';
  } else if (validPos == 'right-start') {
    flexDirection = 'row-reverse';
    boxMargin = { marginRight: '10px' };
  } else if (validPos == 'left-start') {
    flexDirection = 'row';
    boxMargin = { marginLeft: '10px' };
  }

  let marginAuto = {};
  if (validPos == 'top' || validPos == 'bottom') {
    marginAuto = { marginLeft: 'auto', marginRight: 'auto' };
    boxStyle = { justifyContent: 'start', ...boxStyle };
  } else if (validPos == 'left-start' || validPos == 'right-start') {
    marginAuto = { marginTop: 'auto', marginBottom: 'auto' };
    if (validPos == 'left-start')
      boxStyle = { justifyContent: 'end', ...boxStyle };
    else if (validPos == 'right-start') {
      boxStyle = { justifyContent: 'start', ...boxStyle };
    }
  }

  let borderRadius = {};
  if (validPos == 'top') {
    borderRadius = { borderRadius: '10px 10px 10px 0' };
  } else if (validPos == 'bottom') {
    borderRadius = { borderRadius: '0px 10px 10px 10px' };
  } else if (validPos == 'left-start') {
    borderRadius = { borderRadius: '10px 10px 0px 10px' };
  } else if (validPos == 'right-start') {
    borderRadius = { borderRadius: '10px 10px 10px 0px' };
  }

  const [currIdx, setCurrIdx] = useState(0);
  const intervalRef = useRef(null);

  useEffect(() => {
    setCurrIdx(0);
  }, [content]);

  useEffect(() => {
    if (typeof autoplay !== "number" && autoplay !== true) {
      return;
    }

    if (Array.isArray(content) && content.length > 1) {
      intervalRef.current = setInterval(
        () => {
          setCurrIdx((prev) => (prev + 1) % content.length);
        },
        autoplay === true ? 5000 : autoplay * 1000
      );
    }

    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
  }, [content, autoplay]);

  const goToNext = () => {
    setCurrIdx((prev) => (prev + 1) % content.length);
    if (intervalRef.current) clearInterval(intervalRef.current);
  };

  const goToPrev = () => {
    setCurrIdx((prev) => (prev - 1 + content.length) % content.length);
    if (intervalRef.current) clearInterval(intervalRef.current);
  };

  const Svg = () => {
    return (<Box width='fit-content' sx={{ ...marginAuto }} height={tommyHeight} ><SvgTommy height={'100%'} /></Box>);
  };

  const Bubble = () => {
    return (<Box
      style={{ padding: '10px', ...marginAuto, backgroundColor: tipBgColor || "#E4EAC3", color: "#000", ...tipStyle, ...borderRadius }}
    >
      {
        !!content ? (
          Array.isArray(content) ? (
            <>
              {content[currIdx]}
              {manualplay ? (
                <Stack direction="row" justifyContent="center">
                  <Button onClick={goToPrev}>
                    <NavigateBefore />
                  </Button>
                  <Button onClick={goToNext}>
                    <NavigateNext />
                  </Button>
                </Stack>
              ) : (
                <></>
              )}
            </>
          ) : (
            content
          )
        ) : <>  </>
      }
    </Box>
    );
  }

  return (
    <Box sx={[{ ...boxMargin, position: "absolute", zIndex: 99, display: 'flex', flexDirection: flexDirection, width: validPos == 'top' || validPos == 'bottom' ? '100%' : 'fit-content' }]} style={boxStyle}>
      <Bubble />
      <Svg />
    </Box >
  );
}
