import { useTheme } from "@emotion/react";
import { Info, OpenInNew, } from "@mui/icons-material";
import { Backdrop, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, TextField, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBraniUtente, getListaCategorieAudio, getListaTecniche, setSelectedCategoriaAudioIMieiBrani } from "./absSlice";
import { CardBrano } from "./CardBrano";
import 'media-chrome';
import { formatDate } from "../../common/utils";
import { setInfoTecnicaABS } from "../ecommerce/EcommerceSlice";
import { Video } from "./player/Video";
import { Audio } from "./player/Audio";
import { CONFIG } from "../../config/envConfig";

export function MieiBrani(props) {
  const screenRef = useRef();
  const dispatch = useDispatch();
  const gg = useRef();
  const [srcAudio, setSrcAudio] = useState("");
  const theme = useTheme();
  const c = theme.palette;
  const { listaTecniche, pageInfoListaTecniche, braniUtente, listaCategorieAudio, isLoadingBraniUtente, selectedCategoriaAudioIMieiBrani } = useSelector((state) => state.abs);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [orderBy, setOrderBy] = useState("created_at");
  const [order, setOrder] = useState("desc");
  const tableRef = useRef();
  const [video, setVideo] = useState(null);
  const [audio, setAudio] = useState(null);
  const matchSm = useMediaQuery(theme.breakpoints.up("sm"));

  const { user } = useSelector((state) => state.login);

  const [screenH, setScreenH] = useState(null);

  const handleResize = () => {
    if (screenRef.current) {
      setScreenH(window.innerHeight - screenRef.current.getBoundingClientRect().top - CONFIG.FOOTER_HEIGHT);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [screenRef]);

  useEffect(() => {
    dispatch(getBraniUtente({ id_categoria: selectedCategoriaAudioIMieiBrani }));
  }, [selectedCategoriaAudioIMieiBrani]);


  useEffect(() => {
    dispatch(getListaCategorieAudio());
    dispatch(getBraniUtente({ id_categoria: selectedCategoriaAudioIMieiBrani }));
    dispatch(getListaTecniche());
  }, []);


  const handleChangePage = (event, newPage) => {
    dispatch(getListaTecniche({ params: { page: newPage + 1, per_page: rowsPerPage, sort: order, order_by: orderBy } }));
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const countR = parseInt(event.target.value, 5);
    dispatch(getListaTecniche({ params: { per_page: countR, sort: order, order_by: orderBy } }));
    setRowsPerPage(countR);
    setPage(1);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    dispatch(getListaTecniche({ params: { page: page + 1, per_page: rowsPerPage, sort: isAsc ? "desc" : "asc", order_by: property } }));
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const onAttivato = () => {
    dispatch(getBraniUtente({ id_categoria: selectedCategoriaAudioIMieiBrani }));
  }


  const headCells = [
    { id: "created_at", label: "Data", format: (val) => formatDate(val, "DD/MM/YYYY") },
    { id: 'stato', label: "Stato", format: (val) => (val === "RIC" ? "In Elaborazione" : "Consegnato") },
    {
      id: "brani",
      label: "N. Brani",
      sortable: false,
      format: (val) => val.length
    },
    {
      id: 'frasi_negative',
      label: "N. Frasi Negative",
      sortable: false,
      format: (val) => val.length
    },
    {
      id: 'frasi_positive',
      label: "N. Frasi Positive",
      sortable: false,
      format: (val) => val.length
    },
    {
      id: 'utente_professionista',
      label: "Professionista",
      sortable: false,
      format: (val) => val ? val.nome + " " + val.cognome : "-"
    },
    { id: "azioni", label: "Azioni", sortable: false },
  ];



  const audioFiltered = (!!srcAudio ? braniUtente.filter((a) => a.nome?.toLowerCase().includes(srcAudio.toLowerCase()) || a.descrizione?.toLowerCase().includes(srcAudio.toLowerCase())) : braniUtente);

  return (
    <Box ref={screenRef} height={screenH} sx={{ display: 'flex', bgcolor: "#fff", flexDirection: 'column', overflowY: 'auto', p: 1.5, mt: 1 }}>

      {
        user?.tipologia == 'P' ?
          <Box sx={{ mb: 6 }}>
            <Typography variant="h6" align="left">
              Elaborati Tecniche ABS
            </Typography>
            <TableContainer ref={tableRef} sx={{ mt: 2 }}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    {headCells.map((headCell) => (
                      <TableCell key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}>
                        {(headCell.sortable === undefined || headCell.sortable === true) && (
                          <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : "asc"} onClick={() => handleRequestSort(headCell.id)}>
                            {headCell.label}
                          </TableSortLabel>
                        )}
                        {headCell.sortable === false && <span>{headCell.label}</span>}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {listaTecniche?.map((tecnica) => (
                    <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
                      {headCells.map((col) =>
                        col.id === "azioni" ? (
                          <TableCell key={col.id}>
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                              <IconButton onClick={() => { dispatch(setInfoTecnicaABS(tecnica)) }} size="small">
                                <Info />
                              </IconButton>

                              {
                                tecnica.stato == 'COMP' ?
                                  tecnica.estensione == 'mp4' ?
                                    <Button size="small" startIcon={<OpenInNew />} variant="contained" sx={{ ml: 1 }} onClick={() => setVideo(tecnica)}>
                                      Video
                                    </Button> :
                                    <Button size="small" startIcon={<OpenInNew />} variant="contained" sx={{ ml: 1 }} onClick={() => setAudio(tecnica)}>
                                      Audio
                                    </Button>
                                  : <></>
                              }

                            </Box>
                          </TableCell>
                        ) : (
                          <TableCell key={tecnica.id + col.id}>{col.format ? col.format(tecnica[col.id], tecnica) : tecnica[col.id] || "-"}</TableCell>
                        ))}
                    </TableRow>
                  ))}
                  <TableRow key={"emptyRow"} >
                    <TableCell sx={{ verticalAlign: "baseline", fontSize: '1rem' }} colSpan={headCells.length + 1}>
                      {!listaTecniche || listaTecniche?.length === 0 ? "Non sono presenti Tecniche ABS" : ""}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 100]}
                component="div"
                count={pageInfoListaTecniche?.total || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage="Righe"
                labelDisplayedRows={({ from, to, count }) => `${from}-${to} di ${count}`}
              />
            </TableContainer>
          </Box>
          : <></>
      }

      <Box>
        <Typography variant="h6" align="left">
          Libreria Brani
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: matchSm ? 'row' : 'column', justifyContent: 'space-between', mt: 2 }}>
          <Box sx={{}}>
            <Typography variant="body" sx={{ color: c.primary.main, display: 'inline-block' }} align="left">
              Quale brano stai cercando?
            </Typography>
            <Select
              value={selectedCategoriaAudioIMieiBrani}
              size="small"
              sx={{ ml: 2 }}
              onChange={(event) => {
                dispatch(setSelectedCategoriaAudioIMieiBrani(event.target.value));
              }}
            >
              <MenuItem key={'0'} value={'0'}>
                TUTTI
              </MenuItem>
              {listaCategorieAudio.map((f) => (
                <MenuItem key={f.id} value={f.id}>
                  {f.nome.toUpperCase()}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <TextField size="small" sx={{ width: '300px', maxWidth: '100%', mt: matchSm ? 0 : 1 }} label="Cerca brano" value={srcAudio} onChange={(e) => setSrcAudio(e.target.value)} />
        </Box>
        <Box sx={{ mt: 2 }}>
          <Grid ref={gg} container spacing={4} sx={{ marginTop: '0px', pl: 2, overflowY: "none", pb: 3 }}>
            {
              isLoadingBraniUtente && <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
                <CircularProgress color="inherit" />
              </Backdrop>
            }
            {
              !isLoadingBraniUtente && audioFiltered.map((a, i) => (
                <Grid item xs={12} sm={6} md={3} key={i} style={{ paddingTop: '0px' }}>
                  <CardBrano onAttivato={onAttivato} showBorder={false} audio={a} showQta={user?.tipologia == 'PA'} showPlayer={true} showCartIcon={false} showPrice={false} isCardIconChecked={() => { return true; }} onCardAddClick={() => { }} />
                </Grid>
              ))
            }

          </Grid>
          {
            audioFiltered.length == 0 && <Typography variant="body2" align="center" sx={{ mt: 2 }}>Nessun brano trovato</Typography>
          }
        </Box>
      </Box>

      <Dialog open={video != null} onClose={() => setVideo(null)} maxWidth="md" fullWidth>
        <DialogTitle variant="dialogTitle" color="success">
          Elaborato Video
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Video url={video?.media_url} width={'100%'} />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => setVideo(null)}>
            Chiudi
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={audio != null} onClose={() => setAudio(null)} maxWidth="md" fullWidth>
        <DialogTitle variant="dialogTitle" color="success">
          Elaborato Audio
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Audio url={audio?.media_url} width={'100%'} />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => setAudio(null)}>
            Chiudi
          </Button>
        </DialogActions>
      </Dialog>

    </Box>
  );
}
