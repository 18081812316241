import { AddCircle, ChangeCircle, Close, Info, KeyboardArrowLeft, KeyboardArrowRight, OpenInNew } from "@mui/icons-material";
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, MobileStepper, Paper, Stack, Step, StepButton, Stepper, TextField, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { StoreAudio } from "./StoreAudio";
import { useDispatch, useSelector } from "react-redux";
import { getBraniConsigliati, setSelectedUserTecniche, setTecnica } from "./absSlice";
import { CardBrano } from "./CardBrano";
import { setSnackbarData } from "../programmi/programmiSlice";
import Tommy from "../../common/Tommy";
import { RicercaUtente } from "../prestazione/RicercaUtente";
import { aggiungiVoce } from "../ecommerce/EcommerceAPI";
import { getAPIErrorMessage } from "../../common/utils";
import { setCarrello } from "../ecommerce/EcommerceSlice";
import { useTheme } from "@emotion/react";
import { getMediaIstruzioni } from "./absAPI";
import { ReactComponent as SvgTommy } from "../../assets/svg/SvgTommy.svg";
import { Frasi } from "./Frasi";
import { CONFIG } from "../../config/envConfig";

export function TecnicheABS() {
  const theme = useTheme();
  const c = theme.palette;
  const screenRef = useRef();
  const dispatch = useDispatch();
  const [tecnicaError, setTecnicaError] = useState(null);
  const { tecnica, braniConsigliati, isLoadingBraniConsigliati, prezzoTecnica, selectedUserTecniche } = useSelector((state) => state.abs);
  const { user, token } = useSelector((state) => state.login);
  const [urlMediaIstruzioni, setUrlMediaIstruzioni] = useState(null);
  const matchXl = useMediaQuery(theme.breakpoints.up("xl"));
  const matchSm = useMediaQuery(theme.breakpoints.up("sm"));
  const [tommyVisibileMobile, setTommyVisibileMobile] = useState(false);

  const [screenH, setScreenH] = useState(null);

  const handleResize = () => {
    if (screenRef.current) {
      setScreenH(window.innerHeight - screenRef.current.getBoundingClientRect().top - CONFIG.FOOTER_HEIGHT);
    }
  };

  const setSelectedUser = (user) => {
    dispatch(setSelectedUserTecniche(user));
    dispatch(getBraniConsigliati({ id_utente: user?.id }));
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [screenRef]);

  const onAudioAddClick = (audio) => {
    //controllo se esiste già
    if (tecnica.brani.some((a) => a.id === audio.id)) {
      return;
    }
    const _tecnica = JSON.parse(JSON.stringify(tecnica));
    _tecnica.brani.push({
      nome: audio.nome,
      descrizione: audio.descrizione,
      id: audio.id,
    });
    dispatch(setTecnica(_tecnica));
  };

  const isCardIconChecked = (audio) => {
    return tecnica?.brani?.some((a) => a.id == audio.id);
  }

  const onRemoveBrano = (index) => {
    const _tecnica = JSON.parse(JSON.stringify(tecnica));
    _tecnica.brani.splice(index, 1);
    dispatch(setTecnica(_tecnica));
  }

  const prendiMediaIstruzioni = async () => {
    try {
      const res = await getMediaIstruzioni(token);
      if (res.data) {
        setUrlMediaIstruzioni(res.data.url);
      }
    } catch (err) {
      console.log(err);
    }
  };


  useEffect(() => {
    if (user.tipologia == 'P') {
      dispatch(getBraniConsigliati({ id_utente: user.id }));
    }
    prendiMediaIstruzioni();
  }, []);


  const onAggiungiCarrello = async () => {
    //controllo che ci sia almeno una canzone
    if (tecnica.brani.length == 0) {
      setTecnicaError('Seleziona almeno un brano');
      return;
    }

    //controllo che ci sia almeno una frase negativa e positiva
    if (tecnica.frasi_positive.length == 0 || tecnica.frasi_negative.length == 0) {
      setTecnicaError('Inserisci almeno una frase negativa e positiva');
      return;
    }

    //tutte le frasi non devono essere vuote
    if (tecnica.frasi_positive.some((f) => f.testo_frase.trim() == "") || tecnica.frasi_negative.some((f) => f.testo_frase.trim() == "")) {
      setTecnicaError('Sono presenti delle frasi vuote');
      return;
    }


    const data = {
      tecnica_abs: { ...tecnica, id_utente_privato: user.tipologia == 'PA' ? selectedUserTecniche.id : null },
      qta: 1,
      tipo_voce: 'TEC',
      tipo_acquisto: 'QTA'

    }
    try {
      const res = await aggiungiVoce(data, token);
      dispatch(setCarrello(res.data));
      dispatch(setSnackbarData({ message: "Tecnica aggiunta al carrello", severity: "success", vertical: "bottom" }));
    } catch (err) {
      setTecnicaError(getAPIErrorMessage(err));
      return;
    }

    //dispatch(setSnackbarData({ open: true, message: 'Tecnica ABS aggiunta al carrello', severity: 'success', vertical: 'bottom' }));
  };

  const showSelectUtente = user.tipologia == 'PA' && selectedUserTecniche == null;
  const contentScreenH = user.tipologia == 'PA' || urlMediaIstruzioni ? screenH - 40 : screenH;


  const onApriIstruzioni = async () => {

    const link = document.createElement("a");
    link.href = urlMediaIstruzioni;
    link.setAttribute("target", "_blank");
    //link.setAttribute("download", "Informativa.pdf");
    document.body.appendChild(link);
    link.click();
  };


  const BraniConsigliati = () => {
    return <Box sx={{ p: 2, bgcolor: "#fff", mb: 1 }}>
      <Typography variant="h6" align="left">
        Brani Consigliati
      </Typography>
      <Box>
        {isLoadingBraniConsigliati && <CircularProgress />}
        {
          !isLoadingBraniConsigliati &&
          <>
            {
              braniConsigliati.length == 0 && <Typography variant="body2" align="center" sx={{ mt: 2 }}>Nessun brano consigliato</Typography>
            }
            {
              braniConsigliati.length > 0 &&
              <Grid container>
                {
                  braniConsigliati.map((a, i) => (
                    <Grid item xs={6} key={i}>
                      <CardBrano audio={a} showPrice={false} isCardIconChecked={isCardIconChecked} onCardAddClick={onAudioAddClick} />
                    </Grid>
                  ))
                }
              </Grid>
            }
          </>
        }
      </Box>
    </Box>;
  }

  const BraniInseriti = () => {
    return <Box sx={{ p: 2, bgcolor: "#fff", mb: 1, height: matchXl ? contentScreenH : '100%', display: 'flex', flexDirection: 'column' }}>
      <Typography variant="h6" align="left">
        Brani scelti per Tecnica ABS
      </Typography>
      <Box sx={{ flex: 1 }}>
        <Grid container sx={{ overflowY: "auto", mt: 2 }} spacing={1}>
          {tecnica?.brani?.map((audio, i) =>
            <Grid item xl={12} xs={12} sm={6} key={i} sx={{ px: 1, py: 0.5 }} >
              <Box component={Paper} sx={{ pl: 1, display: 'flex', alignItems: "center" }}>
                <Typography variant="field">{audio.nome}</Typography>
                <Box sx={{ flex: 1 }}></Box>
                <IconButton onClick={() => onRemoveBrano(i)} sx={{ ml: 2 }}>
                  <Close />
                </IconButton>
              </Box>

            </Grid>
          )}
        </Grid>
      </Box>
      <Box bgcolor="#f0f0f0" display="flex" flexDirection="column" alignItems="center" justifyContent="center" sx={{ p: 2 }}>
        <Typography variant="subtitle2">Costo Totale:  {Intl.NumberFormat('it-IT', { style: 'currency', currency: 'eur' }).format(prezzoTecnica)}</Typography>
        <Button variant="contained" sx={{ mt: 2 }} onClick={() => { onAggiungiCarrello() }}>
          Aggiungi al carrello
        </Button>
      </Box>
    </Box>;
  }

  const [activeStep, setActiveStep] = useState(0);
  let steps = [];
  if (matchSm) {
    steps = [
      {
        'label': 'Frasi',
      },
      {
        'label': 'Brani',
      },
      {
        'label': 'Riepilogo',
      }
    ]
  } else {
    steps = [
      {
        'label': 'Frasi Negative',
      },
      {
        'label': 'Frasi Positive',
      },
      {
        'label': 'Brani',
      },
      {
        'label': 'Riepilogo',
      }
    ]
  }

  if (activeStep > steps.length - 1) setActiveStep(steps.length - 1);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }

  const TopBar = () => {
    return <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', pb: 1, minHeight: '40px' }}>
      {
        user?.tipologia == 'PA' ?
          <Box sx={{ display: 'flex', flexDirection: matchSm ? 'row' : 'column' }}>
            <Typography variant="bodt" sx={{ mb: 2, pr: 1, color: matchXl ? 'auto' : 'white', m:'auto' }}>
              <b>Utente selezionato:</b> {selectedUserTecniche?.nome} {selectedUserTecniche?.cognome}
            </Typography>
            <Box>

              <Button color={matchXl ? 'primary' : 'light'} startIcon={<ChangeCircle />} size="small" variant="outlined" onClick={() => setSelectedUser(null)} >
                Cambia
              </Button>
            </Box>
          </Box> : <Box></Box>
      }
      <Box>
        {
          urlMediaIstruzioni &&
          <Button color={matchXl ? 'primary' : 'light'} startIcon={<OpenInNew />} onClick={onApriIstruzioni} variant="outlined" size="small">
            istruzioni
          </Button>
        }

      </Box>

    </Box>;
  };

  return (
    <Box ref={screenRef} height={screenH}>
      {
        showSelectUtente ?
          <Box sx={{ backgroundColor: '#fff', display: 'flex', flexDirection: 'column', flex: 1, p: 2, mt: 1 }}>
            <Box sx={{ display: 'flex' }}>
              <Info sx={{ color: c.primary.main, fontSize: '1.5rem', marginTop: 'auto', marginBottom: 'auto' }} />
              <Typography sx={{ ml: '10px', fontSize: '1rem' }}>
                Per proseguire con la Tecnica ABS devi prima selezionare l'utente a cui è rivolta.<br />
                NB. L’utente deve essere precedetemente registrato.
              </Typography>
            </Box>

            <Grid container spacing={1} sx={{ mt: 4 }}>
              <Grid item xs={12} sm={12} md={12} lg={8}>
                <Typography variant="body" sx={{ mt: 4, mb: 0 }}>
                  Ricerca l’utente salvato per codice cliente.
                </Typography>
                <RicercaUtente selectedUser={selectedUserTecniche} setSelectedUser={setSelectedUser}
                  resultMessage={'Risultati della ricerca. Seleziona un utente per proseguire'} />
              </Grid>

            </Grid>

          </Box>
          : <> </>
      }

      {
        !showSelectUtente ?
          (
            <Box sx={{ height: '100%' }} >
              {
                matchXl ?
                  <Box sx={{ mt: 1 }}>
                    {
                      (user?.tipologia == 'PA' || urlMediaIstruzioni) &&
                      <Box sx={{ px: 2 }}>
                        <TopBar />
                      </Box>

                    }
                    <Grid container spacing={1} sx={{ height: contentScreenH }} >
                      <Grid item xs={12} sm={12} md={5} sx={{ height: '100%' }}>
                        <Box sx={{ height: '75%' }}>
                          <Grid container spacing={1} sx={{ height: '100%' }}>
                            <Grid item xs={12} sm={6} sx={{ height: '100%' }}>
                              <Frasi isPositive={false} />
                            </Grid>
                            <Grid item xs={12} sm={6} sx={{ height: '100%' }}>
                              <Frasi isPositive={true} />
                            </Grid>

                          </Grid>
                        </Box>

                        <Box sx={{ height: '25%', p: 4 }}>
                          <Tommy
                            boxStyle={{ height: '100%', position: 'relative' }}
                            tipPos="right-start"
                            autoplay={false}
                            maxWidth={100}
                            tommyHeight="75%"
                            tipStyle={{ maxWidth: '50%', marginLeft: '20px' }}
                            content={[
                              "Benvenuto nell'area Tecniche di ABS. Componi il tuo script personalizzato.",
                              "Inizia inserendo le frasi negative e poi quelle positive.",
                              "Seleziona le unità audio cliccando sul pulsante +",
                              "Aggiungi al carrello e concludi l'acquisto.",
                              "Riceverai in 2-3 giorni lavorativi il tuo materiale multimediale nell'area 'La mia libreria'."
                            ].map((item, idx) => (
                              <Stack key={idx} sx={{ width: '100%', minHeight: 0, justifyContent: "center", alignItems: "center" }}>
                                <Typography variant="h6" sx={{ whiteSpace: "pre-line", fontSize: '1em' }}>
                                  {item}
                                </Typography>
                              </Stack>
                            ))}
                          />
                        </Box>

                      </Grid>
                      <Grid item xs={12} sm={12} md={5} sx={{ height: '100%' }}>
                        <BraniConsigliati />
                        <StoreAudio storeMsg='Scegli i brani per la tu Tecnica ABS' showPrice={false} cardSize={6} onCardAddClick={onAudioAddClick} isCardIconChecked={isCardIconChecked} />
                      </Grid>
                      <Grid item xs={2} sx={{ height: '100%' }}>
                        <BraniInseriti />
                      </Grid>
                    </Grid>
                  </Box>
                  :
                  <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', overflowY: 'auto' }}>
                    {
                      !tommyVisibileMobile ?
                        <Box sx={{ position: 'fixed', bottom: -15, left: 0, height: '50px', width: 'fit-content' }} onClick={() => setTommyVisibileMobile(true)}>
                          <SvgTommy />
                        </Box>
                        :
                        <Box sx={{ position: 'fixed', bottom: 0, left: 10 }}>
                          <Tommy
                            boxStyle={{ height: matchSm ? '150px' : '100px', position: 'relative' }}
                            tipPos="right-start"
                            autoplay={false}
                            maxWidth={100}
                            tommyHeight="75%"
                            tipStyle={{ maxWidth: matchSm ? '300px' : '80%', marginLeft: '20px' }}
                            content={[
                              "Benvenuto nell'area Tecniche di ABS. Componi il tuo script personalizzato.",
                              "Inizia inserendo le frasi negative e poi quelle positive.",
                              "Seleziona le unità audio cliccando sul pulsante +",
                              "Aggiungi al carrello e concludi l'acquisto.",
                              "Riceverai in 2-3 giorni lavorativi il tuo materiale multimediale nell'area 'La mia libreria'."
                            ].map((item, idx) => (
                              <Stack key={idx} sx={{ width: '100%', minHeight: 0, justifyContent: "center", alignItems: "center" }}>
                                <Typography variant="h6" sx={{ whiteSpace: "pre-line", fontSize: '1em' }}>
                                  {item}
                                </Typography>
                              </Stack>
                            ))}
                          />
                          <Box sx={{ mt: 'auto', mb: 1 }}>
                            <IconButton sx={{ backgroundColor: c.primary.main, borderRadius: '5px' }} size="small" onClick={() => setTommyVisibileMobile(false)} variant="contained">
                              <Close sx={{ height: '16px', color: 'white' }} />
                            </IconButton>
                          </Box>

                        </Box>
                    }
                    <Box sx={{ backgroundColor: c.secondary.main, p: 2 }}>
                      <Typography variant="h6" sx={{ color: 'white' }}>
                        Tecniche ABS
                      </Typography>
                      {
                        (user?.tipologia == 'PA' || urlMediaIstruzioni) && !matchXl &&
                        <TopBar />
                      }
                      {
                        matchSm ?

                          <Stepper nonLinear={true} activeStep={activeStep} orientation="horizontal" sx={{ mt: 2, height: '70px' }}>
                            {steps.map((step, index) => (
                              <Step key={step.label}>
                                <StepButton sx={{ cursor: 'pointer' }} onClick={() => setActiveStep(index)}>
                                  <Box sx={{ display: 'flex' }}>
                                    <Typography sx={{ color: "white", ml: 2 }}>
                                      {step.label}
                                    </Typography>
                                  </Box>

                                </StepButton>
                              </Step>
                            ))}
                          </Stepper> :
                          <>
                            <Box sx={{ width: '100%', p: 2, textAlign: 'center', color: 'white' }}>
                              {steps[activeStep]?.label}
                            </Box>
                            <MobileStepper variant="text"
                              steps={steps.length}
                              position="static"
                              activeStep={activeStep}
                              sx={{ backgroundColor: 'transparent', color: 'white' }}
                              nextButton={
                                <Button
                                  size="small"
                                  onClick={handleNext}
                                  sx={{ color: 'white' }}
                                  disabled={activeStep >= steps.length - 1}
                                >
                                  Avanti
                                  {theme.direction === 'rtl' ? (
                                    <KeyboardArrowLeft />
                                  ) : (
                                    <KeyboardArrowRight />
                                  )}
                                </Button>
                              }
                              backButton={
                                <Button size="small" onClick={handleBack} disabled={activeStep === 0} sx={{ color: 'white' }}>
                                  {theme.direction === 'rtl' ? (
                                    <KeyboardArrowRight />
                                  ) : (
                                    <KeyboardArrowLeft />
                                  )}
                                  Indietro
                                </Button>
                              }
                            />
                          </>
                      }
                    </Box>

                    <Box sx={{ flex: 1 }}>
                      {
                        matchSm ?
                          <>
                            {
                              activeStep == 0 && <Grid container spacing={1} sx={{ height: '100%' }}>
                                <Grid item sm={12} md={6}>
                                  <Frasi isPositive={false} />
                                </Grid>
                                <Grid item sm={12} md={6}>
                                  <Frasi isPositive={true} />
                                </Grid>
                              </Grid>
                            }
                            {
                              activeStep == 1 && <Box sx={{ height: '100%' }}>
                                <BraniConsigliati />
                                <StoreAudio storeMsg='Scegli i brani per la tu Tecnica ABS' fullHeight={true} showPrice={false} cardSize={6} onCardAddClick={onAudioAddClick} isCardIconChecked={isCardIconChecked} />
                              </Box>
                            }
                            {
                              activeStep == 2 && <BraniInseriti />
                            }
                          </>
                          :
                          <>
                            {
                              activeStep == 0 && <Frasi isPositive={false} />
                            }
                            {
                              activeStep == 1 && <Frasi isPositive={true} />
                            }
                            {
                              activeStep == 2 && <Box sx={{ height: '100%' }}>
                                <BraniConsigliati />
                                <StoreAudio storeMsg='Scegli i brani per la tu Tecnica ABS' fullHeight={true} showPrice={false} cardSize={6} onCardAddClick={onAudioAddClick} isCardIconChecked={isCardIconChecked} />
                              </Box>
                            }
                            {
                              activeStep == 3 && <BraniInseriti />
                            }
                          </>
                      }


                    </Box>
                  </Box>
              }
              <Dialog open={!!tecnicaError} onClose={() => setTecnicaError(null)} maxWidth="sm" fullWidth>
                <DialogTitle variant="dialogTitle" color="error">
                  Attenzione
                </DialogTitle>
                <DialogContent>
                  <Typography color="error" style={{ whiteSpace: "pre-line" }}>
                    {tecnicaError}
                  </Typography>
                </DialogContent>
                <DialogActions>
                  <Button variant="contained" onClick={() => setTecnicaError(null)}>
                    OK
                  </Button>
                </DialogActions>
              </Dialog>

            </Box>
          )

          :
          <></>

      }
    </Box>
  );
}
