import React, { useEffect, useState } from "react";
import { initialCheckPrestazione, initialCheckVendita, creaPrestazione, getPrestazioneAttiva, creaCessione } from "./prestazioneAPI";
import { useDispatch, useSelector } from "react-redux";
import { getAPIErrorMessage } from "../../common/utils";
import { Tab, Dialog, DialogContent, Box, CircularProgress, Backdrop, Button, Typography, DialogActions, Stack } from "@mui/material";
import { useTheme } from "@emotion/react";
import { setPrestazioneAttiva } from "./prestazioneSlice";
import TabPanel from "@mui/lab/TabPanel";
import TabList from "@mui/lab/TabList";
import TabContext from "@mui/lab/TabContext";
import { InfoPrestazione } from "./InfoPrestazione";
import { getCodici, setCreaVendiPrestazioneData, setSelezionaCodiceData, setSnackbarData } from "../programmi/programmiSlice";
import { useNavigate } from "react-router-dom";
import { InfoVendita } from "./InfoVendita";
import { InserisciUtente } from "./InserisciUtente";
import { register } from "../login/loginAPI";
import { RicercaUtente } from "./RicercaUtente";
import { Info } from "@mui/icons-material";

export function DialogCreaVendiPrestazione({ props, onClose }) {
  const { isVendita, tipoAttivazione, codiciServizio, idProgramma, onVendiSuccess, onCreaSuccess } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const c = theme.palette;

  const { token, user } = useSelector((state) => state.login);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isInitialLoading, setIsInitialLoading] = React.useState(true);
  const [initialLoadingError, setInitialLoadingError] = React.useState("");
  const [error, setError] = React.useState("");
  const [datiProgramma, setDatiProgramma] = React.useState(null);
  const [datiAbbonamento, setDatiAbbonamento] = React.useState(null);
  const [selectedTab, setSelectedTab] = useState("S");
  const [datiUtentePrivatoManuale, setDatiUtentePrivatoManuale] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);

  const init = async () => {
    setIsInitialLoading(true);
    if (isVendita) {
      await initialCheckVendi();
    } else {
      await initialCheckCrea();
    }
    setIsInitialLoading(false);
  };

  const initialCheckCrea = async () => {
    let response;
    try {
      response = await initialCheckPrestazione({ codice_servizio: codiciServizio[0], tipo_attivazione: tipoAttivazione, id_programma: idProgramma }, token);
      setDatiProgramma(response.data.programma);
      setDatiAbbonamento(response.data.abbonamento);
    } catch (err) {
      setInitialLoadingError(getAPIErrorMessage(err));
    }
  };

  const initialCheckVendi = async () => {
    let response;
    try {
      response = await initialCheckVendita({ codici_servizio: codiciServizio, id_programma: idProgramma }, token);
      setDatiProgramma(response.data.programma);
    } catch (err) {
      setInitialLoadingError(getAPIErrorMessage(err));
    }
  };



  const onConfirm = async () => {
    setIsLoading(true);

    let _datiUtente = null;
    let _id_utente_privato = null;
    if (user.tipologia === "PA" || user.tipologia === "PP") {
      if (selectedTab === "S") {
        if (selectedUser === null) {
          setError("Selezionare un utente");
          setIsLoading(false);
          return;
        }
        _id_utente_privato = selectedUser.id;
      } else if (selectedTab === "R") {
        let response = null;
        try {
          response = await register({ ...datiUtentePrivatoManuale, tipologia: "P" }, token);
        } catch (err) {
          setError(getAPIErrorMessage(err));
          setIsLoading(false);
          return;
        }
        _id_utente_privato = response.data.id;
      } else if (selectedTab === "M") {
        if (isVendita) {
          setError("Non e' possibile inserire manualmente i dati di un utente per una cessione");
          setIsLoading(false);
          return;
        }
        _datiUtente = datiUtentePrivatoManuale;
      }
    } else if (user.tipologia === "P") {
      _id_utente_privato = user.id;
    }

    let response;
    if (isVendita) {
      const payload = {
        id_utente_destinatario: _id_utente_privato,
        codici_servizio: codiciServizio,
      };
      try {
        response = await creaCessione(payload, token);
      } catch (err) {
        setError(getAPIErrorMessage(err));
        return;
      } finally {
        setIsLoading(false);
      }

      if (onVendiSuccess === null || onVendiSuccess === undefined) {
        _onVendiSuccess();
      } else {
        onVendiSuccess();
      }
    } else {
      const payload = {
        codice_servizio: codiciServizio[0],
        tipo_attivazione: tipoAttivazione,
        id_programma: idProgramma,
        tipo_censimento: _id_utente_privato !== null ? "R" : "M",
        id_utente_privato: _id_utente_privato,
        id_abbonamento: datiAbbonamento?.id,
        nome_paziente: _datiUtente?.nome,
        cognome_paziente: _datiUtente?.cognome,
        data_nascita_paziente: _datiUtente?.data_nascita,
        sesso_paziente: _datiUtente?.sesso,
        peso_paziente: _datiUtente?.peso,
        altezza_paziente: _datiUtente?.altezza,
        email_paziente: _datiUtente?.email,
        telefono_paziente: _datiUtente?.telefono,
      };
      try {
        response = await creaPrestazione(payload, token);
      } catch (err) {
        setError(getAPIErrorMessage(err));
        return;
      } finally {
        setIsLoading(false);
      }

      const prestazione = response.data;
      dispatch(setPrestazioneAttiva(prestazione));

      if (onCreaSuccess === null || onCreaSuccess === undefined) {
        _onCreaSuccess(prestazione);
      } else {
        onCreaSuccess(prestazione);
      }
    }
  };

  useEffect(() => {
    init();
  }, []);

  const _onCreaSuccess = (prestazione) => {
    loadPrestazioneAttiva();
    dispatch(setCreaVendiPrestazioneData(null));
    dispatch(setSelezionaCodiceData(null));
    navigate("/tommy/esecuzione-prestazione", { state: { id_prestazione: prestazione.id } });
  };

  const _onVendiSuccess = (prestazione) => {
    dispatch(setCreaVendiPrestazioneData(null));
    dispatch(setSelezionaCodiceData(null));
    dispatch(setSnackbarData({ message: "Cessione effettuata con successo", severity: "success", vertical: "bottom"}));
    dispatch(getCodici());
  };

  const loadPrestazioneAttiva = async () => {
    if (user.tipologia === "P" || user.tipologia === "PA") {
      let response;
      try {
        response = await getPrestazioneAttiva(token);
      } catch (err) {
        return;
      }
      if (response.data !== false) {
        dispatch(setPrestazioneAttiva(response.data));
      } else {
        dispatch(setPrestazioneAttiva(null));
      }
    }
  };

  return (
    <>
      <Dialog open={true} onClose={() => onClose()} maxWidth="md" fullWidth={true}>
        {(isInitialLoading || isLoading) && (
          <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isInitialLoading || isLoading}>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}

        <DialogContent sx={{ p: 2, pt: 2, fontSize: "14px", minHeight: 400 }}>
          {!isInitialLoading && initialLoadingError !== "" && (
            <Typography style={{ color: c.text.error, whiteSpace: "pre-line" }} sx={{ mt: 4 }}>
              {initialLoadingError}
            </Typography>
          )}
          {!isInitialLoading && initialLoadingError === "" && (
            <>
              {isVendita && <InfoVendita codiciServizio={codiciServizio} programma={datiProgramma} />}
              {!isVendita && <InfoPrestazione prestazione={{ codice_servizio: codiciServizio[0], programma: datiProgramma, id_abbonamento: datiAbbonamento?.id }} />}
              {user?.tipologia !== "P" && (
                <>
                  <Typography sx={{ mb: 0, mt: 1, fontWeight: "bold", fontSize: "14px" }}>Per chi è il programma?</Typography>

                  <TabContext value={selectedTab}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <TabList onChange={(e, value) => setSelectedTab(value)} variant="scrollable" scrollButtons allowScrollButtonsMobile>
                        <Tab label={"Utente Salvato"} value={"S"} />
                        {!isVendita && <Tab label={"Inserisci utente"} value={"M"} />}
                        {user?.tipologia == 'PA' && <Tab label={"Registra utente"} value={"R"} />}
                      </TabList>
                    </Box>

                    <TabPanel value={"S"} index={0}  sx={{py: '2rem', px: '0.5rem'}}>
                      <Box sx={{ display: 'flex' }}>
                        <Info sx={{ color: c.primary.main, fontSize: '20px' }} />
                        <Typography sx={{ ml: '5px', fontSize: '14px' }}>
                          Ricerca l’utente salvato per codice cliente.
                        </Typography>
                      </Box>

                      <RicercaUtente selectedUser={selectedUser} setSelectedUser={setSelectedUser}
                        resultMessage={'Risultati della ricerca. Seleziona un utente e clicca su "Conferma"'} />
                    </TabPanel>
                    <TabPanel value={"M"} index={1}  sx={{py: '2rem', px: '0.5rem'}}>
                      <Box sx={{ display: 'flex', mb: 2 }}>
                        <Info sx={{ color: c.primary.main, fontSize: '20px' }} />
                        <Typography sx={{ ml: '5px', fontSize: '14px' }}>
                          Inserisci i dati personali del nuovo cliente per un avviare un nuovo programma
                        </Typography>
                      </Box>

                      <InserisciUtente isRegistrazione={false} datiUtentePrivatoManuale={datiUtentePrivatoManuale} setDatiUtentePrivatoManuale={setDatiUtentePrivatoManuale} />
                    </TabPanel>
                    <TabPanel value={"R"} index={1}  sx={{py: '2rem', px: '0.5rem'}}>
                      <Box sx={{ display: 'flex', mb: 2, alignItems:'center' }}>
                        <Info sx={{ color: c.primary.main, fontSize: '20px' }} />
                        <Typography sx={{ ml: '5px', fontSize: '14px' }}>
                          Registra il tuo cliente se non è già registrato per trasferire unità audio o programmi.<br/>
                          Si ricorda che il professionista è obbligato, prima di registrare un nuovo utente, ad informare l’interessato sul trattamento dei dati personali.
                        </Typography>
                      </Box>

                      <InserisciUtente isRegistrazione={true} datiUtentePrivatoManuale={datiUtentePrivatoManuale} setDatiUtentePrivatoManuale={setDatiUtentePrivatoManuale} />
                    </TabPanel>
                  </TabContext>
                </>
              )}
            </>
          )}


          {error !== "" && (
            <Typography style={{ color: c.text.error, whiteSpace: "pre-line" }} >
              {error}
            </Typography>
          )}


        </DialogContent>


        <DialogActions sx={{ justifyContent: 'space-between' }}>

          <Typography sx={{ mb: 0, mt: 1, fontWeight: "bold", fontSize: "0.6rem", color: 'gray', flex: 1 }}>
            {
              !isInitialLoading && !initialLoadingError ? '':'' //'Cliccando su conferma, accetti i termini e le condizioni del servizio.' : ''
            }

          </Typography>
          <Box sx={{flex: 1, display:'flex', justifyContent:'end'}}>
            <Button onClick={() => onClose()} style={{ color: "black" }}>
              Annulla
            </Button>
            {initialLoadingError === "" && (
              <Button variant="contained" onClick={() => onConfirm()} sx={{ ml: 2 }}>
                Conferma
              </Button>
            )}
          </Box>

        </DialogActions>
      </Dialog>
    </>
  );
}
