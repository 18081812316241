const getAPIErrorMessage = (error) => {
  if (error.code === "ERR_NETWORK") {
    return "Errore di connessione. Controlla la tua connessione e riprova";
  }
  if (error.response?.status >= 500) {
    return "Errore del server. Riprova più tardi";
  }

  if (error.config.responseType === "arraybuffer") {
    return JSON.parse(String.fromCharCode.apply(null, new Uint8Array(error.response.data))).message;
  }

  var message = error.response.data.message;

  if (Array.isArray(message)) {
    var newMessage = "";
    for (const m of message) {
      newMessage += "Il campo " + m + "\n";
    }
    message = newMessage;
  }
  return message;
};

const formatDate = (inputDateString) => {
  const dateObject = new Date(inputDateString);
  const day = dateObject.getDate();
  const month = dateObject.getMonth() + 1; // Mese inizia da 0, quindi aggiungi 1
  const year = dateObject.getFullYear();
  // Aggiungi zeri se necessario
  const formattedDay = day < 10 ? `0${day}` : day;
  const formattedMonth = month < 10 ? `0${month}` : month;
  // Formatta la data nel formato desiderato
  const formattedDate = `${formattedDay}/${formattedMonth}/${year}`;
  return formattedDate;
};

export { getAPIErrorMessage, formatDate };
