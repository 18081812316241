import * as React from "react";
import { Box, Dialog, DialogTitle, DialogContent, DialogActions, FormControl, Typography, IconButton, Grid, CircularProgress, Button, TextField } from "@mui/material";
import { Add, CloudUploadOutlined, Download, Publish } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { getListaConfigurazioniExcel } from "../../dashboardAdminSlice";
import { creaConfigurazioneExcel, pubblicaExcel, scaricaConfigurazioneExcel } from "../../dashboardAdminAPI";
import { formatDate, getAPIErrorMessage } from "../../../../common/utils";
import LocalPaginateTable from "../LocalPaginateTable";

export function ConfigurazioniExcel() {
  const dispatch = useDispatch();

  const { listaConfigurazioniExcel, isLoading } = useSelector((state) => state.dashboardAdmin);
  const tokenAdmin = useSelector((state) => state.loginadmin.tokenAdmin);

  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [isSaving, setIsSaving] = React.useState(false);
  const [dialogError, setDialogError] = React.useState("");
  const [errAction, setErrAction] = React.useState("");

  React.useEffect(() => {
    dispatch(getListaConfigurazioniExcel());
  }, []);

  const saveConfigurazione = async () => {
    if (selectedFile == null) {
      setDialogError("Devi selezionare un file");
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);

    setIsSaving(true);

    try {
      await creaConfigurazioneExcel(formData, tokenAdmin);
    } catch (error) {
      setDialogError(getAPIErrorMessage(error));
      return;
    } finally {
      setIsSaving(false);
    }

    dispatch(getListaConfigurazioniExcel());
    setIsDialogOpen(false);
  };

  const openDialog = () => {
    setDialogError("");
    setSelectedFile(null);
    setIsDialogOpen(true);
  };

  const scaricaExcel = async (configurazione) => {
    try {
      var data = await scaricaConfigurazioneExcel(configurazione.id, tokenAdmin);

      const url = window.URL.createObjectURL(new Blob([data], { type: "application/vnd.ms-excel" }));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "ConfigurazioneExcel.xlsx"); // configurazione.nome_file
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      setErrAction(getAPIErrorMessage(e));
      return;
    }
  };

  const publish = async (configurazione) => {
    try {
      await pubblicaExcel(configurazione.id, tokenAdmin);
    } catch (error) {
      setErrAction(getAPIErrorMessage(error));
      return;
    }

    dispatch(getListaConfigurazioniExcel());
  };

  const onFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  return (
    <>
      <Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button variant="contained" endIcon={<Add />} onClick={openDialog}>
            Aggiungi Configurazione
          </Button>
        </Box>
        <LocalPaginateTable
          scope="Configurazioni Excel"
          isLoading={isLoading}
          cols={[
            { id: "nome_file", label: "Nome File" },
            { id: "versione", label: "Versione" },
            { id: "data_caricamento", label: "Data Creazione", format: (val, conf) => formatDate(val) || "-" },
            {
              id: "bozza",
              format: (val, conf) =>
                !!val ? (
                  <Button variant="contained" startIcon={<Publish />} onClick={() => publish(conf)}>
                    Pubblica
                  </Button>
                ) : (
                  "Pubblicato "
                ),
            },
            {
              id: "azioni",
              label: "Scarica",
              format: (val, conf) => (
                <IconButton onClick={() => scaricaExcel(conf)}>
                  <Download />
                </IconButton>
              ),
            },
          ]}
          list={listaConfigurazioniExcel}
          filterCols={["nome_file", "versione", "data_caricamento"]}
          stOrder="version"
        />
      </Box>

      <Dialog open={!!errAction} maxWidth="sm" fullWidth>
        <DialogTitle variant="dialogTitle" color="error">
          Attenzione
        </DialogTitle>
        <DialogContent>
          <Typography color="error" style={{ whiteSpace: "pre-line" }}>
            {errAction}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => setErrAction("")}>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog per l'edit */}
      <Dialog open={isDialogOpen} maxWidth="sm" fullWidth>
        <DialogTitle variant="dialogTitle">Aggiungi Configurazione Excel</DialogTitle>
        {isSaving && (
          <Box sx={{ bgcolor: "rgba(255,255,255,.6)", zIndex: 4, height: "100%", width: "100%", position: "absolute", display: "flex", alignItems: "center", justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        )}
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                {!!selectedFile && <TextField InputProps={{ readOnly: true }} value={selectedFile.name} label="Nome File" />}
                <Button component="label" variant="contained" sx={{ my: 2 }} startIcon={<CloudUploadOutlined />}>
                  Scegli un file excel
                  <input type="file" onChange={onFileChange} style={{ display: "none" }} accept="application/vnd.ms-excel, application/msexcel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
                </Button>
              </FormControl>
            </Grid>
          </Grid>

          <Typography color="error" style={{ whiteSpace: "pre-line" }}>
            {dialogError}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDialogOpen(false)} sx={{ color: "#000" }}>
            Annulla
          </Button>
          <Button variant="contained" onClick={() => saveConfigurazione()}>
            SALVA
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
