import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";
import { AccessAlarm, CheckCircle, Info } from "@mui/icons-material";

export function InfoPrestazione({ prestazione = null }) {
  const theme = useTheme();
  const c = theme.palette;

  return (
    <>
      <Box sx={{ mb: 4 }}>
        <Typography style={{ color: c.primary.main, fontSize: "1.5rem", fontWeight: "bold" }} sx={{ mb: 3 }}>
          Programma {prestazione?.programma?.nome.toUpperCase()}
        </Typography>
        {prestazione?.id_abbonamento == null && (
          <>
            <Typography style={{ display: "inline-block", color: c.text.primary, fontSize: "14px" }}>Hai selezionato il codice</Typography>
            <Typography style={{ display: "inline-block", color: "white", fontSize: "0.8rem", backgroundColor: c.primary.main, padding: "4px 8px 4px 8px", fontWeight: "bold", borderRadius: "5px", marginLeft: "10px" }}>{prestazione?.codice_servizio}</Typography>
          </>
        )}
        {prestazione?.id_abbonamento != null &&
          <Box sx={{ display: 'flex' }}>
            <Info sx={{ color: c.primary.main, fontSize: '20px', mr: '5px' }} />
            <Typography style={{ color: c.text.primary, fontSize: "16px" }}>
              Utilizzo dell'abbonamento attivo per questo programma
            </Typography>
          </Box>
        }
      </Box>

      <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>Descrizione programma:</Typography>
      <Typography sx={{ mb: 4 }}>{prestazione?.programma?.descrizione}</Typography>

      <Box sx={{ mb: 4, display: "column", alignItems: "center" }}>
        <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>Per effettuare il programma ti serviranno:</Typography>
        <Box sx={{ mb: 4, display: "flex", alignItems: "center" }}>
          <AccessAlarm sx={{ color: c.primary.dark, height: "20px", width: "20px" }} />
          <Typography sx={{ ml: 1, fontSize: "14px", color: c.primary.dark, fontWeight: "bold" }}>{prestazione?.programma?.durata} minuti</Typography>
        </Box>
      </Box>

      <Typography sx={{ mb: 1, fontWeight: "bold", fontSize: "14px" }}>Cosa prevede:</Typography>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={12} md={4} lg={3} sx={{ verticalAlign: "middle" }} alignItems="center">
          <CheckCircle sx={{ color: c.primary.dark, verticalAlign: "bottom", mr: 1, height: "20px", width: "20px" }} />
          <Typography sx={{ display: "inline-block", fontSize: "14px" }}>Breve intervista:</Typography>
        </Grid>
        <Grid item xs={12} md={8} lg={9}>
          <Typography sx={{ display: "inline-block", fontSize: "14px" }}>
            Per poter elaborare il tuo programma è necessario conoscerci. Tommy, il tuo expert assistant, avrà bisogno di alcune informazioni. Dedica qualche minuto per fornire i dati necessari. Sarai guidato durante tutto il processo.
          </Typography>
        </Grid>
      </Grid>
      {prestazione?.programma?.flg_genius_machine ? (
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={12} md={4} lg={3} sx={{ verticalAlign: "middle" }} alignItems="center">
            <CheckCircle sx={{ color: c.primary.dark, verticalAlign: "bottom", mr: 1, height: "20px", width: "20px" }} />
            <Typography sx={{ display: "inline-block", fontSize: "14px" }}>Genius Machine:</Typography>
          </Grid>
          <Grid item xs={12} md={8} lg={9}>
            <Typography sx={{ display: "inline-block", fontSize: "14px" }}>
              Alcuni programmi richiedono dati provenienti dalla Genius Machine (nelle sue varie versioni). Quando ti verrà richiesto, collega il pc alla Genius Machine tramite una porta USB, apri l'app Genius TSF Expert e dopo il riferimento ambientale, effettua la rilevazione test.
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
    </>
  );
}
