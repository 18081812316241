import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../config/axiosConfig';

export const getProgrammi = createAsyncThunk(
    'programmi/getProgrammi',
    async (_, thunkAPI) => {
        try {
            const state = thunkAPI.getState();
            const token = state.login.token;
            const config = {
                headers: {
                    Authorization: "Bearer " + token,
                },
            }
            const response = await api.get('/api/programmi', config);
            return { listaProgrammi: response.data.data };
        } catch (error) {
            throw error.response.data;
        }
    }
);

const initialState = {
    listaProgrammi: [],
    isLoadingProgrammi: false,
    prestazioneAttiva: null
};

export const prestazioniSlice = createSlice({
    name: 'prestazioni',
    initialState,
    reducers: {
        setPrestazioneAttiva: (state, action) => {
            state.prestazioneAttiva = { ...action.payload };
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getProgrammi.pending, (state, action) => {
                state.isLoadingProgrammi = true;
            })
            .addCase(getProgrammi.fulfilled, (state, action) => {
                state.listaProgrammi = action.payload.listaProgrammi;
                state.isLoadingProgrammi = false;
            })
            .addCase(getProgrammi.rejected, (state, action) => {
                state.isLoadingProgrammi = false;
            })
    },
});

export const {
    setPrestazioneAttiva
} = prestazioniSlice.actions;

export default prestazioniSlice.reducer;