import React, { useState } from "react";
import { cercaUtentePrivato, cercaUtenteProfessionista } from "./prestazioneAPI";
import { useSelector } from "react-redux";
import { getAPIErrorMessage } from "../../common/utils";
import { TextField, Box, Grid, Button, Typography, Paper, TableContainer, Table, TableBody, TableRow, TableCell, Checkbox, TableHead } from "@mui/material";
import { useTheme } from "@emotion/react";
import { Search } from "@mui/icons-material";
import { styled } from "@mui/material/styles";

export function RicercaUtente({ selectedUser, setSelectedUser, resultMessage }) {
  const theme = useTheme();
  const c = theme.palette;

  const { token, user } = useSelector((state) => state.login);
  const [error, setError] = React.useState("");
  const [searchUtente, setSearchUtente] = useState("");
  const [risultatiRicerca, setRisultatiRicerca] = useState(null);

  const isUserSelected = (data) => {
    return selectedUser?.id === data.id;
  };

  const handleUserSelectionClick = (event, data) => {
    if (isUserSelected(data)) {
      setSelectedUser(null);
    } else {
      setSelectedUser(data);
    }
  };


  const StyledTable = styled(Table)(({ theme }) => ({
    "& .MuiTableCell-root": {
      padding: "6px 0px 6px 0px" // <-- arbitrary value
    },
  }));

  const onSearchUser = async () => {
    if (searchUtente === "") {
      setError("Inserire un valore da cercare");
      return;
    }

    let response;
    setError("");
    setSelectedUser(null);
    try {
      if (user.tipologia == 'PA') {
        response = await cercaUtentePrivato(searchUtente, token);
      } else {
        response = await cercaUtenteProfessionista(searchUtente, token);
      }
      setRisultatiRicerca(response.data);
    } catch (err) {
      setError(getAPIErrorMessage(err));
    }
  };



  return (
    <>
      <Grid container spacing={2} sx={{ mb: 4, mt: 0 }}>
        <Grid item xs={12} sm={8} md={8}>
          <TextField
            size="small"
            value={searchUtente}
            fullWidth
            onChange={(event, newValue) => {
              setSearchUtente(event.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                onSearchUser();
              }
            }}
            InputLabelProps={{ sx: { color: c.primary.background } }}
            label="Cerca per codice utente"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={4} md={4} sx={{ margin: "auto" }}>
          <Button startIcon={<Search />} variant="contained" onClick={onSearchUser}>
            Cerca
          </Button>
        </Grid>
      </Grid>
      {risultatiRicerca && risultatiRicerca.length === 0 && <Typography sx={{ mb: 2 }}>Nessun risultato trovato</Typography>}
      {risultatiRicerca && risultatiRicerca.length > 0 && (
        <Box sx={{ width: "100%" }}>
          <Box sx={{ mb: 2 }}>{resultMessage}</Box>
          <Paper sx={{ width: "100%", mb: 2 }}>
            <TableContainer>
              <StyledTable sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox"> </TableCell>
                    {
                      user?.tipologia == 'PP' && <>
                        <TableCell align="center"> Ragione Sociale</TableCell>
                        <TableCell align="center"> Indirizzo</TableCell>
                        <TableCell align="center"> CAP</TableCell>
                        <TableCell align="center"> Città</TableCell>
                      </>
                    }
                    {
                      user?.tipologia == 'PA' && <>
                        <TableCell align="center"> Nome</TableCell>
                        <TableCell align="center"> Cogome</TableCell>
                        <TableCell align="center"> CF</TableCell>
                      </>
                    }

                    <TableCell align="center"> Codice Utente</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {risultatiRicerca.map((row, index) => {
                    const isItemSelected = isUserSelected(row);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow hover onClick={(event) => handleUserSelectionClick(event, row)} role="checkbox" aria-checked={isItemSelected} tabIndex={-1} key={row.id} selected={isItemSelected} sx={{ cursor: "pointer" }}>
                        <TableCell padding="checkbox">
                          <Checkbox color="primary" checked={isItemSelected} inputProps={{ "aria-labelledby": labelId }} />
                        </TableCell>
                        {
                          user?.tipologia == 'PP' && <>
                            <TableCell align="center">{row.ragione_sociale}</TableCell>
                            <TableCell align="center">{row.indirizzo} </TableCell>
                            <TableCell align="center">{row.cap} </TableCell>
                            <TableCell align="center">{row.citta} </TableCell>
                          </>
                        }
                        {
                          user?.tipologia == 'PA' && <>
                            <TableCell align="center">{row.nome}</TableCell>
                            <TableCell align="center">{row.cognome}</TableCell>
                            <TableCell align="center">{row.cf}</TableCell>
                          </>
                        }


                        <TableCell align="center">{row.codice_utente}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </StyledTable>
            </TableContainer>
          </Paper>
        </Box>


      )}

      {error !== "" && (
        <Typography style={{ color: c.text.error, whiteSpace: "pre-line" }} sx={{ mt: 4 }}>
          {error}
        </Typography>
      )}
    </>
  );
}
