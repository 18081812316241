import React, { useEffect } from "react";
import { Grid, Box, Typography, Button, useTheme, Paper, Table, TableBody, TableCell, TableHead, TableRow, useMediaQuery } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { getDashboardCounter, getListaAbbonamenti, getListaProgrammiAcquistati, getListaProgrammiCodici, getListaPrestazioni } from "./HomepageSlice";
import { ReactComponent as SvgHeartReferti } from "./../../assets/svg/SvgHeartReferti.svg";
import { useNavigate } from "react-router-dom";
import { DownloadForOffline } from "@mui/icons-material";
import CardProgramma from "../programmi/components/CardProgramma";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { scaricaReferto } from "../prestazione/prestazioneAPI";
import { getAPIErrorMessage } from "../../common/utils";
import { setSnackbarData } from "../programmi/programmiSlice";

export function HomepagePrivato() {
  const theme = useTheme();
  const matchSm = useMediaQuery(theme.breakpoints.up('sm'));
  const matchMd = useMediaQuery(theme.breakpoints.up('md'));
  const matchLg = useMediaQuery(theme.breakpoints.up('lg'));

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { listaProgrammiCodici, listaProgrammiAcquistati, codiciAttivi, listaPrestazioni } = useSelector((state) => state.homepage);
  const c = theme.palette;
  const { token } = useSelector((state) => state.login);
  const cardProgrammiRef = React.useRef();
  const [tableH, setTableH] = React.useState(null);

  const handleResize = () => {
    if (cardProgrammiRef.current) {
      setTableH(cardProgrammiRef.current.getBoundingClientRect().height);
    }
  };



  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [cardProgrammiRef]);

  useEffect(() => {
    setTimeout(() => {
      handleResize();
    }, 100);
  }, [codiciAttivi]);


  const onScarica = async (id) => {
    let response;
    try {
      response = await scaricaReferto(id, token);
    } catch (e) {
      dispatch(setSnackbarData({ message: getAPIErrorMessage(e), severity: "error", vertical:'bottom' }));
      return;
    }

    const url = window.URL.createObjectURL(new Blob([response], { type: "application/pdf" }));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "Elaborato.pdf");
    document.body.appendChild(link);
    link.click();
  };

  function formatDate(inputDateString) {
    const dateObject = new Date(inputDateString);

    const day = dateObject.getDate();
    const month = dateObject.getMonth() + 1; // Mese inizia da 0, quindi aggiungi 1
    const year = dateObject.getFullYear();

    // Aggiungi zeri se necessario
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;

    // Formatta la data nel formato desiderato
    const formattedDate = `${formattedDay}/${formattedMonth}/${year}`;

    return formattedDate;
  }


  function formatDateReferto(dataYMD) {
    // Dividi la data in anno, mese e giorno
    var partiData = dataYMD.split("-");

    // Crea una nuova data nel formato desiderato
    var dataConvertita = partiData[2] + "/" + partiData[1] + "/" + partiData[0];

    // Restituisci la data convertita
    return dataConvertita;
  }

  useEffect(() => {
    dispatch(getListaProgrammiCodici());
    dispatch(getListaProgrammiAcquistati());
    dispatch(getListaAbbonamenti());
    dispatch(getListaPrestazioni());
    dispatch(getDashboardCounter());
  }, []);



  const visualizzaTuttiStyle = {
    backgroundColor: c.secondary.light,
    color: c.primary.dark,
    "&:hover": {
      backgroundColor: c.secondary.main,
      color: c.primary.light,
    },
  };

  const downloadStyle = {
    backgroundColor: c.secondary.light,
    color: c.primary.dark,
    "&:hover": {
      backgroundColor: c.secondary.main,
      color: c.primary.light,
    },
  };

  const acquistaProgrammaTableUtenteStyle = {
    marginLeft: "5%",
    backgroundColor: c.secondary.light,
    color: c.primary.dark,
    "&:hover": {
      backgroundColor: c.secondary.main,
      color: c.primary.light,
    },
  };


  const headCellsUtente = [
    { id: "programma", label: "Programma" },
    { id: "codice", label: "Codice" },
    { id: "data_acquisto", label: "Data Acquisto" },
    { id: "azioni", label: "" },
  ];



  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: !matchSm || (matchMd && !matchLg) ? 1 : 2,
    slidesToScroll: 2,
  };


  var showExpander = listaProgrammiCodici.length == 0 && listaProgrammiAcquistati.length > 0;

  return (
    <Grid container sx={{ ...paginaUtenteStyle }}>
      {/* PARTE SINISTRA */}
      <Grid item md={6} xs={12}>
        <Box sx={{ ...boxPrivatoStyle, pb: '50px' }} ref={cardProgrammiRef}>
          <Typography sx={titoloStyle}>N. Programmi Attivi</Typography>
          <Typography sx={{ ...programmiAttiviStyle, mb: 3 }}>{codiciAttivi}</Typography>
          <div style={{ position: "absolute", right: 10, top: 10 }}>
            {codiciAttivi > 0 && (
              <Button variant="outlined" sx={visualizzaTuttiStyle} onClick={() => navigate("/tommy/programmi", { state: { tabTarget: "0" } })}>
                Visualizza tutti
              </Button>
            )}
          </div>

          {
            showExpander && (<Box sx={{height: '250px'}}></Box>)
          }

          <Slider arrows={false} {...settings}>{listaProgrammiCodici && listaProgrammiCodici.slice(0, 6).map((prog, index) => <CardProgramma key={index} tipoAttivazione="C" codiceServizio={prog} onClick={() => { }} />)}</Slider>
        </Box>
      </Grid>
      {/* PARTE DESTRA */}
      <Grid item md={6} xs={12}>
        <Box sx={{ ...boxPrivatoStyle, height: matchMd ? tableH : 'auto' }}>
          <Typography sx={titoloStyle}>I tuoi ultimi programmi acquistati</Typography>
          {listaProgrammiAcquistati && listaProgrammiAcquistati.length == 0 && (
            <div style={{ display: "flex", height: "300px", marginTop: "10px" }}>
              <Typography style={{ fontSize: "16px" }}>Nessun acquisto effettuato</Typography>
            </div>
          )}
          {listaProgrammiAcquistati && listaProgrammiAcquistati.length > 0 && (
            <Paper elevation={1} className="scrollDashboard" style={{ maxHeight: "95%", overflowY: "auto", marginTop: 5 }}>
              <Table size={matchMd ? 'medium' : 'small'}>
                <TableHead>
                  <TableRow>
                    {headCellsUtente.map((headCell) => (
                      <TableCell key={headCell.id} style={{ textAlign: headCell.id === "programma" ? "left" : "center", width: "auto", color: "rgb(124,124,124)", fontSize: "0.8rem" }}>
                        {headCell.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {listaProgrammiAcquistati &&
                    listaProgrammiAcquistati.map((prog, index) => (
                      <TableRow key={prog.id} style={{ backgroundColor: index % 2 === 0 ? "white" : "rgb(244,242,242)" }}>
                        <TableCell style={{ textAlign: "left", width: "auto" }}>
                          <span style={{ fontWeight: "bold" }}>{prog.programma.nome}</span>
                        </TableCell>
                        <TableCell style={{ textAlign: "center", width: "auto" }}>
                          <span style={{ fontWeight: "bold" }}>{prog.codice_servizio}</span>
                        </TableCell>
                        <TableCell style={{ textAlign: "center", width: "auto" }}>{formatDate(prog.created_at)}</TableCell>
                        <TableCell style={{ textAlign: "left", width: "auto" }}>
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <Button size={matchMd ? 'medium' : 'small'} variant="outlined" sx={acquistaProgrammaTableUtenteStyle} onClick={() => navigate("/tommy/ecommerce", { state: { categoria: prog.programma.categorie[0] } })}>
                              ACQUISTA DI NUOVO
                            </Button>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Paper>
          )}
        </Box>
      </Grid>
      {/* PARTE IN BASSO */}
      <Grid item xs={12} sx={{ mt: '2rem' }}>
        <Box sx={boxPrivatoStyle2} style={{ marginTop: '1rem' }}>
          <Box sx={{ display: "flex", alignItems: "center", marginTop: "7px", justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ marginRight: "5px" }}>
                <SvgHeartReferti />
              </div>
              <Typography sx={titoloStyle}>Elaborati recenti</Typography>
            </Box>

            <div>
              {listaPrestazioni.length > 0 && (
                <Button variant="outlined" sx={visualizzaTuttiStyle} onClick={() => navigate("/tommy/elaborati")}>
                  Visualizza tutti
                </Button>
              )}
            </div>

          </Box>

          <Grid container spacing={2} sx={{ mt: 1 }}>
            {listaPrestazioni &&
              listaPrestazioni.slice(0, 3).map((prest, index) => (
                <Grid key={index} item xs={12} sm={6} md={4}>
                  <div style={{ display: "flex", alignItems: "center", marginTop: "-10px" }}>
                    <Box style={boxRefertoStyle}>
                      <Typography style={{ fontWeight: "bold", marginTop: "-4%", fontSize: "14px" }}>{prest.programma.nome}</Typography>
                      <Typography sx={{ height: !matchSm ? 'auto' : "65px", overflow: "auto", fontSize: "14px", mt: 1 }}>{prest.programma.descrizione}</Typography>

                      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: '20px' }}>
                        <Typography>
                          <span style={{ fontWeight: "bold", fontSize: "14px" }}>Utilizzo: </span> {formatDateReferto(prest.data_prestazione.substring(0, 10))}
                        </Typography>
                        <Typography style={{ color: "#255300", fontSize: "14px" }}>
                          {prest.codice_servizio !== "Abbonamento" ? "Codice Servizio: " : ""}
                          <span style={{ fontWeight: "bold" }}>{prest.codice_servizio}</span>{" "}
                        </Typography>
                      </div>
                      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: '20px' }}>
                        <Typography style={{ fontSize: "14px" }}>
                          <span style={{ fontWeight: "bold", fontSize: "14px" }}>Elaborato del: </span> {formatDateReferto(prest.data_prestazione.substring(0, 10))}
                        </Typography>
                        <div style={{ display: "flex", flexDirection: "column", gap: "8px", width: "50%", float: "right" }}>
                          <Button variant="outlined" sx={downloadStyle} startIcon={<DownloadForOffline />} onClick={() => onScarica(prest.id)}>
                            Download
                          </Button>
                        </div>
                      </div>
                    </Box>
                  </div>
                </Grid>
              ))}
          </Grid>

          {listaPrestazioni && listaPrestazioni.length == 0 && (
            <div style={{ display: "flex", height: "200px", marginTop: "10px" }}>
              <Typography style={{ fontSize: "16px" }}>Non ci sono elaborati recenti</Typography>
            </div>
          )}
        </Box>
      </Grid>
    </Grid>
  );
}



const boxRefertoStyle = {
  marginRight: "1%",
  padding: "30px",
  backgroundColor: "white",
  width: "100%",
};

const boxPrivatoStyle = {
  marginTop: "5%",
  marginLeft: "2%",
  marginRight: "2%",
  padding: "2%",
  width: "96%",
  backgroundColor: "white",
  position: "relative",
};

const boxPrivatoStyle2 = {
  padding: "1rem",
  width: "100%",
  backgroundColor: "rgb(248, 248, 248)",
};

const titoloStyle = {
  color: "#255300",
  fontSize: "1.1rem",
  fontWeight: "bold",
};



const programmiAttiviStyle = {
  color: "black",
  fontSize: "1.7rem",
  fontWeight: "bold",
  marginTop: "1%",
};


const paginaUtenteStyle = {
  backgroundColor: "rgb(248, 248, 248)",
};
