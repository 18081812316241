import React, { useEffect, useRef } from "react";
import { CircularProgress, Backdrop, Box, Button, Typography, Stack, Dialog, DialogTitle, DialogContent, DialogActions, useMediaQuery, IconButton } from "@mui/material";
import { useTheme } from "@emotion/react";
import { getAPIErrorMessage } from "../../../common/utils";
import { completaPassaggioGeniusMachine, getPrestazioneDetails, cambiaStatoPrestazione, checkCopiaDatiGeniusMachineDaPrecedente, importaDatiGeniusMachineDaPrestazionePrecedente } from "../prestazioneAPI";
import { useDispatch, useSelector } from "react-redux";
import { ContentCopy, DoneAll } from "@mui/icons-material";
import { setSnackbarData } from "../../programmi/programmiSlice";

export function GeniusMachine({ readOnly = false, prestazione = null, onSuccess = () => { }, setPrestazione = () => { } }) {
  const theme = useTheme();
  const c = theme.palette;
  const dispatch = useDispatch();

  const { token, user } = useSelector((state) => state.login);
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [isCopied, setIsCopied] = React.useState(false);
  const currentTimer = useRef();
  const [datiPrestazionePrecedente, setDatiPrestazionePrecedente] = React.useState(null);
  const matchMd = useMediaQuery(theme.breakpoints.up('md'));

  const checkIfPuoCopiareDaPrecedente = async () => {
    try {
      const response = await checkCopiaDatiGeniusMachineDaPrecedente(prestazione?.id, token);
      if (response.data !== false) {
        setDatiPrestazionePrecedente(response.data);
      }
    } catch (e) {
      //console.log(e);
    }
  };

  const importaDatiPrecedenti = async () => {
    let response;
    try {
      //lo metto come se avessi caricato i dati
      response = await importaDatiGeniusMachineDaPrestazionePrecedente(prestazione?.id, { id_dati_genius_machine: datiPrestazionePrecedente }, token);
    } catch (e) {
      dispatch(setSnackbarData({ message: getAPIErrorMessage(e), severity: "error", vertical: 'bottom' }));
      return;
    }
    const _prestazione = response.data;
    setPrestazione(_prestazione);
    clearIntervalFuncton();
  };

  const onFinish = async () => {
    //se è gia completata e la sto riguardando vado solo avanti
    if (readOnly) {
      onSuccess();
      return;
    }

    setIsLoading(true);

    let response;
    try {
      response = await completaPassaggioGeniusMachine(prestazione?.id, {}, token);
    } catch (e) {
      setError(getAPIErrorMessage(e));
      return;
    } finally {
      setIsLoading(false);
    }

    setPrestazione(response.data);
    onSuccess();
  };

  const checkPrestazioneStatus = async () => {
    let response;
    try {
      response = await getPrestazioneDetails(prestazione?.id, token);
    } catch (e) {
      return;
    }
    const _prestazione = response.data;
    if (_prestazione.stato === 30) {
      setPrestazione(_prestazione);
      clearIntervalFuncton();
    }
  };

  const ascoltaPerDatiGeniusMachine = async () => {
    let response;
    try {
      //lo metto come se avessi finito il questionario
      response = await cambiaStatoPrestazione(prestazione?.id, { stato: 20 }, token);
    } catch (e) {
      return;
    }
    const _prestazione = response.data;
    setPrestazione(_prestazione);
    setIntervalFunction();
  };

  const skipAscoltoDatiGeniusMachine = async () => {
    let response;
    try {
      //lo metto come se avessi caricato i dati
      response = await cambiaStatoPrestazione(prestazione?.id, { stato: 30 }, token);
    } catch (e) {
      return;
    }
    const _prestazione = response.data;
    setPrestazione(_prestazione);
    clearIntervalFuncton();
  };

  const setIntervalFunction = () => {
    clearIntervalFuncton();
    currentTimer.current = setInterval(checkPrestazioneStatus, 2500);
  };

  const clearIntervalFuncton = () => {
    if (currentTimer != null && currentTimer.current != null) {
      clearInterval(currentTimer.current);
    }
  };

  useEffect(() => {
    //inizializzo le risposte se rientra
    if (prestazione.stato === 20) {
      setIntervalFunction();
      checkIfPuoCopiareDaPrecedente();
    }

    return () => {
      clearIntervalFuncton();
    };
  }, []);

  const onCopy = () => {
    navigator.clipboard.writeText(user.codice_utente);
    setIsCopied(true);
    if (!matchMd) {
      dispatch(setSnackbarData({ message: "Codice utente copiato", severity: "success", vertical: 'bottom' }));
    }
  };

  return (
    <Stack spacing={4}>
      {isLoading && (
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}

      <Typography style={{ color: c.primary.main, fontSize: "1.6rem", fontWeight: "bold" }} sx={{}}>
        Genius Machine
      </Typography>
      <Typography sx={{ fontSize: '1.2rem' }}>Per questo programma è previsto l'utilizzo della Genius Machine</Typography>
      <Stack spacing={1} sx={{ pl: 2 }}>
        <Typography sx={{ height: "20px", fontSize: '1.1rem' }} variant="div">
          Segui i seguenti passaggi per avviare il test:<br />
        </Typography>
        <Stack spacing={1} sx={{ pl: 2, pt: 2 }}>
          <Box>
            <Typography sx={{ height: "20px", fontSize: '0.9rem' }} variant="div">
              1. Collega la Genius Machine al computer
            </Typography>

          </Box>
          <Box>

            <Typography sx={{ height: "20px", fontSize: '0.9rem' }} variant="div">
              2. Non hai il programma sul tuo computer?
            </Typography>
          </Box>
          <Box sx={{ pl: 3 }}>
            <Typography sx={{ height: "20px", fontSize: '0.9rem' }} variant="div">
              Se possiedi il sensore Genius TSF, scarica <a href={navigator.userAgent.includes('Windows') ? 'ms-windows-store://pdp/?ProductId=9MVXV9H2W8B0' : 'https://apps.microsoft.com/detail/9MVXV9H2W8B0'} target="_blank" rel="noreferrer">Genius TSF Expert</a>.
            </Typography>
            <br />
            <Typography sx={{ height: "20px", fontSize: '0.9rem', mt: '5px' }} variant="div">
              Se invece possiedi il sensore Genius MT, scarica <a href={navigator.userAgent.includes('Windows') ? 'ms-windows-store://pdp/?ProductId=9NRM4312TVKC' : 'https://apps.microsoft.com/detail/9NRM4312TVKC'} target="_blank" rel="noreferrer">Genius MT Expert</a>.
            </Typography>
          </Box>
          <Box>
            <Typography sx={{ height: "20px", fontSize: '0.9rem' }} variant="div">
              3. Avvia il programma
            </Typography>
          </Box>
          <Box>
            <Typography sx={{ height: "20px", fontSize: '0.9rem' }} variant="div">
              4. Apri la pagina "Impostazioni" e clicca sul pulsante "Stato e Test sensore"
            </Typography>
          </Box>
          <Box>
            <Typography sx={{ height: "20px", fontSize: '0.9rem' }} variant="div" >
              5. Se il sensore è collegato correttamente, dare "OK" al messaggio di conferma e torna alla pagina "Home"
            </Typography>
          </Box>
          <Box direction="row" alignItems="center">
            <Typography sx={{ height: "20px", fontSize: '0.9rem' }} variant="div">
              6. Inserisci il seguente Codice Utente nel campo apposito:{" "}
            </Typography>
            <b style={{ fontSize: "1.2rem", marginInline: "8px" }}>{user?.codice_utente}</b>
            {
              matchMd ? <Button variant="contained" size="small" sx={{ mx: 1 }} onClick={onCopy} startIcon={<ContentCopy />}>
                Copia
              </Button> : <IconButton size="small" onClick={onCopy} sx={{ mx: 1, color: c.primary.main }}><ContentCopy /></IconButton>
            }

            {matchMd && isCopied && (
              <>
                <DoneAll color="primary" sx={{ ml: 2 }} />
                <Typography sx={{ ml: 1, color: c.primary.main }} variant="div">
                  Copiato!
                </Typography>
              </>
            )}
          </Box>
          <Box>
            <Typography sx={{ height: "20px", fontSize: '0.9rem' }} variant="div">
              7. Premi il pulsante verde "Riferimento"
            </Typography>
          </Box>
          <Box>
            <Typography sx={{ height: "20px", fontSize: '0.9rem' }} variant="div">
              8. Premi il pulsante "Avvia"
            </Typography>
          </Box>
        </Stack>
      </Stack>
      <Typography variant="div" sx={{ fontSize: '1rem' }}>Una volta completato il test verrà mostrato un messaggio di conferma sia sul programma Genius MT Expert che su questa schermata.</Typography>

      {prestazione.stato === 20 && (
        <Stack sx={{ pt: 4 }}>
          <Stack direction="row" alignItems="center">
            <Typography variant="s" color="primary.dark" sx={{ fontSize: '1.5rem' }}>
              In attesa di ricevere i dati
            </Typography>
            <CircularProgress sx={{ color: "primary.dark", ml: 2 }} size={'2rem'} />
          </Stack>
          {!readOnly && prestazione.dati_genius_machine != null && (
            <Box sx={{ mt: 4 }}>
              <Typography sx={{ mb: 2, color: c.primary.main }} variant="h5">
                Attenzione
              </Typography>
              <Typography sx={{ mb: 2 }} variant="div">
                Sono gia stati caricati in precedenza dei dati. Se non si vuole ri-eseguire il test Genius Machien clicca il pulsante qui sotto per proseguire.
              </Typography>
              <Button variant="contained" onClick={skipAscoltoDatiGeniusMachine}>
                Continua
              </Button>
            </Box>
          )}
          {!readOnly && prestazione.dati_genius_machine == null && datiPrestazionePrecedente && (
            <Box sx={{ display: "flex", alignItems: "end", flexDirection: 'column', mb: 2, mt: 2 }}>
              <Box sx={{ display: "flex", alignItems: "end", flexDirection: 'column' }}>
                <Typography sx={{ color: c.secondary.main, fontWeight: "bold", fontSize: '12px' }}>{user?.tipologia == 'PA' ? 'L\'utente ha gia svolto di recente il test con la Genius Machine?' : 'Hai gia svolto di recente il test con la Genius Machine?'}<br /> Importa i precedenti risultati senza ri-eseguire il test.</Typography>
                <Button variant="contained" size="small" onClick={importaDatiPrecedenti} sx={{ mt: 1, mr: 1 }}>
                  Importa
                </Button>
              </Box>
            </Box>
          )}
        </Stack>
      )}

      {prestazione.stato >= 30 && (
        <Stack sx={{ pt: 4 }}>
          <Stack direction="row" alignItems="center">
            <DoneAll sx={{ color: "primary.dark", mr: 1 }} />
            <Typography variant="s" color="primary.dark" sx={{ fontSize: '1.5rem' }}>
              Dati ricevuti correttamente!
            </Typography>
          </Stack>

          <Stack sx={{ flexDirection: "row-reverse", justifyContent: "space-between", width: "100%", mt: 4 }}>
            <Box>
              <Button variant="contained" onClick={onFinish}>
                {readOnly ? "Step successivo" : matchMd?  "Conferma e passa allo Step Successivo" : "Conferma e prosegui"}
              </Button>
            </Box>
            <Box>
              {!readOnly && (
                <Button variant="outlined" onClick={ascoltaPerDatiGeniusMachine}>
                  Ripeti Test
                </Button>
              )}
            </Box>
          </Stack>
        </Stack>
      )}

      <Dialog open={!!error} onClose={() => setError("")} maxWidth="sm" fullWidth>
        <DialogTitle variant="dialogTitle" color="error">
          Attenzione
        </DialogTitle>
        <DialogContent>
          <Typography color="error" style={{ whiteSpace: "pre-line" }}>
            {error}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => setError("")}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
}
