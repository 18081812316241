export default function SvgProgramma({ fill }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="29.311" height="33.817" viewBox="0 0 29.311 33.817">
      <g id="noun-leaves-1018751_1_" data-name="noun-leaves-1018751 (1)" transform="translate(-10.6 -5)">
        <path
          id="Tracciato_5488"
          data-name="Tracciato 5488"
          d="M25.274,5a7.852,7.852,0,0,1,0,13.073A7.852,7.852,0,0,1,25.274,5ZM14.511,19.749a7.82,7.82,0,0,0,7.411.223,7.715,7.715,0,0,0-3.911-6.294,7.927,7.927,0,0,0-7.411-.223A7.89,7.89,0,0,0,14.511,19.749Zm3.538,10.391a7.855,7.855,0,0,0,3.911-6.294,7.719,7.719,0,0,0-7.411.223,7.855,7.855,0,0,0-3.911,6.294A7.822,7.822,0,0,0,18.049,30.139ZM28.775,32.3a7.842,7.842,0,0,0-3.5-6.518,7.817,7.817,0,0,0,0,13.035A7.8,7.8,0,0,0,28.775,32.3ZM36,24.069a7.82,7.82,0,0,0-7.411-.223A7.715,7.715,0,0,0,32.5,30.139a7.82,7.82,0,0,0,7.411.223A7.715,7.715,0,0,0,36,24.069ZM32.5,13.678a7.945,7.945,0,0,0-3.911,6.294A7.719,7.719,0,0,0,36,19.749a7.855,7.855,0,0,0,3.911-6.294A7.822,7.822,0,0,0,32.5,13.678Z"
          fill={fill || "#fff"}
        />
      </g>
    </svg>
  );
}
