import { useTheme } from "@emotion/react";
import { Backdrop, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper, Stack, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getOrdine } from "./ordineAPI";
import { useDispatch, useSelector } from "react-redux";
import { getAPIErrorMessage } from "../../common/utils";
import { AccessTime, CheckCircle, Info } from "@mui/icons-material";
import DialogInfoProgramma from "../../common/DialogInfoProgramma";
import { resetTecnica, setSelectedUserTecniche } from "../abs/absSlice";
import { getCarrello, setInfoTecnicaABS } from "../ecommerce/EcommerceSlice";
import { Footer } from "../homepage/Footer";
import { CONFIG } from "../../config/envConfig";

export function OrdineEsito() {
  const navigate = useNavigate();
  const screenRef = React.useRef();
  const riepilogoRef = React.useRef();
  const dispatch = useDispatch();
  const theme = useTheme();
  const [searchParams, setSearchParams] = useSearchParams();
  const token = useSelector((state) => state.login.token);
  const currentTimer = useRef();
  const [error, setError] = React.useState(null);
  const [ordine, setOrdine] = React.useState(null);
  const [screenH, setScreenH] = React.useState(0);
  const [riepilogoH, setRiepilogoH] = React.useState(0);
  const [infoProgramma, setInfoProgramma] = React.useState(false);
  const matchLg = useMediaQuery(theme.breakpoints.up("lg"));
  const matchSm = useMediaQuery(theme.breakpoints.up("sm"));

  const abb_types = { SETT: "Settimanale", MENS: "Mensile", TRIM: "Trimestrale", SEM: "Semestrale", ANNUAL: "Annuale", VITA: "A Vita" };

  useEffect(() => {
    setIntervalFunction();
    checkOrdineStatus();

    return () => {
      clearIntervalFuncton();
    };
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [screenRef, riepilogoRef]);

  const handleResize = () => {
    if (screenRef.current) {
      setScreenH(window.innerHeight - screenRef.current.getBoundingClientRect().top - CONFIG.FOOTER_HEIGHT);
    }
    if (riepilogoRef.current) {
      setRiepilogoH(window.innerHeight - riepilogoRef.current?.getBoundingClientRect().top);
    }
  };

  const checkOrdineStatus = async () => {
    setError(null);
    let response;
    try {
      response = await getOrdine(searchParams.get("id_ordine"), token);
    } catch (e) {
      setError(getAPIErrorMessage(e));
      clearIntervalFuncton();
      return;
    }
    const _ordine = response.data;
    setOrdine(_ordine);

    if (_ordine.pagamento_saferpay_processato == true) {
      //svuoto abs
      dispatch(getCarrello());
      dispatch(resetTecnica(null));
      dispatch(setSelectedUserTecniche(null));

      clearIntervalFuncton();
    } else {
      //continuo a chiamare
    }

    setTimeout(() => {
      handleResize();
    }, 100);
  };

  const setIntervalFunction = () => {
    clearIntervalFuncton();
    currentTimer.current = setInterval(checkOrdineStatus, 2500);
  };

  const clearIntervalFuncton = () => {
    if (currentTimer != null && currentTimer.current != null) {
      clearInterval(currentTimer.current);
    }
  };

  const concluso = ordine?.pagamento_saferpay_processato;
  const pagato = ordine?.stato == "PAGATO";

  const ordineContainsProgrammi = ordine?.voci?.some((voce) => voce.tipo_voce === "PROG");
  const ordineContainsTecnicaOrAudio = ordine?.voci?.some((voce) => voce.tipo_voce === "TEC" || voce.tipo_voce === "AUD");

  const boxHeight = matchSm ? '180px' : 'auto';
  const showDetails = matchSm;
  let border = {};
  if (matchSm) {
    border = { border: "1px solid #F7F2F2" };
  } else {
    border = { borderTop: "1px solid #bbbbbb" };
  }

  return (
    <>
      <Box height={screenH}>
        {
          error == null ?
            <Box sx={{ mt: '16px' }}>
              {(ordine?.pagamento_saferpay_processato != true) && (
                <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
                  <CircularProgress color="inherit" />
                </Backdrop>
              )}
              <Grid container spacing={2} ref={screenRef} height={screenH} justifyContent={'center'} sx={{ overflowY: matchLg ? 'hidden' : 'auto', p: 1 }}>
                <Grid item xs={12} sm={8} md={5} lg={5}>
                  <Paper elevation={3} sx={{ p: 2, textAlign: "center", width: "100%", py: 5, mt: window.innerWidth >= theme.breakpoints.values.lg ? 5 : 0 }}>
                    <Stack spacing={3}>
                      <Typography variant="dialogTitle">{concluso ? (pagato ? "Ordine concluso" : "Ordine annullato") : "Elaborazione Ordine"}</Typography>
                      <Typography variant="title2">{!concluso ? "Attesa conferma Pagamento" : ordine?.stato == "PAGATO" ? "Pagamento Confermato" : ordine?.stato == "FALLITO" ? "Pagamento Fallito" : ordine?.stato == "ANNULLATO" ? "Pagamento Annullato" : ""}</Typography>
                      {pagato ? (
                        <Typography>
                          Totale: <span style={{ color: "#255300", fontWeight: "bold" }}>{Intl.NumberFormat('it-IT', { style: 'currency', currency: 'eur' }).format(ordine?.totale_finale)}</span>
                        </Typography>
                      ) : (
                        <> </>
                      )}

                      <Typography>
                        Numero Ordine: <span style={{ color: "#255300", fontWeight: "bold" }}>{ordine?.numero_ordine}</span>
                      </Typography>
                      {pagato ?
                        <Box>
                          {
                            ordineContainsTecnicaOrAudio && ordineContainsProgrammi ?
                              <Typography>Potrai vedere i tuoi acquisti nella pagina "I miei programmi" e "La mia libreria" di Audio Brain Stimulation</Typography>
                              : ordineContainsTecnicaOrAudio && !ordineContainsProgrammi ?
                                <Typography>Potrai vedere i tuoi acquisti nella schermata "La mia libreria" della pagina Audio Brain Stimulation</Typography>
                                : ordineContainsProgrammi && !ordineContainsTecnicaOrAudio ?
                                  <Typography>Potrai vedere i programmi acquistati nella pagina "I miei programmi"</Typography>
                                  : <> </>
                          }
                        </Box>

                        : <> </>
                      }
                      <div>
                        <Button variant="contained" onClick={() => navigate("/tommy/homepage")}>
                          Torna alla Home
                        </Button>
                      </div>
                    </Stack>
                  </Paper>
                </Grid>
                {pagato ? (
                  <Grid item xs={12} lg={7}>
                    <Box height={40}>
                      <Typography variant="dialogTitle">Riepilogo ordine:</Typography>
                    </Box>
                    <Box ref={riepilogoRef} style={{ overflowY: matchLg ? "auto" : 'hidden', height: matchLg ? riepilogoH : 'auto' }}>
                      {ordine?.voci.map((item) =>
                      (<>
                        {
                          item.tipo_voce == 'TEC' &&
                          <Stack key={item.id} sx={{ flexDirection: "row", height: "100px", mb: 2 }}>
                            <Stack spacing={2} sx={{ flex: 2, p: 2, pr: 6, bgcolor: item.tipo_acquisto === "ABB" ? "#edf0dc" : "#f0f0f0", overflow: "hidden", border: "1px solid #F7F2F2" }}>
                              <Typography sx={{ fontWeight: "bold" }}>Tecnica ABS</Typography>
                              <Box style={{ marginTop: '10px' }} >
                                <Button startIcon={<Info />} onClick={() => dispatch(setInfoTecnicaABS({ ...item.tecnica_abs, showFrasi: false }))} size="small" variant="outlined" fontSize="small">
                                  Dettagli
                                </Button>
                              </Box>
                            </Stack>
                            <Stack spacing={2} sx={{ flex: 1, p: 2, border: "1px solid #F7F2F2" }}>
                              <Typography sx={{ mb: 1, ml: 1, fontSize: "14px" }}>
                                Totale<span style={{ float: "right", color: "#255300", fontWeight: "bold" }}>{Intl.NumberFormat('it-IT', { style: 'currency', currency: 'eur' }).format(item.prezzo_totale_voce)}</span>
                              </Typography>
                            </Stack>
                          </Stack>
                        }
                        {
                          item.tipo_voce == 'PROG' &&

                          <Grid container key={item.id} sx={{ height: boxHeight, mb: 2 }}>
                            <Grid item xs={12} sm={4} spacing={2} sx={{ display: 'flex', height: boxHeight, flexDirection: 'column', p: 2, bgcolor: item.tipo_acquisto === "ABB" ? "#edf0dc" : "#f0f0f0", overflow: "hidden", border: "1px solid #F7F2F2" }}>
                              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography sx={{ fontWeight: 'bold' }} component="div">
                                  {item.programma.nome}
                                </Typography>
                                {
                                  !showDetails ?
                                    <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                                      <Info sx={{ color: "#255300", cursor: "pointer", alignSelf: "flex-end" }} onClick={() => setInfoProgramma(item.programma.id)} />
                                    </Box> : <></>
                                }
                              </Box>
                              <Typography sx={{ color: "text.secondary", textOverflow: "ellipsis", overflowY: 'auto', flex: 1 }}>{item.programma.descrizione}</Typography>
                            </Grid>

                            {
                              showDetails ?
                                <Grid item xs={12} sm={4} sx={{ display: 'flex', height: boxHeight, flexDirection: 'column', p: 2, bgcolor: item.tipo_acquisto === "ABB" ? "#edf0dc" : "#f0f0f0", border: "1px solid #F7F2F2", justifyContent: "space-between" }}>
                                  <Box>
                                    <Stack spacing={1}>
                                      <Stack sx={{ flexDirection: "row", justifyContent: "space-between" }}>
                                        <Typography style={{ fontWeight: "bold", fontSize: "14px" }}>Cosa prevede: </Typography>
                                        <Stack direction="row">
                                          <AccessTime style={{ color: "#255300", marginRight: "5px", fontSize: '1rem' }} />
                                          <Typography sx={{ color: "#255300", fontSize: "0.9rem", fontWeight: "bold" }}>{item.programma.durata} min</Typography>
                                        </Stack>
                                      </Stack>

                                    </Stack>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                      <CheckCircle sx={{ color: "#255300", fontSize: '1.25rem' }} />
                                      <Typography style={{ marginLeft: 4, fontSize: "14px" }}> Breve intervista </Typography>
                                    </div>
                                    {item.programma.flg_genius_machine ? (
                                      <div style={{ display: "flex", alignItems: "center" }}>
                                        <CheckCircle sx={{ color: "#255300", fontSize: '1.25rem' }} />
                                        <Typography style={{ marginLeft: 4, fontSize: "14px" }}> Genius Machine </Typography>
                                      </div>
                                    ) : (
                                      <></>
                                    )}
                                  </Box>

                                  <Info sx={{ color: "#255300", cursor: "pointer", alignSelf: "flex-end", fontSize: '1.25rem' }} onClick={() => setInfoProgramma(item.programma.id)} />
                                </Grid> : <></>
                            }

                            <Grid item xs={12} sm={4} spacing={2} sx={{ p: 2, height: boxHeight, ...border, bgcolor: matchSm ? 'white' : item.tipo_acquisto === "ABB" ? "#edf0dc" : "#f0f0f0" }}>
                              <Typography style={{ fontWeight: "bold", fontSize: "14px", paddingBottom: "3px" }}>Costo Programma:</Typography>
                              {item.tipo_acquisto === "ABB" ? (
                                <Typography sx={{ mb: 1, fontSize: "14px" }}>
                                  Abbonamento<span style={{ float: "right", color: "#255300", fontWeight: "bold" }}>{abb_types[item.tipo_abbonamento]}</span>
                                </Typography>
                              ) : (
                                <Typography sx={{ mb: 1, fontSize: "14px" }}>
                                  Quantità<span style={{ float: "right", color: "#255300", fontWeight: "bold" }}>{item.qta}</span>
                                </Typography>
                              )}
                              <Typography sx={{ mb: 1, fontSize: "14px" }}>
                                Totale<span style={{ float: "right", color: "#255300", fontWeight: "bold" }}>{Intl.NumberFormat('it-IT', { style: 'currency', currency: 'eur' }).format(item.prezzo_totale_voce)}</span>
                              </Typography>
                            </Grid>
                          </Grid>
                        }
                        {
                          item.tipo_voce == 'AUD' &&
                          <Stack key={item.id} sx={{ flexDirection: "row", height: "100px", mb: 2 }}>
                            <Stack spacing={2} sx={{ flex: 2, p: 2, pr: 6, bgcolor: item.tipo_acquisto === "ABB" ? "#edf0dc" : "#f0f0f0", overflow: "hidden", border: "1px solid #F7F2F2" }}>
                              <Typography sx={{ fontWeight: "bold" }}>Brano</Typography>
                              <Typography sx={{ color: "text.secondary", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{item.audio.nome}</Typography>
                            </Stack>
                            <Stack spacing={2} sx={{ flex: 1, p: 2, border: "1px solid #F7F2F2" }}>
                              <Typography sx={{ mb: 1, ml: 1, fontSize: "14px" }}>
                                Quantità<span style={{ float: "right", color: "#255300", fontWeight: "bold" }}>{item.qta}</span>
                              </Typography>
                              <Typography sx={{ mb: 1, ml: 1, fontSize: "14px" }}>
                                Totale<span style={{ float: "right", color: "#255300", fontWeight: "bold" }}>{Intl.NumberFormat('it-IT', { style: 'currency', currency: 'eur' }).format(item.prezzo_totale_voce)}</span>
                              </Typography>
                            </Stack>
                          </Stack>
                        }
                      </>
                      )

                      )}
                    </Box >
                  </Grid>
                ) : (
                  <></>
                )}
              </Grid>
            </Box>
            :
            <></>
        }
      </Box>
      <Footer />
      {infoProgramma && <DialogInfoProgramma id_programma={infoProgramma} isOpen={setInfoProgramma} />}

      <Dialog open={!!error} onClose={() => setError("")} maxWidth="sm" fullWidth>
        <DialogTitle variant="dialogTitle" color="error">
          Attenzione
        </DialogTitle>
        <DialogContent>
          <Typography color="error" style={{ whiteSpace: "pre-line" }}>
            {error}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => setError("")}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
