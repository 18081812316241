import { useTheme } from "@emotion/react";
import { Backdrop, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, FormControl, InputLabel, MenuItem, OutlinedInput, Paper, Select, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getBraniUtente } from "./absAPI";

export default function DialogSelectBrani({ onClose, onSuccess }) {
  const c = useTheme().palette;
  const { token } = useSelector((state) => state.login);
  const [braniSelected, setBraniSelected] = useState([]);
  const [dialogError, setDialogError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [braniUtente, setBraniUtente] = useState([]);

  const init = async () => {
    setIsLoading(true);
    try {
      const response = await getBraniUtente({}, token);
      setBraniUtente(response.data.filter((el)=>el.qta_disponibile > 0));

      return response.data;
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };


  useEffect(() => {
    init();
  }, []);



  const onAttivaCodice = async () => {
    let error = "";
    if (braniSelected.length === 0) error += "Seleziona almeno un brano\n";

    setDialogError(error);
    if (error.length > 0) {
      return;
    }

    onSuccess({ braniSelected: braniSelected });
  };

  const closeDialog = () => {
    setDialogError(null);
    setBraniSelected([]);
    onClose();
  };

  return (
    <Dialog open={true} onClose={closeDialog} maxWidth="sm" fullWidth>

      {
        isLoading ?
          <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
            <CircularProgress color="inherit" />
          </Backdrop>
          : <>  </>
      }

      <DialogContent sx={{ p: 4, pt: 2, fontSize: "14px", minHeight: 200 }}>
        {
          <Stack direction="column" sx={{ justifyContent: "center", alignItems: "center" }}>
            <Typography variant="h5" sx={{ fontFamily: "Poppins-SemiBold", color: c.primary.main, p: 2 }}>
              Seleziona uno o più brani per la cessione
            </Typography>

            <FormControl fullWidth sx={{ my: 2 }}>
              <InputLabel>Brani</InputLabel>
              <Select
                input={<OutlinedInput label="Brani" />}
                multiple={true}
                value={braniSelected}
                onChange={(e) => {
                  const value = e.target.value;
                  setBraniSelected(value);
                }}
                renderValue={(selected) => {
                  return (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value) => (
                        <Box key={value} sx={{ p: 0.5, bgcolor: c.primary.main, color: "#fff", borderRadius: '5px' }} >
                          {braniUtente.find((b) => b.id === value).nome}
                        </Box>
                      ))}
                    </Box>
                  );
                }
                }
              >
                {braniUtente.map((brano) => (
                  <MenuItem key={brano.id} value={brano.id}>
                    <Box sx={{ display: "flex", justifyContent: "space-between", width: '100%' }}>
                      <Typography>{brano.nome}</Typography>
                      <Typography>{brano.qta_disponibile}</Typography>
                    </Box>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {dialogError && (
              <Typography variant="p" sx={{ fontFamily: "Poppins-SemiBold", color: c.error.main, whiteSpace: "pre-line", textAlign: "center" }}>
                {dialogError}
              </Typography>
            )}
          </Stack>

        }

      </DialogContent>

      <DialogActions>
        <Button onClick={closeDialog} color="primary">
          Annulla
        </Button>
        <Button onClick={onAttivaCodice} color="primary" variant="contained">
          Continua
        </Button>
      </DialogActions>

    </Dialog>
  );
}
