import { createSlice } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";

// export const getCodici = createAsyncThunk("profilo/getCodici", async (props, thunkAPI) => {
//   try {
//     const state = thunkAPI.getState();
//     const token = state.login.token;
//     const config = {
//       params: { flg_attivo: 1, ...props?.params },
//       headers: {
//         Authorization: "Bearer " + token,
//       },
//     };
//     const response = await api.get("/api/codici_servizio", config);
//     return { listaCodici: response.data.data };
//   } catch (error) {
//     throw thunkAPI.rejectWithValue({ message: getAPIErrorMessage(error) });
//   }
// });

export const initialState = {
  selectedTab: 0,

  error: null,
};

export const profiloSlice = createSlice({
  name: "profilo",
  initialState,
  reducers: {
    setSelectedTab: (state, action) => {
      state.selectedTab = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // //#region codici
      // .addCase(getCodici.pending, (state, action) => {
      //   state.isLoadingCodici = true;
      // })
      // .addCase(getCodici.fulfilled, (state, action) => {
      //   state.listaCodici = action.payload.listaCodici;
      //   state.isLoadingCodici = false;
      // })
      // .addCase(getCodici.rejected, (state, action) => {
      //   state.isLoadingCodici = false;
      //   state.error = action.error.message || action.error;
      // })
    // // #endregion
    .addCase(PURGE, (state) => {
      return initialState;
    });
  },
});

export const { setSelectedTab } = profiloSlice.actions;

export default profiloSlice.reducer;
