import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate, useLocation, Outlet, useNavigate, BrowserRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { check, checkAdmin } from "../services/api";
import Loading from "./Loading";
import { DashboardAdmin } from "./dashboardadmin/DashboardAdmin";
import { LoginAdmin } from "./loginadmin/LoginAdmin";
import { Login } from "./login/Login";
import { Register } from "./login/components/Register";
import { Homepage } from "./homepage/Homepage";
import { Programmi } from "./programmi/Programmi";
import { Navbar } from "./navbar/Navbar";
import { Prestazione } from "./prestazione/Prestazione";
import { Referti } from "./programmi/Referti";
import { Profilo } from "./profilo/Profilo";
import { Ecommerce } from "./ecommerce/Ecommerce";
import { setSelectedSection } from "./login/loginSlice";
import { OrdineEsito } from "./ordine/OrdineEsito";
import instance from "../config/axiosConfig";
import { AudioBrainStimulation } from "./abs/ABSPage";
import { Ordini } from "./ordine/Ordini";
import { StoricoAbbonamenti } from "./programmi/StoricoAbbonamenti";
import { Checkout } from "./ecommerce/Checkout";
import { Footer } from "./homepage/Footer";

const CheckRoute = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, token, selectedSection } = useSelector((state) => state.login);
  const tokenAdmin = useSelector((state) => state.loginadmin.tokenAdmin);
  const [isLoading, setIsLoading] = useState(true);

  const isLoginOrRegister = location.pathname.includes("login") || location.pathname.includes("registrazione");

  const bloccoProfPassivo = ['esecuzione-prestazione', 'elaborati', 'abs'];

  useEffect(() => {
    if (!token && !tokenAdmin) {
      if (!isLoginOrRegister) {
        navigateToPath(-1);
      }
    }
  }, [token, tokenAdmin]);

  useEffect(() => {

    if (!isLoginOrRegister) {
      dispatch(setSelectedSection(location.pathname));
    }

    const fetchData = async () => {
      setIsLoading(false);

      if (isLoginOrRegister) {
        if (token != null || tokenAdmin != null) {
          navigate(selectedSection, { replace: true });
          return;
        } else {
          return;
        }
      } else {
        if (tokenAdmin == null && token == null) {
          navigateToPath(-1);
          return;
        }
      }


      //-1 sta per ruolo non valido, -2 per route non valida, 1 per valido
      let _isValid = 0;

      if (location.pathname.includes("admin")) {
        if (tokenAdmin == null && token != null) {
          navigate(selectedSection, { replace: true });
          return;
        } else {
          const response = await checkAdmin(tokenAdmin);
          if (response === true) {
            _isValid = 1;
          } else {
            _isValid = -1;
          }
        }
      } else if (location.pathname.includes("tommy")) {
        if (token == null && tokenAdmin != null) {
          navigate(selectedSection, { replace: true });
          return;
        } else {
          const response = await check(token);
          if (response === true) {
            if (user.tipologia === "PP") {
              const arr = location.pathname.split("/");
              if (bloccoProfPassivo.includes(arr[arr.length - 1])) {
                _isValid = -2;
              } else {
                _isValid = 1;
              }
            } else {
              _isValid = 1;
            }
          } else {
            _isValid = -1;
          }
        }
      }

      setIsLoading(false);
      navigateToPath(_isValid);
    };

    fetchData();
  }, [location]);

  const navigateToPath = (isValid) => {
    if (isValid === -1 || isValid === -2) {
      if (isValid === -1) {
        dispatch({ type: "USER_LOGOUT" });
        let pathname = "/tommy/login";
        if (location.pathname.includes("admin")) {
          pathname = "/admin/login";
        }

        navigate(pathname, { replace: true });
      } else {
        let pathname = "/tommy/homepage";
        if (location.pathname.includes("admin")) {
          pathname = "/admin/dashboard";
        } else if (location.pathname.includes("tommy")) {
          pathname = "/tommy/homepage";
        }

        dispatch(setSelectedSection(pathname));
        navigate(pathname, { replace: true });
      }
    } else {
      if (!location.pathname.includes("login")) {
        dispatch(setSelectedSection(location.pathname));
      }
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <Outlet />
    </>
  );
};

const NavbarWrapper = () => {
  return (
    <>
      <Navbar />
      <Outlet />
    </>
  );
};

/*const NavigateOut = () => {
    window.location.href = CONFIG.LSGENIUS_URL;
    return null;
};*/

const MainRoutes = () => {
  const dispatch = useDispatch();
  instance.setDispatch(dispatch);

  return (
    <BrowserRouter basename="/">
      <Routes>
        <Route element={<CheckRoute />}>
          <Route path="/admin/dashboard" element={<DashboardAdmin />} />

          <Route path="/admin/login" element={<LoginAdmin />} />
          <Route path="/tommy/login" element={<Login />} />
          <Route path="/tommy/registrazione" element={<Register />} />
          <Route path="/admin" element={<Navigate to="/admin/login" replace />} />
          <Route path="/tommy" element={<Navigate to="/tommy/login" replace />} />

          <Route element={<NavbarWrapper />}>
            <Route path="/tommy/homepage" element={<Homepage />} />
            <Route path="/tommy/profilo" element={<Profilo />} />
            <Route path="/tommy/storico-abbonamenti" element={<StoricoAbbonamenti />} />
            <Route path="/tommy/ordini" element={<Ordini />} />
            <Route path="/tommy/programmi" element={<Programmi />} />
            <Route path="/tommy/elaborati" element={<Referti />} />
            <Route path="/tommy/abs" element={<AudioBrainStimulation />} />
            <Route path="/tommy/ecommerce/ordine-esito" element={<OrdineEsito />} />
            <Route path="/tommy/ecommerce" element={<Ecommerce />} />
            <Route path="/tommy/ecommerce/checkout" element={<Checkout />} />
            <Route path="/tommy/esecuzione-prestazione" element={<Prestazione />} />
          </Route>
        </Route>

        <Route path="*" element={<Navigate to="/tommy/login" replace />} />
      </Routes>
    </BrowserRouter>
  );
};

export default MainRoutes;
