import React, { useEffect, useRef, useState } from "react";
import { Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, TableSortLabel, FormControl, TextField, CircularProgress, Box, Grid, InputAdornment, IconButton, Collapse, Divider, Stack, Backdrop } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getListaOrdini } from "./ordineSlice";
import { formatDate } from "../../common/utils";
import { KeyboardArrowDown, KeyboardArrowUp, Search } from "@mui/icons-material";
import { Footer } from "../homepage/Footer";

const headCells = [
  { id: "numero_ordine", label: "Numero Ordine" },
  { id: "data_ordine", label: "Data", format: (val, data) => formatDate(val) },
  // { id: "totale", label: "Totale", format: (val, data) => Intl.NumberFormat('it-IT', { style: 'currency', currency: 'eur' }).format(val) },
  // { id: "sconto", label: "Sconto", format: (val, data) => Intl.NumberFormat('it-IT', { style: 'currency', currency: 'eur' }).format(val) },
  { id: "totale_finale", label: "Totale Finale", format: (val, data) => Intl.NumberFormat('it-IT', { style: 'currency', currency: 'eur' }).format(val) },
  { id: "stato", label: "Stato" },
  { id: "saferpay_payment_method", label: "Metodo Pagamento" },
  { id: "data_pagamento", label: "Data Pagamento", format: (val, data) => (val ? formatDate(val) : "-") },
];
const abb_types = { SETT: "Settimanale", MENS: "Mensile", TRIM: "Trimestrale", SEM: "Semestrale", ANNUAL: "Annuale", VITA: "A Vita" };

export function Ordini() {
  const screenRef = useRef();
  const startEmpryListRef = useRef();
  const dispatch = useDispatch();
  const { isLoading, listaOrdini, pageInfoListaOrdini } = useSelector((state) => state.ordini);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = useState("data_ordine");
  const [order, setOrder] = useState("desc");
  const [search, setSearch] = useState("");
  const { user } = useSelector((state) => state.login);

  const [screenH, setScreenH] = useState(null);

  const handleResize = () => {
    if (screenRef.current) {
      setScreenH(window.innerHeight - screenRef.current.getBoundingClientRect().top);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [screenRef]);

  useEffect(() => {
    dispatch(getListaOrdini({ params: { per_page: rowsPerPage, sort: order, order_by: orderBy } }));
  }, []);

  const handleChangePage = (event, newPage) => {
    dispatch(getListaOrdini({ params: { page: newPage + 1, per_page: rowsPerPage, sort: order, order_by: orderBy, search } }));
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const countR = parseInt(event.target.value, 10);
    dispatch(getListaOrdini({ params: { per_page: countR, sort: order, order_by: orderBy, search } }));
    setRowsPerPage(countR);
    setPage(1);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    dispatch(getListaOrdini({ params: { page: page + 1, per_page: rowsPerPage, sort: isAsc ? "desc" : "asc", order_by: property, search } }));
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const searchOrdine = () => {
    dispatch(getListaOrdini({ params: { per_page: rowsPerPage, sort: order, order_by: orderBy, search } }));
  };

  const Row = ({ ordine }) => {
    const [open, setOpen] = useState(false);

    return (
      <React.Fragment key={ordine.id}>
        <TableRow sx={{ "& > .css-15tl2q9-MuiTableCell-root": { borderBottom: "unset" } }}>
          <TableCell sx={{}}>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </TableCell>
          {headCells.map((col) => (
            <TableCell key={ordine.id + col.id}>{col.format ? col.format(ordine[col.id], ordine) : ordine[col.id] || "-"}</TableCell>
          ))}
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Divider textAlign="left">Dettaglio Acquisti</Divider>
              <Box sx={{ m: 1, pl: 5 }}>
                <Grid container spacing={2}>
                  {ordine.voci?.map((voce, i) => (
                    <>
                      {
                        voce.tipo_voce == 'TEC' &&
                        <Grid item xs={12} key={i}>
                          <Stack direction={{ xs: "row", sm: "row" }} spacing={2}>
                            <Stack flex={1} direction="row">
                              <strong style={{ marginRight: "8px" }}>Tecnica ABS </strong>
                              {
                                user?.tipologia == 'PA' ?
                                  <span>{voce.tecnica_abs.utente_privato.nome} {voce.tecnica_abs.utente_privato.cognome}</span>
                                  :
                                  <></>
                              }
                            </Stack>
                            <Stack flex={1} direction="column">
                              <Box>
                                <strong style={{ marginRight: "8px" }}>Brani: </strong>
                                {
                                  voce.tecnica_abs.brani?.map((brano, i) => (
                                    <span key={i}>{brano.nome}{i < voce.tecnica_abs.brani.length - 1 ? ", " : ""}</span>
                                  ))
                                }
                              </Box>
                              <Box>
                                <strong style={{ marginRight: "8px" }}>N. Frasi Negative: </strong>{voce.tecnica_abs.frasi_negative?.length}
                              </Box>
                              <Box>
                                <strong style={{ marginRight: "8px" }}>N. Frasi Positive:  </strong>{voce.tecnica_abs.frasi_positive?.length}
                              </Box>
                            </Stack>

                            <Stack flex={1} direction="row">
                              <strong>Totale Voce: </strong><span> {Intl.NumberFormat('it-IT', { style: 'currency', currency: 'eur' }).format(voce.prezzo_totale_voce)}</span>
                            </Stack>
                          </Stack>
                        </Grid>
                      }
                      {
                        voce.tipo_voce == 'AUD' &&
                        <Grid item xs={12} key={i}>
                          <Stack direction={{ xs: "row", sm: "row" }} spacing={2}>
                            <Stack flex={1} direction="row">
                              <strong style={{ marginRight: "8px" }}>Brano: </strong>
                              {voce.audio.nome}
                            </Stack>
                            <Stack flex={1} direction="row">
                              <strong style={{ marginRight: "8px" }}>Quantità: </strong>
                              {voce.qta}
                            </Stack>
                            <Stack flex={1} direction="row">
                              <strong>Totale Voce: </strong>{Intl.NumberFormat('it-IT', { style: 'currency', currency: 'eur' }).format(voce.prezzo_totale_voce)}
                            </Stack>
                          </Stack>
                        </Grid>
                      }
                      {
                        voce.tipo_voce == 'PROG' &&
                        <Grid item xs={12} key={i}>
                          <Stack direction={{ xs: "row", sm: "row" }} spacing={2}>
                            <Stack flex={1} direction="row">
                              <strong style={{ marginRight: "8px" }}>Programma: </strong>
                              {voce.programma.nome}
                            </Stack>
                            {voce.tipo_acquisto == "ABB" ? (
                              <Stack flex={1} direction="row">
                                <strong style={{ marginRight: "8px" }}>Abbonamento: </strong> {abb_types[voce.tipo_abbonamento]}
                              </Stack>
                            ) : (
                              <Stack flex={1} direction="row">
                                <strong style={{ marginRight: "8px" }}>Quantita: </strong>
                                {voce.qta}
                              </Stack>
                            )}
                            <Stack flex={1} direction="row">
                              <strong style={{ marginRight: "8px" }}>Totale Voce: </strong>{Intl.NumberFormat('it-IT', { style: 'currency', currency: 'eur' }).format(voce.prezzo_totale_voce)}
                            </Stack>
                          </Stack>
                        </Grid>
                      }
                    </>
                  ))}
                </Grid>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  };

  return (
    <Box style={{ display: "flex", height: screenH, flexDirection: "column", overflowY: 'auto' }} ref={screenRef}>
      <Box sx={{ px: 2, py: 2, flex: 1 }}>
        <Stack sx={{ flexDirection: "row", alignItems: "center", mb: 2 }}>
          <Typography variant="title" color="secondary">
            Cronologia Ordini
          </Typography>
        </Stack>
        <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 2 }}>

          {isLoading && (
            <Backdrop open={true} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, color: "#fff" }}>
              <CircularProgress />
            </Backdrop>
          )}

          <TableContainer component={Paper}>
            <Grid alignItems={"center"} container direction={"row"} spacing={2} sx={{ p: 2 }}>
              <Grid item xs={12} md={5}>
                <FormControl fullWidth>
                  <TextField
                    label="Cerca"
                    variant="outlined"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    onKeyDown={(e) => e.key === "Enter" && searchOrdine()}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={searchOrdine}>
                            <Search />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Table>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  {headCells.map((headCell) => (
                    <TableCell key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}>
                      {(headCell.sortable === undefined || headCell.sortable === true) && (
                        <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : "asc"} onClick={() => handleRequestSort(headCell.id)} sx={{ fontWeight: 600 }}>
                          {headCell.label}
                        </TableSortLabel>
                      )}
                      {headCell.sortable === false && <span>{headCell.label}</span>}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {listaOrdini?.map((ordine) => (
                  <Row key={ordine.id} ordine={ordine} />
                ))}
                <TableRow ref={startEmpryListRef} key={"emptyRow"} >
                  <TableCell sx={{ verticalAlign: "baseline", fontSize: 18 }} colSpan={headCells.length + 1}>
                    {!listaOrdini || listaOrdini?.length === 0 ? "Non sono presenti ordini" : ""}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={pageInfoListaOrdini?.total || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="Righe"
              labelDisplayedRows={({ from, to, count }) => `${from}-${to} di ${count}`}
            />
          </TableContainer>
        </Box>
      </Box>

      <Footer/>

    </Box>
  );
}
