import React from "react";
import { useSelector } from "react-redux";
import { Grid, Box, Button, Typography, TextField, Dialog, DialogTitle, DialogContent, DialogActions, Backdrop, CircularProgress, Stack, FormControl, InputLabel, Select, MenuItem, useMediaQuery } from "@mui/material";
import { InfoPrestazione } from "../InfoPrestazione";
import Tommy from "../../../common/Tommy";
import { aggiornaDatiUtentePrivato } from "../prestazioneAPI";
import { getAPIErrorMessage } from "../../../common/utils";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { useTheme } from "@emotion/react";

export function DatiIniziali({ readOnly = false, prestazione = null, setPrestazione = () => { }, onSuccess = () => { } }) {
  const { token, user } = useSelector((state) => state.login);
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const theme = useTheme();
  const c = theme.palette;
  const matchMd = useMediaQuery(theme.breakpoints.up('md'));

  const onFinish = async () => {
    setIsLoading(true);


    const payload = {
      altezza: prestazione?.dati_paziente?.altezza,
      peso: prestazione?.dati_paziente?.peso,
      data_nascita: prestazione?.dati_paziente?.data_nascita,
      sesso: prestazione?.dati_paziente?.sesso,
      nome: prestazione?.dati_paziente?.nome,
      cognome: prestazione?.dati_paziente?.cognome,
      email: prestazione?.dati_paziente?.email,
      telefono: prestazione?.dati_paziente?.telefono
    };

    let response;
    try {
      response = await aggiornaDatiUtentePrivato(prestazione?.id, payload, token);
    } catch (e) {
      setError(getAPIErrorMessage(e));
      return;
    } finally {
      setIsLoading(false);
    }

    setPrestazione(response.data);
    onSuccess();
  };

  const onInputFieldChange = (e) => {
    const { name, value } = e.target;
    setPrestazione({ ...prestazione, dati_paziente: { ...prestazione.dati_paziente, [name]: value } });
  }


  return (
    <Box>
      {isLoading && (
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
          <CircularProgress color="primary" />
        </Backdrop>
      )}


      <InfoPrestazione prestazione={prestazione} />


      <Box sx={{ mt: 2, mb: 2, fontSize: "1rem" }}>
        <Typography sx={{ mb: 1, mt: 2, color: c.primary.main }} variant="h6">Dati personali</Typography>

        <Grid container spacing={2} sx={{ mb: 2 }}>


          <Grid item xs={12} sm={6} lg={3}>
            {
              readOnly || prestazione.tipo_censimento != "M" ?
                <>
                  <b>Nome: </b> {prestazione?.dati_paziente?.nome}
                </>
                :
                <TextField label="Nome" name="nome" onChange={onInputFieldChange} InputLabelProps={{ shrink: !!prestazione?.dati_paziente?.nome }} variant="outlined" size="small" value={prestazione?.dati_paziente?.nome} />
            }
          </Grid>

          <Grid item xs={12} sm={6} lg={3}>
            {
              readOnly || prestazione.tipo_censimento != "M" ?
                <>
                  <b>Cognome: </b>  {prestazione?.dati_paziente?.cognome}
                </>
                :
                <TextField label="Cognome" name="cognome" onChange={onInputFieldChange} InputLabelProps={{ shrink: !!prestazione?.dati_paziente?.cognome }} variant="outlined" size="small" value={prestazione?.dati_paziente?.cognome} />
            }
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            {
              readOnly || prestazione.tipo_censimento != "M" ?
                <>
                  <b>Data Nascita: </b> {new Date(prestazione?.dati_paziente?.data_nascita).toLocaleDateString()}
                </>
                :
                <FormControl>
                  <DatePicker
                    slotProps={{ textField: { size: 'small' } }}
                    size="small"
                    name="data_nascita"
                    format="DD/MM/YYYY"
                    onChange={(value) => {
                      setPrestazione((prevData) => ({
                        ...prevData,
                        dati_paziente: {
                          ...prevData.dati_paziente,
                          data_nascita: value && value.format("YYYY-MM-DD"),
                        },
                      }));
                    }}
                    value={dayjs(prestazione?.dati_paziente?.data_nascita)}
                    fullWidth
                    maxDate={dayjs(new Date())}
                    label="Data di nascita"
                    renderInput={(params) => {
                      return <TextField {...params} size="small" />;
                    }}
                  ></DatePicker>
                </FormControl>
            }

          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            {
              readOnly || prestazione.tipo_censimento != "M" ?
                <>
                  <b>Sesso: </b> {prestazione?.dati_paziente?.sesso === "F" ? "Femmina" : "Maschio"}
                </>
                :
                <>
                  <FormControl>
                    <InputLabel>Sesso</InputLabel>
                    <Select
                      size="small"
                      displayEmpty
                      value={prestazione?.dati_paziente?.sesso}
                      label="Sesso"
                      name="sesso"
                      placeholder="Sesso"
                      renderValue={(selected) => {
                        if (selected === "" || selected == null) {
                          return "";
                        }

                        return selected == 'M' ? "Maschio" : "Femmina";
                      }}
                      onChange={onInputFieldChange}
                    >
                      <MenuItem value="F">Femmina</MenuItem>
                      <MenuItem value="M">Maschio</MenuItem>
                    </Select>
                  </FormControl>
                </>
            }

          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            {
              readOnly ?
                <>
                  <b>Peso: </b> {prestazione?.dati_paziente?.peso} kg
                </>
                :
                <TextField label="Peso (kg)" name="peso" onChange={onInputFieldChange} InputLabelProps={{ shrink: !!prestazione?.dati_paziente?.peso }} variant="outlined" size="small" value={prestazione?.dati_paziente?.peso} />
            }
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            {
              readOnly ?
                <>
                  <b>Altezza: </b> {prestazione?.dati_paziente?.altezza} cm
                </>
                :
                <TextField label="Altezza (cm)" name="altezza" onChange={onInputFieldChange} InputLabelProps={{ shrink: !!prestazione?.dati_paziente?.altezza }} variant="outlined" size="small" value={prestazione?.dati_paziente?.altezza} />
            }
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            {
              readOnly ?
                <>
                  <b>Email: </b> {prestazione?.dati_paziente?.email}

                </>
                :
                <TextField label="Email" name="email" onChange={onInputFieldChange} InputLabelProps={{ shrink: !!prestazione?.dati_paziente?.email }} variant="outlined" size="small" value={prestazione?.dati_paziente?.email} />
            }
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            {
              readOnly ?
                <>
                  <b>Telefono: </b> {prestazione?.dati_paziente?.telefono}
                </>
                :
                <TextField label="Telefono" name="telefono" onChange={onInputFieldChange} InputLabelProps={{ shrink: !!prestazione?.dati_paziente?.telefono }} variant="outlined" size="small" value={prestazione?.dati_paziente?.telefono} />
            }
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ mt: '2rem' }}>
        <Tommy
          boxStyle={{ position: "relative", paddingTop: 24 }}
          maxWidth={"none"}
          tipPos={'right-start'}
          tipStyle={{ marginLeft: '20px' }}
          content={
            <Box>
              <Typography>
                {
                  readOnly ? "Hai concluso con successo la prestazione ma puoi ripercorrere gli step effettuati." : "Sei pronto per iniziare, clicca su 'Inizia la prestazione' per procedere."
                }
              </Typography>
              <Button variant="contained" onClick={onFinish} sx={{ mt: 2 }} >
                {readOnly ? "Prosegui" : "Inizia la prestazione"}
              </Button>
            </Box>

          }
        />
      </Box>

      {error && (
        <Dialog open={!!error} onClose={() => setError(null)} maxWidth="sm" fullWidth>
          <DialogTitle variant="dialogTitle" color="error">
            Attenzione
          </DialogTitle>
          <DialogContent>
            <Typography color="error" style={{ whiteSpace: "pre-line" }}>
              {error}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={() => setError(null)}>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      )}

    </Box>
  );
}
