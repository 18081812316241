import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";
import { formatDate } from "../../common/utils";
import Loading from "../Loading";
import { useRef } from "react";
import PaginateTable from "../programmi/components/PaginateTable";
import { getBraniAcquistati } from "./absSlice";
import { CONFIG } from "../../config/envConfig";

export function Movimenti() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.login.user);

  const { pageInfoBraniAcquistati, listaBraniAcquistati, isLoadingBraniAcquistati } = useSelector((state) => state.abs);

  const screenRef = useRef();

  const [screenH, setScreenH] = useState(null);

  const handleResize = () => {
    if (screenRef.current) {
      setScreenH(window.innerHeight - screenRef.current.getBoundingClientRect().top - CONFIG.FOOTER_HEIGHT);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [screenRef]);


  useEffect(() => {
    dispatch(getBraniAcquistati());

  }, []);



  const colsMovimenti = [
    { id: "audio", label: "Brano", format: (value) => value.nome },
    {id: 'qta', label: 'Quantità', format: (value, data) => value},
    { id: "direzione", label: "Tipologia", format: (value) => value == 'USCI' ? 'Cessione' : 'Acquisto', sortable: false },
    { id: "tipo_movimento", label: "Origine Acquisto", format: (value, data) => (data.direzione == 'USCI' ? '-' : value === "ORDINE" ? "E-Commerce" : value == 'REGALO' ? 'Regalo' : data.cessione.utenteCedente.ragione_sociale) },
  ];

  if (user?.tipologia !== "P") {
    colsMovimenti.push({ id: "id", sortable: false, label: "Ceduto a", format: (value, row) => row.cessione && row.direzione == 'USCI' ? row.cessione.utenteDestinatario.nome + " " + row.cessione.utenteDestinatario.cognome : "-" });
  }

  colsMovimenti.push(
    { id: "created_at", label: "Data Movimento", format: (value) => formatDate(value) },
  )


  return (
    <Box ref={screenRef} height={screenH} sx={{ display: 'flex', bgcolor: "#fff", flexDirection: 'column', overflowY: 'auto', p: 1.5, mt: 1 }}>
      <Typography variant="h6" align="left">
        Riepilogo movimenti
      </Typography>

      {isLoadingBraniAcquistati && <Loading />}
      <PaginateTable
        index={1}
        emptyTable="Nessun movimento presente"
        data={listaBraniAcquistati}
        pageInfo={pageInfoBraniAcquistati}
        f={getBraniAcquistati}
        cols={colsMovimenti}
      />
    </Box>
  );
}
