import React from 'react';
import MainRoutes from './pages/MainRoutes';

function App() {
  return (
    <MainRoutes />
  );
}

export default App;
