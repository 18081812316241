import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAbbonamenti, getCodici, getStoricoAbbonamenti, getMovimentiCodici } from "./programmiSlice";
import SvgProgramma from "../../assets/svg/SvgProgramma";
import { Box, Stack, Tab, Typography, useTheme } from "@mui/material";
import CardsGrid from "./components/CardsGrid";
import { useLocation } from "react-router-dom";
import SearchOrderStack from "./components/SearchOrderStack";
import PaginateTable from "./components/PaginateTable";
import { formatDate } from "../../common/utils";
import SvgOrdDesc from "../../assets/svg/SvgOrdDesc";
import SvgOrdAsc from "../../assets/svg/SvgOrdAsc";
import { ReactComponent as SvgChk } from "../../assets/svg/SvgChk.svg";
import { ReactComponent as SvgcircleErr } from "../../assets/svg/SvgcircleErr.svg";
import { setSelectedTab } from "./programmiSlice";
import TabPanel from "@mui/lab/TabPanel";
import TabList from "@mui/lab/TabList";
import TabContext from "@mui/lab/TabContext";
import Loading from "../Loading";
import { Footer } from "../homepage/Footer";

export function Programmi() {
  const dispatch = useDispatch();
  const location = useLocation();
  const state = location.state;
  const theme = useTheme();
  const c = theme.palette;
  const t = theme.typography;
  const selectedTab = useSelector((state) => state.programmi.selectedTab);
  const user = useSelector((state) => state.login.user);
  const listaCodici = useSelector((state) => state.programmi.listaCodici);
  const loadingCodici = useSelector((state) => state.programmi.isLoadingCodici);
  const listaAbbonamenti = useSelector((state) => state.programmi.listaAbbonamenti);
  const loadingAbbonamenti = useSelector((state) => state.programmi.isLoadingAbbonamenti);
  const listaMovimentiCodici = useSelector((state) => state.programmi.listaMovimentiCodici);
  const pageInfoMovimentiCodici = useSelector((state) => state.programmi.pageInfoMovimentiCodici);
  const loadingMovimentiCodici = useSelector((state) => state.programmi.isLoadingMovimentiCodici);
  const [listaCodiciGrouped, setListaCodiciGrouped] = useState([{}]);
  const [screenH, setScreenH] = useState(0);
  const screenRef = React.useRef();

  const handleResize = () => {
    if (screenRef.current) {
      setScreenH(window.innerHeight - screenRef.current.getBoundingClientRect().top);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [screenRef]);


  const [tabs, setTabs] = useState([
    { label: "Programmi Attivi", value: "0" },
    { label: "Movimenti Codici", value: "1" },
  ]);

  useEffect(() => {
    dispatch(getCodici());
    dispatch(getMovimentiCodici());
    if (user.tipologia == "PA") {
      setTabs([
        { label: "Programmi Attivi", value: "0" },
        { label: "Abbonamenti Attivi", value: "2" },
        { label: "Movimenti Codici", value: "1" },
      ]);
      dispatch(getAbbonamenti());
      dispatch(getStoricoAbbonamenti());
    } else if (user.tipologia == "P") {
      setTabs([
        { label: "Programmi Attivi", value: "0" },
        { label: "Abbonamenti Attivi", value: "2" },
      ]);
      dispatch(getAbbonamenti());
    } else {
      setTabs([
        { label: "Programmi Attivi", value: "0" },
        { label: "Movimenti Codici", value: "1" },
      ]);
    }
  }, []);

  useEffect(() => {
    raggruppaCodici();
  }, [listaCodici]);



  const raggruppaCodici = () => {
    const _listaCodiciGrouped = [];
    listaCodici.forEach((element) => {
      if (!_listaCodiciGrouped[element.programma.id]) {
        _listaCodiciGrouped[element.programma.id] = { programma: element.programma, codici: [element.codice_servizio] };
      } else {
        const arr = _listaCodiciGrouped[element.programma.id].codici;
        arr.push(element.codice_servizio);
        _listaCodiciGrouped[element.programma.id].codici = arr;
      }
    });

    //to array
    const _listaCodiciGroupedValues = [];
    for (const key in _listaCodiciGrouped) {
      _listaCodiciGroupedValues.push(_listaCodiciGrouped[key]);
    }

    setListaCodiciGrouped(_listaCodiciGroupedValues);
  };

  const colsMovimenti = [
    { id: "programma", label: "Programma", format: (value) => value.nome },
    { id: "codice_servizio", label: "Codice servizio", format: (value) => value || "-" },
    { id: "tipo_movimento", label: "Origine Acquisto", format: (value, data) => (value === "ORDINE" ? "E-Commerce" : value == 'REGALO' ? 'Regalo' : data.cessione.utenteCedente.ragione_sociale) },
    { id: "created_at", label: "Data Acquisto", format: (value) => formatDate(value) },
  ];

  if (user?.tipologia !== "PP") {
    colsMovimenti.push({ id: "attivo", sortable: false, label: "Attivo", format: (value, row) => (row.venduto ? '-' : value ? <SvgChk /> : <SvgcircleErr />) });
  }
  if (user?.tipologia !== "P") {
    colsMovimenti.push({ id: "venduto", sortable: false, label: "Ceduto", format: (value) => (value ? <SvgChk /> : "-") });
  }
  if (user?.tipologia !== "P") {
    colsMovimenti.push({ id: "id", sortable: false, label: "Ceduto a", format: (value, row) => row.cessione && row.venduto ? row.cessione.utenteDestinatario.nome + " " + row.cessione.utenteDestinatario.cognome : "-" });
    colsMovimenti.push({ id: "id", sortable: false, label: "Ceduto il", format: (value, row) => row.cessione && row.venduto ? new Date(row.cessione.data_cessione).toLocaleDateString() : "-" });
  }

  useEffect(() => {
    if (state?.tabTarget && state?.tabTarget != null) {
      dispatch(setSelectedTab(state.tabTarget));
    }
  }, [state]);

  return (
    <Box style={{ display: "flex", height: screenH, flexDirection: "column", overflowY:'auto' }} ref={screenRef}>
      <Box sx={{ px: 2, py: 2, flex: 1 }}>
        <Stack sx={{ flexDirection: "row", alignItems: "center" }}>
          <SvgProgramma fill={c.secondary.main} />
          <Typography sx={{ ml: 2, fontSize: '1.5rem' }} variant="title" color="secondary">
            Programmi Acquistati
          </Typography>
        </Stack>
        <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 2 }}>
          <TabContext value={selectedTab?.toString()} >
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList onChange={(e, value) => dispatch(setSelectedTab(value))} variant="scrollable" scrollButtons allowScrollButtonsMobile>
                {tabs.map((tab, index) => (
                  <Tab style={t.s} key={index} value={tab.value} label={tab.label} />
                ))}
              </TabList>
            </Box>

            <TabPanel value="0" index={0} sx={{py: '2rem', px: '0.5rem'}}>
              {loadingCodici && <Loading />}
              <SearchOrderStack index={0} f={getCodici} />
              <CardsGrid grouped={true} tipoAttivazione="C" index={0} emptyList="Non sono presenti programmi attivi" data={listaCodiciGrouped} />
            </TabPanel>

            <TabPanel value="1" index={1} sx={{py: '2rem', px: '0rem'}}>
              {loadingMovimentiCodici && <Loading />}
              <PaginateTable
                index={1}
                emptyTable="Nessun movimento presente"
                data={listaMovimentiCodici}
                pageInfo={pageInfoMovimentiCodici}
                f={getMovimentiCodici}
                cols={colsMovimenti}
                height='auto'
              />
            </TabPanel>

            <TabPanel value="2" index={2} sx={{py: '2rem', px: '0.5rem'}}>
              {loadingAbbonamenti && <Loading />}
              <SearchOrderStack
                index={2}
                f={getAbbonamenti}
                orderList={[
                  { order_by: "data_inizio", sort: "desc", label: "Dal più recente", svg: <SvgOrdDesc /> },
                  { order_by: "data_inizio", sort: "asc", label: "Dal meno recente", svg: <SvgOrdAsc /> },
                ]}
              />
              <CardsGrid tipoAttivazione="A" index={"2"} emptyList="Nessun abbonamento attivo presente" data={listaAbbonamenti} />
            </TabPanel>
          </TabContext>
        </Box>
      </Box>

      <Footer />
    </Box>
  );
}
