import React, { useState, useEffect, useRef } from "react";
import { Grid, Box, Typography, TextField, Button, Paper, IconButton, Select, MenuItem, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack, Backdrop, CircularProgress, useMediaQuery, useTheme, Hidden, Checkbox, FormControlLabel, FormGroup, FormLabel, FormHelperText, FormControl } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { getListaCategorie, getListaProgrammi, getCarrello, setCarrello } from "./EcommerceSlice";
import { useLocation } from "react-router-dom";
import { AccessTime, CheckCircle, AddCircleOutline, RemoveCircleOutline, ShoppingCart, Info, NavigateBefore, AddCircle } from "@mui/icons-material";
import { aggiungiVoce } from "./EcommerceAPI";
import DialogInfoProgramma from "../../common/DialogInfoProgramma";
import DefaultCategoryThumbnail from "../../assets/img/default_category_thumbnail.png";
import { getAPIErrorMessage } from "../../common/utils";
import Tommy from "../../common/Tommy";
import { setSnackbarData } from "../programmi/programmiSlice";
import { Footer } from "../homepage/Footer";
import { CONFIG } from "../../config/envConfig";

export function Ecommerce() {
  const dispatch = useDispatch();
  const location = useLocation();
  const state = location?.state;
  const screenRef = useRef();
  const theme = useTheme();
  const matchSm = useMediaQuery(theme.breakpoints.up('sm'));
  const matchMd = useMediaQuery(theme.breakpoints.up('md'));
  const [informativaClicked, setInformativaClicked] = useState({});
  const [informativaError, setInformativaError] = useState({});

  const { listaCategorie, listaProgrammi, isProgrammiLoading } = useSelector((state) => state.ecommerce);
  const { token, user } = useSelector((state) => state.login);
  const [valoriTextField, setValoriTextField] = useState({});
  const [selectedCategoria, setSelectedCategoria] = useState();
  const [hoveredItem, setHoveredItem] = useState(null);
  // const [cardNumber, setCardNumber] = useState("");
  // const [expiry, setExpiry] = useState("");
  // const [cvv, setCvv] = useState("");
  const [abbonamentiSelezionati, setAbbonamentiSelezionati] = useState({});
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [messageErrorDialog, setMessageErrorDialog] = useState();
  const [infoProgramma, setInfoProgramma] = useState(false);
  const [screenH, setScreenH] = useState(0);
  const descriptionLenght = 200;
  const [tommyErr, setTommyErr] = useState(false);


  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [screenRef.current]);

  useEffect(() => {
    dispatch(getListaCategorie());
    dispatch(getCarrello());
  }, []);

  useEffect(() => {
    user.tipologia !== "PP" &&
      listaProgrammi
        .filter((element) => {
          return element.prezzi.abbonamento_settimanale || element.prezzi.abbonamento_mensile || element.prezzi.abbonamento_trimestrale || element.prezzi.abbonamento_semestrale || element.prezzi.abbonamento_annuale || element.prezzi.abbonamento_vita;
        })
        .forEach((item) => {
          setAbbonamentiSelezionati((prevState) => ({
            ...prevState,
            [item.id]: "SEL",
          }));
        });


    const _informativaClicked = {};
    const _informativaError = {};
    for (const programma of listaProgrammi) {
      if (programma.flg_gdpr) {
        _informativaClicked[programma.id] = false;
        _informativaClicked[programma.id + "_abb"] = false;
        _informativaError[programma.id + "_abb"] = false;
      }
    }
    setInformativaClicked(_informativaClicked);
    setInformativaError(_informativaError);
  }, [listaProgrammi]);

  useEffect(() => {
    if (state && state.categoria) {
      handleCategoriaClick(state.categoria);
    }
  }, [state]);

  const handleTipoAbbonamentoChange = (item, selectedOptions) => {
    setAbbonamentiSelezionati((prevState) => ({
      ...prevState,
      [item.id]: selectedOptions,
    }));
  };

  const handleResize = () => {
    if (screenRef.current) {
      setScreenH(window.innerHeight - screenRef.current.getBoundingClientRect().top - CONFIG.FOOTER_HEIGHT);
    }
  };

  function getTipiAbbonamento(item) {
    let res = [];
    if (item.prezzi.abbonamento_settimanale) {
      res.push({ tipo: "Settimanale", prezzo: item.prezzi.abbonamento_settimanale.prezzo });
    }
    if (item.prezzi.abbonamento_mensile) {
      res.push({ tipo: "Mensile", prezzo: item.prezzi.abbonamento_mensile.prezzo });
    }
    if (item.prezzi.abbonamento_trimestrale) {
      res.push({ tipo: "Trimestrale", prezzo: item.prezzi.abbonamento_trimestrale.prezzo });
    }
    if (item.prezzi.abbonamento_semestrale) {
      res.push({ tipo: "Semestrale", prezzo: item.prezzi.abbonamento_semestrale.prezzo });
    }
    if (item.prezzi.abbonamento_annuale) {
      res.push({ tipo: "Annuale", prezzo: item.prezzi.abbonamento_annuale.prezzo });
    }
    if (item.prezzi.abbonamento_vita) {
      res.push({ tipo: "A Vita", prezzo: item.prezzi.abbonamento_vita.prezzo });
    }
    return res;
  }


  function handleCategoriaClick(item) {
    setSelectedCategoria(item);
    dispatch(getListaProgrammi(item.id));
  }

  async function handleAddItem(item, tipo, durata) {
    if (tipo == 'cod' && item.flg_gdpr && !informativaClicked[item.id]) {
      setInformativaError((prevState) => ({ ...prevState, [item.id]: true }));
      return;
    }
    if (tipo == 'abb' && item.flg_gdpr && !informativaClicked[item.id + "_abb"]) {
      setInformativaError((prevState) => ({ ...prevState, [item.id + "_abb"]: true }));
      return;
    }


    let data;
    if (tipo === "cod") {
      data = {
        id_programma: item.id,
        tipo_acquisto: "QTA",
        tipo_voce: 'PROG',
        qta: valoriTextField[item.nome] ? valoriTextField[item.nome] : 1,
      };
    } else if (tipo === "abb") {
      if (durata === "SEL") {
        //mostro alert
        setMessageErrorDialog("Selezionare un tipo di abbonamento");
        setOpenErrorDialog(true);
        return;
      }
      var durataMapping = {
        Settimanale: "SETT",
        Mensile: "MENS",
        Trimestrale: "TRIM",
        Semestrale: "SEM",
        Annuale: "ANNUAL",
        "A Vita": "VITA",
      };

      data = {
        id_programma: item.id,
        tipo_acquisto: "ABB",
        tipo_voce: 'PROG',
        tipo_abbonamento: durataMapping[durata], // Default a stringa vuota se la durata non è mappata
        qta: 1,
      };
    }
    try {
      const res = await aggiungiVoce(data, token);
      dispatch(setCarrello(res.data));
      dispatch(setSnackbarData({ message: "Programma aggiunto al carrello", severity: "success", vertical: "bottom" }));
    } catch (err) {
      setTommyErr(getAPIErrorMessage(err));
      return;
    }
  }


  // Funzioni per incrementare/decrementare la quantità - da implementare
  const handleIncrement = (nome) => {
    setValoriTextField({ ...valoriTextField, [nome]: (valoriTextField[nome] || 1) + 1 });
  };

  const handleDecrement = (nome) => {
    if (valoriTextField[nome] > 1) {
      setValoriTextField({ ...valoriTextField, [nome]: valoriTextField[nome] - 1 });
    }
  };

  const handleKeyPress = (event) => {
    const inputChar = String.fromCharCode(event.charCode);
    if (!/^\d+$/.test(inputChar)) {
      event.preventDefault();
    }
  };



  const orderedRange = (ranges) => {
    const order = [...ranges].sort((a, b) => parseInt(a.quantita) - parseInt(b.quantita));
    return order.map((r, i) => (
      <Stack direction='row' sx={{ mb: 1, ml: 1, justifyContent: 'space-between' }}>
        <Typography key={i} sx={{ fontSize: "14px" }}>
          Pacchetto da {r.quantita}
          {
            i + 1 < order.length ?
              parseInt(r.quantita) === (parseInt(order[i + 1].quantita) - 1) ?
                ' codici' :
                ` a ${parseInt(order[i + 1].quantita) - 1} codici` :
              " codici in su"
          }, prezzo unitario
        </Typography>
        <span style={{ float: "right", color: "#255300", fontWeight: "bold", fontSize: "14px" }}>{formatPrezzo(r.prezzo)}</span>
      </Stack>
    ));
  };

  useEffect(() => {
    if (!!tommyErr)
      setTimeout(() => {
        setTommyErr(false);
      }, 5000);
  }, [tommyErr]);

  const boxHeight = matchMd ? '240px' : matchSm ? '200px' : 'auto';
  const showDetails = matchSm;
  let border = {};
  if (matchMd) {
    border = { border: "1px solid #F7F2F2" };
  } else {
    border = { borderTop: "1px solid #bbbbbb" };
  }

  const formatPrezzo = (prezzo) => {
    if (prezzo == '' || prezzo == null) {
      return '';
    }
    return Intl.NumberFormat('it-IT', { style: 'currency', currency: 'eur' }).format(prezzo);
  }

  return (
    <>
      <Box sx={{ backgroundColor: "rgb(248, 248, 248)", overflowY: 'auto', display: 'flex', flexDirection: 'column' }} ref={screenRef} height={screenH}>
        <Box sx={{ flex: 1 }}>


          {!!tommyErr ? (
            <>
              <Backdrop sx={{ color: "#fff", zIndex: 98 }} open={true} />
              <Tommy
                right
                tommyStyle={{ width: '300px', height: '300px' }}
                tipStyle={{ marginRight: '20px' }}
                tipPos={"left-start"}
                boxStyle={{ bottom: '50px', right: '30px', position: 'aboslute' }}
                content={
                  <>
                    <Typography variant="subtitle2" color="error">
                      Attenzione
                    </Typography>
                    <Typography fontSize={16} sx={{ mb: 2 }}>{tommyErr}</Typography>
                  </>
                }
              />
            </>
          ) : (
            <></>
          )}

          <Grid container sx={{ ...paginaStyle }}>
            <>
              {/* PARTE SINISTRA */}
              <Grid item xs={12}>
                <Box sx={boxStyle}>
                  {!selectedCategoria && (
                    <>
                      <Typography sx={{ ...titoloStyle, fontSize: '1.25rem' }}>
                        <span style={{ fontWeight: "bold" }}>Ecommerce</span>
                      </Typography>
                      <Typography sx={titoloStyle}>Seleziona una categoria di programmi da attivare:</Typography>
                      {listaCategorie.length === 0 ? <Typography sx={{ mt: 4 }}>Nessuna categoria presente</Typography> : <></>}
                      <Box sx={{ mt: 4, px: '2rem' }}>
                        <Grid container spacing={2}>
                          {listaCategorie.map((item) => (
                            <Grid item key={item.id} xs={12} sm={6} md={4} lg={3} sx={{ pb: 1 }}>
                              <Paper
                                elevation={2}
                                style={{
                                  height: 205,
                                  display: "flex",
                                  borderRadius: 16,
                                  cursor: "pointer",
                                  overflow: "hidden",
                                  flexDirection: "column",
                                  justifyContent: "space-between",
                                  transition: "background-color 0.3s",
                                  backgroundColor: hoveredItem === item.id ? "#899B3C" : "white",
                                }}
                                onMouseEnter={() => setHoveredItem(item.id)}
                                onMouseLeave={() => setHoveredItem(null)}
                                onClick={() => handleCategoriaClick(item)}
                              >
                                <img src={item.thumbnail_url ? item.thumbnail_url : DefaultCategoryThumbnail} alt={item.nome} style={{ width: "100%", height: 150, objectFit: "cover" }} />
                                <Typography variant="h6" align="center" sx={{ p: 1, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                                  {item.nome}
                                </Typography>
                              </Paper>
                            </Grid>
                          ))}
                        </Grid>
                      </Box>
                    </>
                  )}
                  {selectedCategoria && (
                    <>
                      {isProgrammiLoading ? (
                        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
                          <CircularProgress color="inherit" />
                        </Backdrop>
                      ) : (
                        <>
                          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: 4 }}>
                            <Typography sx={{ ...titoloStyle, fontSize: '24px' }}>{selectedCategoria.nome.toUpperCase()}</Typography>
                            <Box>
                              <Button startIcon={<NavigateBefore />} variant="contained" onClick={() => setSelectedCategoria()}>
                                <Box sx={{ whiteSpace: "nowrap" }}>INDIETRO</Box>
                              </Button>
                            </Box>

                          </Box>

                          <Typography sx={titoloStyle}>Quale programma vorresti attivare?</Typography>

                          <Typography sx={testoInferioreStyle}>Di seguito sono elencati i programmi che puoi acquistare. Ogni programma ha un costo specifico che varia in base alla quantità acquistata. Visualizzerai le scontistiche all'inserimento dei programmi nel carrello.</Typography>

                          <Box style={{}}>
                            {/* PROGRAMMI CODICI */}
                            {listaProgrammi.map((item) => (
                              <Grid container key={item.id} sx={{ mt: 2 }}>

                                <Grid item xs={12} sm={6} md={3}>

                                  <Box sx={{ pl: 2, pr: 1, py: 2, height: boxHeight, bgcolor: "#f0f0f0", display: 'flex', flexDirection: 'column' }}>
                                    {
                                      !showDetails ?
                                        <Box sx={{ position: 'absolute', right: '30px' }}>
                                          <Info sx={{ color: "#255300", cursor: "pointer", alignSelf: "flex-end" }} onClick={() => setInfoProgramma(item.id)} />
                                        </Box> : <></>
                                    }
                                    <Typography sx={{ ...titoloStyle, height: "2vh" }} component="div">
                                      {item.nome}
                                    </Typography>
                                    <Typography sx={{ ...testoInferioreStyle, textAlign: "left", flex: 1, overflowY: 'auto' }} color="text.secondary">
                                      {item.descrizione}
                                    </Typography>
                                  </Box>
                                </Grid>
                                {
                                  showDetails ? <Grid item xs={0} sm={6} md={3}>
                                    <Stack sx={{ px: 1, py: 2, height: boxHeight, bgcolor: "#f0f0f0", justifyContent: "space-between" }}>
                                      <Box>
                                        <Stack sx={{ flexDirection: "row", justifyContent: "space-between" }}>
                                          <Typography style={{ fontWeight: "bold", fontSize: "14px" }}>Cosa prevede: </Typography>
                                          <div style={{ display: "flex", alignItems: "center", float: "right", marginRight: "10px" }}>
                                            <AccessTime style={{ color: "#255300", marginRight: "5px", fontSize: '1rem' }} />
                                            <Typography sx={{ ...titoloStyle, fontSize: '0.9rem' }}>{item.durata} min</Typography>
                                          </div>
                                        </Stack>
                                        <div style={{ display: "flex", alignItems: "center", marginTop: 10 }}>
                                          <CheckCircle sx={{ color: "#255300" }} />
                                          <Typography style={{ marginLeft: 4, fontSize: "14px" }}> Breve intervista </Typography>
                                        </div>

                                        {item.flg_genius_machine ? (
                                          <div style={{ display: "flex", alignItems: "center", marginTop: 8 }}>
                                            <CheckCircle sx={{ color: "#255300" }} />
                                            <Typography style={{ marginLeft: 4, fontSize: "14px" }}> Genius Machine </Typography>
                                          </div>
                                        ) : (
                                          <></>
                                        )}
                                      </Box>
                                      <Info sx={{ color: "#255300", cursor: "pointer", alignSelf: "flex-end" }} onClick={() => setInfoProgramma(item.id)} />
                                    </Stack>
                                  </Grid>
                                    : <></>
                                }

                                <Grid item xs={12} sm={6} md={3}>
                                  <Box sx={{ pl: 2, pr: 1, py: 2, height: boxHeight, ...border, backgroundColor: matchMd ? 'white' : "#f0f0f0", overflow: "auto" }}>
                                    <Typography style={{ fontWeight: "bold", fontSize: "14px", paddingBottom: "3px" }}>Costo Programma:</Typography>
                                    <Typography sx={{ mb: 1, ml: 1, fontSize: "14px" }}>
                                      Cadauno <span style={{ float: "right", color: "#255300", fontWeight: "bold" }}> {formatPrezzo(item.prezzi?.singolo)}</span>
                                    </Typography>

                                    {item.prezzi.range && item.prezzi.range.length > 0 && orderedRange(item.prezzi.range)}
                                  </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                  <Stack sx={{ px: 1, py: 2, height: boxHeight, ...border, backgroundColor: matchMd ? 'white' : "#f0f0f0", justifyContent: "space-between" }}>
                                    <Stack sx={{ flexDirection: "row", alignItems: "center", mt: 2 }}>
                                      <IconButton onClick={() => handleDecrement(item.nome)}>
                                        <RemoveCircleOutline />
                                      </IconButton>
                                      <TextField
                                        margin="none"
                                        size="small"
                                        inputProps={{ "aria-label": "quantity", style: { textAlign: "center" } }}
                                        sx={{ width: "100px", mx: 0 }}
                                        onKeyPress={handleKeyPress}
                                        onChange={(e) => {
                                          // Aggiorna il valore nel tuo stato quando il campo di testo cambia
                                          setValoriTextField({ ...valoriTextField, [item.nome]: e.target.value });
                                        }}
                                        value={valoriTextField[item.nome] || 1}
                                      />
                                      <IconButton onClick={() => handleIncrement(item.nome)}>
                                        <AddCircleOutline />
                                      </IconButton>
                                    </Stack>
                                    <Box sx={{ alignSelf: "flex-end", display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
                                      {
                                        item.flg_gdpr ?

                                          <FormControl
                                            required
                                            error={informativaError[item.id]}
                                            component="fieldset"
                                            sx={{ mt: 1 }}
                                            variant="standard"
                                          >
                                            <FormLabel component="legend" sx={{ fontSize: '0.6rem' }}>Informativa Privacy</FormLabel>
                                            <FormGroup>
                                              <FormControlLabel
                                                control={
                                                  <Checkbox size="small" checked={informativaClicked[item.id]} onChange={() => {
                                                    setInformativaClicked((prevState) => ({ ...prevState, [item.id]: !informativaClicked[item.id] }));
                                                    setInformativaError((prevState) => ({ ...prevState, [item.id]: false }));
                                                  }} name="gilad" />
                                                }
                                                label={<span style={{ fontSize: '0.6rem' }}> Presto il consenso al trattamento dei dati relativi alla salute secondo l’art. 9 del GDPR.</span>}
                                              />

                                            </FormGroup>
                                            {
                                              informativaError[item.id] && <FormHelperText sx={{ fontSize: '0.6rem' }}>Richiesto</FormHelperText>
                                            }
                                          </FormControl>
                                          : <></>
                                      }
                                      <Box>
                                        <Button size={matchSm ? 'medium' : 'small'} variant="contained" startIcon={<ShoppingCart />} onClick={() => handleAddItem(item, "cod", "")}>
                                          Aggiungi
                                        </Button>
                                      </Box>
                                    </Box>

                                  </Stack>
                                </Grid>
                              </Grid>
                            ))}

                            {/* ABBONAMENTI */}
                            {user?.tipologia !== "PP" &&
                              listaProgrammi
                                .filter((element) => {
                                  return element.prezzi.abbonamento_settimanale || element.prezzi.abbonamento_mensile || element.prezzi.abbonamento_trimestrale || element.prezzi.abbonamento_semestrale || element.prezzi.abbonamento_annuale || element.prezzi.abbonamento_vita;
                                })
                                .map((item) => {
                                  return (
                                    <Box key={item.id}>
                                      <Grid container sx={{ mt: 2 }}>
                                        <Grid item xs={12} sm={6} md={3}>
                                          <Box sx={{ pl: 2, pr: 1, py: 2, height: boxHeight, bgcolor: "#edf0dc", display: 'flex', flexDirection: 'column' }}>
                                            {
                                              !showDetails ?
                                                <Box sx={{ position: 'absolute', right: '30px' }}>
                                                  <Info sx={{ color: "#255300", cursor: "pointer", alignSelf: "flex-end" }} onClick={() => setInfoProgramma(item.id)} />
                                                </Box> : <></>
                                            }
                                            <Typography sx={titoloStyle} component="div">
                                              {item.nome}
                                            </Typography>
                                            <Typography sx={{ ...testoInferioreStyle, textAlign: "left", flex: 1, overflowY: 'auto' }} color="text.secondary">
                                              {item.descrizione}
                                            </Typography>
                                          </Box>
                                        </Grid>

                                        {showDetails ? <Grid item xs={0} sm={6} md={3}>
                                          <Stack sx={{ px: 1, py: 2, height: boxHeight, bgcolor: "#edf0dc", justifyContent: "space-between" }}>
                                            <Box>
                                              <Stack sx={{ flexDirection: "row", justifyContent: "space-between" }}>
                                                <Typography style={{ fontWeight: "bold", fontSize: "14px" }}>Cosa prevede: </Typography>
                                                <div style={{ display: "flex", alignItems: "center", float: "right", marginRight: "10px" }}>
                                                  <AccessTime style={{ color: "#255300", marginRight: "5px", fontSize: '1rem' }} />
                                                  <Typography sx={{ ...titoloStyle, fontSize: '0.9rem' }}>{item.durata} min</Typography>
                                                </div>
                                              </Stack>
                                              <div style={{ display: "flex", alignItems: "center", marginTop: 10 }}>
                                                <CheckCircle sx={{ color: "#255300" }} />
                                                <Typography style={{ marginLeft: 4, fontSize: "14px" }}> Breve intervista </Typography>
                                              </div>

                                              {item.flg_genius_machine ? (
                                                <div style={{ display: "flex", alignItems: "center", marginTop: 8 }}>
                                                  <CheckCircle sx={{ color: "#255300" }} />
                                                  <Typography style={{ marginLeft: 4, fontSize: "14px" }}> Genius Machine </Typography>
                                                </div>
                                              ) : (
                                                <></>
                                              )}
                                            </Box>
                                            <Info sx={{ color: "#255300", cursor: "pointer", alignSelf: "flex-end" }} onClick={() => setInfoProgramma(item.id)} />
                                          </Stack>
                                        </Grid> : <></>}
                                        <Grid item xs={12} sm={12} md={6}>
                                          <Stack sx={{ px: 1, py: 2, height: boxHeight, ...border, justifyContent: "space-between", bgcolor: matchMd ? 'white' : '#edf0dc' }}>
                                            {abbonamentiSelezionati && (
                                              <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'space-between' }}>
                                                <Box>


                                                  <Typography style={{ fontWeight: "bold", fontSize: "14px" }}>Costo Abbonamento Programma:</Typography>
                                                  <Stack sx={{ flexDirection: "row", alignItems: "center", mt: 2, justifyContent: 'space-between' }}>
                                                    <Select size="small" value={abbonamentiSelezionati[item.id] || "SEL"} onChange={(event) => handleTipoAbbonamentoChange(item, event.target.value)} style={{ width: "60%" }}>
                                                      <MenuItem disabled value="SEL">
                                                        <em>Tipo abbonamento</em>
                                                      </MenuItem>
                                                      {getTipiAbbonamento(item).map((x) => (
                                                        <MenuItem key={x.tipo} value={x.tipo}>
                                                          {x.tipo}
                                                        </MenuItem>
                                                      ))}
                                                    </Select>
                                                    <Typography style={{ color: "#255300", fontWeight: "bold", marginLeft: 32 }}>
                                                      {
                                                        formatPrezzo(getTipiAbbonamento(item).find((x) => x.tipo === abbonamentiSelezionati[item.id])?.prezzo)
                                                      }
                                                    </Typography>
                                                  </Stack>
                                                  <Box sx={{ mt: 2, flex: 4, pr: 2 }}>
                                                    {abbonamentiSelezionati[item.id] === "SEL" && <Typography style={{ marginLeft: 4, fontSize: "14px" }}>Selezionare il tipo di abbonamento da acquistare</Typography>}
                                                    {abbonamentiSelezionati[item.id] === "Settimanale" && <Typography style={{ marginLeft: 4, fontSize: "14px" }}>Attiva un numero predefinito di programmi alla settimana a tua scelta</Typography>}

                                                    {abbonamentiSelezionati[item.id] === "Mensile" && <Typography style={{ marginLeft: 4, fontSize: "14px" }}>Attiva un numero predefinito di programmi al mese a tua scelta</Typography>}

                                                    {abbonamentiSelezionati[item.id] === "Trimestrale" && <Typography style={{ marginLeft: 4, fontSize: "14px" }}>Attiva un numero predefinito di programmi al trimestre a tua scelta</Typography>}

                                                    {abbonamentiSelezionati[item.id] === "Semestrale" && <Typography style={{ marginLeft: 4, fontSize: "14px" }}>Attiva un numero predefinito di programmi al semestre a tua scelta</Typography>}

                                                    {abbonamentiSelezionati[item.id] === "Annuale" && <Typography style={{ marginLeft: 4, fontSize: "14px" }}>Attiva un numero predefinito di programmi all'anno a tua scelta</Typography>}

                                                    {abbonamentiSelezionati[item.id] === "A Vita" && <Typography style={{ marginLeft: 4, fontSize: "14px" }}>Attiva un numero predefinito di programmi a vita a tua scelta</Typography>}
                                                  </Box>
                                                </Box>

                                                <Box sx={{ justifyContent: "space-between", display: 'flex', flexDirection: 'row', alignItems: 'end' }}>
                                                  {
                                                    item.flg_gdpr ?

                                                      <FormControl
                                                        required
                                                        error={informativaError[item.id + "_abb"]}
                                                        component="fieldset"
                                                        sx={{ mt: 1 }}
                                                        variant="standard"
                                                      >
                                                        <FormLabel component="legend" sx={{ fontSize: '0.6rem' }}>Informativa Privacy</FormLabel>
                                                        <FormGroup>
                                                          <FormControlLabel
                                                            control={
                                                              <Checkbox size="small" checked={informativaClicked[item.id + "_abb"]} onChange={() => {
                                                                setInformativaClicked((prevState) => ({ ...prevState, [item.id + "_abb"]: !informativaClicked[item.id + "_abb"] }));
                                                                setInformativaError((prevState) => ({ ...prevState, [item.id + "_abb"]: false }));
                                                              }} name="gilad" />
                                                            }
                                                            label={<span style={{ fontSize: '0.6rem' }}> Presto il consenso al trattamento dei dati relativi alla salute secondo l’art. 9 del GDPR.</span>}
                                                          />
                                                        </FormGroup>
                                                        {
                                                          informativaError[item.id + "_abb"] && <FormHelperText sx={{ fontSize: '0.6rem' }}>Richiesto</FormHelperText>
                                                        }
                                                      </FormControl>
                                                      : <></>
                                                  }
                                                  <Box>
                                                    <Button size={matchSm ? 'medium' : 'small'} variant="contained" startIcon={<ShoppingCart />} onClick={() => handleAddItem(item, "abb", abbonamentiSelezionati[item.id])}>
                                                      Aggiungi
                                                    </Button>
                                                  </Box>
                                                </Box>


                                              </Box>
                                            )}


                                          </Stack>
                                        </Grid>
                                      </Grid>
                                    </Box>
                                  );
                                })}

                            {listaProgrammi.length == 0 ? <Typography sx={{ mt: 4, fontSize: "18px" }}>Nessun programma presente</Typography> : <></>}
                          </Box>
                        </>
                      )}
                    </>
                  )}
                </Box>
              </Grid>
            </>

          </Grid>
        </Box>

        {infoProgramma && <DialogInfoProgramma id_programma={infoProgramma} isOpen={setInfoProgramma} />}

        <Dialog open={openErrorDialog} onClose={() => setOpenErrorDialog(false)} aria-labelledby="error-dialog-title" aria-describedby="error-dialog-description">
          <DialogTitle id="error-dialog-title">Attenzione!</DialogTitle>
          <DialogContent>
            <DialogContentText id="error-dialog-description">{messageErrorDialog}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenErrorDialog(false)} color="primary">
              Chiudi
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
      <Footer />
    </>
  );
}

const boxStyle = {
  marginTop: "1%",
  padding: "1rem",
  width: "100%",
  backgroundColor: "white",
  position: "relative",
};

const titoloStyle = {
  color: "#255300",
  fontSize: "1rem",
  fontWeight: "bold",
};

const testoInferioreStyle = {
  color: "rgb(124,124,124)",
  fontSize: "1rem",
  marginTop: "1%",
};

const paginaStyle = {
  backgroundColor: "white",
  height: "100%",
};
