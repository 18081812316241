import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { annullaPrestazione, getPrestazioneDetails } from "./prestazioneAPI";
import { useDispatch, useSelector } from "react-redux";
import { getAPIErrorMessage } from "../../common/utils";
import { Grid, CircularProgress, Backdrop, Box, Stepper, Button, Typography, Step, StepButton, Stack, Menu, MenuItem, IconButton, useMediaQuery, MobileStepper } from "@mui/material";
import DialogGeneral from "../../common/DialogGeneral";
import { useTheme } from "@emotion/react";
import { Questionario } from "./steps/Questionario";
import { DatiIniziali } from "./steps/DatiIniziali";
import { GeniusMachine } from "./steps/GeniusMachine";
import { Completa } from "./steps/Completa";
import { Referto } from "./steps/Referto";
import { Cancel, Check, KeyboardArrowLeft, KeyboardArrowRight, MoreVert } from "@mui/icons-material";
import { setSnackbarData } from "../programmi/programmiSlice";
import { setPrestazioneAttiva } from "./prestazioneSlice";
import Tommy from "../../common/Tommy";
import { Footer } from "../../pages/homepage/Footer";
import { CONFIG } from "../../config/envConfig";

export function Prestazione() {
  const theme = useTheme();
  const c = theme.palette;
  const location = useLocation();
  const navigate = useNavigate();
  const state = location?.state;
  const { token } = useSelector((state) => state.login);
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const [dialogTitle, setDialogTitle] = React.useState("");
  const [dialogText, setDialogText] = React.useState("");
  const [dialogAction, setDialogAction] = React.useState(null);
  const [dialogActionClose, setDialogActionClose] = React.useState(null);
  const [steps, setSteps] = React.useState([]);
  const [activeStep, setActiveStep] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const [prestazione, setPrestazione] = React.useState(null);
  const [readOnly, setReadOnly] = React.useState(false);
  const [anchorMenu, setAnchorMenu] = React.useState(null);
  const [dialogRejectText, setDialogRejectText] = React.useState("Annulla");
  const menuOpen = Boolean(anchorMenu);
  const dispatch = useDispatch();
  const stepperRef = useRef();
  const [tommyWidth, setTommyWidth] = useState(0);
  const matchMd = useMediaQuery(theme.breakpoints.up("md"));
  const containerRef = useRef(null);
  const contentRef = useRef(null);
  const [contentMinH, setContentMinH] = useState(null);

  useEffect(() => {
    if (prestazione?.stato >= 80) {
      setReadOnly(true);
    }
  }, [prestazione]);

  const handleNext = async () => {
    if (matchMd) {
      stepRef.current.scrollTop = 0;
    } else {
      containerRef.current.scrollTop = 0;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const init = async () => {
    setIsLoading(true);

    const _prestazione = await getPrestazione();
    if (_prestazione === null) return;
    if (_prestazione.programma.flg_genius_machine) {
      if (_prestazione.stato === 20 || _prestazione.stato === 30) {
        setActiveStep(2);
      } else if (_prestazione.stato === 35) {
        setActiveStep(3);
      } else if (_prestazione.stato === 80) {
        setActiveStep(4);
      } else if (_prestazione.stato === 90) {
        setActiveStep(5);
      }
    } else {
      if (_prestazione.stato === 20) {
        setActiveStep(2);
      } else if (_prestazione.stato === 80) {
        setActiveStep(3);
      } else if (_prestazione.stato === 90) {
        setActiveStep(4);
      }
    }

    setIsLoading(false);
  };

  const getPrestazione = async () => {
    let response;
    try {
      response = await getPrestazioneDetails(state?.id_prestazione, token);
    } catch (err) {
      let msg = getAPIErrorMessage(err);
      msg += ". Verrai reindirizzato alla pagina iniziale";
      showGoBackDialog(msg);
      dispatch(setPrestazioneAttiva(null));
      return null;
    }

    const prestazione = response.data;
    setPrestazione(prestazione);

    const step = [];

    step.push({
      label: "Dati Iniziali",
      key: "INIT",
      stato: 10,
    });

    step.push({
      label: "Intervista",
      key: "QUEST",
      stato: 20,
    });

    if (prestazione.programma.flg_genius_machine) {
      step.push({
        label: "Esecuzione test Genius Machine",
        key: "GENIUS",
        stato: 35,
      });
    }

    step.push({
      label: "Completa",
      key: "COMPL",
      stato: 80
    });

    step.push({
      label: "Elaborato",
      key: "REF",
      stato: 90,
    });

    setSteps(step);

    return prestazione;
  };

  const showGoBackDialog = (msg) => {
    setDialogTitle("Errore");
    setDialogText(msg);
    setDialogAction(() => () => navigate("/tommy/homepage"));
    setDialogActionClose(() => () => navigate("/tommy/homepage"));
    setDialogRejectText(null);
    setIsDialogOpen(true);
  };

  useEffect(() => {
    init();
  }, []);

  const handleAnnullaPrestazione = async () => {
    handleMenuClose();
    setDialogTitle("Attenzione");
    setDialogText("Sei sicuro di voler annullare la prestazione? Se si ha utilizzato un codice, questo ritornerà disponibile per una nuova prestazione");
    setDialogAction(() => () => {
      onAnnullaPrestazioneConfirm();
    });
    setDialogActionClose(() => () => {
      setIsDialogOpen(false);
    });
    setDialogRejectText("Annulla");
    setIsDialogOpen(true);
  };

  const onAnnullaPrestazioneConfirm = async () => {
    setIsDialogOpen(false);
    try {
      await annullaPrestazione(prestazione.id, token);
      dispatch(setSnackbarData({ message: "Prestazione annullata con successo", severity: "success", vertical: "bottom" }));
      dispatch(setPrestazioneAttiva(null));
      navigate("/tommy/homepage");
    } catch (err) {
      let msg = getAPIErrorMessage(err);
      dispatch(setSnackbarData({ message: msg, severity: "error", vertical: "bottom" }));
    }
  };

  const divRef = useRef(null);
  const stepRef = useRef(null);
  const [gridH, setGridH] = useState(null);
  // const [cardsList, setCardsList] = useState(data);

  useEffect(() => {
    const calculateDistance = () => {
      const element = divRef.current;
      if (element) setGridH(window.innerHeight - element.getBoundingClientRect().top);
      if (stepperRef.current) setTommyWidth(stepperRef.current.getBoundingClientRect().width);
    };
    calculateDistance();

    window.addEventListener("resize", calculateDistance);
    return () => {
      window.removeEventListener("resize", calculateDistance);
    };
  }, []);

  const handleMenuClick = (event) => {
    setAnchorMenu(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorMenu(null);
  };

  const [screenH, setScreenH] = useState(null);
  const screenRef = useRef();


  const handleResize = () => {
    if (screenRef.current) {
      setScreenH(window.innerHeight - screenRef.current.getBoundingClientRect().top);
    }

    if (contentRef.current) {
      setContentMinH(window.innerHeight - contentRef.current.getBoundingClientRect().top - CONFIG.FOOTER_HEIGHT);
    }
  };

  console.log(contentMinH)

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [screenRef, contentRef, isLoading, activeStep]);




  const canClickOnStep = (stepStato) => {
    //ritorno true a tutti gli step completato + il primo non completato
    if (prestazione?.stato >= stepStato) {
      return true;
    }

    //prendo il primo stato successivo a quello attuale
    const nextStep = steps.find((s) => s.stato > prestazione?.stato);
    if (nextStep?.stato === stepStato) {
      return true;
    }

    return false;

  }

  return (
    <div style={{ display: "flex", flexDirection: "column" }} height={screenH} ref={screenRef}>
      <Box sx={{ flex: 1 }}>
        {isLoading && (
          <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        {
          <Stack ref={divRef} sx={{ mr: -2 }}>
            <Grid container ref={containerRef} spacing={0} height={screenH} sx={{ overflowY: matchMd ? 'hidden' : "auto", display: matchMd ? 'flex' : 'inline' }}>
              <Grid height={matchMd ? screenH : 'fit-content'} item xs={12} sm={12} md={3} sx={{ overflowY: matchMd ? 'auto' : "none", backgroundColor: c.secondary.main }}>
                <Box ref={stepperRef} sx={{ backgroundColor: c.secondary.main, height: matchMd ? screenH : 'auto', width: '100%', display: 'flex', flexDirection: 'column' }}>
                  <Box sx={{ p: 4, position: matchMd ? "sticky" : 'inherit', top: 0, backgroundColor: c.secondary.main }}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                      <Typography sx={{ color: "white" }}>Step Programma <br /> {prestazione?.programma.nome.toUpperCase()}</Typography>

                      <>
                        <IconButton id="demo-customized-button" aria-controls={menuOpen ? "demo-customized-menu" : undefined} aria-haspopup="true" aria-expanded={menuOpen ? "true" : undefined} variant="contained" sx={{ color: "white", mr: -2 }} onClick={handleMenuClick}>
                          <MoreVert />
                        </IconButton>
                        <Menu id="demo-customized-menu" MenuListProps={{ "aria-labelledby": "demo-customized-button" }} anchorEl={anchorMenu} open={menuOpen} onClose={handleMenuClose}>
                          <MenuItem onClick={handleAnnullaPrestazione} disableRipple disabled={prestazione?.stato >= 80} sx={{ fontSize: '1rem' }}>
                            <Cancel sx={{ mr: 2 }} />
                            Annulla Prestazione
                          </MenuItem>
                        </Menu>
                      </>
                    </Stack>

                    {steps.length > 0 && (
                      <>
                        {
                          matchMd ?

                            <Stepper nonLinear={true} activeStep={activeStep} orientation="vertical" sx={{ mt: 2 }}>
                              {steps.map((step, index) => (
                                <Step key={step.label}>
                                  <StepButton sx={{ cursor: canClickOnStep(step.stato) ? "pointer" : "default" }} onClick={() => canClickOnStep(step.stato) && setActiveStep(index)}>
                                    <Box sx={{ display: 'flex' }}>
                                      {prestazione?.stato >= step.stato ? <Check sx={{ color: "white" }} /> : <></>}
                                      <Typography sx={{ color: "white", ml: 2 }}>
                                        {step.label}
                                      </Typography>
                                    </Box>

                                  </StepButton>
                                </Step>
                              ))}
                            </Stepper> :
                            <>
                              <Box sx={{ width: '100%', p: 2, textAlign: 'center', color: 'white' }}>
                                {steps[activeStep]?.label}
                              </Box>
                              <MobileStepper variant="text"
                                steps={steps.length + 1}
                                position="static"
                                activeStep={activeStep}
                                sx={{ backgroundColor: 'transparent', color: 'white' }}
                                nextButton={
                                  <Button
                                    size="small"
                                    onClick={handleNext}
                                    sx={{ color: 'white' }}
                                    disabled={activeStep > steps.length - 1 || canClickOnStep(steps[activeStep + 1]?.stato) === false}
                                  >
                                    Avanti
                                    {theme.direction === 'rtl' ? (
                                      <KeyboardArrowLeft />
                                    ) : (
                                      <KeyboardArrowRight />
                                    )}
                                  </Button>
                                }
                                backButton={
                                  <Button size="small" onClick={handleBack} disabled={activeStep === 0} sx={{ color: 'white' }}>
                                    {theme.direction === 'rtl' ? (
                                      <KeyboardArrowRight />
                                    ) : (
                                      <KeyboardArrowLeft />
                                    )}
                                    Indietro
                                  </Button>
                                }
                              />
                            </>
                        }
                      </>
                    )}
                  </Box>
                  {
                    matchMd ? <Box sx={{ flex: 1 }}>  </Box> : <> </>
                  }

                  {!(steps[activeStep]?.key === "INIT" || steps[activeStep]?.key === "COMPL" || steps[activeStep]?.key === "REF" || activeStep === steps.length) && !isLoading ? (
                    <Tommy
                      tommyHeight={matchMd ? '100px' : '75px'}
                      boxStyle={{ position: "relative", bottom: "30px", left: "10px" }}
                      tipPos="right-start"
                      tipStyle={{ marginLeft: "10px", maxWidth: '70%', }}
                      content={
                        steps[activeStep]?.key === "QUEST" ? (
                          <>
                            <Typography sx={{ fontSize: '1rem' }}>
                              {
                                readOnly ? "Intervista" : " Completa l'Intervista"
                              }
                            </Typography>
                            <Typography variant="l" sx={{ fontSize: '0.8rem' }}>
                              {
                                readOnly ?
                                  "Ripercorri le risposte date." : 'Rispondi in modo sincero alle domande, gli elaborati faranno riferimento alle risposte che darai!'
                              }

                            </Typography>
                          </>
                        ) : steps[activeStep]?.key === "GENIUS" ? (
                          <Typography sx={{ fontSize: '0.8rem' }}>
                            {
                              readOnly ? "Grazie per aver usato la Genius Machine!" : " Segui tutti i passaggi riportati."
                            }
                          </Typography>
                        ) : (
                          <></>
                        )
                      }
                    />
                  ) : (
                    <></>
                  )}
                </Box>
              </Grid>

              <Grid ref={stepRef} height={matchMd ? screenH : 'auto'} item xs={12} sm={12} md={9} sx={{ overflowY: matchMd ? 'auto' : "none" }}>
                <Stack>
                  <Box ref={contentRef} sx={[{ p: 4, minHeight: matchMd ? screenH - CONFIG.FOOTER_HEIGHT : contentMinH }, (activeStep === steps.length || steps[activeStep]?.key === "COMPL" || steps[activeStep]?.key === "REF") && { position: "sticky", top: 0 }]}>
                    {steps[activeStep]?.key === "INIT" && <DatiIniziali prestazione={prestazione} setPrestazione={setPrestazione} readOnly={readOnly} onSuccess={handleNext} />}
                    {steps[activeStep]?.key === "QUEST" && <Questionario onChangeDomanda={() => { if (matchMd) { stepRef.current.scrollTop = 0; } else { } }} prestazione={prestazione} readOnly={readOnly} setPrestazione={setPrestazione} onSuccess={handleNext} />}
                    {steps[activeStep]?.key === "GENIUS" && <GeniusMachine prestazione={prestazione} readOnly={readOnly} setPrestazione={setPrestazione} onSuccess={handleNext} />}
                    {steps[activeStep]?.key === "COMPL" && <Completa prestazione={prestazione} readOnly={readOnly} setPrestazione={setPrestazione} onSuccess={handleNext} />}
                    {steps[activeStep]?.key === "REF" && <Referto prestazione={prestazione} readOnly={readOnly} setPrestazione={setPrestazione} onSuccess={handleNext} />}
                    {!isLoading && activeStep === steps.length && (

                      <Tommy
                        tipBgColor={"#fff"}
                        boxStyle={{ position: "relative", paddingTop: "5%" }}
                        tipPos={matchMd ? "right-start" : 'bottom'}
                        content={
                          <>
                            <Typography style={{ color: c.primary.main, fontSize: "26px", fontWeight: "bold" }} sx={{}}>
                              Tutti gli step sono stati completati
                            </Typography>
                            <Button variant="contained" sx={{ mt: 3 }} onClick={() => navigate("/tommy/homepage")}>
                              Torna alla Home
                            </Button>
                          </>
                        }
                      />
                    )}
                  </Box>
                  <Footer />
                </Stack>
              </Grid>
            </Grid>
          </Stack>
        }

        <DialogGeneral open={isDialogOpen} title={dialogTitle} text={dialogText} cbClose={dialogActionClose} rejectButtonText={dialogRejectText} confirmButtonText="Continua" cbAction={dialogAction} />
      </Box>
    </div>
  );
}
