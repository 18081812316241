import { Add, CloudUploadOutlined } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { aggiornaMedia, creaMedia } from "../../dashboardAdminAPI";
import { useDispatch, useSelector } from "react-redux";
import { getAPIErrorMessage } from "../../../../common/utils";
import { getListaMedia } from "../../dashboardAdminSlice";

const initState = { id: null, codice_media: "", oggetto: "" };

export default function MediaAdd({ isDialogOpen, setIsDialogOpen, selectedMedia = initState, setSelectedMedia = null, sxBtn = {}, acceptedTypes } = {}) {
  const dispatch = useDispatch();
  const [mediaData, setMediaData] = useState(selectedMedia);
  const [dialogError, setDialogError] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const tokenAdmin = useSelector((state) => state.loginadmin.tokenAdmin);

  useEffect(() => {
    setMediaData(selectedMedia);
  }, [isDialogOpen]);

  const saveMedia = async () => {
    if (selectedFile == null && mediaData.id == null) {
      setDialogError("Selezionare un file.");
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("codice_media", mediaData.codice_media ?? "");
    formData.append("oggetto", mediaData.oggetto ?? "");

    try {
      if (mediaData.id == null) {
        await creaMedia(formData, tokenAdmin);
      } else {
        await aggiornaMedia(mediaData.id, formData, tokenAdmin);
      }
    } catch (error) {
      setDialogError(getAPIErrorMessage(error));
      return;
    }

    dispatch(getListaMedia());
    onClose();
  };

  const onFileChange = (event) => {
    setDialogError("");
    setSelectedFile(event.target.files[0]);
  };

  const onClose = () => {
    setSelectedFile(null);
    setMediaData(initState);
    if (setSelectedMedia != null) setSelectedMedia(initState);
    setDialogError("");
    setIsDialogOpen(false);
  };

  return (
    <>
      <Button
        sx={{ ...sxBtn }}
        fullWidth
        variant="contained"
        endIcon={<Add />}
        onClick={() => {
          setMediaData(initState);
          setIsDialogOpen(true);
        }}
      >
        Aggiungi Media
      </Button>

      {/* Dialog per l'edit */}
      <Dialog open={isDialogOpen} maxWidth="sm" fullWidth>
        <DialogTitle variant="dialogTitle">{mediaData.id != null ? "Modifica Media" : "Aggiungi Media"}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ pt: 1 }}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField label="Codice Media" name="codice_media" value={mediaData.codice_media ?? ""} onChange={(e) => setMediaData({ ...mediaData, codice_media: e.target.value })} />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField label="Oggetto" name="oggetto" value={mediaData.oggetto ?? ""} onChange={(e) => setMediaData({ ...mediaData, oggetto: e.target.value })} />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                {(mediaData.id != null || selectedFile != null) && <TextField InputProps={{ readOnly: true }} label="Nome File" value={selectedFile?.name || mediaData?.nome_file || ""} sx={{ mb: 2 }} />}

                <Button component="label" variant="contained" startIcon={<CloudUploadOutlined />}>
                  Scegli un file
                  <input type="file" onChange={onFileChange} style={{ display: "none" }} accept={acceptedTypes || "image/*, audio/*, text/*, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf"} />
                </Button>
              </FormControl>
            </Grid>
          </Grid>

          <Typography color="error" style={{ whiteSpace: "pre-line" }} sx={{ mt: 3 }}>
            {dialogError}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} sx={{ color: "#000" }}>
            Annulla
          </Button>
          <Button variant="contained" onClick={() => saveMedia()}>
            SALVA
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
