import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Box, Button, Grid, IconButton, InputAdornment, Link, Modal, Paper, TextField, Typography } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { checkAdmin } from "../../services/api";
import { useTheme } from "@emotion/react";
import { login, sendForgotPassword, setForgotSuccess, setPasswordAsync } from "./loginAdminSlice";

export function LoginAdmin() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const c = theme.palette;
  const { isLoading, isLoadingForgot, isLoadingSetPsw, error, errorForgot, errorSetPsw, resetPassword, loginSuccess, resetSuccess, token } = useSelector((state) => state.loginadmin);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [psw, setPsw] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [errorForm, setErrorForm] = useState(false);
  const [errorForgotForm, setErrorForgotForm] = useState(false);
  const [errorSetPswForm, setErrorSetPswForm] = useState(false);

  const [disabled, setDisabled] = useState(true);
  const [disabledForgot, setDisabledForgot] = useState(true);
  const [disabledNewPsw, setDisabledNewPsw] = useState(true);

  const [forgotPassword, setForgotPassword] = useState(false);
  const [openModalForgot, setOpenModalForgot] = useState(false);

  const [pswVis, setPswVis] = useState(false);
  const [newPswVis, setNewPswVis] = useState(false);
  const [repeatPswVis, setRepeatPswVis] = useState(false);

  useEffect(() => {
    const checkSession = async () => {
      if (token) {
        const checked = await checkAdmin(token);
        if (checked) {
          navigate("/admin/dashboard");
        }
      }
    };
    checkSession();
  }, []);

  useEffect(() => {
    if (username !== "" && psw !== "" && !errorForm) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [username, psw, errorForm]);

  useEffect(() => {
    if (email !== "" && !errorForgotForm) {
      setDisabledForgot(false);
    } else {
      setDisabledForgot(true);
    }
  }, [email]);

  useEffect(() => {
    if (newPassword !== "" && confirmPassword !== "" && !errorSetPswForm) {
      setDisabledNewPsw(false);
    } else {
      setDisabledNewPsw(true);
    }
  }, [newPassword, confirmPassword]);

  useEffect(() => {
    if (loginSuccess) {
      navigate("/admin/dashboard");
      // dispatch(setLoginSuccess(false));
    }
  }, [loginSuccess]);

  useEffect(() => {
    if (resetSuccess) {
      setForgotPassword(false);
      setOpenModalForgot(true);
      dispatch(setForgotSuccess(false));
    }
  }, [resetSuccess]);

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(login({ username: username, password: psw }));
  };

  const handleUserReset = (event) => {
    event.preventDefault();
    dispatch(sendForgotPassword({ email }));
  };

  const handleSetPassword = (event) => {
    event.preventDefault();
    dispatch(setPasswordAsync({ newPassword, confirmPassword }));
  };

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <Grid
        container
        item
        justifyContent="center"
        alignItems="center"
        sm={false}
        md={7}
        sx={{
          backgroundColor: (theme) => theme.palette.text.background,
          display: { xs: "none", sm: "none", md: "flex" },
          position: "relative",
          flexDirection: "column",
        }}
      >
        <Typography sx={{ fontFamily: "Poppins-SemiBold", fontSize: "23px", p: 2 }}>Questa è l'area riservata agli amministratori</Typography>
        <Box onClick={() => navigate("/tommy/login")}>
          <Typography sx={{ textDecoration: "underline", color: c.primary.main, cursor: "pointer", p: 2 }}>Se sei un utente normale, clicca qui per accedere alla piattaforma</Typography>
        </Box>
      </Grid>

      <Grid item sm={12} md={5} component={Paper} elevation={6} square container justifyContent="center" alignItems="center" flexDirection="column">
        {/* Login */}
        {!resetPassword && !forgotPassword && (
          <>
            <Box
              sx={{
                my: 8,
                mx: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
              }}
            >
              <Typography component="h1" variant="h4" color="secondary.main" fontFamily="Poppins-Medium" sx={{ marginBottom: "1rem" }}>
                Benvenuto,
              </Typography>
              <Typography align="center" color="text.sub" component="p" textAlign="left">
                Inserisci le tue credenziali per accedere all'area riservata
              </Typography>
              <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                <TextField
                  error={errorForm}
                  margin="normal"
                  fullWidth
                  id="username"
                  label="Nome Utente"
                  name="username"
                  autoComplete="username"
                  autoFocus
                  value={username}
                  onChange={(event) => {
                    setUsername(event.target.value);
                    if (event.target.value.length > 0) {
                      setErrorForm(false);
                    } else {
                      setErrorForm(true);
                    }
                  }}
                  sx={{
                    backgroundColor: "text.background",
                  }}
                />
                <TextField
                  margin="normal"
                  // required
                  fullWidth
                  name="password"
                  label="Password"
                  type={pswVis ? "text" : "password"}
                  id="password"
                  autoComplete="current-password"
                  value={psw}
                  onChange={(event) => {
                    setPsw(event.target.value);
                  }}
                  sx={{ backgroundColor: "text.background" }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton aria-label="toggle password visibility" onClick={() => setPswVis(!pswVis)} edge="end">
                          {pswVis ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Button type="submit" fullWidth variant="contained" disabled={disabled || isLoading} sx={{ mt: 3, mb: 2, backgroundColor: "primary.dark", height: "50px" }}>
                  <Typography>{isLoading ? "Loading..." : "ACCEDI"}</Typography>
                </Button>
                <Grid container>
                  <Grid item xs>
                    <Link variant="body2" onClick={() => setForgotPassword(true)} sx={{ cursor: "pointer" }}>
                      Password dimenticata?
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            {error && (
              <Typography align="center" color="text.sub" component="p" sx={{ color: (t) => t.palette.text.error }}>
                {error.message ? error.message : "Login Error"}
              </Typography>
            )}
          </>
        )}

        {/* email to reset password */}
        {forgotPassword && (
          <>
            <Box
              sx={{
                my: 8,
                mx: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
              }}
            >
              <Typography component="h1" variant="h4" color="secondary.main" fontFamily="Poppins-Medium" sx={{ marginBottom: "1rem" }}>
                Password dimenticata
              </Typography>
              <Typography align="center" color="text.sub" componennt="p" textAlign="left">
                Inserisci la tua email per resettare la password
              </Typography>
              <Box component="form" noValidate onSubmit={handleUserReset} sx={{ mt: 1 }}>
                <TextField
                  error={errorForgotForm}
                  margin="normal"
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  autoComplete="email"
                  value={email}
                  onChange={(event) => {
                    setEmail(event.target.value);
                    if (event.target.value) {
                      setErrorForgotForm(false);
                    } else {
                      setErrorForgotForm(true);
                    }
                  }}
                  sx={{
                    backgroundColor: "text.background",
                  }}
                />
                <Button type="submit" fullWidth variant="contained" disabled={disabledForgot || isLoadingForgot} sx={{ mt: 3, mb: 2, backgroundColor: "primary.dark", height: "50px" }}>
                  {isLoadingForgot ? "Loading..." : "Send Email"}
                </Button>
                <Grid container>
                  <Grid item xs>
                    <Link variant="body2" onClick={() => setForgotPassword(false)} sx={{ cursor: "pointer" }}>
                      Indietro
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            {errorForgot && (
              <Typography align="center" color="text.sub" component="p" sx={{ color: (t) => t.palette.text.error }}>
                {errorForgot}
              </Typography>
            )}
          </>
        )}

        {/* set new password */}
        {resetPassword && (
          <>
            <Box
              sx={{
                my: 8,
                mx: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
              }}
            >
              <Typography component="h1" variant="h4" color="secondary.main" fontFamily="Poppins-Medium" sx={{ marginBottom: "1rem" }}>
                Inserisci una nuova password
              </Typography>
              <Typography align="center" color="text.sub" component="p" textAlign="left">
                Inserisci la tua nuova password e conferma
              </Typography>
              <Box component="form" noValidate onSubmit={handleSetPassword} sx={{ mt: 1 }}>
                <TextField
                  error={errorSetPswForm}
                  margin="normal"
                  fullWidth
                  name="password"
                  label="Password"
                  type={newPswVis ? "text" : "password"}
                  id="password"
                  autoComplete="current-password"
                  autoFocus
                  value={newPassword}
                  onChange={(event) => {
                    setNewPassword(event.target.value);
                  }}
                  sx={{
                    backgroundColor: "text.background",
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton aria-label="toggle password visibility" onClick={() => setNewPswVis(!newPswVis)} edge="end">
                          {newPswVis ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  error={errorSetPswForm}
                  margin="normal"
                  fullWidth
                  name="repeatpassword"
                  label="Repeat Password"
                  type={repeatPswVis ? "text" : "password"}
                  id="repeatpassword"
                  autoComplete="current-password"
                  value={confirmPassword}
                  onChange={(event) => {
                    setConfirmPassword(event.target.value);
                    if (event.target.value !== newPassword) {
                      setErrorSetPswForm(true);
                    } else {
                      setErrorSetPswForm(false);
                    }
                  }}
                  sx={{ backgroundColor: "text.background" }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton aria-label="toggle password visibility" onClick={() => setRepeatPswVis(!repeatPswVis)} edge="end">
                          {repeatPswVis ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Button type="submit" fullWidth variant="contained" disabled={disabledNewPsw || isLoadingSetPsw} sx={{ mt: 3, mb: 2, backgroundColor: "primary.dark", height: "50px" }}>
                  {isLoadingSetPsw ? "Loading..." : "Confirm"}
                </Button>
              </Box>
            </Box>
            {errorSetPswForm && (
              <Typography align="center" color="text.sub" component="p" sx={{ color: (t) => t.palette.text.error }}>
                The passwords entered do not match
              </Typography>
            )}
            {errorSetPsw && (
              <Typography align="center" color="text.sub" component="p" sx={{ color: (t) => t.palette.text.error }}>
                {errorSetPsw}
              </Typography>
            )}
          </>
        )}

        {/* Modal forgot password */}
        <Modal open={openModalForgot} onClose={() => setOpenModalForgot(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Email inviata
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Un email è stata inviata con le istruzioni per resettare la tua password.
            </Typography>
          </Box>
        </Modal>
      </Grid>
    </Grid>
  );
}
