import React from "react";
import { Button, Dialog, DialogActions, DialogContent, Paper, Stack, Typography } from "@mui/material";
import { Info } from "@mui/icons-material";
import { useTheme } from "@emotion/react";

const DialogGeneral = ({ open, title, text, cbClose, cbAction, rejectButtonText = "Annulla", confirmButtonText = "Conferma" }) => {
  const theme = useTheme();
  const c = theme.palette;

  const handleClose = () => {
    cbClose(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs">
      <DialogContent>
        <Stack direction="column" sx={{ justifyContent: "center", alignItems: "center" }}>
          <Info style={{ margin: "10px" }} sx={{ color: "primary.main" }} />
          <Typography variant="h5" sx={{ fontFamily: "Poppins-SemiBold", color: c.primary.main, p: 2 }}>
            {title}
          </Typography>
          <Typography variant="p" sx={{ p: 2 }}>
            {text}
          </Typography>
        </Stack>
      </DialogContent>

      <DialogActions>
        {rejectButtonText != null && (
          <Button sx={{ color: "#000" }} onClick={handleClose}>
            {rejectButtonText}
          </Button>
        )}
        <Button variant="contained" onClick={cbAction}>
          {confirmButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogGeneral;
