import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "./../../config/axiosConfig";

export const getListaProgrammi = createAsyncThunk("dashboardadmin/getListaProgrammi", async (_, thunkAPI) => {
  let token;
  try {
    const state = thunkAPI.getState();
    token = state.loginadmin.tokenAdmin;
    const config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    const response = await api.get("adm/programmi", config);
    return { listaProgrammi: response.data.data };
  } catch (error) {
    throw error.response.data;
  }
});

export const getListaAudio = createAsyncThunk("dashboardadmin/getListaAudio", async (_, thunkAPI) => {
  let token;
  try {
    const state = thunkAPI.getState();
    token = state.loginadmin.tokenAdmin;
    const config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    const response = await api.get("adm/audio", config);
    return { listaAudio: response.data.data };
  } catch (error) {
    throw error.response.data;
  }
});

export const getListaTipoStrutturaReferti = createAsyncThunk("dashboardadmin/getListaTipoStrutturaReferti", async (_, thunkAPI) => {
  let token;
  try {
    const state = thunkAPI.getState();
    token = state.loginadmin.tokenAdmin;
    const config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    const response = await api.get("adm/referto/getListaTipoStruttura", config);
    return { listaTipoStrutturaReferti: response.data.data };
  } catch (error) {
    throw error.response.data;
  }
});

export const getListaConfigurazioniExcel = createAsyncThunk("dashboardadmin/getListaConfigurazioniExcel", async (_, thunkAPI) => {
  let token;
  try {
    const state = thunkAPI.getState();
    token = state.loginadmin.tokenAdmin;
    const config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    const response = await api.get("adm/configurazione-excel", config);
    return { listaConfigurazioniExcel: response.data.data };
  } catch (error) {
    throw error.response.data;
  }
});

export const getListaCodiciRivenditori = createAsyncThunk("dashboardadmin/getListaCodiciRivenditori", async (_, thunkAPI) => {
  let token;
  try {
    const state = thunkAPI.getState();
    token = state.loginadmin.tokenAdmin;
    const config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    const response = await api.get("adm/codici-rivenditori", config);
    return { listaCodiciRivenditori: response.data.data };
  } catch (error) {
    throw error.response.data;
  }
});

export const getListaAutorizzazioniAcquisto = createAsyncThunk("dashboardadmin/getListaAutorizzazioniAcquisto", async (_, thunkAPI) => {
  let token;
  try {
    const state = thunkAPI.getState();
    token = state.loginadmin.tokenAdmin;
    const config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    const response = await api.get("adm/autorizzazioni-acquisto", config);
    return { listaAutorizzazioniAcquisto: response.data.data };
  } catch (error) {
    throw error.response.data;
  }
});


export const getListaMedia = createAsyncThunk("dashboardadmin/getListaMedia", async (_, thunkAPI) => {
  let token;
  try {
    const state = thunkAPI.getState();
    token = state.loginadmin.tokenAdmin;
    const config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    const response = await api.get("adm/media", config);
    return { listaMedia: response.data.data };
  } catch (error) {
    throw error.response.data;
  }
});

export const getListaCategorie = createAsyncThunk("dashboardadmin/getListaCategorie", async (_, thunkAPI) => {
  let token;
  try {
    const state = thunkAPI.getState();
    token = state.loginadmin.tokenAdmin;
    const config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    const response = await api.get("adm/categorie", config);
    return { listaCategorie: response.data.data };
  } catch (error) {
    throw error.response.data;
  }
});

export const getListaCategorieAudio = createAsyncThunk("dashboardadmin/getListaCategorieAudio", async (_, thunkAPI) => {
  let token;
  try {
    const state = thunkAPI.getState();
    token = state.loginadmin.tokenAdmin;
    const config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    const response = await api.get("adm/categorie-audio", config);
    return { listaCategorieAudio: response.data.data };
  } catch (error) {
    throw error.response.data;
  }
});

export const getListaReferti = createAsyncThunk("dashboardadmin/getListaReferti", async (_, thunkAPI) => {
  let token;
  try {
    const state = thunkAPI.getState();
    token = state.loginadmin.tokenAdmin;
    const config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    const response = await api.get("adm/referti", config);
    return { listaReferti: response.data.data };
  } catch (error) {
    throw error.response.data;
  }
});

export const getListaQuestionari = createAsyncThunk("dashboardadmin/getListaQuestionari", async (_, thunkAPI) => {
  let token;
  try {
    const state = thunkAPI.getState();
    token = state.loginadmin.tokenAdmin;
    const config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    const response = await api.get("adm/questionari", config);
    return { listaQuestionari: response.data.data };
  } catch (error) {
    throw error.response.data;
  }
});


export const getListaTecniche = createAsyncThunk("dashboardadmin/getListaTecniche", async (props, thunkAPI) => {
  let token;
  try {
    const state = thunkAPI.getState();
    token = state.loginadmin.tokenAdmin;
    const config = {
      params: { ...props?.params },
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    const response = await api.get("adm/abs", config);
    return { props: props, listaTecniche: response.data.data.data, current_page: response.data.data.current_page, per_page: response.data.data.per_page, total: response.data.data.total };
  } catch (error) {
    throw error.response.data;
  }
});

export const getListaUtenti = createAsyncThunk("dashboardadmin/getListaUtenti", async (props, thunkAPI) => {
  let token;
  try {
    const state = thunkAPI.getState();
    token = state.loginadmin.tokenAdmin;
    const config = {
      params: { ...props?.params },
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    const response = await api.get("adm/utenti", config);
    return { listaUtenti: response.data.data.data, current_page: response.data.data.current_page, per_page: response.data.data.per_page, total: response.data.data.total };
  } catch (error) {
    throw error.response.data;
  }
});

export const getUtente = createAsyncThunk("dashboardadmin/getUtente", async (id, thunkAPI) => {
  let token;
  try {
    const state = thunkAPI.getState();
    token = state.loginadmin.tokenAdmin;
    const config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    const response = await api.get("adm/utenti/" + id, config);
    return { utente: response.data.data };
  } catch (error) {
    throw error.response.data;
  }
});




export const getProgrammiAbilitatiPerUtente = createAsyncThunk("dashboardadmin/getProgrammiAbilitatiPerUtente", async (idUtente, thunkAPI) => {
  let token;
  try {
    const state = thunkAPI.getState();
    token = state.loginadmin.tokenAdmin;
    const config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    const queryParams = new URLSearchParams({ id_utente: idUtente });
    const response = await api.get("adm/programma/getProgrammiAbilitatiPerUtente?" + queryParams, config);
    return { programmi: response.data.data };
  } catch (error) {
    throw error.response.data;
  }
});




export const getListaOrdini = createAsyncThunk("dashboardadmin/getListaOrdini", async (props, thunkAPI) => {
  let token;
  try {
    const state = thunkAPI.getState();
    token = state.loginadmin.tokenAdmin;
    const config = {
      params: { ...props?.params },
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    const response = await api.get("adm/ordini", config);
    return { listaOrdini: response.data.data.data, current_page: response.data.data.current_page, per_page: response.data.data.per_page, total: response.data.data.total };
  } catch (error) {
    throw error.response.data;
  }
});

export const getConteggioTecnicheDaSvolgere = createAsyncThunk("dashboardadmin/getConteggioTecnicheDaSvolgere", async (props, thunkAPI) => {
  let token;
  try {
    const state = thunkAPI.getState();
    token = state.loginadmin.tokenAdmin;
    const config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    const response = await api.get("adm/tecnicaabs/conteggioDaSvolgere", config);
    return { conteggioTecnicheDaSvolgere: response.data.data };
  } catch (error) {
    throw error.response.data;
  }
});

const initialState = {
  isLoading: false,
  isDetailsLoading: false,
  error: null,
  listaCategorie: [],
  listaCategorieAudio: [],
  listaQuestionari: [],
  listaUtenti: [],
  pageInfoListaUtenti: {},
  utente: null,
  listaMedia: [],
  listaProgrammi: [],
  listaAudio: [],
  listaReferti: [],
  listaConfigurazioniExcel: [],
  listaTipoStrutturaReferti: [],
  listaOrdini: [],
  pageInfoListaOrdini: {},
  listaCodiciRivenditori: [],
  listaAutorizzazioniAcquisto: [],
  programmiAbilitatiUtente: [],
  pageInfoListaTecniche: {},
  listaTecnicheLastProps: {},
  listaTecniche: [],
  isLoadingProgrammiAbilitatiUtente: false,
  isLoadingListaAudio: false,
  conteggioTecnicheDaSvolgere: 0,

  selectedSection: "programmi",
  snackbarData: null,

};

export const dashboardAdminSlice = createSlice({
  name: "dashboardadmin",
  initialState,
  reducers: {
    setSelectedSection: (state, action) => {
      state.selectedSection = action.payload;
    },
    setSnackbarData: (state, action) => {
      state.snackbarData = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getListaProgrammi.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getListaProgrammi.fulfilled, (state, action) => {
        state.isLoading = false;
        state.listaProgrammi = action.payload.listaProgrammi;
        state.error = null;
      })
      .addCase(getListaProgrammi.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message ? action.error.message : action.error;
      })

      .addCase(getListaAudio.pending, (state) => {
        state.isLoading = true;
        state.isLoadingListaAudio = true;
        state.error = null;
      })
      .addCase(getListaAudio.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isLoadingListaAudio = false;
        state.listaAudio = action.payload.listaAudio;
        state.error = null;
      })
      .addCase(getListaAudio.rejected, (state, action) => {
        state.isLoading = false;
        state.isLoadingListaAudio = false;
        state.error = action.error.message ? action.error.message : action.error;
      })


      .addCase(getListaCategorie.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getListaCategorie.fulfilled, (state, action) => {
        state.isLoading = false;
        state.listaCategorie = action.payload.listaCategorie;
        state.error = null;
      })
      .addCase(getListaCategorie.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message ? action.error.message : action.error;
      })

      .addCase(getListaCategorieAudio.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getListaCategorieAudio.fulfilled, (state, action) => {
        state.isLoading = false;
        state.listaCategorieAudio = action.payload.listaCategorieAudio;
        state.error = null;
      })
      .addCase(getListaCategorieAudio.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message ? action.error.message : action.error;
      })

      .addCase(getListaQuestionari.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getListaQuestionari.fulfilled, (state, action) => {
        state.isLoading = false;
        state.listaQuestionari = action.payload.listaQuestionari;
        state.error = null;
      })
      .addCase(getListaQuestionari.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message ? action.error.message : action.error;
      })
      .addCase(getListaUtenti.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getListaUtenti.fulfilled, (state, action) => {
        const { listaUtenti, ...pageInfo } = action.payload;
        state.isLoading = false;
        state.listaUtenti = listaUtenti;
        state.pageInfoListaUtenti = pageInfo;
        state.error = null;
      })
      .addCase(getListaUtenti.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message ? action.error.message : action.error;
      })
      .addCase(getListaOrdini.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getListaOrdini.fulfilled, (state, action) => {
        const { listaOrdini, ...pageInfo } = action.payload;
        state.isLoading = false;
        state.listaOrdini = listaOrdini;
        state.pageInfoListaOrdini = pageInfo;
        state.error = null;
      })
      .addCase(getListaOrdini.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message ? action.error.message : action.error;
      })

      .addCase(getUtente.pending, (state) => {
        state.isDetailsLoading = true;
        state.error = null;
      })
      .addCase(getUtente.fulfilled, (state, action) => {
        state.isDetailsLoading = false;
        state.utente = action.payload.utente;
        state.error = null;
      })
      .addCase(getUtente.rejected, (state, action) => {
        state.isDetailsLoading = false;
        state.error = action.error.message ? action.error.message : action.error;
      })


      .addCase(getListaReferti.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getListaReferti.fulfilled, (state, action) => {
        state.isLoading = false;
        state.listaReferti = action.payload.listaReferti;
        state.error = null;
      })
      .addCase(getListaReferti.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message ? action.error.message : action.error;
      })

      .addCase(getListaMedia.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getListaMedia.fulfilled, (state, action) => {
        state.isLoading = false;
        state.listaMedia = action.payload.listaMedia;
        state.error = null;
      })
      .addCase(getListaMedia.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message ? action.error.message : action.error;
      })

      .addCase(getListaConfigurazioniExcel.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getListaConfigurazioniExcel.fulfilled, (state, action) => {
        state.isLoading = false;
        state.listaConfigurazioniExcel = action.payload.listaConfigurazioniExcel;
        state.error = null;
      })
      .addCase(getListaConfigurazioniExcel.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message ? action.error.message : action.error;
      })

      .addCase(getListaCodiciRivenditori.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getListaCodiciRivenditori.fulfilled, (state, action) => {
        state.isLoading = false;
        state.listaCodiciRivenditori = action.payload.listaCodiciRivenditori;
        state.error = null;
      })
      .addCase(getListaCodiciRivenditori.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message ? action.error.message : action.error;
      })


      .addCase(getListaAutorizzazioniAcquisto.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getListaAutorizzazioniAcquisto.fulfilled, (state, action) => {
        state.isLoading = false;
        state.listaAutorizzazioniAcquisto = action.payload.listaAutorizzazioniAcquisto;
        state.error = null;
      })
      .addCase(getListaAutorizzazioniAcquisto.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message ? action.error.message : action.error;
      })


      .addCase(getListaTipoStrutturaReferti.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getListaTipoStrutturaReferti.fulfilled, (state, action) => {
        state.isLoading = false;
        state.listaTipoStrutturaReferti = action.payload.listaTipoStrutturaReferti;
        state.error = null;
      })
      .addCase(getListaTipoStrutturaReferti.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message ? action.error.message : action.error;
      })

      .addCase(getProgrammiAbilitatiPerUtente.pending, (state) => {
        state.programmiAbilitatiUtente = [];
        state.isLoadingProgrammiAbilitatiUtente = true;
        state.error = null;
      })
      .addCase(getProgrammiAbilitatiPerUtente.fulfilled, (state, action) => {
        state.isLoadingProgrammiAbilitatiUtente = false;
        state.programmiAbilitatiUtente = action.payload.programmi;
        state.error = null;
      })
      .addCase(getProgrammiAbilitatiPerUtente.rejected, (state, action) => {
        state.programmiAbilitatiUtente = [];
        state.isLoadingProgrammiAbilitatiUtente = false;
        state.error = action.error.message ? action.error.message : action.error;
      })

      .addCase(getListaTecniche.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getListaTecniche.fulfilled, (state, action) => {
        const { props, listaTecniche, ...pageInfo } = action.payload;
        state.isLoading = false;
        state.listaTecniche = listaTecniche;
        state.listaTecnicheLastProps = props;
        state.pageInfoListaTecniche = pageInfo;
        state.error = null;
      })
      .addCase(getListaTecniche.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message ? action.error.message : action.error;
      })

      .addCase(getConteggioTecnicheDaSvolgere.fulfilled, (state, action) => {
        state.conteggioTecnicheDaSvolgere = action.payload.conteggioTecnicheDaSvolgere;
      });

  },
});

export const { setSelectedSection, setSnackbarData } = dashboardAdminSlice.actions;

export default dashboardAdminSlice.reducer;
