
import React from "react";

import 'media-chrome';
import './Video.css';

export function Video(props) {
    const { url, width } = props;

    return (
        <media-controller class="video-media-theme" style={{width: width?? 'auto'}}>
        <video
          playsinline
          slot="media"
          src={url}
        ></video>
        <media-loading-indicator slot="centered-chrome" noautohide></media-loading-indicator>
        <media-control-bar>
          <media-play-button></media-play-button>
          <media-mute-button></media-mute-button>
          <media-volume-range></media-volume-range>
          <media-time-display></media-time-display>
          <media-time-range></media-time-range>
          <media-duration-display></media-duration-display>
          <media-fullscreen-button></media-fullscreen-button>
        </media-control-bar>
      </media-controller>
    );
}
