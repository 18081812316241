import React, { useState, useEffect, useRef } from "react";
import { Box, Typography, TextField, Button, useTheme, IconButton } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { getListaCategorie, getCarrello, setCarrello, setInfoTecnicaABS } from "../ecommerce/EcommerceSlice";
import { useNavigate } from "react-router-dom";
import { AddCircleOutline, RemoveCircleOutline, RemoveCircle, ShoppingCart, Info } from "@mui/icons-material";
import { aggiornaVoce, rimuoviVoce } from "../ecommerce/EcommerceAPI";


export function Cart({ setDrawerOpen }) {
    const theme = useTheme();
    const dispatch = useDispatch();
    const gridRef = useRef();
    const boxRef = useRef();
    const navigate = useNavigate();

    const { carrello } = useSelector((state) => state.ecommerce);
    const c = theme.palette;
    const { token, user } = useSelector((state) => state.login);
    const [quantita, setQuantita] = useState({});
    // const [cardNumber, setCardNumber] = useState("");
    // const [expiry, setExpiry] = useState("");
    // const [cvv, setCvv] = useState("");

    const [boxH, setBoxH] = useState(0);
    const [tommyErr, setTommyErr] = useState(false);

    const abb_types = { SETT: "Settimanale", MENS: "Mensile", TRIM: "Trimestrale", SEM: "Semestrale", ANNUAL: "Annuale", VITA: "A Vita" };

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        handleResize();

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [gridRef.current, boxRef.current]);

    useEffect(() => {
        dispatch(getListaCategorie());
        dispatch(getCarrello());
    }, []);

    useEffect(() => {
        const quantitaIniziali = {};
        carrello?.voci?.forEach((item) => {
            quantitaIniziali[item.id] = item.qta;
        });
        setQuantita(quantitaIniziali);
    }, [carrello?.voci]);

    const handleResize = () => {
        if (boxRef.current) {
            setBoxH(window.innerHeight - boxRef.current.getBoundingClientRect().top);
        }
    };


    const avviaProgrammaTableStyle = {
        backgroundColor: c.secondary.main,
        color: c.primary.light,
        "&:hover": {
            backgroundColor: c.secondary.light,
            color: c.primary.dark,
        },
    };


    async function handleRimuoviCarrello(id) {
        const res = await rimuoviVoce(id, token);
        dispatch(setCarrello(res.data));
    }

    const timeoutQta = useRef(null);

    const handleAggiornaCarrello = async (item, nuovaQuantita) => {
        clearTimeout(timeoutQta.current);

        setQuantita({ ...quantita, [item.id]: nuovaQuantita });
        timeoutQta.current = setTimeout(async () => {
            let res = null;
            if (nuovaQuantita > 0) {
                const nuoveVoci = carrello?.voci.map((v) => {
                    if (v === item) {
                        // Clona l'oggetto dell'elemento corrente e aggiorna la quantità
                        return { ...v, qta: nuovaQuantita };
                    }
                    return v;
                });
                // Aggiorna lo stato con le nuove voci
                setCarrello({ ...carrello, voci: nuoveVoci });
                var data = {
                    qta: nuovaQuantita,
                };

                res = await aggiornaVoce(item.id, data, token);
            } else {
                res = await rimuoviVoce(item.id, token);
            }

            dispatch(setCarrello(res.data));
        }, 300);
    };



    useEffect(() => {
        if (!!tommyErr)
            setTimeout(() => {
                setTommyErr(false);
            }, 5000);
    }, [tommyErr]);

    return (
        <Box ref={boxRef} sx={{ ...boxStyleCarrello, height: boxH }}>
            <div style={{ backgroundColor: "#f0f0f0", height: "10%", display: "flex", alignItems: "center" }}>
                <ShoppingCart style={{ color: "#255300", marginRight: "15px", marginLeft: "10%" }} />
                <Typography sx={titoloStyle}>IL TUO CARRELLO</Typography>
            </div>
            <div style={{ backgroundColor: "white", height: "65%", maxHeight: "65%", overflowY: "auto" }}>
                {carrello?.voci &&
                    carrello?.voci.map((item) => (


                        <div key={item.id} style={{ borderBottom: "1px solid #f0f0f0", minHeight: '80px', position: "relative" }}>
                            <IconButton onClick={() => handleRimuoviCarrello(item.id)} style={{ position: "absolute", top: 0, right: 0, color: "gray", padding: '0px', paddingRight: '5px' }} fontSize="small">
                                <RemoveCircle />
                            </IconButton>

                            {
                                item.tipo_voce == 'PROG' ?
                                    <>
                                        <Typography sx={{ fontSize: "14px", marginLeft: "5px", marginTop: "5px" }}>{item.tipo_acquisto === "QTA" ? "Programma" : "Abbonamento"}</Typography>
                                        <Typography sx={{ fontSize: "14px", marginLeft: "5px", fontWeight: "bold" }}>{item.programma.nome}</Typography>
                                    </> :
                                    <>
                                        {
                                            item.tipo_voce == 'AUD' ?
                                                <>
                                                    <Typography sx={{ fontSize: "14px", marginLeft: "5px", marginTop: "5px" }}>Brano</Typography>
                                                    <Typography sx={{ fontSize: "14px", marginLeft: "5px", fontWeight: "bold" }}>{item.audio.nome}</Typography>
                                                </> :
                                                <>
                                                    <Typography sx={{ fontSize: "14px", marginLeft: "5px", marginTop: "5px", fontWeight: "bold" }}>Tecnica ABS</Typography>
                                                    <Button startIcon={<Info />} onClick={() => dispatch(setInfoTecnicaABS(item.tecnica_abs))} size="small" style={{ marginLeft: '5px' }} variant="outlined" fontSize="small">
                                                        Dettagli
                                                    </Button>
                                                </>
                                        }
                                    </>

                            }
                            {item.tipo_acquisto == "ABB" && <Typography sx={{ fontSize: "14px", marginLeft: "5px" }}>{abb_types[item.tipo_abbonamento]}</Typography>}
                            {((item.tipo_acquisto === "QTA" && item.tipo_voce == 'PROG')
                                || (item.tipo_voce == 'AUD' && user?.tipologia == 'PA')) && (
                                    <Box sx={{ display: "flex", alignItems: "center", marginBottom: "20px", marginLeft: "5px", justifyContent: "center" }}>
                                        <IconButton onClick={() => handleAggiornaCarrello(item, quantita[item.id] - 1)}>
                                            <RemoveCircleOutline />
                                        </IconButton>
                                        <TextField
                                            margin="none"
                                            size="small"
                                            inputProps={{ "aria-label": "quantity", style: { textAlign: "center" } }}
                                            sx={{ width: "100px", mx: 0 }}
                                            value={quantita[item.id] || 0}
                                            onChange={(e) => {
                                                const newValue = e.target.value;
                                                if (/^\d*$/.test(newValue)) {
                                                    // Controlla se l'input è costituito solo da numeri
                                                    setQuantita((prev) => ({ ...prev, [item.id]: newValue }));
                                                    handleAggiornaCarrello(item, newValue);
                                                }
                                            }}
                                        />
                                        <IconButton onClick={() => handleAggiornaCarrello(item, quantita[item.id] + 1)}>
                                            <AddCircleOutline />
                                        </IconButton>
                                    </Box>
                                )}


                            <Typography sx={{ fontSize: "14px", fontWeight: "bold", position: "absolute", bottom: 5, right: 5, textAlign: "right" }}>
                                TOT. <br></br>{Intl.NumberFormat('it-IT', { style: 'currency', currency: 'eur' }).format(item.prezzo_totale_voce)}
                            </Typography>
                        </div>
                    ))}
            </div>
            <div style={{ backgroundColor: "white", height: "25%", borderTop: "1px solid #f0f0f0", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                <Typography sx={{ fontSize: "14px", marginBottom: "10px" }}>TOTALE</Typography>
                <Typography style={{ fontWeight: "bold" }}>{Intl.NumberFormat('it-IT', { style: 'currency', currency: 'eur' }).format(carrello?.totale)}</Typography>
                <Button
                    variant="contained"
                    sx={avviaProgrammaTableStyle}
                    style={{ marginTop: "5%" }}
                    disabled={carrello?.voci?.length === 0}
                    onClick={() => {
                        setDrawerOpen(false);
                        navigate("/tommy/ecommerce/checkout");
                    }}
                >
                    <Box sx={{ whiteSpace: "nowrap" }}>PROCEDI AL CHECKOUT</Box>
                </Button>
            </div>
        </Box>
    );
}



const boxStyleCarrello = {
    marginTop: "0%",
    borderLeft: "1px solid #f0f0f0",
    height: "100%",
    backgroundColor: "white",
};

const titoloStyle = {
    color: "#255300",
    fontSize: "1rem",
    fontWeight: "bold",
};

