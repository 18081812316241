import * as React from "react";
import { Add, Edit } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import LocalPaginateTable from "../LocalPaginateTable";
import QuestionarioDetails from "./QuestionarioDetails";
import { Box, Button, IconButton } from "@mui/material";
import { getListaQuestionari } from "../../dashboardAdminSlice";

export function Questionari() {
  const dispatch = useDispatch();

  const [selectedQuestionario, setSelectedQuestionario] = React.useState(null);
  const [isEditorOpen, setIsEditorOpen] = React.useState(false);

  const { isLoading, listaQuestionari } = useSelector((state) => state.dashboardAdmin);

  React.useEffect(() => {
    dispatch(getListaQuestionari());
  }, []);

  const openDialog = (questionario) => {
    setSelectedQuestionario(questionario);
    setIsEditorOpen(true);
  };

  return (
    <>
      {isEditorOpen ? (
        <QuestionarioDetails selectedQuestionario={selectedQuestionario || false} setIsEditorOpen={setIsEditorOpen} />
      ) : (
        <Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button variant="contained" endIcon={<Add />} onClick={() => openDialog(false)}>
              Aggiungi Intervista
            </Button>
          </Box>
          <LocalPaginateTable
            scope="Interviste"
            isLoading={isLoading}
            cols={[
              { id: "titolo", label: "Titolo" },
              { id: "descrizione", label: "Descrizione" },
              { id: "bozza", label: "Stato", format: (val, quests) => (val ? "In Bozza" : "Pubblicato") },
              { id: "domande", label: "N. Domande", format: (val, quests) => val.length },
              {
                id: "azioni",
                label: "Azioni",
                sortable: false,
                format: (val, quests) => (
                  <IconButton onClick={() => openDialog(quests)}>
                    <Edit />
                  </IconButton>
                ),
              },
            ]}
            list={listaQuestionari}
            filterCols={["titolo", "descrizione"]}
          />
        </Box>
      )}
    </>
  );
}
