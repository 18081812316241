import { createTheme } from "@mui/material/styles";
// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: "#899B3C",
      light: "#E4EAC3",
      dark: "#6C724E",
      background: "#949494",
    },
    secondary: {
      main: "#255300",
      light: "#fff",
      dark: "#000",
      background: "#e0eff9",
    },
    text: {
      error: "#FF0000",
      correct: "#217725",
      warning: "#DEA848",
      warningBackground: "#FBFBE5",
      success: "#149F40",
    },
    light: {
      main: "#fff",
      light: "#E4EAC3",
      dark: "#6C724E",
      background: "#949494",
    }
  },
  typography: {
    fontFamily: ["Poppins-Regular"].join(","),
    button: { textTransform: "none" },
    tab: { textTransform: "none" },
    b: { fontFamily: "Poppins-Bold" },
    s: { fontFamily: "Poppins-Semibold" },
    m: { fontFamily: "Poppins-Medium" },
    l: { fontFamily: "Poppins-Light", fontSize: 12 },
    title: { fontFamily: "Poppins-Semibold", fontSize: 24 },
    title2: { fontFamily: "Poppins-Semibold", fontSize: 24, color: "#899B3C" },
    subtitle2: { fontFamily: "Poppins-Semibold", fontSize: 20, color: "#899B3C" },
    dialogTitle: { fontSize: 20, color: "#899B3C" },
    field: { fontFamily: "Poppins-Semibold", color: "#6C724E" },
  },
});
export default theme;
