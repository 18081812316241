import * as React from "react";
import { Box, Button, IconButton, } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getListaAudio } from "../../dashboardAdminSlice";
import { Add, Edit } from "@mui/icons-material";
import LocalPaginateTable from "../LocalPaginateTable";
import AudioDetails from "./AudioDetails";

export function Audio() {
  const dispatch = useDispatch();

  const [selectedAudio, setSelectedAudio] = React.useState(null);
  const [isEditorOpen, setIsEditorOpen] = React.useState(false);

  const { listaAudio, isLoading } = useSelector((state) => state.dashboardAdmin);

  React.useEffect(() => {
    dispatch(getListaAudio());
  }, []);


  const openDialog = (audio) => {
    setSelectedAudio(audio);
    setIsEditorOpen(true);
  };


  const getDuarata = (tipoDurata, durata, presetDurata) => {
    if (tipoDurata == '1') {
      return 'Illimitata';
    } else if (tipoDurata == '2') {
      if (durata == 1) {
        return "1 giorno";
      } else {
        return durata + " giorni";
      }
    } else if (tipoDurata == '3') {
      if (presetDurata == 'M1') {
        return "1 mese";
      } else if (presetDurata == 'M3') {
        return "3 mesi";
      } else if (presetDurata == 'M6') {
        return "6 mesi";
      } else if (presetDurata == 'Y1') {
        return "1 anno";
      }
    } if (tipoDurata == '4') {
      return 'Utilizzo Singolo';
    }

    return 'Illimitata';
  };


  return (
    <>
      {isEditorOpen ? (
        <AudioDetails selectedAudio={selectedAudio || false} setIsEditorOpen={setIsEditorOpen} />
      ) : (
        <Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button variant="contained" endIcon={<Add />} onClick={() => openDialog(false)}>
              Aggiungi Audio
            </Button>
          </Box>
          <LocalPaginateTable
            scope="Audio"
            isLoading={isLoading}
            cols={[
              { id: "nome", label: "Nome" },
              { id: "codice_media", label: "Codice" },
              { id: "categorie", label: "Categorie", format: (val) => val.map((cat) => cat.nome).join(", ") },
              {
                id: "durata_privato", label: "Durata Privato", format: (val, data) => {
                  return getDuarata(data['tipo_durata_privato'], data['durata_privato'], data['preset_durata_privato'])
                }
              },
              {
                id: "durata_professionista", label: "Durata Professionista", format: (val, data) => {
                  return getDuarata(data['tipo_durata_professionista'], data['durata_professionista'], data['preset_durata_professionista'])
                }
              },
              { id: "prezzo_privato", label: "Prezzo Privato", format: (val) => Intl.NumberFormat('it-IT', { style: 'currency', currency: 'eur' }).format(val) },
              { id: "prezzo_professionista", label: "Prezzo Professionista", format: (val) => Intl.NumberFormat('it-IT', { style: 'currency', currency: 'eur' }).format(val) },
              {
                id: "azioni",
                label: "Azioni",
                sortable: false,
                format: (val, prog) => (
                  <IconButton onClick={() => openDialog(prog)}>
                    <Edit />
                  </IconButton>
                ),
              },
            ]}
            list={listaAudio}
            filterCols={["nome", "codice"]}
          />


        </Box>
      )}
    </>
  );
}
