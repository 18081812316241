import React, { useEffect } from "react";
import { CircularProgress, Backdrop, Box, Stepper, Button, Typography, Step, StepLabel, Select, MenuItem, Stack, FormControl, InputLabel, Dialog, DialogTitle, DialogContent, DialogActions, useMediaQuery, MobileStepper } from "@mui/material";
import { useTheme } from "@emotion/react";
import { getAPIErrorMessage } from "../../../common/utils";
import { rispondiQuestionario, checkCopiaQuestionarioDaPrecedente } from "../prestazioneAPI";
import { useDispatch, useSelector } from "react-redux";
import { Check, CheckBox, CheckBoxOutlineBlank, CheckCircle, KeyboardArrowLeft, KeyboardArrowRight, LastPage, NavigateBefore, NavigateNext, RadioButtonChecked, RadioButtonUnchecked } from "@mui/icons-material";
import { setSnackbarData } from "../../programmi/programmiSlice";
import { Audio } from "../../abs/player/Audio";
import { Video } from "../../abs/player/Video";

export function Questionario({ onChangeDomanda = () => { }, readOnly = false, prestazione = null, onSuccess = () => { }, setPrestazione = () => { } }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const c = theme.palette;

  const { token, user } = useSelector((state) => state.login);
  const [activeStep, setActiveStep] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const [selectedRisposte, setSelectedRisposte] = React.useState({});
  const [selectedSottoRisposte, setSelectedSottoRisposte] = React.useState({}); //e' solo per le domande con risposte singole quindi per ora lo gestisco come array dove l'indice e' l'id della domanda
  const [risposteQuestionarioPrecedente, setRisposteQuestionarioPrecedente] = React.useState(null);
  const matchMd = useMediaQuery(theme.breakpoints.up("md"));

  const [error, setError] = React.useState(null);
  const [steps, setSteps] = React.useState([]);
  const [dialogFinish, setDialogFinish] = React.useState(false);

  useEffect(() => {
    initializeQuestionario(prestazione.risposte_questionario.questionario, prestazione.risposte_questionario.risposte_domande);
    checkIfPuoCopiareDaPrecedente();
  }, []);

  const checkIfPuoCopiareDaPrecedente = async () => {
    try {
      const response = await checkCopiaQuestionarioDaPrecedente(prestazione?.id, token);
      if (response.data !== false) {
        setRisposteQuestionarioPrecedente(response.data);
      }
    } catch (e) {
      //console.log(e);
    }
  };

  const importaDomandePrecedenti = () => {
    initializeQuestionario(risposteQuestionarioPrecedente.questionario, risposteQuestionarioPrecedente.risposte_domande);
    dispatch(setSnackbarData({ severity: "success", message: "Risposte importate correttamente", vertical:'bottom' }));
  };

  const initializeQuestionario = (questionario, risposte_domande) => {
    const stepDomande = [];
    for (const domanda of questionario.domande) {
      let risposte_domanda = null;
      if (risposte_domande != null) {
        risposte_domanda = risposte_domande.find((risposta) => risposta.id_domanda === domanda.id);
      }
      const stepDomanda = { domanda: domanda, risposte_date: risposte_domanda?.risposte, sotto_risposte_date: risposte_domanda?.sotto_risposte };
      stepDomande.push(stepDomanda);
    }
    setSteps(stepDomande);

    //inizializzo le risposte se rientra
    const newRisposte = {};
    for (const step of stepDomande) {
      newRisposte[step.domanda.id] = step.risposte_date || [];
    }
    setSelectedRisposte(newRisposte);

    const newSottoRisposte = {};
    for (const step of stepDomande) {
      newSottoRisposte[step.domanda.id] = [];
      if (step.sotto_risposte_date && step.sotto_risposte_date.length > 0) {
        for (const sotto_risposta_data of step.sotto_risposte_date) {
          if (sotto_risposta_data.risposta) {
            newSottoRisposte[step.domanda.id].push(sotto_risposta_data.id_sotto_risposta);
          }
        }
      }
    }
    setSelectedSottoRisposte(newSottoRisposte);
  };

  const handleRisposta = (answerId, questIndex) => {
    //se è gia completata e la sto riguardando vado solo avanti
    if (readOnly) {
      return;
    }
    const domanda = steps[questIndex].domanda;
    let questSelectedDomanda = [...(selectedRisposte[domanda.id] || [])];

    if (domanda.tipologia === "RISP-SING") {
      questSelectedDomanda = [answerId];
    } else if (domanda.tipologia === "RISP-MULT") {
      if (questSelectedDomanda.includes(answerId)) {
        questSelectedDomanda = questSelectedDomanda.filter((item) => item !== answerId);
      } else if (questSelectedDomanda.length < domanda.max_risposte) {
        questSelectedDomanda.push(answerId);
      }
    }

    let newRisposte = { ...selectedRisposte, [domanda.id]: questSelectedDomanda };
    if (JSON.stringify(newRisposte) !== JSON.stringify(selectedRisposte)) {
      setSelectedRisposte(newRisposte);
      setSelectedSottoRisposte({ ...selectedSottoRisposte, [domanda.id]: [] });
    }
  };

  const handleSottoRisposta = (event, questIndex) => {
    if (readOnly) return;
    const newSottoRisposte = { ...selectedSottoRisposte };
    newSottoRisposte[steps[questIndex].domanda.id] = event.target.value;
    setSelectedSottoRisposte(newSottoRisposte);
  };

  const checkIfRispostaIsSelected = (questIndex, log = false) => {
    if (!(steps[questIndex]?.domanda?.id in selectedRisposte) || selectedRisposte[steps[questIndex]?.domanda?.id]?.length === 0) {
      if (log) {
        setError("Seleziona almeno una Risposta");
      }
      return false;
    } else {
      const selectedOpzione = steps[questIndex]?.domanda?.opzioni_domanda?.find((opzione) => opzione.id === selectedRisposte[steps[questIndex]?.domanda?.id][0]);
      if (selectedOpzione?.show_sotto_opzioni && selectedSottoRisposte[steps[questIndex]?.domanda?.id]?.length === 0) {
        if (log) {
          setError("Seleziona almeno una Sotto Risposta");
        }
        return false;
      }
    }
    return true;
  };

  const checkIfTutteDomandeSonoRisposte = () => {
    const missingRisposte = [];
    for (let i = 0; i < steps.length; i++) {
      if (!checkIfRispostaIsSelected(i)) {
        missingRisposte.push(i);
      }
    }
    return missingRisposte;
  };

  const handleNext = (forceLast = false) => {

    const selected = checkIfRispostaIsSelected(activeStep, true);
    if (!selected) {
      return;
    }

    if (activeStep + 1 == steps.length || forceLast) {
      //ricontrollo tutte le risposte
      const missingRisposte = checkIfTutteDomandeSonoRisposte();

      if (missingRisposte.length > 0) {
        const msg = "Le seguenti domande non hanno risposte selezionate: " + missingRisposte.map((i) => i + 1).join(", ") + ".";
        setError(msg);
        return;
      }

    }

    setError(null);
    if (activeStep + 1 == steps.length || forceLast) {
      if (readOnly) {
        onFinish();
      } else {
        setDialogFinish(true);
      }
    } else {
      setActiveStep((prev) => prev + 1);
    }
    onChangeDomanda();
  };

  const handleBack = () => {
    setError(null);
    setActiveStep((prev) => prev - 1);
    onChangeDomanda();
  };

  const handleReset = () => {
    setError(null);
    setDialogFinish(false);
    setActiveStep(0);
    onChangeDomanda();
  };

  const onFinish = async () => {
    setDialogFinish(false);
    //se è gia completata e la sto riguardando vado solo avanti
    if (readOnly) {
      onSuccess();
      return;
    }
    setIsLoading(true);
    const payload = { risposte_domande: [] };

    for (const [idDomanda, risposte] of Object.entries(selectedRisposte)) {
      const sotto_risposte = [];
      for (const opzione of steps.find((step) => step.domanda.id === idDomanda).domanda.opzioni_domanda) {
        if (opzione.show_sotto_opzioni) {
          for (const sotto_opzione of opzione.sotto_opzioni) {
            sotto_risposte.push({
              id_sotto_risposta: sotto_opzione.id,
              risposta: selectedSottoRisposte[idDomanda].includes(sotto_opzione.id) ? 1 : 0,
            });
          }
        }
      }
      payload.risposte_domande.push({
        id_domanda: idDomanda,
        risposte: risposte,
        sotto_risposte: sotto_risposte,
      });
    }

    let response;
    try {
      response = await rispondiQuestionario(prestazione?.id, payload, token);
    } catch (e) {
      setError(
        getAPIErrorMessage(e)
          .match(/\.\d+\./g)
          .map((match) => match.replace(/\./g, ""))
          .map((i) => `Selezionare una risposta alla Domanda ${parseInt(i) + 1}.`)
          .join("\n")
      );
      return;
    } finally {
      setIsLoading(false);
    }

    setPrestazione(response.data);
    setDialogFinish(false);
    onSuccess();
  };

  return (
    <Box>
      {isLoading && (
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
          <CircularProgress color="primary" />
        </Backdrop>
      )}

      <Typography style={{ color: c.primary.main, fontSize: "1.6rem", fontWeight: "bold" }} sx={{ mb: 4 }}>
        Intervista
      </Typography>

      {risposteQuestionarioPrecedente && !readOnly && (
        <Box sx={{ display: "flex", alignItems: "end", flexDirection: 'column', mb: 2 }}>
          <Box sx={{ display: "flex", alignItems: "end", flexDirection: 'column' }}>
            <Typography sx={{ color: c.secondary.main, fontWeight: "bold", fontSize: '0.75rem' }}>{user?.tipologia == 'PA' ? 'L\'utente ha gia svolto questo programma?' : ' Hai già fatto questo programma?'}<br /> Importa le precedenti risposte dell'intervista e effettua le correzioni che ritieni opportune.</Typography>
            <Button variant="contained" size="small" onClick={importaDomandePrecedenti} sx={{ mt: 1, mr: 1 }}>
              Importa
            </Button>
          </Box>
        </Box>
      )}

      {steps.length > 0 && (
        <>

          {matchMd ? <Stepper nonLinear={true} sx={{ mb: 2, overflowX: 'auto', pb: 2 }} activeStep={activeStep}>
            {steps.map((step, index) => (
              <Step key={index}>
                <StepLabel sx={{ cursor: "pointer" }} onClick={() => setActiveStep(index)}>
                  {
                    checkIfRispostaIsSelected(index) ? <Check sx={{ color: c.primary.main }} /> : <></>
                  }
                </StepLabel>
              </Step>
            ))}
          </Stepper>
            :
            <>
              <Box sx={{ width: '100%', p: 2, textAlign: 'center', color: c.secondary.main }}>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>


                  <b>Domanda {activeStep + 1}</b>
                  {
                    checkIfRispostaIsSelected(activeStep) ? <CheckCircle sx={{ color: c.primary.main, ml: 1 }} /> : <></>
                  }
                </Box>
              </Box>
              <MobileStepper variant="text"
                steps={steps.length}
                position="static"
                activeStep={activeStep}
                sx={{ backgroundColor: 'transparent', color: c.secondary.main, mb: 2 }}
                nextButton={
                  <Button
                    size="small"
                    onClick={() => handleNext()}
                  >
                    Avanti
                    {theme.direction === 'rtl' ? (
                      <KeyboardArrowLeft />
                    ) : (
                      <KeyboardArrowRight />
                    )}
                  </Button>
                }
                backButton={
                  <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                    {theme.direction === 'rtl' ? (
                      <KeyboardArrowRight />
                    ) : (
                      <KeyboardArrowLeft />
                    )}
                    Indietro
                  </Button>
                }
              />
            </>
          }
        </>
      )}



      {activeStep < steps.length && (
        <Stack spacing={3}>
          {
            matchMd ? <Typography sx={{ color: c.secondary.main, fontWeight: "bold" }}>Domanda {activeStep + 1}</Typography> : <> </>
          }


          <Typography variant="m">{steps[activeStep]?.domanda?.testo}</Typography>

          {steps[activeStep]?.domanda?.show_media && (
            <Box sx={{ display: 'flex', justifyContent: "center" }}>
              {steps[activeStep]?.domanda?.tipo_media === "IMG" && <img src={steps[activeStep]?.domanda?.media_url} alt="img" style={{ maxWidth: "500px", maxHeight: "400px" }} />}
              {steps[activeStep]?.domanda?.tipo_media === "VID" && (
                <Video url={steps[activeStep]?.domanda?.media_url} />
              )}
              {steps[activeStep]?.domanda?.tipo_media === "AUD" && (
                <Audio url={steps[activeStep]?.domanda?.media_url} />
              )}
            </Box>
          )}

          {steps[activeStep]?.domanda?.note && (
            <Typography sx={{}}>
              <b>Note:</b> {steps[activeStep]?.domanda?.note}
            </Typography>
          )}

          {/* <Typography variant="body2" sx={{ textAlign: "right", fontFamily: "Poppins-Light" }}>
            {steps[activeStep]?.domanda?.tipologia === "RISP-SING" && " Risposta Singola"}
            {steps[activeStep]?.domanda?.tipologia === "RISP-MULT" && " Risposta Multipla - max " + steps[activeStep]?.domanda?.max_risposte + " risposte"}
          </Typography> */}
          {steps[activeStep]?.domanda?.tipologia === "RISP-MULT" && (
            <Typography variant="body2" sx={{ textAlign: "right", fontFamily: "Poppins-Light" }}>
              Risposta Multipla - max {
                steps[activeStep]?.domanda?.max_risposte == 1 ? "1 scelta" : steps[activeStep]?.domanda?.max_risposte + " scelte"
              }
            </Typography>
          )}

          <Box sx={{}}>
            {steps[activeStep]?.domanda?.opzioni_domanda?.map((opzione, index) => {
              const selected = !!selectedRisposte[steps[activeStep]?.domanda?.id]?.includes(opzione?.id);

              const Opzione = ({ style }) => (steps[activeStep]?.domanda?.tipologia === "RISP-MULT" ? <CheckBoxOutlineBlank sx={{ ...style }} /> : <RadioButtonUnchecked sx={{ ...style }} />);

              const SelectedOpzione = ({ style }) => (steps[activeStep]?.domanda?.tipologia === "RISP-MULT" ? <CheckBox sx={{ ...style }} /> : <RadioButtonChecked sx={{ ...style }} />);

              return (
                <Stack
                  key={index}
                  direction="row"
                  sx={{
                    cursor: readOnly ? "default" : "pointer",
                    border: "1px solid " + (selected ? "#899B3C" : "#949494"),
                    m: '1rem',
                    p: '1rem',
                    alignItems: "center",
                    ":hover": readOnly ? {} : { boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)" },
                  }}
                  onClick={() => handleRisposta(opzione.id, activeStep)}
                >
                  {selected ? <SelectedOpzione style={{ color: "primary.main", mr: 1 }} /> : <Opzione style={{ color: "primary.background", mr: 1 }} />}

                  <Box sx={{ flex: 1 }}>
                    {opzione.testo}
                    {selected && opzione.show_sotto_opzioni && (
                      <FormControl fullWidth sx={{ mt: 2 }} size="small">
                        <InputLabel>Seleziona una o più sotto risposte</InputLabel>
                        <Select renderValue={(selectedSottoOpzioni) => {
                          return (
                            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                              {selectedSottoOpzioni.map((value) => (
                                <Box key={value} sx={{ p: 0.5, bgcolor: c.primary.main, color: "#fff", borderRadius: '5px' }} >
                                  {opzione.sotto_opzioni.find((b) => b.id === value).testo}
                                </Box>
                              ))}
                            </Box>
                          );
                        }}
                          sx={{ zIndex: 9 }}
                          multiple
                          value={selectedSottoRisposte[steps[activeStep].domanda.id] || []}
                          label="Seleziona una o più sotto risposte"
                          onChange={(event) => handleSottoRisposta(event, activeStep)}>
                          {opzione.sotto_opzioni.map((sotto_opzione) => (
                            <MenuItem key={sotto_opzione.id} value={sotto_opzione.id}>
                              {sotto_opzione.testo}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  </Box>
                  {opzione.tipologia === "TXTIMG" && (
                    <Stack sx={{ alignItems: "center", justifyContent: "center", height: "150px", width: "250px", ml: 2 }}>
                      <img src={opzione.thumbnailUrl} alt="img" style={{ maxWidth: "250px", maxHeight: "150px" }} />
                    </Stack>
                  )}
                </Stack>
              );
            })}
          </Box>
        </Stack>
      )}

      {error && (
        <Dialog open={!!error} onClose={() => setError(null)} maxWidth="sm" fullWidth>
          <DialogTitle variant="dialogTitle" color="error">
            Attenzione
          </DialogTitle>
          <DialogContent>
            <Typography color="error" style={{ whiteSpace: "pre-line" }}>
              {error}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={() => setError(null)}>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {activeStep < steps.length && (
        <Box sx={{ display: 'flex', flexDirection: "row", justifyContent: "space-between", mt: 4 }}>
          <Box>
            <Button size={matchMd ? 'medium' : 'small'} variant="outlined" disabled={activeStep === 0} onClick={handleBack} startIcon={<NavigateBefore />}>
              {
                matchMd ? "Domanda Precedente" : "Precedente"
              }
            </Button>
          </Box>


          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Button size={matchMd ? 'medium' : 'small'} variant="contained" onClick={() => handleNext()} endIcon={<NavigateNext />}>
              {activeStep === steps.length - 1 ? (readOnly ? "Avanti" : matchMd ? "Concludi Intervista" : "Concludi") : matchMd ? "Domanda Successiva" : 'Successiva'}
            </Button>
            {
              activeStep < steps.length - 1 && checkIfTutteDomandeSonoRisposte().length == 0 && (
                <Button size={matchMd ? 'medium' : 'small'} variant="contained" onClick={() => { handleNext(true) }} endIcon={<LastPage />} sx={{ mt: 4 }}>
                  {readOnly ? "Avanti" : matchMd ? "Concludi Intervista" : "Concludi"}
                </Button>
              )

            }
          </Box>

        </Box>
      )}

      <Dialog open={dialogFinish} maxWidth="sm" fullWidth>
        <DialogTitle color="#000" variant="dialogTitle" align="center">
          L'intervista è stata completata
        </DialogTitle>
        <DialogContent sx={{ display: "flex", justifyContent: "space-between", mt: 4 }}>
          {!readOnly ? (
            <Button variant="outlined" onClick={handleReset}>
              Ricomincia Intervista
            </Button>
          ) : (
            <Box felx={1} />
          )}
          <Button variant="contained" onClick={onFinish}>
            Step successivo
          </Button>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
