import api from '../../config/axiosConfig';

export const aggiungiVoce = async (data, token) => {
    const config = {
        headers: {
            Authorization: "Bearer " + token,
        },
    };

    const response = await api.post('/api/carrello/voce', data, config);
    return response.data;
}

export const aggiornaVoce = async (id, data, token) => {
    const config = {
        headers: {
            Authorization: "Bearer " + token,
        },
    };

    const response = await api.put('/api/carrello/voce/' + id, data, config);
    return response.data;
}

export const rimuoviVoce = async (id, token) => {
    const config = {
        headers: {
            Authorization: "Bearer " + token,
        },
    };

    const response = await api.delete('/api/carrello/voce/' + id, config);
    return response.data;
}

export const inviaOrdine = async (token) => {
    const config = {
        headers: {
            Authorization: "Bearer " + token,
        },
    };

    const response = await api.post('/api/ordini', {}, config);
    return response.data;
}