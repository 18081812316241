import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../config/axiosConfig";

export const getListaOrdini = createAsyncThunk("ordine/getListaOrdini", async (props, thunkAPI) => {
  let token;
  try {
    const state = thunkAPI.getState();
    token = state.login.token;
    const config = {
      params: { ...props?.params },
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    const response = await api.get("api/ordini", config);
    return { listaOrdini: response.data.data.data, current_page: response.data.data.current_page, per_page: response.data.data.per_page, total: response.data.data.total };
  } catch (error) {
    throw error.response.data;
  }
});

const initialState = {
  isLoading: false,
  listaOrdini: [],
  pageInfoListaOrdini: {},
  error: null,
};

export const ordineSlice = createSlice({
  name: "ordine",
  initialState,
  reducers: {
    setSelectedSection: (state, action) => {
      state.selectedSection = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(getListaOrdini.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getListaOrdini.fulfilled, (state, action) => {
        const { listaOrdini, ...pageInfo } = action.payload;
        state.isLoading = false;
        state.listaOrdini = listaOrdini;
        state.pageInfoListaOrdini = pageInfo;
        state.error = null;
      })
      .addCase(getListaOrdini.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message ? action.error.message : action.error;
      });
  },
});

export const { setSelectedSection } = ordineSlice.actions;

export default ordineSlice.reducer;
