import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Typography, Backdrop, CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions, useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";
import { completaPrestazione } from "../prestazioneAPI";
import { getAPIErrorMessage } from "../../../common/utils";
import { setPrestazioneAttiva } from "../prestazioneSlice";
import Tommy from "../../../common/Tommy";

export function Completa({ readOnly = false, prestazione = null, onSuccess = () => { }, setPrestazione = () => { } }) {
  const theme = useTheme();
  const c = theme.palette;
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = React.useState(false);
  const { token } = useSelector((state) => state.login);
  const [error, setError] = React.useState(null);
  const matchMd = useMediaQuery(theme.breakpoints.up('md'));

  const onFinish = async () => {
    //se è gia completata e la sto riguardando vado solo avanti
    if (readOnly) {
      onSuccess();
      return;
    }

    setIsLoading(true);

    let response;
    try {
      response = await completaPrestazione(prestazione?.id, {}, token);
    } catch (e) {
      setError(getAPIErrorMessage(e));
      return;
    } finally {
      setIsLoading(false);
    }

    setPrestazione(response.data);
    dispatch(setPrestazioneAttiva(null));
    onSuccess();
  };

  return (
    <Box>
      {isLoading ? (
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <Tommy
          tipBgColor={"#fff"}
          boxStyle={{ position: "relative", paddingTop: '5%' }}
          tipPos={matchMd? "right-start":'bottom'}
          content={
            <>
              <Typography style={{ color: c.primary.main, fontSize: "1.6rem", fontWeight: "bold" }}>
                {
                  readOnly ? "Prestazione completata" : "Concludi la prestazione"
                }
              </Typography>
              <Typography sx={{ mb: 3, fontSize: '1rem' }} variant="body" component={"div"}>
                {readOnly ? "La prestazione è già stata completata\n\n" : "Tutti i passaggi sono stati completati. Conferma cliccando il pulsante sottostante per concludere la prestazione."}
              </Typography>

              <Button variant="contained" onClick={onFinish}>
                {readOnly ? "Continua" : "Concludi Prestazione"}
              </Button>
            </>
          }
        />
      )}

      <Dialog open={!!error} onClose={() => setError("")} maxWidth="sm" fullWidth>
        <DialogTitle variant="dialogTitle" color="error">
          Attenzione
        </DialogTitle>
        <DialogContent>
          <Typography color="error" style={{ whiteSpace: "pre-line" }}>
            {error}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => setError("")}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
