import api from './../config/axiosConfig';
import { setDataCheck } from "../pages/login/loginSlice";
import { store } from '../redux/store';

export const checkAdmin = async (token) => {
    let config = {
        headers: {
            Authorization: "Bearer " + token,
        },
    };
    try {
        await api.post("/adm/check", {}, config);
        // store.dispatch(setDataCheck(response.data.data.user));
        return true;
    } catch (error) {
        console.log(error);
        return false;
    }
};

export const check = async (token) => {
    let config = {
        headers: {
            Authorization: "Bearer " + token,
        },
    };
    try {
        const response = await api.post("/api/check", {}, config);
        store.dispatch(setDataCheck(response.data.data.user));
        return true;
    } catch (error) {
        console.log(error);
        return false;
    }
};
