import React from "react";
import { Select, MenuItem, TextField, Grid, InputLabel, FormControl } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";

export function InserisciUtente({ isRegistrazione, datiUtentePrivatoManuale, setDatiUtentePrivatoManuale }) {

  return (
    <>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            onChange={(event, newValue) => {
              setDatiUtentePrivatoManuale((prevData) => ({
                ...prevData,
                nome: event.target.value,
              }));
            }}
            value={datiUtentePrivatoManuale?.nome}
            fullWidth
            label="Nome"
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <TextField
            onChange={(event, newValue) => {
              setDatiUtentePrivatoManuale((prevData) => ({
                ...prevData,
                cognome: event.target.value,
              }));
            }}
            value={datiUtentePrivatoManuale?.cognome}
            fullWidth
            label="Cognome"
          />
        </Grid>



        <Grid item xs={12} sm={6} md={3}>
          <FormControl fullWidth>
            <InputLabel>Sesso</InputLabel>
            <Select
              fullWidth
              displayEmpty
              value={datiUtentePrivatoManuale?.sesso}
              label="Sesso"
              placeholder="Sesso"
              renderValue={(selected) => {
                if (selected === "" || selected == null) {
                  return "";
                }

                return selected == 'M'? "Maschio" : "Femmina";
              }}
              onChange={(event, newValue) => {
                setDatiUtentePrivatoManuale((prevData) => ({
                  ...prevData,
                  sesso: event.target.value,
                }));
              }}
            >
              <MenuItem value="F">Femmina</MenuItem>
              <MenuItem value="M">Maschio</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <TextField
            inputProps={{ type: "number", style: { textAlign: "right" }, min: 0 }}
            onChange={(event, newValue) => {
              setDatiUtentePrivatoManuale((prevData) => ({
                ...prevData,
                peso: event.target.value,
              }));
            }}
            value={datiUtentePrivatoManuale?.peso}
            fullWidth
            label="Peso in kg"
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <TextField
            inputProps={{ type: "number", style: { textAlign: "right" }, min: 0 }}
            onChange={(event, newValue) => {
              setDatiUtentePrivatoManuale((prevData) => ({
                ...prevData,
                altezza: event.target.value,
              }));
            }}
            value={datiUtentePrivatoManuale?.altezza}
            fullWidth
            label="Altezza in cm"
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <DatePicker
            format="DD/MM/YYYY"
            onChange={(value) => {
              setDatiUtentePrivatoManuale((prevData) => ({
                ...prevData,
                data_nascita: value && value.format("YYYY-MM-DD"),
              }));
            }}
            fullWidth
            sx={{width: '100%'}}
            maxDate={dayjs(new Date())}
            label="Data di nascita"
            renderInput={(params) => {
              return <TextField {...params} />;
            }}
          ></DatePicker>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <TextField
            onChange={(event, newValue) => {
              setDatiUtentePrivatoManuale((prevData) => ({
                ...prevData,
                email: event.target.value,
              }));
            }}
            value={datiUtentePrivatoManuale?.email}
            fullWidth
            label="Email"
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <TextField
            onChange={(event, newValue) => {
              setDatiUtentePrivatoManuale((prevData) => ({
                ...prevData,
                telefono: event.target.value,
              }));
            }}
            fullWidth
            value={datiUtentePrivatoManuale?.telefono}
            label="Telefono"
          />
        </Grid>


        {isRegistrazione && (
          <>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                onChange={(event, newValue) => {
                  setDatiUtentePrivatoManuale((prevData) => ({
                    ...prevData,
                    indirizzo: event.target.value,
                  }));
                }}
                fullWidth
                value={datiUtentePrivatoManuale?.indirizzo}
                label="Indirizzo"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                onChange={(event, newValue) => {
                  setDatiUtentePrivatoManuale((prevData) => ({
                    ...prevData,
                    cap: event.target.value,
                  }));
                }}
                value={datiUtentePrivatoManuale?.cap}
                fullWidth
                label="Cap"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                onChange={(event, newValue) => {
                  setDatiUtentePrivatoManuale((prevData) => ({
                    ...prevData,
                    citta: event.target.value,
                  }));
                }}
                value={datiUtentePrivatoManuale?.citta}
                fullWidth
                label="Citta"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                onChange={(event, newValue) => {
                  setDatiUtentePrivatoManuale((prevData) => ({
                    ...prevData,
                    cf: event.target.value,
                  }));
                }}
                value={datiUtentePrivatoManuale?.cf}
                fullWidth
                label="Codice Fiscale"
              />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}
