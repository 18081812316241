import React, { useState, useEffect, useRef } from "react";
import { Grid, Box, Typography, TextField, Button, useTheme, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack, useMediaQuery } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { getListaCategorie, getCarrello, setCarrello, setInfoTecnicaABS } from "./EcommerceSlice";
import { AccessTime, CheckCircle, AddCircleOutline, RemoveCircleOutline, RemoveCircle, ShoppingCart, Info, MusicNote } from "@mui/icons-material";
import { aggiornaVoce, rimuoviVoce } from "./EcommerceAPI";
import { inviaOrdine } from "../ecommerce/EcommerceAPI";
import DialogInfoProgramma from "../../common/DialogInfoProgramma";
import { getAPIErrorMessage } from "../../common/utils";
import { LoadingButton } from "@mui/lab";
import Tommy from "../../common/Tommy";
import { Footer } from "../homepage/Footer";
import { CONFIG } from "../../config/envConfig";

export function Checkout() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const boxRef = useRef();
  const matchSm = useMediaQuery(theme.breakpoints.up('sm'));
  const matchMd = useMediaQuery(theme.breakpoints.up('md'));
  const acquistaRef = useRef();
  const [dettagliH, setDettagliH] = useState(0);

  const { carrello } = useSelector((state) => state.ecommerce);
  const c = theme.palette;
  const { token, user } = useSelector((state) => state.login);
  const [quantita, setQuantita] = useState({});

  // const [cardNumber, setCardNumber] = useState("");
  // const [expiry, setExpiry] = useState("");
  // const [cvv, setCvv] = useState("");
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [messageErrorDialog, setMessageErrorDialog] = useState();
  const [infoProgramma, setInfoProgramma] = useState(false);
  const [boxH, setBoxH] = useState(0);
  const [acquistaLoading, setAcquistaLoading] = useState(false);
  const descriptionLenght = 200;
  const [tommyErr, setTommyErr] = useState(false);


  const abb_types = { SETT: "Settimanale", MENS: "Mensile", TRIM: "Trimestrale", SEM: "Semestrale", ANNUAL: "Annuale", VITA: "A Vita" };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [boxRef.current, acquistaRef.current]);

  useEffect(() => {
    dispatch(getListaCategorie());
    dispatch(getCarrello());
  }, []);

  useEffect(() => {
    const quantitaIniziali = {};
    carrello?.voci?.forEach((item) => {
      quantitaIniziali[item.id] = item.qta;
    });
    setQuantita(quantitaIniziali);
  }, [carrello?.voci]);





  const handleResize = () => {
    if (boxRef.current) {
      setBoxH(window.innerHeight - boxRef.current.getBoundingClientRect().top - CONFIG.FOOTER_HEIGHT);
    }
  };


  // const handleCardNumberChange = (event) => {
  //   const value = event.target.value.replace(/\D/g, ""); // Rimuove tutto tranne i numeri
  //   const formattedNumber = value
  //     .slice(0, 16)
  //     .replace(/(.{4})/g, "$1 ")
  //     .trim();
  //   setCardNumber(formattedNumber);
  // };

  // const handleExpiryChange = (event) => {
  //   let value = event.target.value
  //     .replace(
  //       /[^0-9/]/g,
  //       "" // Rimuove tutto tranne i numeri e la barra
  //     )
  //     .replace(
  //       // Impedisce l'inserimento di più barre
  //       /(\/)+/g,
  //       "/"
  //     )
  //     .replace(
  //       // Inserisce una barra dopo due numeri se non è già presente
  //       /(^[\d]{2})([\d]+)/g,
  //       "$1/$2"
  //     );

  //   if (value.length === 2 && expiry.length !== 3) {
  //     // Aggiunge la barra se l'utente ha digitato due numeri e non sta cancellando
  //     value += "/";
  //   }

  //   setExpiry(value.substr(0, 5)); // Limita a MM/YY
  // };

  // const handleCvvChange = (event) => {
  //   const value = event.target.value.replace(/[^0-9]/g, "").substr(0, 3);
  //   setCvv(value);
  // };

  const avviaProgrammaTableStyle = {
    backgroundColor: c.secondary.main,
    color: c.primary.light,
    "&:hover": {
      backgroundColor: c.secondary.light,
      color: c.primary.dark,
    },
  };

  async function handleRimuoviCarrello(id) {
    const res = await rimuoviVoce(id, token);
    dispatch(setCarrello(res.data));
  }

  const timeoutQta = useRef(null);

  const handleAggiornaCarrello = async (item, nuovaQuantita) => {
    clearTimeout(timeoutQta.current);

    setQuantita({ ...quantita, [item.id]: nuovaQuantita });
    timeoutQta.current = setTimeout(async () => {
      let res = null;
      if (nuovaQuantita > 0) {
        const nuoveVoci = carrello?.voci.map((v) => {
          if (v === item) {
            // Clona l'oggetto dell'elemento corrente e aggiorna la quantità
            return { ...v, qta: nuovaQuantita };
          }
          return v;
        });
        // Aggiorna lo stato con le nuove voci
        setCarrello({ ...carrello, voci: nuoveVoci });
        var data = {
          qta: nuovaQuantita,
        };

        res = await aggiornaVoce(item.id, data, token);
      } else {
        res = await rimuoviVoce(item.id, token);
      }

      dispatch(setCarrello(res.data));
    }, 300);
  };


  const onAcquista = async () => {
    setAcquistaLoading(true);
    try {
      const response = await inviaOrdine(token);
      const data = response.data;
      window.location.href = data.url;
      // non metto setAcquistaLoading(false); perche tanto esce dalla pagina
      // se lo mettessi ce il rischio che il bottone ritorni attivo prima di uscire dalla pagina
    } catch (e) {
      setAcquistaLoading(false);
      setMessageErrorDialog(getAPIErrorMessage(e));
      setOpenErrorDialog(true);
    }
  };

  useEffect(() => {
    if (!!tommyErr)
      setTimeout(() => {
        setTommyErr(false);
      }, 5000);
  }, [tommyErr]);


  const boxHeight = matchMd ? '240px' : matchSm ? '180px' : 'auto';
  const showDetails = matchSm;
  let border = {};
  if (matchSm) {
    border = { border: "1px solid #F7F2F2" };
  } else {
    border = { borderTop: "1px solid #bbbbbb" };
  }


  return (
    <>
      <Box sx={{ backgroundColor: "rgb(248, 248, 248)", display: 'flex', flexDirection: 'column' }} ref={boxRef} height={boxH}>
        {!!tommyErr ? (
          <Tommy
            right
            tommyStyle={{ width: '300px', height: '300px' }}
            tipStyle={{ marginRight: '20px' }}
            tipPos={"left-start"}
            boxStyle={{ bottom: '50px', right: '30px', position: 'aboslute' }}
            content={
              <>
                <Typography variant="subtitle2" color="error">
                  Attenzione
                </Typography>
                <Typography fontSize={16} sx={{ mb: 2 }}>{tommyErr}</Typography>
              </>
            }
          />
        ) : (
          <></>
        )}

        <Grid container sx={{ ...paginaStyle, height: boxH }}>

          {/* PARTE SINISTRA */}

          <Grid item xs={12} md={8} sx={{ height: matchMd ? '100%' : boxH - 150 /*altezza footer acquista*/ }}>
            <Box sx={{ ...boxStyleCheckout, overflowY: 'auto', height: '100%' }}>

              <Typography sx={{ ...titoloStyle, fontSize: '24px' }}>Checkout</Typography>

              <Typography sx={testoInferioreStyle}>Il pagamento dei servizi avviene tramite Carta di Credito. Inserisci i dati della carta e procedi al pagamento.</Typography>
              <div style={{ marginTop: "10px", marginBottom: "20px", display: "flex" }}>
                <ShoppingCart style={{ color: "#255300" }} />
                <Typography sx={titoloStyle}>IL TUO CARRELLO</Typography>
              </div>

              <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                {/* PROGRAMMI CODICI */}
                {carrello?.voci.map((item) => (
                  <div key={item.id}>
                    {/* Assicurati di avere una chiave univoca */}
                    {item.tipo_acquisto === "QTA" && item.tipo_voce == 'PROG' ? (
                      <Grid container spacing={0} sx={{ mt: 2 }}>
                        <Grid item xs={12} sm={4}>
                          <Box sx={{ pl: 2, pr: 1, py: 2, height: boxHeight, bgcolor: "#f0f0f0", display: 'flex', flexDirection: 'column' }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                              <Typography sx={{ ...titoloStyle, height: "2vh" }} component="div">
                                {item.programma.nome}
                              </Typography>
                              {
                                !showDetails ?
                                  <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                                    <Info sx={{ color: "#255300", cursor: "pointer", alignSelf: "flex-end" }} onClick={() => setInfoProgramma(item.programma.id)} />
                                  </Box> : <></>
                              }
                            </Box>

                            <Typography sx={{ ...testoInferioreStyle, textAlign: "left", flex: 1, overflowY: 'auto' }} color="text.secondary">
                              {item.programma.descrizione}
                            </Typography>
                          </Box>
                        </Grid>
                        {
                          showDetails ?

                            <Grid item xs={12} sm={4}>
                              <Stack sx={{ px: 1, py: 2, height: boxHeight, bgcolor: "#f0f0f0", justifyContent: "space-between" }}>
                                <Box>
                                  <Stack sx={{ flexDirection: "row", justifyContent: "space-between" }}>
                                    <Typography style={{ fontWeight: "bold", fontSize: "14px" }}>Cosa prevede: </Typography>
                                    <div style={{ display: "flex", alignItems: "center", float: "right", marginRight: "10px" }}>
                                      <AccessTime style={{ color: "#255300", marginRight: "5px", fontSize: '1rem' }} />
                                      <Typography sx={{ ...titoloStyle, fontSize: '0.9rem' }}>{item.programma.durata} min</Typography>
                                    </div>
                                  </Stack>
                                  <div style={{ display: "flex", alignItems: "center", marginTop: 10 }}>
                                    <CheckCircle sx={{ color: "#255300" }} />
                                    <Typography style={{ marginLeft: 4, fontSize: "14px" }}> Breve intervista </Typography>
                                  </div>

                                  {item.programma.flg_genius_machine ? (
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                      <CheckCircle sx={{ color: "#255300" }} />
                                      <Typography style={{ marginLeft: 4, fontSize: "14px" }}> Genius Machine </Typography>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </Box>
                                <Info sx={{ color: "#255300", cursor: "pointer", alignSelf: "flex-end" }} onClick={() => setInfoProgramma(item.programma.id)} />
                              </Stack>
                            </Grid> : <></>
                        }
                        <Grid item xs={12} sm={4}>
                          <Stack sx={{ px: 1, height: boxHeight, ...border, backgroundColor: matchSm ? 'white' : "#f0f0f0", flexDirection: "column", justifyContent: "space-between" }}>
                            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "end" }}>
                              <IconButton onClick={() => handleRimuoviCarrello(item.id)} style={{ color: "gray", alignSelf: "flex-end" }} fontSize="small">
                                <RemoveCircle />
                              </IconButton>
                            </Box>
                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
                              <IconButton onClick={() => handleAggiornaCarrello(item, quantita[item.id] - 1)}>
                                <RemoveCircleOutline />
                              </IconButton>
                              <TextField
                                margin="none"
                                size="small"
                                inputProps={{ "aria-label": "quantity", style: { textAlign: "center" } }}
                                sx={{ width: "100px", mx: 0 }}
                                value={quantita[item.id] || 0}
                                onChange={(e) => {
                                  const newValue = e.target.value;
                                  if (/^\d*$/.test(newValue)) {
                                    // Controlla se l'input è costituito solo da numeri
                                    setQuantita((prev) => ({ ...prev, [item.id]: newValue }));
                                    handleAggiornaCarrello(item, newValue);
                                  }
                                }}
                                onBlur={(e) => {
                                  setQuantita((prev) => ({ ...prev, [item.id]: quantita[item.id] }));
                                }}
                              />
                              <IconButton onClick={() => handleAggiornaCarrello(item, quantita[item.id] + 1)}>
                                <AddCircleOutline />
                              </IconButton>
                            </Box>
                            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "end" }}>

                              <Typography sx={{ ...titoloStyle, pr: 1, pb: 1, textAlign: 'end' }}>{Intl.NumberFormat('it-IT', { style: 'currency', currency: 'eur' }).format(item.prezzo_totale_voce)}</Typography>
                            </Box>

                          </Stack>
                        </Grid>
                      </Grid>
                    ) : item.tipo_acquisto === "ABB" && item.tipo_voce == 'PROG' ? (
                      <Grid container spacing={0} sx={{ my: 2 }}>
                        <Grid item xs={12} sm={4}>
                          <Box sx={{ pl: 2, pr: 1, py: 2, height: boxHeight, bgcolor: "#edf0dc", display: 'flex', flexDirection: 'column' }}>

                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                              <Typography sx={{ ...titoloStyle, height: "2vh" }} component="div">
                                {item.programma.nome}
                              </Typography>
                              {
                                !showDetails ?
                                  <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                                    <Info sx={{ color: "#255300", cursor: "pointer", alignSelf: "flex-end" }} onClick={() => setInfoProgramma(item.programma.id)} />
                                  </Box> : <></>
                              }
                            </Box>

                            <Typography sx={{ ...testoInferioreStyle, textAlign: "left", flex: 1, overflowY: 'auto' }} color="text.secondary">
                              {item.programma.descrizione}
                            </Typography>
                          </Box>
                        </Grid>

                        {
                          showDetails ?

                            <Grid item xs={4}>
                              <Stack sx={{ px: 1, py: 2, height: boxHeight, bgcolor: "#edf0dc", justifyContent: "space-between" }}>
                                <Box>
                                  <Stack sx={{ flexDirection: "row", justifyContent: "space-between" }}>
                                    <Typography style={{ fontWeight: "bold", fontSize: "14px" }}>Cosa prevede: </Typography>
                                    <div style={{ display: "flex", alignItems: "center", float: "right", marginRight: "10px" }}>
                                      <AccessTime style={{ color: "#255300", marginRight: "5px", fontSize: '1rem' }} />
                                      <Typography sx={{ ...titoloStyle, fontSize: '0.9rem' }}>{item.programma.durata} min</Typography>
                                    </div>
                                  </Stack>
                                  <div style={{ display: "flex", alignItems: "center", marginTop: 10 }}>
                                    <CheckCircle sx={{ color: "#255300" }} />
                                    <Typography style={{ marginLeft: 4, fontSize: "14px" }}> Breve intervista </Typography>
                                  </div>

                                  {item.programma.flg_genius_machine ? (
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                      <CheckCircle sx={{ color: "#255300" }} />
                                      <Typography style={{ marginLeft: 4, fontSize: "14px" }}> Genius Machine </Typography>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </Box>
                                <Info sx={{ color: "#255300", cursor: "pointer", alignSelf: "flex-end" }} onClick={() => setInfoProgramma(item.programma.id)} />
                              </Stack>
                            </Grid> : <></>
                        }

                        <Grid item xs={12} sm={4}>
                          <Stack sx={{ px: 1, height: boxHeight, ...border, flexDirection: "row", justifyContent: "space-between", bgcolor: matchSm ? 'white' : '#edf0dc' }}>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <span>{"Abbonamento " + abb_types[item?.tipo_abbonamento]}</span>
                            </Box>

                            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                              <IconButton onClick={() => handleRimuoviCarrello(item.id)} style={{ color: "gray", alignSelf: "flex-end" }} fontSize="small">
                                <RemoveCircle />
                              </IconButton>

                              <Typography sx={{ ...titoloStyle, pr: 1, pb: 1 }}>{Intl.NumberFormat('it-IT', { style: 'currency', currency: 'eur' }).format(item.prezzo_totale_voce)}</Typography>
                            </Box>
                          </Stack>
                        </Grid>
                      </Grid>
                    ) : null}
                    {
                      item.tipo_voce == 'AUD' ?
                        <Grid container spacing={0} sx={{ mt: 2 }}>
                          <Grid item xs={8}>
                            <Box sx={{ pl: 2, pr: 1, py: 2, height: boxHeight, bgcolor: "#f0f0f0", display: 'flex', overflowY: 'hidden' }}>
                              <MusicNote style={{ color: c.secondary.main }} />
                              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography sx={{ ...titoloStyle, height: "2vh" }} component="div">
                                  {item.audio.nome}
                                </Typography>
                                <Typography sx={{ ...testoInferioreStyle, textAlign: "left", flex: 1, overflowY: 'auto' }} color="text.secondary">
                                  {item.audio.descrizione}
                                </Typography>
                              </Box>

                            </Box>
                          </Grid>

                          <Grid item xs={4}>
                            <Stack sx={{ px: 1, height: boxHeight, border: "1px solid #F7F2F2", flexDirection: "row", justifyContent: item.tipo_voce == 'AUD' && user?.tipologia == 'PA' ? "space-between" : "end" }}>

                              {
                                item.tipo_voce == 'AUD' && user?.tipologia == 'PA' ?

                                  <Box sx={{ display: "flex", alignItems: "center" }}>
                                    <IconButton onClick={() => handleAggiornaCarrello(item, quantita[item.id] - 1)}>
                                      <RemoveCircleOutline />
                                    </IconButton>
                                    <TextField
                                      margin="none"
                                      size="small"
                                      inputProps={{ "aria-label": "quantity", style: { textAlign: "center" } }}
                                      sx={{ width: "100px", mx: 0 }}
                                      value={quantita[item.id] || 0}
                                      onChange={(e) => {
                                        const newValue = e.target.value;
                                        if (/^\d*$/.test(newValue)) {
                                          // Controlla se l'input è costituito solo da numeri
                                          setQuantita((prev) => ({ ...prev, [item.id]: newValue }));
                                          handleAggiornaCarrello(item, newValue);
                                        }
                                      }}
                                      onBlur={(e) => {
                                        setQuantita((prev) => ({ ...prev, [item.id]: quantita[item.id] }));
                                      }}
                                    />
                                    <IconButton onClick={() => handleAggiornaCarrello(item, quantita[item.id] + 1)}>
                                      <AddCircleOutline />
                                    </IconButton>
                                  </Box> : <></>
                              }

                              <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                                <IconButton onClick={() => handleRimuoviCarrello(item.id)} style={{ color: "gray", alignSelf: "flex-end" }} fontSize="small">
                                  <RemoveCircle />
                                </IconButton>

                                <Typography sx={{ ...titoloStyle, pr: 1, pb: 1 }}>{Intl.NumberFormat('it-IT', { style: 'currency', currency: 'eur' }).format(item.prezzo_totale_voce)}</Typography>
                              </Box>
                            </Stack>
                          </Grid>
                        </Grid>
                        : <></>
                    }
                    {
                      item.tipo_voce == 'TEC' ?
                        <Grid container spacing={0} sx={{ mt: 2 }}>
                          <Grid item xs={8}>
                            <Box sx={{ pl: 2, pr: 1, py: 2, height: "100px", bgcolor: "#f0f0f0", overflowY: 'hidden' }}>
                              <Typography sx={{ ...titoloStyle, height: "2vh" }} component="div">
                                Tecnica ABS
                              </Typography>
                              <Button startIcon={<Info />} onClick={() => dispatch(setInfoTecnicaABS(item.tecnica_abs))} size="small" style={{ marginTop: '10px' }} variant="outlined" fontSize="small">
                                Dettagli
                              </Button>
                            </Box>
                          </Grid>

                          <Grid item xs={4}>
                            <Stack sx={{ px: 1, height: "100px", border: "1px solid #F7F2F2", flexDirection: "row", justifyContent: "end" }}>


                              <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                                <IconButton onClick={() => handleRimuoviCarrello(item.id)} style={{ color: "gray", alignSelf: "flex-end" }} fontSize="small">
                                  <RemoveCircle />
                                </IconButton>

                                <Typography sx={{ ...titoloStyle, pr: 1, pb: 1 }}>{Intl.NumberFormat('it-IT', { style: 'currency', currency: 'eur' }).format(item.prezzo_totale_voce)}</Typography>
                              </Box>
                            </Stack>
                          </Grid>
                        </Grid>
                        : <></>
                    }
                  </div>
                ))}
              </div>
            </Box>
          </Grid>
          {/* PARTE DESTRA */}
          <Grid item xs={12} md={4} style={{ backgroundColor: "white" }} sx={{ ...boxStyleCheckoutTotale, height: matchMd ? '100%' : '150px' }} ref={acquistaRef}>
            <Box>
              <Typography sx={{ ...titoloStyle, fontSize: '1.5rem' }}>Procedi all'acquisto</Typography>
              <div style={{ display: "flex", justifyContent: "space-between", marginTop: '0.5rem', width: "50%" }}>
                <div style={{ width: "100%" }}>
                  <Typography>Totale:</Typography>
                  <Typography variant="s" fontSize={18}>
                    {Intl.NumberFormat('it-IT', { style: 'currency', currency: 'eur' }).format(carrello?.totale)}
                  </Typography>

                </div>

              </div>
              <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                <LoadingButton
                  loading={acquistaLoading}
                  variant="contained"
                  sx={avviaProgrammaTableStyle}
                  disabled={carrello?.voci?.length === 0}
                  onClick={() => {
                    onAcquista();
                  }}
                >
                  <Box sx={{ whiteSpace: "nowrap" }}>ACQUISTA</Box>
                </LoadingButton>
              </Box>

            </Box>
          </Grid>

        </Grid>




        {infoProgramma && <DialogInfoProgramma id_programma={infoProgramma} isOpen={setInfoProgramma} />}

        <Dialog open={openErrorDialog} onClose={() => setOpenErrorDialog(false)} aria-labelledby="error-dialog-title" aria-describedby="error-dialog-description">
          <DialogTitle id="error-dialog-title">Attenzione!</DialogTitle>
          <DialogContent>
            <DialogContentText id="error-dialog-description">{messageErrorDialog}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenErrorDialog(false)} color="primary">
              Chiudi
            </Button>
          </DialogActions>
        </Dialog>
      </Box >
      <Footer />
    </>
  );
}


const boxStyleCheckout = {
  padding: "2%",
  backgroundColor: "white",
  display: "flex",
  flexDirection: "column",
};

// const boxStyleCheckoutPayment = {
//   marginTop: "1%",
//   marginLeft: "1%",
//   marginRight: "2%",
//   padding: "2%",
//   width: "96%",
//   height: "70vh",
//   backgroundColor: "white",
//   position: "relative",
//   display: "flex",
//   justifyContent: "center",
//   alignItems: "center",
// };

const boxStyleCheckoutTotale = {
  borderTop: "1px solid #f0f0f0",
  backgroundColor: "white",
  padding: '1rem',
};

const titoloStyle = {
  color: "#255300",
  fontSize: "1rem",
  fontWeight: "bold",
};

const testoInferioreStyle = {
  color: "rgb(124,124,124)",
  fontSize: "1rem",
  marginTop: "1%",
};

const paginaStyle = {
  backgroundColor: "white",
};
