
import React, { useEffect, useRef, useState } from "react";

import 'media-chrome';
import './Audio.css';

export function Audio(props) {
    const { url, readonlyTimeout = null, width = 'auto' } = props;
    const mediaControllerRef = useRef(null);
    const timeRangeRef = useRef(null);
    const playButtonRef = useRef(null);
    const currentTimer = useRef();
    const secondUsedRef = useRef(0);
    const startTimeRef = useRef(new Date());

    const handlePlayRequest = (event) => {
        if (readonlyTimeout != null) {
            startTimeRef.current = new Date();
            setTimeoutFunction();
        }

    };

    const handleStopRequest = (event) => {
        if (readonlyTimeout != null) {
            const now = new Date();
            const secondsUsedInSession = Math.floor((now.getTime() - startTimeRef.current.getTime()) / 1000);
            secondUsedRef.current += secondsUsedInSession;
            clearTimeoutFuncton();
        }
    };

    useEffect(() => {
        const mediaController = mediaControllerRef.current;
        mediaController.addEventListener('mediaplayrequest', handlePlayRequest);
        mediaController.addEventListener('mediapauserequest', handleStopRequest);
        return () => {
            clearTimeoutFuncton();
            mediaController.removeEventListener('mediaplayrequest', handlePlayRequest);
            mediaController.removeEventListener('mediapauserequest', handleStopRequest);
        };
    }, []);

    const setTimeoutFunction = () => {
        clearTimeoutFuncton();
        currentTimer.current = setTimeout(setTimeRangeReadOnly, Math.max(0, readonlyTimeout - secondUsedRef.current) * 1000);
    };

    const clearTimeoutFuncton = () => {
        if (currentTimer != null && currentTimer.current != null) {
            clearTimeout(currentTimer.current);
        }
    };

    const setTimeRangeReadOnly = () => {
        timeRangeRef.current.classList.add('time-range-readonly');
        playButtonRef.current.style.display = 'none';
        clearTimeoutFuncton();
    }

    return (
        <media-controller audio class="audio-media-theme" style={{ width: width, borderRadius: '8px' }} ref={mediaControllerRef}>
            <audio
                slot="media"
                src={url}
            ></audio>
            <media-control-bar style={{ display: 'flex', justifyContent: 'space-between' }}>
                <media-play-button ref={playButtonRef} ></media-play-button>
                <media-time-display showduration></media-time-display>
                <media-time-range ref={timeRangeRef}></media-time-range>
                <media-mute-button></media-mute-button>
            </media-control-bar>
        </media-controller>
    );
}
