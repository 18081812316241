import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../config/axiosConfig";



export const getListaCategorieAudio = createAsyncThunk("abs/getListaCategorieAudio", async (_, thunkAPI) => {
  let token;
  try {
    const state = thunkAPI.getState();
    token = state.login.token;
    const config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    const response = await api.get("api/categorie-audio", config);
    return { listaCategorieAudio: response.data.data };
  } catch (error) {
    throw error.response.data;
  }
});

export const getListaAudio = createAsyncThunk("abs/getListaAudio", async (data, thunkAPI) => {
  let token;
  try {
    const state = thunkAPI.getState();
    token = state.login.token;
    const config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    if (data.id_categoria === '0') {
      delete data.id_categoria;
    }
    const queryParams = new URLSearchParams(data);
    const response = await api.get("api/audio?" + queryParams, config);
    return { listaAudio: response.data.data };
  } catch (error) {
    throw error.response.data;
  }
});


export const getBraniConsigliati = createAsyncThunk("abs/getBraniConsigliati", async (data, thunkAPI) => {
  let token;
  try {
    const state = thunkAPI.getState();
    token = state.login.token;
    const config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    const queryParams = new URLSearchParams(data);
    const response = await api.get("api/abs/braniConsigliati?" + queryParams, config);
    return { braniConsigliati: response.data.data };
  } catch (error) {
    throw error.response.data;
  }
});


export const getPrezzoTecnica = createAsyncThunk("abs/getPrezzoTecnica", async (data, thunkAPI) => {
  let token;
  try {
    const state = thunkAPI.getState();
    token = state.login.token;
    const config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    const response = await api.get("api/abs/getPrezzoTecnica", config);
    return { prezzoTecnica: response.data.data };
  } catch (error) {
    throw error.response.data;
  }
});


export const getBraniUtente = createAsyncThunk("abs/getBraniUtente", async (data, thunkAPI) => {
  let token;
  try {
    const state = thunkAPI.getState();
    token = state.login.token;
    const config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    if (data.id_categoria === '0') {
      delete data.id_categoria;
    }
    const queryParams = new URLSearchParams(data);
    const response = await api.get("api/abs/getBraniUtente?" + queryParams, config);
    return { braniUtente: response.data.data };
  } catch (error) {
    throw error.response.data;
  }
});


export const getBraniAcquistati = createAsyncThunk("abs/getBraniAcquistati", async (props, thunkAPI) => {
  try {
    const state = thunkAPI.getState();
    const token = state.login.token;
    const config = {
      params: { ...props?.params },
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    const response = await api.get("/api/abs/getBraniAcquistati", config);
    return { listaBraniAcquistati: response.data.data.data, current_page: response.data.data.current_page, per_page: response.data.data.per_page, total: response.data.data.total };
  } catch (error) {
    console.error("getBraniAcquistati=>", error);
    throw error.response.data;
  }
});

export const getListaTecniche = createAsyncThunk("abs/getListaTecniche", async (props, thunkAPI) => {
  try {
    const state = thunkAPI.getState();
    const token = state.login.token;
    const config = {
      params: { ...props?.params },
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    const response = await api.get("api/abs/getTecniche", config);
    return { props: props, listaTecniche: response.data.data.data, current_page: response.data.data.current_page, per_page: response.data.data.per_page, total: response.data.data.total };
  } catch (error) {
    throw error.response.data;
  }
});

export const initialState = {
  // listaCodici: [],
  // isLoadingCodici: false,
  // listaAbbonamenti: [],
  // isLoadingAbbonamenti: false,
  // listaPrestazioni: [],
  // pageInfoPrestazioni: {},
  // isLoadingPrestazioni: false,
  // listaStoricoAbbonamenti: [],
  // pageInfoStoricoAbbonamenti: {},
  // isLoadingStoricoAbbonamenti: false,
  // listaMovimentiCodici: [],
  // pageInfoMovimentiCodici: {},
  // isLoadingMovimentiCodici: false,
  selectedTab: 'store',

  // selezionaCodiceData: null,
  // creaVendiPrestazioneData: null,

  // snackbarData: null,

  // error: null,
  cart: [],
  braniConsigliati: [],
  isLoadingBraniConsigliati: false,
  listaAudio: [],
  listaCategorieAudio: [],
  isLoadingBraniUtente: false,
  braniUtente: [],
  selectedCategoriaAudioStore: '0',
  selectedCategoriaAudioIMieiBrani: '0',
  tecnica: { frasi_positive: [], frasi_negative: [], brani: [] },
  error: null,
  cartError: null,
  isLoadingCategorieAudio: false,
  isLoadingAudio: false,
  prezzoTecnica: null,
  selectedUserTecniche: null,
  isLoadingBraniAcquistati: false,
  pageInfoBraniAcquistati: {},
  listaBraniAcquistati: [],
  pageInfoListaTecniche: {},
  listaTecniche: [],
};

export const absSlice = createSlice({
  name: "abs",
  initialState,
  reducers: {
    setSelectedTab: (state, action) => {
      state.selectedTab = action.payload;
    },
    setSelectedCategoriaAudioStore: (state, action) => {
      state.selectedCategoriaAudioStore = action.payload;
    },
    setSelectedCategoriaAudioIMieiBrani: (state, action) => {
      state.selectedCategoriaAudioIMieiBrani = action.payload;
    },
    setTecnica: (state, action) => {
      state.tecnica = action.payload;
    },
    resetTecnica: (state) => {
      state.tecnica = { frasi_positive: [], frasi_negative: [], brani: [] };
    },
    setSelectedUserTecniche: (state, action) => {
      state.selectedUserTecniche = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getListaCategorieAudio.pending, (state, action) => {
        state.isLoadingCategorieAudio = true;
        state.error = null;
      })
      .addCase(getListaCategorieAudio.fulfilled, (state, action) => {
        state.listaCategorieAudio = action.payload.listaCategorieAudio;
        state.isLoadingCategorieAudio = false;
        state.error = null;
      })
      .addCase(getListaCategorieAudio.rejected, (state, action) => {
        state.isLoadingCategorieAudio = false;
        state.error = action.error.message ? action.error.message : action.error;
      })

      .addCase(getListaAudio.pending, (state, action) => {
        state.isLoadingAudio = true;
        state.error = null;
      })
      .addCase(getListaAudio.fulfilled, (state, action) => {
        state.listaAudio = action.payload.listaAudio;
        state.isLoadingAudio = false;
        state.error = null;
      })
      .addCase(getListaAudio.rejected, (state, action) => {
        state.isLoadingAudio = false;
        state.error = action.error.message ? action.error.message : action.error;
      })

      .addCase(getBraniConsigliati.pending, (state, action) => {
        state.isLoadingBraniConsigliati = true;
        state.error = null;
      })
      .addCase(getBraniConsigliati.fulfilled, (state, action) => {
        state.braniConsigliati = action.payload.braniConsigliati;
        state.isLoadingBraniConsigliati = false;
        state.error = null;
      })
      .addCase(getBraniConsigliati.rejected, (state, action) => {
        state.isLoadingBraniConsigliati = false;
        state.error = action.error.message ? action.error.message : action.error;
      })

      .addCase(getPrezzoTecnica.fulfilled, (state, action) => {
        state.prezzoTecnica = action.payload.prezzoTecnica;
      })

      .addCase(getBraniUtente.rejected, (state, action) => {
        state.isLoadingBraniUtente = false;
        state.error = action.error.message ? action.error.message : action.error;
      })
      .addCase(getBraniUtente.fulfilled, (state, action) => {
        state.isLoadingBraniUtente = false;
        state.braniUtente = action.payload.braniUtente;
        state.error = null;
      })
      .addCase(getBraniUtente.pending, (state, action) => {
        state.isLoadingBraniUtente = true;
        state.error = null;
      })

      .addCase(getBraniAcquistati.pending, (state, action) => {
        state.isLoadingBraniAcquistati = true;
      })
      .addCase(getBraniAcquistati.fulfilled, (state, action) => {
        const { listaBraniAcquistati, ...pageInfo } = action.payload;
        state.listaBraniAcquistati = listaBraniAcquistati;
        state.pageInfoBraniAcquistati = pageInfo;
        state.isLoadingBraniAcquistati = false;
      })
      .addCase(getBraniAcquistati.rejected, (state, action) => {
        state.isLoadingBraniAcquistati = false;
        state.error = action.error.message || action.error;
      })

      .addCase(getListaTecniche.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getListaTecniche.fulfilled, (state, action) => {
        const { props, listaTecniche, ...pageInfo } = action.payload;
        state.isLoading = false;
        state.listaTecniche = listaTecniche;
        state.pageInfoListaTecniche = pageInfo;
        state.error = null;
      })
      .addCase(getListaTecniche.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message ? action.error.message : action.error;
      });


    //#region codici
    // .addCase(getCodici.pending, (state, action) => {
    //   state.isLoadingCodici = true;
    // })
    // .addCase(getCodici.fulfilled, (state, action) => {
    //   state.listaCodici = action.payload.listaCodici;
    //   state.isLoadingCodici = false;
    // })
    // .addCase(getCodici.rejected, (state, action) => {
    //   state.isLoadingCodici = false;
    //   state.error = action.error.message || action.error;
    // })
    // #endregion
  },
});

export const {
  setSelectedTab,
  setSelectedCategoriaAudioStore,
  setSelectedCategoriaAudioIMieiBrani,
  setTecnica,
  setSelectedUserTecniche,
  resetTecnica
} = absSlice.actions;

export default absSlice.reducer;
