import React from "react";
import { useSelector } from "react-redux";
import { Box, Button, Typography, Backdrop, CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions, useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";
import { elaboraReferto, scaricaReferto } from "../prestazioneAPI";
import { getAPIErrorMessage } from "../../../common/utils";
import Tommy from "../../../common/Tommy";

export function Referto({ readOnly = false, prestazione = null, onSuccess = () => { }, setPrestazione = () => { } }) {
  const theme = useTheme();
  const c = theme.palette;
  const [isLoading, setIsLoading] = React.useState(false);
  const { token } = useSelector((state) => state.login);
  const [error, setError] = React.useState(null);
  const matchMd = useMediaQuery(theme.breakpoints.up('md'));

  //visto che readonly prende >= 80, qui uso un altro controllo

  const onFinish = async () => {
    //se è gia completata e la sto riguardando vado solo avanti
    if (prestazione.stato >= 90) {
      onSuccess();
      return;
    }

    setIsLoading(true);

    let response;
    try {
      response = await elaboraReferto(prestazione?.id, {}, token);
    } catch (e) {
      setError(getAPIErrorMessage(e));
      return;
    } finally {
      setIsLoading(false);
    }

    await _scaricaReferto();

    setPrestazione(response.data);
    onSuccess();
  };

  const _scaricaReferto = async () => {
    var data = await scaricaReferto(prestazione.id, token);

    const url = window.URL.createObjectURL(new Blob([data], { type: "application/pdf" }));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "Elaborato.pdf");
    document.body.appendChild(link);
    link.click();
  };

  return (
    <Box>
      {isLoading ? (
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <Tommy
          tipBgColor={"#fff"}
          boxStyle={{ position: "relative", paddingTop: "5%" }}
          tipPos={matchMd ? "right-start" : 'bottom'}
          content={
            <>
              <Typography style={{ color: c.primary.main, fontSize: "1.6rem", fontWeight: "bold" }} sx={{}}>
                Elaborato
              </Typography>

              {prestazione.stato === 80 && (
                <Typography sx={{ mb: 3, fontSize: '1rem' }} variant="body" component={"div"}>
                  La Prestazione è stata conclusa con successo. <br/> Clicca il pusante qui sotto per generare e scaricare l'elaborato.
                </Typography>
              )}

              {prestazione.stato >= 90 && (
                <Box sx={{}}>
                  <Typography sx={{ mb: 3, fontSize: '1rem' }} variant="body" component={"div"}>
                    Elaborato già generato, premere il pulsante sottostante per scaricarlo.
                  </Typography>

                  <Button variant="contained" onClick={_scaricaReferto}>
                    Scarica Elaborato
                  </Button>
                  <Button variant="contained" onClick={onFinish} sx={{ ml: 4 }}>
                    Continua
                  </Button>
                </Box>
              )}

              {prestazione.stato === 80 && (
                <Button variant="contained" onClick={onFinish}>
                  Genera e scarica l'elaborato
                </Button>
              )}
            </>
          }
        />

      )}

      <Dialog open={!!error} onClose={() => setError("")} maxWidth="sm" fullWidth>
        <DialogTitle variant="dialogTitle" color="error">
          Attenzione
        </DialogTitle>
        <DialogContent>
          <Typography color="error" style={{ whiteSpace: "pre-line" }}>
            {error}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => setError("")}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
