import React, { useEffect, useState } from "react";
import { Box, Button, Typography, TextField, CircularProgress, InputLabel, Select, MenuItem, FormControl, Grid, Dialog, DialogTitle, DialogContent, DialogActions, ListItemText, Accordion, AccordionSummary, AccordionDetails, Backdrop, Divider, Paper, Snackbar, Alert, Stack } from "@mui/material";
import { creaReferto, aggiornaReferto, getReferto, pubblicaBozzaReferto } from "../../dashboardAdminAPI";
import { getListaReferti, setSnackbarData } from "../../dashboardAdminSlice";
import { useDispatch, useSelector } from "react-redux";
import { MediaSelect } from "../media/MediaSelect";
import { formatDate, getAPIErrorMessage } from "../../../../common/utils";
import { Add, ArrowBack, ExpandMore, Save } from "@mui/icons-material";
import PagineReferto from "./PagineReferto";

export default function RefertiDetails({ setIsEditorOpen, selectedReferto = false }) {
  const dispatch = useDispatch();
  const tokenAdmin = useSelector((state) => state.loginadmin.tokenAdmin);
  const { listaReferti, listaTipoStrutturaReferti } = useSelector((state) => state.dashboardAdmin);
  const [isLoading, setIsLoading] = useState(false);

  const [refertoData, setRefertoData] = useState({ nome: "", titolo_intestazione: "", id_media_cover: "", id_media_preambolo: "", id_media_appendice: "", id_media_chiusura: "", pagine: [] });
  const [lastPubblicato, setLastPubblicato] = useState(null);
  const [isCreated, setIsCreated] = useState(false);
  const [selectPagina, setSelectPagina] = useState("");
  const [selectPaginaPubblicata, setSelectPaginaPubblicata] = useState("");
  const [errorCreaReferto, setErrorCreaReferto] = useState("");

  const [dialogImportaVisbile, setDialogImportaVisbile] = useState(false);
  const [selectedImportReferto, setSelectedImportReferto] = useState("");
  const [errorImportReferto, setErrorImportReferto] = useState("");

  const [snackPub, setSnackPub] = useState(false);
  const [snackPubDone, setSnackPubDone] = useState(false);

  useEffect(() => {
    if (selectedReferto?.id) loadReferto(selectedReferto.id);
  }, []);

  const loadReferto = async (id) => {
    try {
      setIsLoading(true);
      let data;
      const response = await getReferto(id, tokenAdmin);
      data = response.data;
      if (data.bozzaReferto != null && data.bozza) {
        data.pagine = processPagine(data);
        setLastPubblicato(data);
        setSelectPaginaPubblicata(selectPaginaPubblicata != "" ? selectPaginaPubblicata : data.pagine[0].posizione.toString());

        data = { ...data, ...data.bozzaReferto, id: data.id };
      } else setLastPubblicato(null);

      data.pagine = processPagine(data);
      setRefertoData(data);
      setSelectPagina(selectPagina != "" ? selectPagina : data.pagine[0].posizione.toString());
    } catch (e) {
      dispatch(setSnackbarData({ message: getAPIErrorMessage(e), open: true, error: true }));
      onClose()
    } finally {
      setIsLoading(false);
    }
  };

  const processPagine = (referto) => {
    const pagine = referto.pagine.map((pagina) => {
      pagina.tipo_struttura_obj = listaTipoStrutturaReferti.find((tipoStruttura) => tipoStruttura.codice === pagina.tipo_struttura);
      pagina.elementi = pagina.elementi.map((elemento) => {
        const el_struttura = pagina.tipo_struttura_obj.elementi.find((elementoStruttura) => elementoStruttura.codice === elemento.codice_elemento_struttura);
        elemento.tipologia = el_struttura.tipologia;
        elemento.label = el_struttura.label;
        return elemento;
      });
      return pagina;
    });

    return pagine;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setRefertoData((prevData) => ({ ...prevData, [name]: value }));
  };

  const onImportReferto = function () {
    if (!selectedImportReferto?.trim()) {
      setErrorImportReferto("Seleziona un Elaborato");
      return;
    }
    const referto = listaReferti.find((referto) => referto.id === selectedImportReferto);
    const newReferto = { ...refertoData, pagine: processPagine(JSON.parse(JSON.stringify(referto))) };
    setRefertoData(newReferto);
    setSelectPagina(newReferto.pagine[0]?.posizione.toString() || "");
    setDialogImportaVisbile(false);
  };

  const addNewPagina = () => {
    const lun = refertoData.pagine.length;
    let newNumeroPagina = lun + 1;
    if (refertoData.pagine[lun - 1]?.posizione) {
      newNumeroPagina = refertoData.pagine[lun - 1].posizione + 1;
    }

    setRefertoData((prevData) => ({ ...prevData, pagine: [...prevData.pagine, { titolo: "Pagina " + newNumeroPagina, testo: "", posizione: newNumeroPagina, tipo_struttura: "", elementi: [] }] }));
    setSelectPagina(newNumeroPagina.toString());
  };

  const handleRefertoSubmit = async (pubblica = false) => {
    let response;

    try {
      setIsLoading(true);
      const data = {
        nome: refertoData.nome,
        titolo_intestazione: refertoData.titolo_intestazione,
        id_media_cover: refertoData.id_media_cover,
        id_media_preambolo: refertoData.id_media_preambolo,
        id_media_appendice: refertoData.id_media_appendice,
        id_media_chiusura: refertoData.id_media_chiusura,
        pagine: refertoData.pagine,
      };
      if (pubblica) {
        response = await pubblicaBozzaReferto(data, tokenAdmin, refertoData.id);
      } else {
        if (selectedReferto || isCreated) {
          response = await aggiornaReferto(data, tokenAdmin, refertoData.id);
        } else {
          response = await creaReferto(data, tokenAdmin);
        }
      }
    } catch (e) {
      let err = getAPIErrorMessage(e);
      if (e.response?.data?.message) {
        err = e.response.data.message;
        if (Array.isArray(err)) {
          err = err
            .map((str) => {
              let errStr = str.split("'");
              let field = errStr[1].split(".");
              if (field.length > 1 && field[0] === "pagine") {
                if (field.length > 3) field = [`${field[4]} nella pagina n.${parseInt(field[1]) + 1} all'elemento n.${parseInt(field[3]) + 1}`];
                else field = [`${field[2]} nella pagina n.${parseInt(field[1]) + 1}`];
              }
              errStr[1] = field[0];
              errStr[0] = "Il campo ";

              return errStr.join("");
            })
            .join("\n");
        }
      }
      setErrorCreaReferto(err);
      return false;
    } finally {
      setIsLoading(false);
    }
    await loadReferto(response.data.id);
    if (!pubblica) setSnackPub(true);
    if (pubblica) setSnackPubDone(true);
    setIsCreated(true);
    return true;
  };

  const onClose = () => {
    dispatch(getListaReferti());
    setErrorCreaReferto("");
    setIsEditorOpen(false);
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackPub(false);
  };

  const handleClosePubbDoneSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackPubDone(false);
  };

  return (
    <>
      {isLoading && (
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
          <CircularProgress />
        </Backdrop>
      )}
      <Box sx={{ bgcolor: "#fff", p: 2 }} component={Paper}>
        <Grid container columnSpacing={8} rowSpacing={4}>
          <Grid item xs={selectedReferto || isCreated ? 6 : 12}>
            <Button startIcon={<ArrowBack />} onClick={onClose}>
              Indietro
            </Button>
          </Grid>
          {(selectedReferto || isCreated) && (
            <Grid item xs={6} display="flex" justifyContent="flex-end" alignItems="center">
              {refertoData.bozza ? (
                <>
                  <Typography variant="m" color="primary.main" sx={{ display: "inline-block" }}>
                    Elaborato in bozza dal {formatDate(refertoData.inizio_modifiche_bozza)}
                  </Typography>
                  <Button variant="contained" onClick={async () => handleRefertoSubmit(true)} sx={{ display: "inline-block", ml: 2 }}>
                    Pubblica
                  </Button>
                </>
              ) : (
                <Typography variant="m" color="primary.main">
                  Elaborato pubblicato il {formatDate(refertoData.ultima_data_pubblicazione)}
                </Typography>
              )}
            </Grid>
          )}
          <Grid item xs={12} sx={{ py: 2 }} display="flex" justifyContent="center">
            <Typography variant="title2">{selectedReferto?.id ? "Modifica Elaborato" : "Aggiungi Elaborato"}</Typography>
          </Grid>
          <Grid item xs={6} display="flex" alignItems="center" sx={{ mt: 1 }}>
            <FormControl fullWidth>
              <TextField label="Nome" name="nome" value={refertoData.nome} onChange={handleInputChange} />
              {lastPubblicato != null && (
                <Typography color="text.secondary" sx={{ mt: 2, ml: 2 }}>
                  Pubblicato: {lastPubblicato.nome}
                </Typography>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={6} display="flex" alignItems="center" sx={{ mt: 1 }}>
            <FormControl fullWidth>
              <TextField label="Titolo Intestazione" name="titolo_intestazione" value={refertoData.titolo_intestazione} onChange={handleInputChange} />
              {lastPubblicato != null && (
                <Typography color="text.secondary" sx={{ mt: 2, ml: 2 }}>
                  Pubblicato: {lastPubblicato.titolo_intestazione}
                </Typography>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={6} alignItems="center" display="flex">
            <MediaSelect required label="Documento Cover Start" supportedTypes={["PDF"]} currentSelectedMediaId={refertoData.id_media_cover} onSelectCallback={(media) => handleInputChange({ target: { name: "id_media_cover", value: media?.id } })} />
          </Grid>
          <Grid item xs={6} alignItems="center" display="flex">
            <MediaSelect label="Documento Preambolo" supportedTypes={["PDF"]} currentSelectedMediaId={refertoData.id_media_preambolo} onSelectCallback={(media) => handleInputChange({ target: { name: "id_media_preambolo", value: media?.id } })} />
          </Grid>
          <Grid item xs={6} alignItems="center" display="flex">
            <MediaSelect label="Documento Appendice" supportedTypes={["PDF"]} currentSelectedMediaId={refertoData.id_media_appendice} onSelectCallback={(media) => handleInputChange({ target: { name: "id_media_appendice", value: media?.id } })} />
          </Grid>
          <Grid item xs={6} alignItems="center" display="flex">
            <MediaSelect required label="Documento Cover End" supportedTypes={["PDF"]} currentSelectedMediaId={refertoData.id_media_chiusura} onSelectCallback={(media) => handleInputChange({ target: { name: "id_media_chiusura", value: media?.id } })} />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="subtitle2" sx={{ ml: 1 }}>
                Pagine
              </Typography>
              <Box sx={{ flex: 1 }} />
              <Button
                variant="contained"
                onClick={() => {
                  setSelectedImportReferto("");
                  setErrorImportReferto("");
                  setDialogImportaVisbile(true);
                }}
              >
                Importa da Elaborato
              </Button>
              <Button variant="contained" onClick={addNewPagina} endIcon={<Add />} sx={{ ml: 2 }}>
                Aggiungi Pagina
              </Button>
            </Box>
            {refertoData?.pagine.length > 0 && <PagineReferto key="bozza" selectedTab={selectPagina} setSelectedTab={setSelectPagina} refertoData={refertoData} setRefertoData={setRefertoData} />}
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          {refertoData.bozza == true && lastPubblicato?.pagine.length > 0 && (
            <Grid item xs={12}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMore />}>Pagine Elaborato Pubblicate</AccordionSummary>
                <AccordionDetails>
                  <PagineReferto key="last_pubblicato" selectedTab={selectPaginaPubblicata} setSelectedTab={setSelectPaginaPubblicata} refertoData={lastPubblicato} setRefertoData={() => {}} readOnly={true} />
                </AccordionDetails>
              </Accordion>
            </Grid>
          )}
          <Grid item xs={12}>
            <Box sx={{ display: "flex", mb: 2 }}>
              <Box sx={{ flex: 1 }}></Box>
              <Button variant="contained" onClick={() => handleRefertoSubmit(false)} color="primary" sx={{ ml: 2 }} startIcon={<Save />}>
                {selectedReferto || isCreated ? "Salva come Bozza" : "Crea Elaborato"}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Snackbar open={snackPub} autoHideDuration={5000} onClose={handleCloseSnack} anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
        <Paper>
          <Alert onClose={handleCloseSnack} sx={{ width: "100%", alignItems: "center" }}>
            <Stack direction="row" alignItems="center">
              <Typography sx={{ fontFamily: "Poppins-Medium" }}>Vuoi pubblicare l'Elaborato?</Typography>
              <Button
                variant="contained"
                onClick={async () => {
                  handleRefertoSubmit(true);
                  handleCloseSnack();
                }}
                sx={{ display: "inline-block", ml: 2 }}
              >
                Pubblica
              </Button>
            </Stack>
          </Alert>
        </Paper>
      </Snackbar>

      <Snackbar open={snackPubDone} autoHideDuration={5000} onClose={handleClosePubbDoneSnack} anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
        <Paper>
          <Alert onClose={handleClosePubbDoneSnack} sx={{ width: "100%", alignItems: "center" }}>
            <Stack direction="row" alignItems="center">
              <Typography sx={{ fontFamily: "Poppins-Medium" }}>Elaborato Pubblicato</Typography>
            </Stack>
          </Alert>
        </Paper>
      </Snackbar>

      <Dialog open={!!errorCreaReferto} onClose={() => setErrorCreaReferto("")} maxWidth="sm" fullWidth>
        <DialogTitle variant="dialogTitle" color="error">
          Attenzione
        </DialogTitle>
        <DialogContent>
          <Typography color="error" style={{ whiteSpace: "pre-line" }}>
            {errorCreaReferto}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => setErrorCreaReferto("")}>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={dialogImportaVisbile} onClose={() => setDialogImportaVisbile(false)} maxWidth="sm" fullWidth>
        <DialogTitle variant="dialogTitle">Importa pagine da Elaborato</DialogTitle>
        <DialogContent>
          <FormControl sx={{ mt: 2 }} fullWidth>
            <InputLabel style={{ backgroundColor: "white" }}>Elaborato</InputLabel>
            <Select label="Elaborato" value={selectedImportReferto} onChange={(e) => setSelectedImportReferto(e.target.value)}>
              {listaReferti
                .filter((referto) => referto.id !== refertoData?.id)
                .map((referto) => (
                  <MenuItem key={referto.id} value={referto.id}>
                    <ListItemText primary={referto.nome} />
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          {errorImportReferto !== "" && (
            <Typography color="error" sx={{ whiteSpace: "pre-line", mt: 3 }}>
              {errorImportReferto}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogImportaVisbile(false)} sx={{ color: "#000" }}>
            Annulla
          </Button>
          <Button variant="contained" onClick={() => onImportReferto()}>
            IMPORTA
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
