import * as React from "react";
import { AddShoppingCart, Logout, MusicNote, ShoppingBasket, Storefront } from "@mui/icons-material";
import { Utenti } from "./components/Utenti";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import { pubblicaTutto } from "./dashboardAdminAPI";
import { Referti } from "./components/referti/Referti";
import { useDispatch, useSelector } from "react-redux";
import { MediaPage } from "./components/media/MediaPage";
import { Categorie } from "./components/categorie/Categorie";
import { Programmi } from "./components/programmi/Programmi";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Questionari } from "./components/questionari/Questionari";
import { getConteggioTecnicheDaSvolgere, setSelectedSection, setSnackbarData } from "./dashboardAdminSlice";
import { ConfigurazioniExcel } from "./components/configurazione_excel/ConfigurazioniExcel";
import { Assignment, Category, DisplaySettings, FolderShared, People, PermMedia, Psychology, Publish, QuestionAnswer } from "@mui/icons-material";
import { Drawer as MuiDrawer, Box, AppBar as MuiAppBar, MenuItem, Avatar, Menu, Toolbar, List, Typography, Divider, IconButton, ListItemButton, ListItemIcon, ListItemText, Button, Dialog, DialogContent, DialogActions, Snackbar, Alert, Badge } from "@mui/material";
import { getAPIErrorMessage } from "../../common/utils";
import { Ordini } from "./components/Ordini";
import { CodiciRivenditori } from "./components/codici_rivenditori/CodiciRivenditori";
import { AutorizzazioniAcquisto } from "./components/autorizzazioni_acquisto/AutorizzazioniAcquisto";
import { Audio } from "./components/audio/Audio";
import { CategorieAudio } from "./components/categorie_audio/CategorieAudio";
import { Configurazione } from "./components/Configurazione";
import { Tecniche } from "./components/abs/Tecniche";
import { useEffect } from "react";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));


const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0px 0px 0px 0px',
  },
}));



export function DashboardAdmin() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(true);
  const { selectedSection, snackbarData, conteggioTecnicheDaSvolgere } = useSelector((state) => state.dashboardAdmin);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openDialogPubb, setOpenDialogPubb] = React.useState(false);
  const openAnchor = Boolean(anchorEl);
  const { tokenAdmin, user } = useSelector((state) => state.loginadmin);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch({ type: "USER_LOGOUT" });
    navigate("/admin/login");
  };

  const onConfirmPubblicaTutto = async () => {
    setOpenDialogPubb(false);
    try {
      await pubblicaTutto(tokenAdmin);
    } catch (error) {
      dispatch(setSnackbarData({ message: getAPIErrorMessage(error), severity: "error" }));
      return;
    }

    dispatch(setSnackbarData({ message: "Pubblicazione effettuata con successo", severity: "success" }));
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    dispatch(setSnackbarData(null));
  };

  useEffect(() => {
    dispatch(getConteggioTecnicheDaSvolgere());
  }, []);

  const drawerItems = [
    { id: "media", label: "Media", icon: <PermMedia />, component: <MediaPage /> },
    { id: "categorie", label: "Categorie", icon: <Category />, component: <Categorie /> },
    { id: "configurazione_referti", label: "Configurazione Elaborati", label2: "Conf. Elaborati", icon: <Assignment />, component: <Referti /> },
    { id: "questionari", label: "Interviste", icon: <QuestionAnswer />, component: <Questionari /> },
    { id: "programmi", label: "Programmi", icon: <FolderShared />, component: <Programmi /> },
    { id: "categorie_audio", label: "Cat. Audio", icon: <Category />, component: <CategorieAudio /> },
    { id: "audio", label: "Audio", icon: <MusicNote />, component: <Audio /> },
    {
      id: "brainstimulation", label: "Tecniche ABS", icon:
        <StyledBadge badgeContent={conteggioTecnicheDaSvolgere} color="secondary" >
          <Psychology />
        </StyledBadge>,
      component: <Tecniche />
    },
    { id: "listaUtenti", label: "Lista Utenti", icon: <People />, component: <Utenti /> },
    { id: "listaOrdini", label: "Lista Ordini", icon: <ShoppingBasket />, component: <Ordini /> },
    { id: "configurazione_excel", label: "Configurazione Excel", label2: "Conf. Excel", icon: <DisplaySettings />, component: <ConfigurazioniExcel /> },
    { id: "codici_rivenditori", label: "Codici Rivenditori", label2: "Codici Riv.", icon: <Storefront />, component: <CodiciRivenditori /> },
    { id: "autorizzazioni_acquisto", label: "Autorizzazioni Acquisto", label2: "Autorizzazioni Acq.", icon: <AddShoppingCart />, component: <AutorizzazioniAcquisto /> },
    { id: "configurazione", label: "Configurazione", icon: <DisplaySettings />, component: <Configurazione /> },
  ];


  return (
    <>
      <Box sx={{ display: "flex" }}>
        <AppBar position="absolute" open={open} sx={{ zIndex: 600 }}>
          <Toolbar sx={{ pr: "24px" }}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography component="h1" variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
              {drawerItems.find((i) => i.id == selectedSection)?.label}
            </Typography>
            <IconButton onClick={handleClick} size="small" sx={{ ml: 2 }} aria-controls={openAnchor ? "account-menu" : undefined} aria-haspopup="true" aria-expanded={openAnchor ? "true" : undefined}>
              <Avatar sx={{ width: 32, height: 32 }}>{user?.username?.charAt(0)}</Avatar>
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={openAnchor}
              onClose={handleClose}
              onClick={handleClose}
              slotProps={{
                paper: {
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": { width: 32, height: 32, ml: -0.5, mr: 1 },
                    "&:before": { content: '""', display: "block", position: "absolute", top: 0, right: 14, width: 10, height: 10, bgcolor: "background.paper", transform: "translateY(-50%) rotate(45deg)", zIndex: 0 },
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem onClick={handleLogout}>
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                Logout
              </MenuItem>
            </Menu>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open} sx={{ zIndex: 500, height: '100vh', overflowY: 'auto' }}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            {drawerItems.map((i) => (
              <ListItemButton key={i.id} onClick={() => dispatch(setSelectedSection(i.id))} selected={selectedSection == i.id}>
                <ListItemIcon>{i.icon}</ListItemIcon>
                <ListItemText primary={i.label2 || i.label} />
              </ListItemButton>
            ))}
          </List>
          <Box sx={{ flex: 1, display: "flex", alignItems: "flex-end", px: open ? 2 : 0.5 }}>
            <Button fullWidth variant="contained" startIcon={<Publish />} sx={{ "& .MuiButton-startIcon": !open ? { margin: "0px" } : {}, mb:2 }} size="large" onClick={() => setOpenDialogPubb(true)}>
              {open && "Pubblica Tutto"}
            </Button>
          </Box>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) => (theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[900]),
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Box sx={{ mx: 4, my: 2 }}>{drawerItems.find((i) => i.id == selectedSection)?.component}</Box>
        </Box>
      </Box>

      <Dialog open={openDialogPubb} maxWidth="xs">
        <DialogContent sx={{ textAlign: "center" }}>
          <Typography variant="title2">Pubblica TUTTO</Typography>
          <Box sx={{ my: 3 }} />
          <Typography variant="p">Sei sicuro di voler pubblicare tutti gli elementi in bozza?</Typography>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setOpenDialogPubb(false)} sx={{ color: "#000" }}>
            Annulla
          </Button>
          <Button onClick={onConfirmPubblicaTutto} variant="contained">
            CONFERMA
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={snackbarData !== null} anchorOrigin={{ vertical: "top", horizontal: "center" }} autoHideDuration={4000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarData?.severity} variant="filled" sx={{ width: "100%" }}>
          {snackbarData?.message}
        </Alert>
      </Snackbar>
    </>
  );
}
