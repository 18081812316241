import api from '../../config/axiosConfig';

export const register = async (data) => {
    const response = await api.post('/api/register', data);
    return response.data;
}

export const getCarosello = async () => {
  const response = await api.get('/api/carosello');
  return response.data.data;
}