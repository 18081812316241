import React, { useEffect, useRef, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  TableSortLabel,
  FormControl,
  TextField,
  CircularProgress,
  Box,
  Grid,
  InputAdornment,
  IconButton,
  Collapse,
  Divider,
  Stack,
  Typography,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  Select,
  MenuItem,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getListaOrdini } from "../dashboardAdminSlice";
import { formatDate, getAPIErrorMessage } from "../../../common/utils";
import { InsertDriveFile, KeyboardArrowDown, KeyboardArrowUp, Search } from "@mui/icons-material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { exportOrdini } from "../dashboardAdminAPI";

const headCells = [
  { id: "numero_ordine", label: "Numero Ordine" },
  { id: "utente.nome", label: "Utente", format: (val, data) => data.utente.nome + " " + data.utente.cognome },
  { id: "utente.tipologia", label: "Tipo Utente", format: (val, data) => data.utente.tipologia === "P" ? "Privato" : data.utente.tipologia === "PA" ? "Professionista" : "Rivenditore" },
  { id: 'utente.codice_utente', label: 'Codice Utente', format: (val, data) => data.utente.codice_utente },
  { id: "data_ordine", label: "Data", format: (val, data) => formatDate(val) },
  //{ id: "totale", label: "Totale", format: (val, data) => Intl.NumberFormat('it-IT', { style: 'currency', currency: 'eur' }).format(val) },
  //{ id: "sconto", label: "Sconto", format: (val, data) => Intl.NumberFormat('it-IT', { style: 'currency', currency: 'eur' }).format(val) },
  { id: "totale_finale", label: "Totale Finale", format: (val, data) => Intl.NumberFormat('it-IT', { style: 'currency', currency: 'eur' }).format(val) },
  { id: "stato", label: "Stato" },
  { id: "saferpay_payment_method", label: "Metodo Pagamento" },
  { id: "data_pagamento", label: "Data Pagamento", format: (val, data) => (val ? formatDate(val) : "-") },
];

const filters = [
  { id: "codice_utente", label: "Codice Utente" },
  { id: "codice_rivenditore", label: "Codice Rivenditore" },
  { id: "tipologia", label: "Tipologia" },
];

export function Ordini() {
  const tableRef = useRef();
  const startEmpryListRef = useRef();
  const dispatch = useDispatch();
  const { isLoading, listaOrdini, pageInfoListaOrdini } = useSelector((state) => state.dashboardAdmin);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = useState("data_ordine");
  const [order, setOrder] = useState("desc");
  const [search, setSearch] = useState("");
  const [emptyListH, setEmptyListH] = useState(0);
  const [exportUtenti, setExportUtenti] = useState(false);
  const [start_date, setStartDate] = useState(undefined);
  const [end_date, setEndDate] = useState(undefined);
  const [tipo_filtro, setTipoFiltro] = useState(filters[0].id);
  const [filtroSrc, setFiltroSrc] = useState("");
  const [errExportUtenti, setErrExportUtenti] = useState({});
  const tokenAdmin = useSelector((state) => state.loginadmin.tokenAdmin);
  const [exportError, setExportError] = useState(null);

  useEffect(() => {
    setEmptyListH(startEmpryListRef ? window.innerHeight - startEmpryListRef.current?.getBoundingClientRect().top - 68 : 0);
  }, [startEmpryListRef, listaOrdini]);

  useEffect(() => {
    dispatch(getListaOrdini({ params: { per_page: rowsPerPage, sort: order, order_by: orderBy } }));
  }, []);

  const handleChangePage = (event, newPage) => {
    dispatch(getListaOrdini({ params: { page: newPage + 1, per_page: rowsPerPage, sort: order, order_by: orderBy, search } }));
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const countR = parseInt(event.target.value, 10);
    dispatch(getListaOrdini({ params: { per_page: countR, sort: order, order_by: orderBy, search } }));
    setRowsPerPage(countR);
    setPage(1);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    dispatch(getListaOrdini({ params: { page: page + 1, per_page: rowsPerPage, sort: isAsc ? "desc" : "asc", order_by: property, search } }));
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const searchOrdine = () => {
    dispatch(getListaOrdini({ params: { per_page: rowsPerPage, sort: order, order_by: orderBy, search } }));
  };

  const clearExportUtenti = () => {
    setStartDate(undefined);
    setEndDate(undefined);
    setTipoFiltro(filters[0].id);
    setFiltroSrc("");
    setErrExportUtenti({});
    setExportUtenti(false);
  };
  const abb_types = { SETT: "Settimanale", MENS: "Mensile", TRIM: "Trimestrale", SEM: "Semestrale", ANNUAL: "Annuale", VITA: "A Vita" };

  const onEsporta = async () => {
    setExportError(null);
    let err = {};
    if (!end_date) err = { ...err, end_date: "Inserire la Data di fine Ordini" };
    if (!start_date) err = { ...err, start_date: "Inserire la Data di inizio Ordini" };
    if (tipo_filtro !== "tipologia" && !filtroSrc?.trim()) err = { ...err, filtroSrc: `Inserire il ${tipo_filtro === "codice_utente" ? "Codice Utente" : "Codice Rivenditore"}` };
    if (tipo_filtro === "tipologia" && !filtroSrc?.trim()) err = { ...err, filtroSrc: `Selezionare la Tipologia` };

    let fileName = 'Ordini_';
    if (tipo_filtro === "tipologia") {
      if (filtroSrc === "P") fileName += "Privati";
      if (filtroSrc === "PA") fileName += "Professionisti Attivi";
      if (filtroSrc === "PP") fileName += "Professionisti Passivi";
    } else  if(tipo_filtro === "codice_utente") {
      fileName += `CodiceUtente_${filtroSrc}`;
    }else {
      fileName += `CodiceRivenditore_${filtroSrc}`;
    }

    if (start_date && end_date) {
      fileName += `_${formatDateYYYYMMGG(start_date)}_${formatDateYYYYMMGG(end_date)}`;
    }

    fileName += ".xlsx";

    setErrExportUtenti(err);
    if (Object.keys(err).length > 0) {
      return;
    }

    let response;
    try {
      response = await exportOrdini({ start_date, end_date, tipo_filtro, filtroSrc }, tokenAdmin);
    } catch (e) {
      setExportError(getAPIErrorMessage(e));
      return;
    }

    const url = window.URL.createObjectURL(new Blob([response], { type: "application/vnd.ms-excel" }));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName); 
    document.body.appendChild(link);
    link.click();
  };

  function formatDateYYYYMMGG(inputDateString) {
    // Creazione di un oggetto Date dalla stringa
    var data = new Date(inputDateString);

    // Ottenere il giorno, il mese e l'anno dalla data
    var giorno = data.getDate();
    var mese = data.getMonth() + 1; // Mese inizia da zero, quindi aggiungi 1
    var anno = data.getFullYear();

    // Aggiungere zero davanti a giorno e mese se sono inferiori a 10
    giorno = giorno < 10 ? "0" + giorno : giorno;
    mese = mese < 10 ? "0" + mese : mese;

    // Creare la stringa nel formato desiderato
    return giorno + mese + anno;
  }

  const Row = ({ ordine }) => {
    const [open, setOpen] = useState(false);

    return (
      <React.Fragment key={ordine.id}>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </TableCell>
          {headCells.map((col) => (
            <TableCell key={ordine.id + col.id}>{col.format ? col.format(ordine[col.id], ordine) : ordine[col.id] || "-"}</TableCell>
          ))}
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Divider textAlign="left">Dettaglio Acquisti</Divider>
              <Box sx={{ m: 1, pl: 5 }}>
              <Grid container spacing={2}>
                  {ordine.voci?.map((voce, i) => (
                    <>
                      {
                        voce.tipo_voce == 'TEC' &&
                        <Grid item xs={12} key={i}>
                          <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                            <Stack flex={1} direction="row">
                              <strong style={{ marginRight: "8px" }}>Tecnica ABS </strong>
                            </Stack>
                            <Stack flex={1} direction="row">
                              <strong style={{ marginRight: "8px" }}>Brani: </strong>
                              {
                                voce.tecnica_abs.brani?.map((brano, i) => (
                                  <span key={i}>{brano.nome}{i < voce.tecnica_abs.brani.length - 1 ? ", " : ""}</span>
                                ))
                              }
                            </Stack>
                            <Stack flex={1} direction="row">
                              <strong style={{ marginRight: "8px" }}>N. Frasi Negative: </strong>{ voce.tecnica_abs.frasi_negative?.length} 
                           
                            </Stack>
                            <Stack flex={1} direction="row">
                              <strong style={{ marginRight: "8px" }}>N. Frasi Positive:  </strong>{ voce.tecnica_abs.frasi_positive?.length} 
                          
                            </Stack>
                           
                            <Stack flex={1} direction="row">
                              <strong>Totale Voce: </strong>{Intl.NumberFormat('it-IT', { style: 'currency', currency: 'eur' }).format(voce.prezzo_totale_voce)}
                            </Stack>
                          </Stack>
                        </Grid>
                      }
                      {
                        voce.tipo_voce == 'AUD' &&
                        <Grid item xs={12} key={i}>
                          <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                            <Stack flex={1} direction="row">
                              <strong style={{ marginRight: "8px" }}>Brano: </strong>
                              {voce.audio.nome}
                            </Stack>
                            <Stack flex={1} direction="row">
                              <strong style={{ marginRight: "8px" }}>Quantità: </strong>
                              {voce.qta}
                            </Stack>
                            <Stack flex={2} direction="row">

                            </Stack>
                            <Stack flex={1} direction="row">
                              <strong>Totale Voce: </strong>{Intl.NumberFormat('it-IT', { style: 'currency', currency: 'eur' }).format(voce.prezzo_totale_voce)}
                            </Stack>
                          </Stack>
                        </Grid>
                      }
                      {
                        voce.tipo_voce == 'PROG' &&
                        <Grid item xs={12} key={i}>
                          <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                            <Stack flex={1} direction="row">
                              <strong style={{ marginRight: "8px" }}>Programma: </strong>
                              {voce.programma.nome}
                            </Stack>
                            {voce.tipo_acquisto == "ABB" ? (
                              <Stack flex={1} direction="row">
                                <strong style={{ marginRight: "8px" }}>Abbonamento: </strong> {abb_types[voce.tipo_abbonamento]}
                              </Stack>
                            ) : (
                              <Stack flex={1} direction="row">
                                <strong style={{ marginRight: "8px" }}>Quantita: </strong>
                                {voce.qta}
                              </Stack>
                            )}
                            <Stack flex={1} direction="row">
                              <strong style={{ marginRight: "8px" }}>Totale Voce: </strong>{Intl.NumberFormat('it-IT', { style: 'currency', currency: 'eur' }).format(voce.prezzo_totale_voce)}
                            </Stack>
                          </Stack>
                        </Grid>
                      }
                    </>
                  ))}
                </Grid>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  };

  return (
    <>
      {isLoading && (
        <Box sx={{ bgcolor: "rgba(255,255,255,.6)", opacity: 5, zIndex: 4, height: tableRef.current?.getBoundingClientRect().height, width: tableRef.current?.getBoundingClientRect().width, position: "absolute", display: "flex", alignItems: "center", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      )}
      <TableContainer ref={tableRef} component={Paper}>
        <Grid alignItems={"center"} container direction={"row"} spacing={2} sx={{ p: 2 }}>
          <Grid item xs={12} md={5}>
            <FormControl fullWidth>
              <TextField
                label="Cerca"
                variant="outlined"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onKeyDown={(e) => e.key === "Enter" && searchOrdine()}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={searchOrdine}>
                        <Search />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={0} md={5} style={{ display: "block" }} />
          <Grid item xs={4} md={2}>
            <Button fullWidth variant="contained" size="large" endIcon={<InsertDriveFile />} onClick={() => setExportUtenti(true)}>
              ESPORTA
            </Button>
          </Grid>
        </Grid>

        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              {headCells.map((headCell) => (
                <TableCell key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}>
                  {(headCell.sortable === undefined || headCell.sortable === true) && (
                    <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : "asc"} onClick={() => handleRequestSort(headCell.id)}>
                      {headCell.label}
                    </TableSortLabel>
                  )}
                  {headCell.sortable === false && <span>{headCell.label}</span>}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {listaOrdini?.map((ordine) => (
              <Row key={ordine.id} ordine={ordine} />
            ))}
            <TableRow ref={startEmpryListRef} key={"emptyRow"} style={{ height: emptyListH }}>
              <TableCell sx={{ verticalAlign: "baseline", fontSize: 18 }} colSpan={headCells.length + 1}>
                {!listaOrdini || listaOrdini?.length === 0 ? "Non sono presenti ordini" : ""}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={pageInfoListaOrdini?.total || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Righe"
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} di ${count}`}
        />
      </TableContainer>
      {/* Dialog per l'esportazione */}
      <Dialog open={exportUtenti} maxWidth="sm" fullWidth sx={{ p: 2 }}>
        <DialogTitle variant="dialogTitle">Esporta Ordini Utenti</DialogTitle>
        <DialogContent>
          <Grid container columnSpacing={2} sx={{ mt: 2 }}>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <DesktopDatePicker
                  format="DD/MM/YYYY"
                  onChange={(value) => value && setStartDate(value.format("YYYY-MM-DD"))}
                  fullWidth
                  InputLabelProps={{ sx: { color: "primary.background" } }}
                  variant="standard"
                  label="Data Inizio"
                  maxDate={dayjs(end_date || new Date())}
                  slotProps={{
                    field: { clearable: true, onClear: () => setStartDate(undefined) },
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <DesktopDatePicker
                  format="DD/MM/YYYY"
                  onChange={(value) => value && setEndDate(value.format("YYYY-MM-DD"))}
                  fullWidth
                  InputLabelProps={{ sx: { color: "primary.background" } }}
                  variant="standard"
                  label="Data Fine"
                  minDate={start_date ? dayjs(start_date) : undefined}
                  maxDate={dayjs(new Date())}
                  slotProps={{
                    field: { clearable: true, onClear: () => setEndDate(undefined) },
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
          <FormControl fullWidth sx={{ my: 2 }}>
            <Select
              value={tipo_filtro}
              onChange={(event) => {
                if (event.target.value === "tipologia") setFiltroSrc("P");
                else setFiltroSrc("");
                setTipoFiltro(event.target.value);
              }}
            >
              {filters.map((f) => (
                <MenuItem key={f.id} value={f.id}>
                  {f.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            {tipo_filtro === "tipologia" ? (
              <Select value={filtroSrc} onChange={(event) => setFiltroSrc(event.target.value)}>
                <MenuItem value={"P"}>Privato</MenuItem>
                <MenuItem value={"PA"}>Professionista</MenuItem>
                <MenuItem value={"PP"}>Rivenditore</MenuItem>
              </Select>
            ) : (
              <TextField label={tipo_filtro === "codice_utente" ? "Codice Utente" : "Codice Rivenditore"} variant="outlined" value={filtroSrc} onChange={(e) => setFiltroSrc(e.target.value)} />
            )}
          </FormControl>
          {Object.keys(errExportUtenti).map((scope) => (
            <Typography key={scope} sx={{ mt: 1, textAlign: "center" }} color={"red"}>
              {errExportUtenti[scope]}
            </Typography>
          ))}

          {
            exportError != null && (
              <Typography sx={{ mt: 1, textAlign: "center" }} color={"red"}>
                {exportError}
              </Typography>
            )
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={clearExportUtenti} sx={{ color: "#000" }}>
            Annulla
          </Button>
          <Button
            variant="contained"
            onClick={() => onEsporta()}
          >
            ESPORTA
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
