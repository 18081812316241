import { useTheme } from '@emotion/react';
import { Grid, Hidden, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const NavbarItem = ({ glow = false, shortVarian = true, to, params = {}, icon, title, selected, height = '50px' }) => {
    const navigate = useNavigate();
    const theme = useTheme();
    const matchLg = useMediaQuery(theme.breakpoints.up('lg'));

    let style = {};
    if (selected) {
        style = {
            backgroundColor: '#255300',
        }
    } else {
        style = {
        }
    }

    return (
        <Grid item sx={{ cursor: 'pointer', color: 'white', display: 'flex', borderRadius: '10px',  paddingLeft: matchLg ? '8px!important' : '12px!important', paddingRight: matchLg ? '8px' : '12px', margin: matchLg ? '3px' : '3px', height: height, ...style }}>
            <Typography onClick={() => to ? navigate(to, { state: params }) : null} display="flex" justifyContent="center" alignItems="center">
                {icon}
                {
                    shortVarian ?
                        <Hidden lgDown>
                            <span style={{ margin: 0, padding: 0, marginLeft: '0.5rem' }} className={glow ? 'glow' : ''} >
                                {title}
                            </span>
                        </Hidden> :
                        <span style={{ margin: 0, padding: 0, marginLeft: '0.5rem' }} className={glow ? 'glow' : ''} >
                            {title}
                        </span>
                }

            </Typography>
        </Grid>
    )
};

export default NavbarItem;