import * as React from "react";
import { Box, IconButton, Paper, Table, TableHead, TableRow, TableCell, TableSortLabel, TableBody, TablePagination, TableContainer } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getListaTecniche } from "../../dashboardAdminSlice";
import { formatDate } from "../../../../common/utils";
import { Edit } from "@mui/icons-material";
import TecnicaDetails from "./TecnicaDetails";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";

export function Tecniche() {
  const dispatch = useDispatch();
  const tableRef = useRef();
  const startEmpryListRef = useRef();
  const [selectedTecnica, setSelectedTecnica] = React.useState(null);
  const [isEditorOpen, setIsEditorOpen] = React.useState(false);
  const [emptyListH, setEmptyListH] = useState(0);

  const { listaTecniche, pageInfoListaTecniche } = useSelector((state) => state.dashboardAdmin);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = useState("created_at");
  const [order, setOrder] = useState("desc");

  useEffect(() => {
    setEmptyListH(startEmpryListRef ? window.innerHeight - startEmpryListRef.current?.getBoundingClientRect().top - 68 : 0);
  }, [startEmpryListRef, listaTecniche]);

  React.useEffect(() => {
    dispatch(getListaTecniche());
  }, []);


  const headCells = [
    { id: 'numero_tecnica', label: "N. Tecnica", format: (val) => val != null ? val : "-" },
    { id: "created_at", label: "Data", format: (val) => formatDate(val, "DD/MM/YYYY") },
    { id: 'stato', label: "Stato", format: (val) => (val === "RIC" ? "Da Svolgere" : "Consegnato") },
    {
      id: "brani",
      label: "N. Brani",
      sortable: false,
      format: (val) => val.length
    },
    {
      id: 'frasi_negative',
      label: "N. Frasi Negative",
      sortable: false,
      format: (val) => val.length
    },
    {
      id: 'frasi_positive',
      label: "N. Frasi Positive",
      sortable: false,
      format: (val) => val.length
    },
    {
      id: 'utente_privato',
      label: "Utente",
      sortable: false,
      format: (val) => val.codice_utente
    },
    {
      id: 'utente_professionista',
      label: "Professionista",
      sortable: false,
      format: (val) => val ? val.codice_utente : "-"
    },
    { id: "azioni", label: "Azioni", sortable: false },
  ];


  const handleChangePage = (event, newPage) => {
    dispatch(getListaTecniche({ params: { page: newPage + 1, per_page: rowsPerPage, sort: order, order_by: orderBy } }));
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const countR = parseInt(event.target.value, 10);
    dispatch(getListaTecniche({ params: { per_page: countR, sort: order, order_by: orderBy } }));
    setRowsPerPage(countR);
    setPage(1);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    dispatch(getListaTecniche({ params: { page: page + 1, per_page: rowsPerPage, sort: isAsc ? "desc" : "asc", order_by: property } }));
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const openSelection = (tecnica) => {
    setSelectedTecnica(tecnica);
    setIsEditorOpen(true);
  }



  return (
    <>
      {isEditorOpen ? (
        <TecnicaDetails selectedTecnica={selectedTecnica || false} setIsEditorOpen={setIsEditorOpen} />
      ) : (
        <Box>
          <TableContainer ref={tableRef} component={Paper}>

            <Table>
              <TableHead>
                <TableRow>
                  {headCells.map((headCell) => (
                    <TableCell key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}>
                      {(headCell.sortable === undefined || headCell.sortable === true) && (
                        <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : "asc"} onClick={() => handleRequestSort(headCell.id)}>
                          {headCell.label}
                        </TableSortLabel>
                      )}
                      {headCell.sortable === false && <span>{headCell.label}</span>}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {listaTecniche?.map((tecnica) => (
                  <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
                    {headCells.map((col) =>
                      col.id === "azioni" ? (
                        <TableCell key={col.id}>
                          <IconButton onClick={() => openSelection(tecnica)}>
                            <Edit />
                          </IconButton>

                        </TableCell>
                      ) : (
                        <TableCell key={tecnica.id + col.id}>{col.format ? col.format(tecnica[col.id], tecnica) : tecnica[col.id] || "-"}</TableCell>
                      ))}
                  </TableRow>
                ))}
                <TableRow ref={startEmpryListRef} key={"emptyRow"} style={{ height: emptyListH }}>
                  <TableCell sx={{ verticalAlign: "baseline", fontSize: 18 }} colSpan={headCells.length + 1}>
                    {!listaTecniche || listaTecniche?.length === 0 ? "Non sono presenti ordini" : ""}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={pageInfoListaTecniche?.total || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="Righe"
              labelDisplayedRows={({ from, to, count }) => `${from}-${to} di ${count}`}
            />
          </TableContainer>
        </Box>
      )}
    </>
  );
}
