import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { CONFIG } from "../../config/envConfig";

export function Footer() {
  const theme = useTheme();
  const c = theme.palette;


  return (
    <Box sx={{ display: 'flex', pr: 2, backgroundColor: '#eee', height: CONFIG.FOOTER_HEIGHT + 'px', justifyContent:'end' }}>
      <Typography sx={{ fontSize: '11px' }}><a href={process.env.REACT_APP_TOMMY_API_URL + "/documents/privacy_policy.pdf"} target="_blank" style={{ color: c.primary.main, textDecoration: 'none' }}>Privacy Policy</a></Typography>
      <Typography sx={{ ml: 1, fontSize: '11px' }}><a href={process.env.REACT_APP_TOMMY_API_URL + "/documents/Termini_e_Condizioni.pdf"} target="_blank" style={{ color: c.primary.main, textDecoration: 'none' }}>Termini e Condizioni</a></Typography>
    </Box>
  );
}