import React, { useEffect, useRef, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TablePagination,
  TableSortLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Switch,
  FormControlLabel,
  CircularProgress,
  Box,
  Collapse,
  Grid,
  Stack,
  Divider,
  InputAdornment,
  Typography,
  OutlinedInput,
  Tab,
  Autocomplete,
  Checkbox,
  createFilterOptions,
  ListItem,
  ListItemText,
  ListItemButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";
import { getUtente, getListaUtenti, getProgrammiAbilitatiPerUtente, setSnackbarData, getListaAudio } from "../dashboardAdminSlice";
import { AudioFile, CheckBox, CheckBoxOutlineBlank, Delete, KeyboardArrowDown, KeyboardArrowUp, Redeem, Search } from "@mui/icons-material";
import { formatDate, getAPIErrorMessage } from "../../../common/utils";
import { regalaBrani, regalaCodici, updateUtente, deleteUtente } from "../dashboardAdminAPI";
import { LoadingButton, TabContext, TabList, TabPanel } from "@mui/lab";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { useTheme } from "@emotion/react";

const headCells = [
  { id: "codice_utente", label: "Codice Utente" },
  {
    id: "nome",
    label: "Nome",
    sortable: false
  },
  {
    id: "cognome",
    label: "Cognome",
    sortable: false
  },
  { id: "email", label: "Email" },
  { id: "tipologia", label: "Tipologia", format: (val) => (val === "P" ? "Privato" : val === "PP" ? "Rivenditore" : val === "PA" ? "Professionista" : "") },
  { id: 'created_at', label: 'Data Registrazione', format: (val) => formatDate(val) },
  { id: "flg_enabled", label: "Abilitato", format: (val) => (val ? "SI" : "NO") },
  { id: "flg_reset_password", label: "Password Cambiata", format: (val) => (val ? "NO" : "SI") },
  { id: "azioni", label: "Azioni", sortable: false },
];

const Row = ({ utente, actionClick, regalaCodiciClick, regalaBraniClick, handleDeleteClick }) => {
  const [open, setOpen] = useState(false);

  const collapsedCols =
    utente.tipologia === "P"
      ? [
        { id: "indirizzo", label: "Indirizzo" },
        { id: "cap", label: "CAP" },
        { id: "citta", label: "Città" },
        { id: "cf", label: "Codice Fiscale" },
        { id: "sesso", label: "Sesso" },
        { id: "peso", label: "Peso (kg)" },
        { id: "altezza", label: "Altezza (cm)" },
        { id: "data_nascita", label: "Data Nascita", format: (val) => formatDate(val) },
      ]
      : [
        { id: "piva", label: "Partita IVA" },
        { id: "cf", label: "Codice Fiscale" },
        { id: "ragione_sociale", label: "Ragione Sociale" },
        { id: "indirizzo", label: "Indirizzo" },
        { id: "cap", label: "CAP" },
        { id: "citta", label: "Città" },
        { id: "pec", label: "PEC" },
        { id: "sdi", label: "SDI" },
        { id: "codice_rivenditore", label: "Codice Rivenditore" },
      ];

  return (
    <React.Fragment key={utente.id}>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        {headCells.map((col) =>
          col.id === "azioni" ? (
            <TableCell key={col.id}>
              <IconButton onClick={() => actionClick(utente.id)}>
                <EditIcon />
              </IconButton>
              <IconButton onClick={() => regalaCodiciClick(utente.id)}>
                <Redeem />
              </IconButton>
              {
                utente.tipologia !== "PP" && <IconButton onClick={() => regalaBraniClick(utente.id)}>
                  <AudioFile />
                </IconButton>
              }
              <IconButton onClick={() => handleDeleteClick(utente.id)} >
                <Delete />
              </IconButton>


            </TableCell>
          ) : (
            <TableCell key={col.id}>{col.format ? col.format(utente[col.id], utente) : utente[col.id] || "-"}</TableCell>
          )
        )}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Divider textAlign="left">Informazioni Utente</Divider>
            <Box sx={{ m: 1, pl: 5 }}>
              <Grid container spacing={2}>
                {collapsedCols.map((col) => (
                  <Grid key={`${col.id}`} item xs={4} sx={{ alignItems: "center" }}>
                    <Stack direction={"row"}>
                      <div style={{ flex: 1 }}>
                        <b>{col.label}: </b>
                      </div>
                      <div style={{ flex: 1 }}>{col.format ? col.format(utente[col.id], utente) : utente[col.id] || "-"}</div>
                    </Stack>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export function Utenti() {
  const tableRef = useRef();
  const startEmpryListRef = useRef();
  const dispatch = useDispatch();
  const theme = useTheme();
  const c = theme.palette;
  const tokenAdmin = useSelector((state) => state.loginadmin.tokenAdmin);
  const { listaUtenti, pageInfoListaUtenti, utente, isLoadingListaAudio, isLoading, isDetailsLoading, programmiAbilitatiUtente, isLoadingProgrammiAbilitatiUtente, listaAudio } = useSelector((state) => state.dashboardAdmin);
  const [openDialog, setOpenDialog] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = useState("codice_utente");
  const [order, setOrder] = useState("asc");
  const [search, setSearch] = useState("");
  const [utenteModifica, setUtenteModifica] = useState(false);
  const [emptyListH, setEmptyListH] = useState(0);
  const [isDialogRegalaCodiciOpen, setIsDialogRegalaCodiciOpen] = useState(false);
  const [isDialogRegalaBraniOpen, setIsDialogRegalaBraniOpen] = useState(false);
  const [braniSelected, setBraniSelected] = useState([]);
  const [qtaBrani, setQtaBrani] = useState(0);
  const [errorRegalaBrani, setErrorRegalaBrani] = useState("");
  const [programmaSlcted, setProgrammaSlcted] = useState(null);
  const [numeroCodici, setNumeroCodici] = useState(0);
  const [errorRegalaCodici, setErrorRegalaCodici] = useState("");
  const [selectedUtenteId, setSelectedUtenteId] = useState(null);
  const [selectedUtente, setSelectedUtente] = useState(null);
  const [isLoadingRegalaCodici, setIsLoadingRegalaCodici] = useState(false);
  const [isLoadingRegalaBrani, setIsLoadingRegalaBrani] = useState(false);
  const [selectedTab, setSelectedTab] = useState("C");
  const [abbonamentoInizio, setAbbonamentoInizio] = useState(null);
  const [abbonamentoFine, setAbbonamentoFine] = useState(null);
  const [modificaUtenteError, setModificaUtenteError] = useState(false);
  const [groupedListaAudio, setGroupedListaAudio] = useState([]);
  const [dialogDeleteUtente, setDialogDeleteUtente] = useState(null);
  const [dialogError, setDialogError] = useState(false);

  useEffect(() => {
    dispatch(getListaUtenti({ params: { per_page: rowsPerPage, sort: order, order_by: orderBy } }));
  }, []);

  useEffect(() => {
    setEmptyListH(startEmpryListRef ? window.innerHeight - startEmpryListRef.current?.getBoundingClientRect().top - 68 : 0);
  }, [startEmpryListRef, listaUtenti]);

  const handleEditClick = (id) => {
    dispatch(getUtente(id));
    setOpenDialog(true);
  };

  const regalaCodiciClick = (id) => {
    setIsLoadingRegalaCodici(false);
    setSelectedUtenteId(id);
    setSelectedUtente(listaUtenti.find((u) => u.id === id));
    setErrorRegalaCodici("");
    setProgrammaSlcted(null);
    setNumeroCodici(0);
    dispatch(getProgrammiAbilitatiPerUtente(id));
    setAbbonamentoFine(null);
    setAbbonamentoInizio(null);
    setIsDialogRegalaCodiciOpen(true);
    setSelectedTab("C");
  };

  const regalaBraniClick = (id) => {
    setIsLoadingRegalaBrani(false);
    setErrorRegalaBrani("");
    setSelectedUtenteId(id);
    setSelectedUtente(listaUtenti.find((u) => u.id === id));
    dispatch(getListaAudio());
    setIsDialogRegalaBraniOpen(true);
    setBraniSelected([]);
    setQtaBrani(1);
  };

  useEffect(() => {
    // Quando l'utente viene caricato, imposta il valore di abilitato 
    if (utente) {
      setUtenteModifica(JSON.parse(JSON.stringify(utente)));
    }
  }, [utente]);

  const handleDialogClose = async (salva) => {
    setModificaUtenteError("");
    // Gestisci la chiusura del dialog
    if (salva) {
      var data = { flg_enabled: utenteModifica.flg_enabled, email: utenteModifica.email, codice_rivenditore: utenteModifica.codice_rivenditore };
      try {
        await updateUtente(utenteModifica.id, data, tokenAdmin);
        dispatch(getListaUtenti({ params: { page: page + 1, per_page: rowsPerPage, sort: order, order_by: orderBy, search } }));
        setOpenDialog(false);
      } catch (err) {
        setModificaUtenteError(getAPIErrorMessage(err));
      }
    } else {
      setOpenDialog(false);
    }
  };

  const handleDialogRegalaBraniClose = async (salva) => {
    // Gestisci la chiusura del dialog
    if (salva) {
      if (braniSelected === null || braniSelected.length === 0) {
        setErrorRegalaCodici("Seleziona almeno un brano");
        return;
      }

      if (qtaBrani === 0 || qtaBrani === "") {
        setErrorRegalaBrani("Inserisci la quantità di brani da regalare");
        return;
      }


      setIsLoadingRegalaBrani(true);
      try {
        await regalaBrani({ id_utente: selectedUtenteId, id_brani: braniSelected.map(el => el.id), qta: qtaBrani }, tokenAdmin);
      } catch (err) {
        setErrorRegalaBrani(getAPIErrorMessage(err));
        return;
      } finally {
        setIsLoadingRegalaBrani(false);
      }
      dispatch(setSnackbarData({ open: true, variant: "success", message: "Brani regalati correttamente" }));
    }
    setIsDialogRegalaBraniOpen(false);
  };

  const handleDialogRegalaCodiciClose = async (salva) => {
    // Gestisci la chiusura del dialog
    if (salva) {
      if (programmaSlcted === null) {
        setErrorRegalaCodici("Seleziona un programma");
        return;
      }

      if (selectedTab == 'C') {
        if (numeroCodici === 0 || numeroCodici === "") {
          setErrorRegalaCodici("Inserisci il numero di codici da regalare");
          return;
        }
      } else {
        if (abbonamentoInizio === null || abbonamentoFine === null || abbonamentoInizio === "" || abbonamentoFine === "") {
          setErrorRegalaCodici("Inserisci le date di inizio e fine abbonamento");
          return;
        }
      }


      setIsLoadingRegalaCodici(true);
      try {
        await regalaCodici({ id_utente: selectedUtenteId, id_programma: programmaSlcted, numero_codici: numeroCodici, tipo_regalo: selectedTab, inizio_abbonamento: abbonamentoInizio, fine_abbonamento: abbonamentoFine }, tokenAdmin);
      } catch (err) {
        setErrorRegalaCodici(getAPIErrorMessage(err));
        return;
      } finally {
        setIsLoadingRegalaCodici(false);
      }
      dispatch(setSnackbarData({ open: true, variant: "success", message: "Programma regalato correttamente" }));
    }
    setIsDialogRegalaCodiciOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    dispatch(getListaUtenti({ params: { page: newPage + 1, per_page: rowsPerPage, sort: order, order_by: orderBy, search } }));
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const countR = parseInt(event.target.value, 10);
    dispatch(getListaUtenti({ params: { per_page: countR, sort: order, order_by: orderBy, search } }));
    setRowsPerPage(countR);
    setPage(1);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    dispatch(getListaUtenti({ params: { page: page + 1, per_page: rowsPerPage, sort: isAsc ? "desc" : "asc", order_by: property, search } }));
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const searchUtente = () => {
    dispatch(getListaUtenti({ params: { per_page: rowsPerPage, sort: order, order_by: orderBy, search } }));
  };

  useEffect(() => {
    const groupedList = listaAudio.reduce((acc, item) => {
      for (let i = 0; i < item.categorie.length; i++) {
        const key = item.categorie[i]?.nome || "Senza Categoria";
        //se non esiste un item in acc con nome della categoria, lo creo
        if (!acc[key]) {
          acc[key] = { nome: key, options: [] };
        }
        //aggiungo l'item alla categoria
        acc[key].options.push(item);
      }
      return acc;
    }, {});
    var values = [];
    for (var key in groupedList) {
      values.push(groupedList[key]);
    }
    setGroupedListaAudio(values);
  }, [listaAudio]);

  const handleAudioChange = (event, newValue) => {
    //prendo tutte le categorie da newValue e setto come selezionati tutti i brani di quelle categorie
    const categorie = [];
    const brani = [];
    for (let i = 0; i < newValue.length; i++) {
      if (newValue[i].options) {
        categorie.push(newValue[i].nome);
      } else {
        brani.push(newValue[i]);
      }
    }
    //setto anche tutti i brani di categorie
    const groupValues = groupedListaAudio.filter((group) => categorie.includes(group.nome)).flatMap((group) => group.options);
    const newSelectedOptions = [...brani, ...groupValues.filter((option) => !brani.includes(option))];
    setBraniSelected(newSelectedOptions);
  };


  const handleAudioGroupSelect = (groupName) => {
    debugger;
    const groupOptions = groupedListaAudio.find((group) => group.nome === groupName).options;
    const groupValues = groupOptions.map((option) => option.nome);
    const newSelectedOptions = [...groupedListaAudio, ...groupValues.filter((option) => !braniSelected.includes(option))];
    setBraniSelected(newSelectedOptions);
  };

  const isAudioGroupSelected = (groupName) => {
    const groupOptions = groupedListaAudio.find((group) => group.nome === groupName).options;
    return groupOptions.every((option) => braniSelected.includes(option.nome));
  };

  const audioFilterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: (option) => option.nome,
  });


  const handleDeleteClick = async (id) => {
    setDialogDeleteUtente(id);
  };



  return (
    <>
      {isLoading && (
        <Box sx={{ bgcolor: "rgba(255,255,255,.6)", opacity: 5, zIndex: 4, height: tableRef.current?.getBoundingClientRect().height, width: tableRef.current?.getBoundingClientRect().width, position: "absolute", display: "flex", alignItems: "center", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      )}
      <TableContainer ref={tableRef} component={Paper}>
        <Grid alignItems={"center"} container direction={"row"} spacing={2} sx={{ p: 2 }}>
          <Grid item xs={12} md={5}>
            <FormControl fullWidth>
              <TextField
                label="Cerca"
                variant="outlined"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onKeyDown={(e) => e.key === "Enter" && searchUtente()}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={searchUtente}>
                        <Search />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={0} md={5} style={{ display: "block" }} />
        </Grid>

        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              {headCells.map((headCell) => (
                <TableCell key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}>
                  {(headCell.sortable === undefined || headCell.sortable === true) && (
                    <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : "asc"} onClick={() => handleRequestSort(headCell.id)}>
                      {headCell.label}
                    </TableSortLabel>
                  )}
                  {headCell.sortable === false && <span>{headCell.label}</span>}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {listaUtenti?.map((utente, index) => (
              <Row key={utente.id} utente={utente} handleDeleteClick={handleDeleteClick} actionClick={handleEditClick} regalaCodiciClick={regalaCodiciClick} regalaBraniClick={regalaBraniClick} />
            ))}
            <TableRow ref={startEmpryListRef} key={"emptyRow"} style={{ height: emptyListH }}>
              <TableCell sx={{ verticalAlign: "baseline", fontSize: 18 }} colSpan={headCells.length + 1}>
                {!listaUtenti || listaUtenti?.length === 0 ? "Non sono presenti utenti" : ""}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100, 500]}
          component="div"
          count={pageInfoListaUtenti?.total || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Righe"
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} di ${count}`}
        />
      </TableContainer>

      {/* Dialog per l'edit */}
      <Dialog open={openDialog} maxWidth="sm" fullWidth onClose={() => setOpenDialog(false)}>
        <DialogTitle variant="dialogTitle">Modifica Utente</DialogTitle>
        {isDetailsLoading && (
          <Box sx={{ bgcolor: "rgba(255,255,255,.6)", opacity: 5, zIndex: 4, height: "100%", width: "100%", position: "absolute", display: "flex", alignItems: "center", justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        )}
        <DialogContent>
          <Stack direction="row" alignItems="center">
            <Box sx={{ flex: 1 }}>Stato:</Box>
            <FormControlLabel control={<Switch checked={utenteModifica.flg_enabled} onChange={(event) => setUtenteModifica((prev) => {
              return { ...prev, flg_enabled: event.target.checked }
            })} />} label={utenteModifica.flg_enabled ? "Abilitato" : "Disabilitato"} sx={{ flex: 1 }} />
          </Stack>
          <Stack direction="row" alignItems="center" sx={{ mt: 2 }}>
            <Box sx={{ flex: 1 }}>Email:</Box>
            <TextField value={utenteModifica?.email || ""} size="small" sx={{ flex: 1 }} onChange={(event) => setUtenteModifica((prev) => {
              return { ...prev, email: event.target.value }
            })} />
          </Stack>
          {
            utenteModifica?.tipologia !== "P" && <Stack direction="row" alignItems="center" sx={{ mt: 2 }}>
              <Box sx={{ flex: 1 }}>Codice Rivenditore:</Box>
              <TextField value={utenteModifica?.codice_rivenditore || ""} size="small" sx={{ flex: 1 }} onChange={(event) => setUtenteModifica((prev) => {
                return { ...prev, codice_rivenditore: event.target.value }
              })} />
            </Stack>
          }

          {
            modificaUtenteError && (
              <Typography variant="body" gutterBottom sx={{ color: "red" }}>
                {modificaUtenteError}
              </Typography>
            )
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDialogClose(false)} sx={{ color: "#000" }}>
            Annulla
          </Button>
          <Button variant="contained" onClick={() => handleDialogClose(true)}>
            SALVA
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog per regalare codici */}
      <Dialog open={isDialogRegalaCodiciOpen} maxWidth="sm" fullWidth>
        <DialogTitle variant="dialogTitle">Regala Programmi</DialogTitle>
        {isDetailsLoading && (
          <Box sx={{ bgcolor: "rgba(255,255,255,.6)", opacity: 5, zIndex: 4, height: "100%", width: "100%", position: "absolute", display: "flex", alignItems: "center", justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        )}
        <DialogContent>
          <Typography variant="body" gutterBottom>
            Nell'elenco programmi sottostante vedrai solo i programmi attivi e abilitati alla visione e acquisto per quell'utente.
          </Typography>
          {
            isLoadingProgrammiAbilitatiUtente &&
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress color="inherit" />
            </Box>
          }
          {
            !isLoadingProgrammiAbilitatiUtente && programmiAbilitatiUtente.length > 0 &&
            <Box>
              <FormControl fullWidth sx={{ my: 2 }}>
                <InputLabel>Programma</InputLabel>
                <Select
                  input={<OutlinedInput label="Programma" />}
                  value={programmaSlcted || ""}
                  onChange={(e) => {
                    setProgrammaSlcted(e.target.value);
                  }}
                >
                  {programmiAbilitatiUtente?.map((programma) => (
                    <MenuItem key={programma.id} value={programma.id}>
                      {programma.nome}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <TabContext value={selectedTab}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList onChange={(e, value) => setSelectedTab(value)} aria-label="basic tabs example">
                    <Tab label={"Codici"} value={"C"} />
                    {
                      selectedUtente?.tipologia != "PP" &&
                      <Tab label={"Abbonamento"} value={"A"} />
                    }

                  </TabList>
                </Box>

                <TabPanel value={"C"} index={0}>
                  <FormControl fullWidth sx={{ my: 2 }}>
                    <TextField type="number" label="Numero di codici" inputProps={{ min: 0 }} value={numeroCodici} onChange={(e) => setNumeroCodici(e.target.value)} />
                  </FormControl>
                </TabPanel>
                <TabPanel value={"A"} index={0}>
                  <FormControl fullWidth sx={{ my: 2 }}>
                    <DesktopDatePicker
                      format="DD/MM/YYYY"
                      value={abbonamentoInizio ? dayjs(abbonamentoInizio) : null}
                      onChange={(value) => value && setAbbonamentoInizio(value.format("YYYY-MM-DD"))}
                      // InputLabelProps={{ sx: { color: c.primary.background } }}
                      label={'Inizio Abbonamento'}
                      minDate={dayjs(new Date())}
                      sx={{ width: "100%" }}
                      slotProps={{ textField: { variant: "standard" } }}
                    />
                  </FormControl>
                  <FormControl fullWidth sx={{ my: 2 }}>
                    <DesktopDatePicker
                      format="DD/MM/YYYY"
                      value={abbonamentoFine ? dayjs(abbonamentoFine) : null}
                      onChange={(value) => value && setAbbonamentoFine(value.format("YYYY-MM-DD"))}
                      // InputLabelProps={{ sx: { color: c.primary.background } }}
                      label={'Fine Abbonamento'}
                      minDate={dayjs(new Date())}
                      sx={{ width: "100%" }}
                      slotProps={{ textField: { variant: "standard" } }}
                    />
                  </FormControl>
                </TabPanel>

              </TabContext>

            </Box>
          }

          {
            !isLoadingProgrammiAbilitatiUtente && programmiAbilitatiUtente.length === 0 &&
            <Box>
              <Typography variant="body" gutterBottom sx={{ color: "red" }}>
                Non sono presenti programmi attivi e abilitati per questo utente
              </Typography>
            </Box>
          }

          {
            errorRegalaCodici.length > 0 && (
              <Typography variant="body" gutterBottom sx={{ color: "red" }}>
                {errorRegalaCodici}
              </Typography>
            )
          }

        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDialogRegalaCodiciClose(false)} sx={{ color: "#000" }} load>
            Annulla
          </Button>
          {
            programmiAbilitatiUtente.length > 0 &&
            <LoadingButton loading={isLoadingRegalaCodici} variant="contained" onClick={() => handleDialogRegalaCodiciClose(true)}>
              SALVA
            </LoadingButton>
          }

        </DialogActions>
      </Dialog>

      <Dialog open={isDialogRegalaBraniOpen} maxWidth="sm" fullWidth>
        <DialogTitle variant="dialogTitle">Regala Brani</DialogTitle>
        {isDetailsLoading && (
          <Box sx={{ bgcolor: "rgba(255,255,255,.6)", opacity: 5, zIndex: 4, height: "100%", width: "100%", position: "absolute", display: "flex", alignItems: "center", justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        )}
        <DialogContent>
          {
            selectedUtente?.tipologia == "PA" &&
            <Typography variant="body" gutterBottom>
              Ai professionisti è possibile regalare più quantità dello stesso brano. In quanto possono cederle ai propri clienti.
            </Typography>
          }

          {
            isLoadingListaAudio &&
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress color="inherit" />
            </Box>
          }
          {
            !isLoadingListaAudio &&

            <Box>
              <FormControl fullWidth sx={{ my: 2 }}>
                <Autocomplete
                  multiple
                  disableCloseOnSelect
                  options={groupedListaAudio.flatMap((group) => [group, ...group.options])}
                  getOptionLabel={(option) => option.nome}
                  filterOptions={audioFilterOptions}
                  value={braniSelected}
                  onChange={handleAudioChange}
                  renderInput={(params) => <TextField {...params} variant="outlined" label="Brani" />}
                  renderOption={(props, option) => {
                    if (option.options) {
                      return (
                        <ListItemButton
                          onClick={() => handleAudioGroupSelect(option.nome)}
                          selected={isAudioGroupSelected(option.nome)}
                          {...props}
                        >
                          <ListItemText primary={option.nome} />
                        </ListItemButton>
                      );
                    }

                    return (
                      <li {...props}>
                        <Checkbox
                          icon={<CheckBoxOutlineBlank fontSize="small" />}
                          checkedIcon={<CheckBox fontSize="small" />}
                          style={{ marginRight: 8 }}
                          checked={braniSelected.filter((b) => b.nome === option.nome).length > 0}
                        />
                        {option.nome}
                      </li>
                    );
                  }}
                />
                {/* 
                <Select
                  input={<OutlinedInput label="Brani" />}
                  multiple={true}
                  value={braniSelected}
                  onChange={(e) => {
                    const value = e.target.value;
                    setBraniSelected(value);
                  }}
                  renderValue={(selected) => {
                    return (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => (
                          <Box key={value} sx={{ p: 0.5, bgcolor: c.primary.main, color: "#fff", borderRadius: '5px' }} >
                            {listaAudio.find((b) => b.id === value).nome}
                          </Box>
                        ))}
                      </Box>
                    );
                  }
                  }
                >
                  {listaAudio.map((brano) => (
                    <MenuItem key={brano.id} value={brano.id}>
                      <Box sx={{ display: "flex", justifyContent: "space-between", width: '100%' }}>
                        <Typography>{brano.nome}</Typography>
                        <Typography>{brano.qta_disponibile}</Typography>
                      </Box>
                    </MenuItem>
                  ))}
                </Select> */}
              </FormControl>

              {
                selectedUtente?.tipologia == "PA" &&

                <FormControl fullWidth sx={{ my: 2 }}>
                  <TextField type="number" label="Quantità" inputProps={{ min: 0 }} value={qtaBrani} onChange={(e) => setQtaBrani(e.target.value)} />
                </FormControl>
              }

            </Box>
          }

          {
            errorRegalaBrani.length > 0 && (
              <Typography variant="body" gutterBottom sx={{ color: "red" }}>
                {errorRegalaBrani}
              </Typography>
            )
          }

        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDialogRegalaBraniClose(false)} sx={{ color: "#000" }} load>
            Annulla
          </Button>
          <LoadingButton loading={isLoadingRegalaCodici} variant="contained" onClick={() => handleDialogRegalaBraniClose(true)}>
              SALVA
            </LoadingButton>

        </DialogActions>
      </Dialog>

      {/* Dialog Delete */}
      <Dialog open={dialogDeleteUtente != null} onClose={() => setDialogDeleteUtente(null)} maxWidth="sm" fullWidth>
        <DialogTitle variant="dialogTitle" sx={{ color: "#000" }}>
          Elimina Utente
        </DialogTitle>
        <DialogContent>
          <Typography>Sei sicuro di voler eliminare il media?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogDeleteUtente(null)} sx={{ color: "#000" }}>
            Annulla
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={async () => {
              try {
                await deleteUtente(dialogDeleteUtente, tokenAdmin);
                dispatch(setSnackbarData({ open: true, variant: "success", message: "Utente eliminato correttamente" }));
              } catch (e) {
                setDialogError(getAPIErrorMessage(e));
                return;
              } finally {
                setDialogDeleteUtente(null);
              }
              dispatch(getListaUtenti());
            }}
          >
            Elimina
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog Error */}
      <Dialog open={!!dialogError} onClose={() => setDialogError(false)} maxWidth="sm" fullWidth>
        <DialogTitle variant="dialogTitle" color="error">
          Attenzione
        </DialogTitle>
        <DialogContent>
          <Typography color="error" style={{ whiteSpace: "pre-line" }}>
            {dialogError}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => setDialogError(false)}>
            OK
          </Button>
        </DialogActions>
      </Dialog>

    </>
  );
}
