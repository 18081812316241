import * as React from "react";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { MediaSelectDialog } from "./MediaSelectDialog";
import { Close } from "@mui/icons-material";
import { getListaMedia } from "../../dashboardAdminSlice";

export function MediaSelect({ label = "Sfoglia", currentSelectedMediaId = null, onSelectCallback = null, disabled = false, supportedTypes = ["IMG"], required = false }) {
  const dispatch = useDispatch();
  const fiieldRef = React.useRef(null);
  const [selectedMedia, setSelectedMedia] = React.useState(null);
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const [focussable, setFocussable] = React.useState(true);
  const { listaMedia } = useSelector((state) => state.dashboardAdmin);

  let acceptedTypes = [];
  for (const supportedType of supportedTypes) {
    if (supportedType === "IMG") {
      acceptedTypes.push("image/*");
    }
    if (supportedType === "AUD") {
      acceptedTypes.push("audio/*");
    }
    if (supportedType === "TXT") {
      acceptedTypes.push("text/*");
    }
    if (supportedType === "PDF") {
      acceptedTypes.push("application/pdf");
    }
  }

  React.useEffect(() => {
    dispatch(getListaMedia());
  }, []);

  React.useEffect(() => {
    for (const media of listaMedia) {
      if (media.id === currentSelectedMediaId) {
        setSelectedMedia(media);
        break;
      }
    }
  }, [listaMedia, currentSelectedMediaId]);

  const clearMedia = (event) => {
    event.stopPropagation();

    setSelectedMedia(null);
    if (onSelectCallback != null) {
      onSelectCallback(null);
    }
  };

  return (
    <>
      <TextField
        required={required}
        sx={{ input: { cursor: "pointer" } }}
        ref={fiieldRef}
        label={label}
        // disabled={disabled}
        InputProps={{
          readOnly: true,
          endAdornment: selectedMedia ? (
            <InputAdornment position="end" sx={{ height: `${fiieldRef.current?.getBoundingClientRect().height || 56}px`, width: "100%", justifyContent: "flex-end", mx: 0, cursor: "pointer" }}>
              <img
                src={selectedMedia.thumbnailUrl}
                style={{ height: "100%", maxWidth: "100%" }}
                alt={selectedMedia.oggetto}
                onClick={(event) => {
                  event.stopPropagation();
                  window.open(selectedMedia.url || selectedMedia.thumbnailUrl, "_blank");
                }}
              />
              <IconButton disabled={disabled} sx={{ mr: -1, ml: 1 }} onClick={clearMedia}>
                <Close />
              </IconButton>
            </InputAdornment>
          ) : (
            <></>
          ),
        }}
        fullWidth
        value={selectedMedia?.codice_media ? `${selectedMedia.codice_media} (${selectedMedia.nome_file})` : selectedMedia?.nome_file || ""}
        onClick={() => !disabled && setIsDialogOpen(true)}
        onFocus={() => {
          if (!disabled)
            if (focussable && !selectedMedia) {
              setIsDialogOpen(true);
              setFocussable(false);
            }
        }}
      />

      {isDialogOpen && (
        <MediaSelectDialog
          slcted={selectedMedia || null}
          tipologie={supportedTypes}
          acceptedTypes={acceptedTypes}
          onClose={() => setIsDialogOpen(false)}
          onSelectCallback={(media) => {
            setIsDialogOpen(false);
            setSelectedMedia(media);
            if (onSelectCallback != null) {
              onSelectCallback(media);
            }
          }}
        />
      )}
    </>
  );
}
