import { Grid, Stack, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import CardProgramma from "./CardProgramma";
import CardCodiciProgrammaGrouped from "./CardCodiciProgrammaGrouped";

export default function CardsGrid(props) {
  const { grouped, tipoAttivazione, index, data, emptyList, ...other } = props;
  const divRef = useRef(null);
  const [gridH, setGridH] = useState(null);

  useEffect(() => {
    const calculateDistance = () => {
      const element = divRef.current;
      if (element) setGridH(window.innerHeight - element.getBoundingClientRect().top);
    };
    calculateDistance();

    window.addEventListener("resize", calculateDistance);
    return () => {
      window.removeEventListener("resize", calculateDistance);
    };
  }, []);


  return (
    <Stack ref={divRef} key={`cards-grid-${index}`} {...other}>
      {data?.length ? (
        <Grid container spacing={2} sx={{ pb:1, }}>
          {data.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
              {
                grouped === true &&
                <CardCodiciProgrammaGrouped tipoAttivazione={tipoAttivazione} codiceServizio={item}/>
              }
              {
                grouped !== true &&
                <CardProgramma tipoAttivazione={tipoAttivazione} codiceServizio={item} />
              }
            </Grid>
          ))}
        </Grid>
      ) : (
        <Typography>{emptyList}</Typography>
      )}
    </Stack>
  );
}
