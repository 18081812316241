import Field from "./components/Field";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedTab } from "./profiloSlice";
import { changePassword, changeUserData, setChangePwdSuc, resetChangeUD } from "../login/loginSlice";
import { Box, Tab, Typography, useTheme, Grid, Button, Dialog, DialogActions } from "@mui/material";
import { Footer } from "../homepage/Footer";
import { CONFIG } from "../../config/envConfig";

export function Profilo() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const c = theme.palette;
  const t = theme.typography;
  const { user, isLoading, errorChangeUserData, changeUserDataSuccess, isLoadingSetPsw, errorSetPsw, changePwdSuccess } = useSelector((state) => state.login);
  const { selectedTab } = useSelector((state) => state.profilo);
  const [formData, setFormData] = useState({ ...user });
  const [attFields, setAttFields] = useState([]);
  const [anagFields, setAnagFields] = useState([
    { scope: "nome", label: "Nome", reqPa: true  },
    { scope: "cognome", label: "Cognome", reqPa: true },
    { scope: "email", label: "Email", p: " ", disabled: true, reqPa: true },
    { scope: "telefono", label: "Telefono", reqPa: true },
  ]);

  const [hidePwds, setHidePwds] = useState({ password_old: true, password: true, password_confirm: true });
  const [passwords, setPasswords] = useState({ password_old: "", password: "", password_confirm: "" });
  const pwdFields = [
    { scope: "password_old", label: "Password Corrente", p: "Inserisci la Password corrente", autoComplete: "current-password" },
    { scope: "password", label: "Nuova Password", p: "Inserisci la nuova Password", autoComplete: "new-password" },
    { scope: "password_confirm", label: "Conferma Nuova Psssword", p: "Conferma la nuova Password", autoComplete: "new-password" },
  ];

  useEffect(() => {
    dispatch(setChangePwdSuc());
  }, []);

  useEffect(() => {
    if (user && user.tipologia === "P") {
      setAnagFields([
        { scope: "nome", label: "Nome" },
        { scope: "cognome", label: "Cognome" },
        { scope: "email", label: "Email", p: "Email", disabled: true },
        { scope: "telefono", label: "Telefono" },
        { scope: "indirizzo", label: "Indirizzo" },
        { scope: "cap", label: "CAP" },
        { scope: "citta", label: "Città" },
        { scope: "cf", label: "Codice Fiscale" },
        { scope: "sesso", label: "Sesso" },
        { scope: "peso", label: "Peso (kg)", p: "Inserisci Peso in kg" },
        { scope: "altezza", label: "Altezza (cm)", p: "Inserisci Altezza in cm" },
        { scope: "data_nascita", label: "Data Nascita", p: "Inserisci Data di Nascita" },
      ]);
    } else if (user && (user.tipologia === "PP" || user.tipologia === "PA")) {
      setAttFields([
        { scope: "piva", label: "Partita IVA", p: "Inserisci P. IVA" },
        { scope: "cf", label: "Codice Fiscale", reqPa: true },
        { scope: "ragione_sociale", label: "Ragione Sociale" },
        { scope: "indirizzo", label: "Indirizzo", reqPa: true },
        { scope: "cap", label: "CAP", reqPa: true },
        { scope: "citta", label: "Città", reqPa: true },
        { scope: "pec", label: "PEC" },
        { scope: "sdi", label: "SDI" },
        { scope: "codice_rivenditore", label: "Codice Rivenditore", p: " ", disabled: true, reqPa: true },
      ]);
    }
  }, [user]);

  useEffect(() => {
    if (changePwdSuccess || changeUserDataSuccess) {
      dispatch(resetChangeUD());
      dispatch(setChangePwdSuc());
      navigate("/tommy/homepage");
    }
  }, [changePwdSuccess, changeUserDataSuccess]);

  const onTabChange = (event, newValue) => {
    dispatch(setSelectedTab(newValue));
  };

  const handleNewPassword = (event) => {
    event.preventDefault();
    dispatch(changePassword(passwords));
  };

  const handleUpdUserData = (event) => {
    event.preventDefault();
    dispatch(changeUserData(formData));
  };
  const divRef = useRef(null);
  const [maxH, setMaxH] = useState(null);

  useEffect(() => {
    const calculateDistance = () => {
      const element = divRef.current;
      if (element) setMaxH(window.innerHeight - element.getBoundingClientRect().bottom);
    };
    calculateDistance();

    window.addEventListener("resize", calculateDistance);
    return () => {
      window.removeEventListener("resize", calculateDistance);
    };
  }, []);

  const screenRef = React.useRef();
  const [screenH, setScreenH] = React.useState(null);
  const handleResize = () => {
    if (screenRef.current) {
      setScreenH(window.innerHeight - screenRef.current.getBoundingClientRect().top - CONFIG.FOOTER_HEIGHT);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [screenRef]);

  return (
    <Box>


      <Box sx={{ backgroundColor: "#fff", display: 'flex', flexDirection: 'column' }} ref={screenRef} height={screenH}>
        <Box sx={{ px: 3, pb: 2, pt: 1, flex: 1 }}>
          <TabContext value={selectedTab.toString()}>
            <Box ref={divRef} sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList onChange={onTabChange} variant="scrollable" scrollButtons allowScrollButtonsMobile>
                <Tab style={t.s} label="I miei Dati" value="0" />
                <Tab style={t.s} label="Modifica Password" value="1" />
              </TabList>
            </Box>
            <TabPanel value="0" sx={{ height: screenH - 55, overflowY: "auto" }}>
              <Box component="form" sx={{}} noValidate onSubmit={handleUpdUserData}>
                <Box bgcolor="#FDFDFD" borderRadius={5} sx={{ p: 2 }}>
                  <Typography sx={{ color: c.primary.main }}>Dati Anagrafici:</Typography>
                  <Grid container spacing={4}>
                    {anagFields.map((f) => (
                      <Field userType={user?.tipologia}  key={f.scope} field={f} data={formData} setNewVal={(val) => setFormData((prev) => ({ ...prev, [f.scope]: val }))} />
                    ))}
                  </Grid>
                </Box>
                {attFields.length > 0 && (
                  <Box bgcolor="#FDFDFD" borderRadius={5} sx={{ mt: 4, p: 2 }}>
                    <Typography sx={{ color: c.primary.main }}>Dati Attività:</Typography>
                    <Grid container spacing={4}>
                      {attFields.map((f) => (
                        <Field userType={user?.tipologia} key={f.scope} field={f} data={formData} setNewVal={(val) => setFormData((prev) => ({ ...prev, [f.scope]: val }))} />
                      ))}
                    </Grid>
                  </Box>
                )}
              </Box>
              <Box sx={{ mt: 10, display: "flex", justifyContent: "center" }}>
                <Button variant="contained" type="submit" disabled={isLoading} onClick={handleUpdUserData} sx={{ minWidth: 80 }}>
                  {isLoading ? "Caricamento..." : "SALVA"}
                </Button>
              </Box>
              <Dialog open={!!errorChangeUserData} onClose={() => dispatch(resetChangeUD(false))}>
                <Box sx={{ p: 4, display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                  <Typography sx={{ fontFamily: "Poppins-SemiBold", fontSize: "24px", color: c.text.error }}>Errore registrazione</Typography>
                  <Typography sx={{ mt: 2, fontSize: "16px", cursor: "pointer" }}>
                    {errorChangeUserData !== "" ? <span style={{ color: c.text.error, whiteSpace: "pre-line" }}>{errorChangeUserData}</span> : <span>Si è verificato un'errore durante la registrazione del tuo profilo. Controlla di aver inserito correttamente i dati e riprova. </span>}
                  </Typography>
                </Box>
                <DialogActions>
                  <Button onClick={() => dispatch(resetChangeUD(false))} variant="contained">
                    OK
                  </Button>
                </DialogActions>
              </Dialog>
            </TabPanel>
            <TabPanel value="1" sx={{ maxHeight: maxH || (window.innerHeight / 3) * 1.95, overflowY: "auto" }}>
              <Box component="form" sx={{ mt: 3 }} noValidate onSubmit={handleNewPassword}>
                <Grid container spacing={4}>
                  {pwdFields.map((f) => (
                    <Field key={f.scope} field={f} pwd={hidePwds[f.scope]} setHidePwds={setHidePwds} data={passwords} setNewVal={(val) => setPasswords((prev) => ({ ...prev, [f.scope]: val }))} sm={12} />
                  ))}
                </Grid>
                <Box sx={{ mt: 5 }}>
                  {errorSetPsw && (
                    <Typography align="center" color="text.sub" component="p" sx={{ color: (t) => t.palette.text.error, whiteSpace: "pre-wrap" }}>
                      {errorSetPsw}
                    </Typography>
                  )}
                </Box>
                <Box sx={{ mt: 5, display: "flex", justifyContent: "center" }}>
                  <Button variant="contained" type="submit" disabled={isLoadingSetPsw} onClick={handleNewPassword} sx={{ minWidth: 80 }}>
                    {isLoadingSetPsw ? "Caricamento..." : "SALVA"}
                  </Button>
                </Box>
              </Box>
            </TabPanel>
          </TabContext>
        </Box>

      </Box>
      <Footer />
    </Box>
  );
}
