import api from '../../config/axiosConfig';



export const initialCheckPrestazione = async (data, token) => {
    const config = {
        headers: {
            Authorization: "Bearer " + token,
        },
    };

    const response = await api.post('/api/prestazioni/initialCheckPrestazione', data, config);
    return response.data;
}

export const initialCheckVendita = async (data, token) => {
    const config = {
        headers: {
            Authorization: "Bearer " + token,
        },
    };

    const response = await api.post('/api/prestazioni/initialCheckVendita', data, config);
    return response.data;
}

export const creaPrestazione = async (data, token) => {
    const config = {
        headers: {
            Authorization: "Bearer " + token,
        },
    };

    const response = await api.post('/api/prestazioni', data, config);
    return response.data;
}

export const creaCessione = async (data, token) => {
    const config = {
        headers: {
            Authorization: "Bearer " + token,
        },
    };

    const response = await api.post('/api/cessioni', data, config);
    return response.data;
}


export const getDatiUtentePrivato = async (codice_utente, token) => {
    const config = {
        headers: {
            Authorization: "Bearer " + token,
        },
    };

    const response = await api.get('/api/utenti/getDatiUtentePrivato?codice_utente=' + codice_utente, config);
    return response.data;
}

export const cercaUtentePrivato = async (search, token) => {
    const config = {
        headers: {
            Authorization: "Bearer " + token,
        },
    };

    const response = await api.get('/api/utenti/cercaUtentePrivato?search=' + search, config);
    return response.data;
}

export const cercaUtenteProfessionista = async (search, token) => {
    const config = {
        headers: {
            Authorization: "Bearer " + token,
        },
    };

    const response = await api.get('/api/utenti/cercaUtenteProfessionista?search=' + search, config);
    return response.data;
}

export const getPrestazioneDetails = async (id, token) => {
    const config = {
        headers: {
            Authorization: "Bearer " + token,
        },
    };

    const response = await api.get('/api/prestazioni/' + id, config);
    return response.data;
}

export const rispondiQuestionario = async (id, data, token) => {
    const config = {
        headers: {
            Authorization: "Bearer " + token,
        },
    };

    const response = await api.post('/api/prestazioni/' + id + '/rispondiQuestionario', data, config);
    return response.data;
}

export const completaPassaggioGeniusMachine = async (id, data, token) => {
    const config = {
        headers: {
            Authorization: "Bearer " + token,
        },
    };

    const response = await api.post('/api/prestazioni/' + id + '/completaPassaggioGeniusMachine', data, config);
    return response.data;
}

export const completaPrestazione = async (id, data, token) => {
    const config = {
        headers: {
            Authorization: "Bearer " + token,
        },
    };

    const response = await api.post('/api/prestazioni/' + id + '/completaPrestazione', data, config);
    return response.data;
}

export const cambiaStatoPrestazione = async (id, data, token) => {
    const config = {
        headers: {
            Authorization: "Bearer " + token,
        },
    };

    const response = await api.post('/api/prestazioni/' + id + '/cambiaStatoPrestazione', data, config);
    return response.data;
}

export const elaboraReferto = async (id, data, token) => {
    const config = {
        headers: {
            Authorization: "Bearer " + token,
        },
    };

    const response = await api.post('/api/prestazioni/' + id + '/elaboraReferto', data, config);
    return response.data;
}

export const scaricaReferto = async (id, token) => {
    const config = {
        headers: {
            Authorization: "Bearer " + token,
        },
        responseType: 'arraybuffer'
    };
    const response = await api.get('/api/prestazioni/' + id + '/scaricaReferto', config);
    return response.data;
}


export const scaricaAvvertenze = async (id, token) => {
    const config = {
        headers: {
            Authorization: "Bearer " + token,
        },
        responseType: 'arraybuffer'
    };
    const response = await api.get('/api/programmi/' + id + '/scaricaAvvertenzeProgramma', config);
    return response.data;
}

export const scaricaInformativa = async (id, token) => {
    const config = {
        headers: {
            Authorization: "Bearer " + token,
        },
        responseType: 'arraybuffer'
    };
    const response = await api.get('/api/programmi/' + id + '/scaricaInformativaProgramma', config);
    return response.data;
}

export const getPrestazioneAttiva = async (token) => {
    const config = {
        headers: {
            Authorization: "Bearer " + token,
        },
    };
    const response = await api.get('/api/prestazione/getPrestazioneAttiva', config);
    return response.data;
}


export const checkCopiaQuestionarioDaPrecedente = async (id, token) => {
    const config = {
        headers: {
            Authorization: "Bearer " + token,
        },
    };
    const response = await api.get('/api/prestazione/' + id + '/checkCopiaQuestionarioDaPrecedente', config);
    return response.data;
}

export const checkCopiaDatiGeniusMachineDaPrecedente = async (id, token) => {
    const config = {
        headers: {
            Authorization: "Bearer " + token,
        },
    };
    const response = await api.get('/api/prestazione/' + id + '/checkCopiaDatiGeniusMachineDaPrecedente', config);
    return response.data;
}

export const importaDatiGeniusMachineDaPrestazionePrecedente = async (id, data, token) => {
    const config = {
        headers: {
            Authorization: "Bearer " + token,
        },
    };
    const response = await api.post('/api/prestazione/' + id + '/importaDatiGeniusMachineDaPrestazionePrecedente', data, config);
    return response.data;
}

export const getProgrammiConCodiceAttivo = async (token) => {
    const config = {
        headers: {
            Authorization: "Bearer " + token,
        },
    };
    const response = await api.get('/api/codice_servizio/getProgrammiConCodiceAttivo', config);
    return response.data;
}

export const annullaPrestazione = async (id, token) => {
    const config = {
        headers: {
            Authorization: "Bearer " + token,
        },
    };
    const response = await api.post('/api/prestazioni/' + id + '/annullaPrestazione', {}, config);
    return response.data;
}

export const eliminaProfessionista = async (id, token) => {
    const config = {
        headers: {
            Authorization: "Bearer " + token,
        },
    };
    const response = await api.post('/api/prestazioni/' + id + '/eliminaProfessionista', {}, config);
    return response.data;
}

export const aggiornaDatiUtentePrivato = async (id, data, token) => {
    const config = {
        headers: {
            Authorization: "Bearer " + token,
        },
    };
    const response = await api.post('/api/prestazioni/' + id + '/aggiornaDatiUtentePrivato', data, config);
    return response.data;
}