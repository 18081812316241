import * as React from "react";
import { Box, Button, IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getListaCategorieAudio } from "../../dashboardAdminSlice";
import { Add, Edit } from "@mui/icons-material";
import LocalPaginateTable from "../LocalPaginateTable";
import CategoriaAudioDetails from "./CategoriaAudioDetails";

export function CategorieAudio() {
  const dispatch = useDispatch();
  const [selectedCategoria, setSelectedCategoria] = React.useState(null);
  const [isDialogOpen, setIsDialogOpen] = React.useState(null);
  const { listaCategorieAudio, isLoading } = useSelector((state) => state.dashboardAdmin);

  React.useEffect(() => {
    dispatch(getListaCategorieAudio());
  }, []);

  const openDialog = (categoria) => {
    setSelectedCategoria(categoria);
    setIsDialogOpen(true);
  };

  const utentiTypes = [
    {
      'codice': 'P',
      'descrizione': 'Utenti Privati',
    },
    {
      'codice': 'PA',
      'descrizione': 'Professionisti Attivi',
    },
  ];
  return (
    <>
      <Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button variant="contained" endIcon={<Add />} onClick={() => openDialog(null)}>
            Aggiungi Categoria
          </Button>
        </Box>
        <LocalPaginateTable
          scope="Categorie Audio"
          isLoading={isLoading}
          cols={[
            { id: "nome", label: "Nome" },
            {
              id: 'categoria_tipologia_utente',
              label: 'Tipologia Utenti',
              format: function(val, cat) {
                return val.map((item) => {
                  return utentiTypes.find((type) => type.codice === item)?.descrizione;
                }).join(', ');
              }
            },
            // { id: "flg_active", label: "Stato", format: (val, cat) => <FormControlLabel control={<Switch checked={val || true} onChange={() => {}} size="small" />} label={val || true ? "Attivo" : "Disattivo"} /> },
            {
              id: "azioni",
              label: "Azioni",
              format: (val, cat) => (
                <IconButton onClick={() => openDialog(cat)}>
                  <Edit />
                </IconButton>
              ),
            },
          ]}
          list={listaCategorieAudio}
          filterCols={["nome"]}
        />
      </Box>

      <CategoriaAudioDetails isDialogOpen={isDialogOpen} setIsDialogOpen={setIsDialogOpen} selectedCategoria={selectedCategoria} />
    </>
  );
}
