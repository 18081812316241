import { CompareArrows, GraphicEq, Sell, SellOutlined, Storefront, Subscriptions } from "@mui/icons-material";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Hidden, IconButton, List, ListItemButton, ListItemIcon, ListItemText, Toolbar, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { TecnicheABS } from "./TecnicheABS";
import { StoreAudio } from "./StoreAudio";
import { useDispatch, useSelector } from "react-redux";
import { getBraniUtente, getPrezzoTecnica, setSelectedTab } from "./absSlice";
import { getAPIErrorMessage } from "../../common/utils";
import { MieiBrani } from "./MieiBrani";
import { aggiungiVoce } from "../ecommerce/EcommerceAPI";
import { setCarrello } from "../ecommerce/EcommerceSlice";
import DialogSelectBrani from "./DialogSelectBrani";
import { DialogVendiBrani } from "./DialogVendiBrani";
import { setSnackbarData } from "../programmi/programmiSlice";
import { Movimenti } from "./Movimenti";
import { Footer } from "../homepage/Footer";
import { CONFIG } from "../../config/envConfig";



export function AudioBrainStimulation() {
  const theme = useTheme();
  const c = theme.palette;
  const dispatch = useDispatch();
  const screenRef = useRef();
  const matchMd = useMediaQuery(theme.breakpoints.up("md"));

  const { selectedTab, selectedCategoriaAudioIMieiBrani } = useSelector((state) => state.abs);
  const { user, token } = useSelector((state) => state.login);
  const [selezionaBraniDialog, setSelezionaBraniDialog] = useState(false);
  const [error, setError] = useState(null);
  const [vendiBraniDialog, setVendiBraniDialog] = useState(false);
  const [braniVendita, setBraniVendita] = useState([]);

  const [screenH, setScreenH] = useState(null);

  const handleResize = () => {
    if (screenRef.current) {
      setScreenH(window.innerHeight - screenRef.current.getBoundingClientRect().top - CONFIG.FOOTER_HEIGHT);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [screenRef]);


  const drawerItems = [
    { id: "store", label: "Store", icon: <Storefront sx={{ color: selectedTab == 'store' ? 'white' : '' }} /> },
    { id: "miei", label: "La mia libreria", icon: <Subscriptions sx={{ color: selectedTab == 'miei' ? 'white' : '' }} /> },
  ];

  const enabledUserIds = [
    'cdcc7173a6471f7e07ae971f79b78e4d',//utentep local
    'af5b3a8c3a384102ba9d677e3e0547e8',//sarasitzia85 prod
    'b243f11d755f9314920987bbbbbfc1d3',//sarastz prod
    'bdc49d6a41f5ce0b3d017fd99856197d',//experservice prod
    '558ffafde6135acb310a70d60482a21b',//provatest prod
    'd3dff98b42021ed3a298316deb7ac3cf',//gppital prod
    '2d53ed098d2c54948b0bdce1f96fc82d',//lauramagliano prod
  ];

  if (enabledUserIds.includes(user?.id)) {
    drawerItems.push({ id: "tecniche", label: "Tecniche ABS", icon: <GraphicEq sx={{ color: selectedTab == 'tecniche' ? 'white' : '' }} /> });
  }

  if (user.tipologia == 'PA') {
    drawerItems.push({ id: "movimenti", label: "Movimenti", icon: <CompareArrows sx={{ color: selectedTab == 'movimenti' ? 'white' : '' }} /> });
  }

  const onAddAudioClick = async (audio) => {
    const data = {
      id_audio: audio.id,
      qta: 1,
      tipo_voce: 'AUD',
      tipo_acquisto: 'QTA'

    }
    try {
      const res = await aggiungiVoce(data, token);
      dispatch(setCarrello(res.data));
      dispatch(setSnackbarData({ message: "Brano aggiunto al carrello", severity: "success", vertical: "bottom" }));
    } catch (err) {
      setError(getAPIErrorMessage(err));
      return;
    }
  };

  const isCardIconChecked = (audio) => {
    return false;
  };

  useEffect(() => {
    dispatch(getPrezzoTecnica());
  }, []);


  const onSuccessSelezionaBrani = (data) => {
    setBraniVendita(data.braniSelected);
    setVendiBraniDialog(true);
    setSelezionaBraniDialog(false);
  };

  const onVenditaSuccess = () => {
    dispatch(getBraniUtente({ id_categoria: selectedCategoriaAudioIMieiBrani }));//ricarico i brani nella pagina miei brani
    dispatch(setSnackbarData({ message: "Cessione effettuata con successo", severity: "success", vertical: "bottom" }));
    setVendiBraniDialog(false);
  };


  return (
    <Box sx={{ backgroundColor: '#eee' }}>
      <Box ref={screenRef} sx={{ bgcolor: "#eee", display: 'flex', flexDirection: "column" }} height={screenH}>
        <Toolbar sx={{ backgroundColor: 'white' }}>
          <Grid container alignItems="center" columnSpacing={{ xs: 2, sm: 3, md: 3 }}>
            <List component="nav" sx={{ display: 'flex', flexDirection: 'row', pl: 2 }}>
              {drawerItems.map((i) => (
                <ListItemButton key={i.id} onClick={() => dispatch(setSelectedTab(i.id))} selected={selectedTab == i.id} sx={{ backgroundColor: selectedTab == i.id ? 'rgba(137, 155, 60, 0.8)!important' : 'white', borderRadius: '10px', mr: matchMd ? 1 : 0.5 }}>
                  <ListItemIcon sx={{ m: 0, p: 0, minWidth: 'auto' }}>{i.icon}</ListItemIcon>
                  <Hidden mdDown>
                    <ListItemText primary={i.label2 || i.label} sx={{ color: selectedTab == i.id ? 'white' : 'black', ml: 1 }} />
                  </Hidden>
                </ListItemButton>
              ))}
            </List>
            <Box sx={{ flexGrow: 1 }} />
            {
              user?.tipologia == 'PA' ?
                <>
                  <Hidden mdUp>
                    <IconButton
                      aria-label="Add"
                      onClick={() => { setSelezionaBraniDialog(true); }}
                      sx={{}}
                    >
                      <SellOutlined sx={{ color: c.primary.main, fontSize: '1.25em', mt: 0.5 }} />
                    </IconButton>
                  </Hidden>
                  <Hidden mdDown>
                    <Button
                      variant="contained"
                      startIcon={<Sell />}
                      sx={{
                        backgroundColor: c.primary.main,
                        color: 'white',
                        "&:hover": {
                          backgroundColor: c.secondary.main,
                        },
                      }}
                      onClick={() => { setSelezionaBraniDialog(true); }}
                    >
                      <Box sx={{ whiteSpace: "nowrap" }}>CEDI BRANI</Box>
                    </Button>
                  </Hidden>
                </>
                : <></>
            }

          </Grid>
        </Toolbar>
        <Box sx={{ flex: 1 }}>
          {
            selectedTab == 'store' && <StoreAudio cardSize={4} onCardAddClick={onAddAudioClick} isCardIconChecked={isCardIconChecked} />
          }
          {
            selectedTab == 'miei' && <MieiBrani />
          }
          {
            selectedTab == 'tecniche' && <TecnicheABS />
          }
          {
            selectedTab == 'movimenti' && <Movimenti />
          }
        </Box>

        <Dialog open={!!error} onClose={() => setError(null)} maxWidth="sm" fullWidth>
          <DialogTitle variant="dialogTitle" color="error">
            Attenzione
          </DialogTitle>
          <DialogContent>
            <Typography color="error" style={{ whiteSpace: "pre-line" }}>
              {error}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={() => setError(null)}>
              OK
            </Button>
          </DialogActions>
        </Dialog>

        {selezionaBraniDialog && <DialogSelectBrani onClose={() => { setSelezionaBraniDialog(false) }} onSuccess={onSuccessSelezionaBrani} />}
        {vendiBraniDialog && <DialogVendiBrani onClose={() => { setVendiBraniDialog(false) }} onSuccess={onVenditaSuccess} brani={braniVendita} />}
      </Box>
      <Footer />
    </Box>
  );
}
