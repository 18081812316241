import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getInfoProgramma } from "../pages/programmi/programmiAPI";
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Stack, Typography } from "@mui/material";
import { AccessTime, CheckCircle, OpenInNew } from "@mui/icons-material";

export default function DialogInfoProgramma({ id_programma = null, isOpen } = {}) {
  const { token } = useSelector((state) => state.login);
  const [loading, setIsLoading] = useState(false);
  const [prog, setProg] = useState(null);

  useEffect(() => {
    const loadProgramma = async () => {
      try {
        setIsLoading(true);
        const res = await getInfoProgramma({ token, id_programma });
        setProg(res.data);
      } catch (error) {
        onClose()
      } finally {
        setIsLoading(false);
      }
    };

    if (id_programma) loadProgramma();

    return () => { };
  }, [id_programma]);

  const onClose = () => {
    setProg(null);
    isOpen(false);
  };

  const onApriInformativa = async () => {
    // let response;
    // try {
    //   response = await scaricaInformativa(id_programma, token);
    // } catch (e) {
    //   dispatch(setSnackbarData({ message: getAPIErrorMessage(e), severity: "error" }));
    //   return;
    // }

    // const url = window.URL.createObjectURL(new Blob([response], { type: "application/pdf" }));
    const url = prog.url_informativa;
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("target", "_blank");
    //link.setAttribute("download", "Informativa.pdf");
    document.body.appendChild(link);
    link.click();
  };

  return (
    <Dialog open={!!id_programma} onClose={onClose} maxWidth="sm" fullWidth>
      {loading && (
        <Stack sx={{ position: "absolute", height: "100%", width: "100%", bgcolor: "rgba(255,255,255,.6)", zIndex: 3, alignItems: "center", justifyContent: "center" }}>
          <CircularProgress color="primary" />
        </Stack>
      )}
      <DialogTitle variant="dialogTitle" sx={{ fontSize: '1.3rem' }}>Informazioni Programma</DialogTitle>
      <DialogContent sx={{ minHeight: "400px", pt: 2, p:2 }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="m">Nome:</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="s">{prog?.nome}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="m">Descrizione:</Typography>
            <Typography sx={{ textAlign: 'justify' }}>{prog?.descrizione}</Typography>
          </Grid>
          {prog?.thumbnail_url && (
            <Grid item xs={12} textAlign="center" m={1}>
              <img src={prog?.thumbnail_url} style={{ maxHeight: 200, maxWidth: "100%" }} alt="thumbnail" />
            </Grid>
          )}
          <Grid item xs={6}>
            <Typography variant="m">Durata:</Typography>
          </Grid>
          <Grid item xs={6} container direction="row">
            <AccessTime sx={{ color: "primary.dark", mr: 1 }} />
            <Typography variant="m" color="primary.dark">
              {prog?.durata} {prog?.durata == 1 ? "minuto" : "minuti"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="m">Cosa prevede:</Typography>
          </Grid>
          <Grid item xs={6}>
            <Stack direction="row">
              <CheckCircle sx={{ color: "primary.main", mr: 1 }} />
              <Typography variant="m">Breve intervista</Typography>
            </Stack>
            {!!prog?.flg_genius_machine && (
              <Stack direction="row" sx={{ mt: 1 }}>
                <CheckCircle sx={{ color: "primary.main", mr: 1 }} />
                <Typography variant="m">Genius Machine</Typography>
              </Stack>
            )}
          </Grid>
          {
            prog?.id_media_informativa != null ?
              <>
                <Grid item xs={6}>
                  <Typography variant="m">Per saperne di più:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Box>
                    <Button startIcon={<OpenInNew />} onClick={onApriInformativa} variant="outlined">
                      Informativa
                    </Button>
                  </Box>

                </Grid>
              </> : <></>
          }

        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Chiudi
        </Button>
      </DialogActions>
    </Dialog>
  );
}
