import { Visibility, VisibilityOff } from "@mui/icons-material";
import { FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Select, TextField, Typography, useTheme } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";

export default function Field(props) {
  const { userType, data, field, setNewVal, pwd, setHidePwds, ...other } = props;
  const c = useTheme().palette;

  return (
    <Grid item container xs={12} sm={6} alignItems="center" {...other}>
      <Grid item xs={12} md={4}>
        <Typography variant="field">{field.label} {userType=='PA' && field.reqPa ? '*' : ""}</Typography>
      </Grid>
      <Grid item xs={12} md={8}>
        {field.scope === "sesso" ? (
          <FormControl fullWidth variant="standard">
            <InputLabel>{field.p || `Inserisci ${field.label}`}</InputLabel>
            <Select value={data.sesso || ""} variant="standard" onChange={(e) => setNewVal(e.target.value)} label={field.p || `Inserisci ${field.label}`} fullWidth>
              <MenuItem value="F">Femmina</MenuItem>
              <MenuItem value="M">Maschio</MenuItem>
            </Select>
          </FormControl>
        ) : field.scope === "data_nascita" ? (
          <DatePicker
            format="DD/MM/YYYY"
            value={data.data_nascita? dayjs(data.data_nascita) : null}
            onChange={(value) => value && setNewVal(value.format("YYYY-MM-DD"))}
            // InputLabelProps={{ sx: { color: c.primary.background } }}
            label={field.p || `Inserisci ${field.label}`}
            maxDate={dayjs(new Date())}
            sx={{ width: "100%" }}
            slotProps={{ textField: { variant: "standard" } }}
          />
        ) : (
          <TextField
            autoComplete={field.autoComplete || ""}
            error={field.error}
            disabled={field.disabled || false}
            value={data[field.scope] || ""}
            onChange={(e) => setNewVal(e.target.value)}
            fullWidth
            InputLabelProps={{ sx: { color: c.primary.background } }}
            label={field.p || `Inserisci ${field.label}`}
            variant="standard"
            InputProps={
              pwd != null
                ? {
                    inputProps: { type: !!pwd ? "password" : "text" },
                    endAdornment: (
                      <InputAdornment sx={{ mr: 2 }} position="end">
                        <IconButton onClick={() => setHidePwds((prev) => ({ ...prev, [field.scope]: !pwd }))} edge="end">
                          {pwd ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }
                : field.scope === "peso" || field.scope === "altezza"
                ? { inputProps: { type: "number", min: 0 } }
                : {}
            }
          />
        )}
      </Grid>
    </Grid>
  );
}
