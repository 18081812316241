import * as React from "react";
import { Box, Dialog, DialogTitle, DialogContent, DialogActions, Typography, IconButton, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getListaMedia } from "../../dashboardAdminSlice";
import { deleteMedia } from "../../dashboardAdminAPI";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { formatDate, getAPIErrorMessage } from "../../../../common/utils";
import MediaAdd from "./MediaAdd";
import LocalPaginateTable from "../LocalPaginateTable";

export function MediaPage() {
  const dispatch = useDispatch();

  const tokenAdmin = useSelector((state) => state.loginadmin.tokenAdmin);
  const { listaMedia, isLoading } = useSelector((state) => state.dashboardAdmin);

  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const [selectedMedia, setSelectedMedia] = React.useState({ id: null, codice_media: "", oggetto: "" });
  const [dialogDeleteMedia, setDialogDeleteMedia] = React.useState(false);
  const [dialogErr, setDialogErr] = React.useState(false);

  React.useEffect(() => {
    dispatch(getListaMedia());
  }, []);

  return (
    <>
      <Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <MediaAdd sxBtn={{ maxWidth: 180 }} isDialogOpen={isDialogOpen} setIsDialogOpen={setIsDialogOpen} selectedMedia={selectedMedia} setSelectedMedia={setSelectedMedia} />
        </Box>
        <LocalPaginateTable
          scope="Media"
          isLoading={isLoading}
          cols={[
            {
              id: "thumbnailUrl",
              label: "Anteprima",
              format: (val, media) => (

                //"?"+media.updated_at per evitare che cacha l'immagine e poi se l'aggiorno mi rimane quella vecchia
                <a href={media.url || val} target="_blank" rel="noreferrer">
                  <img src={val+"?"+media.updated_at} style={{ maxWidth: "100%", height: "50px" }} alt={media.oggetto} />
                </a>
              ),
            },
            { id: "estensione", label: "Estensione File" },
            { id: "codice_media", label: "Codice Media", format: (val, media) => val || "-" },
            { id: "oggetto", label: "Oggetto", format: (val, media) => val || "-" },
            { id: "nome_file", label: "Nome File" },
            { id: "created_at", label: "Data Creazione", format: (val, media) => formatDate(val) },
            {
              id: "azioni",
              label: "Azioni",
              format: (val, media) => (
                <>
                  <IconButton
                    onClick={() => {
                      setSelectedMedia(media);
                      setIsDialogOpen(true);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => setDialogDeleteMedia(media)}>
                    <DeleteIcon />
                  </IconButton>
                </>
              ),
            },
          ]}
          list={listaMedia}
          filterCols={["codice_media", "oggetto", "nome_file"]}
          stOrder="created_at"
        />
      </Box>

      {/* Dialog Delete */}
      <Dialog open={!!dialogDeleteMedia} onClose={() => setDialogDeleteMedia(false)} maxWidth="sm" fullWidth>
        <DialogTitle variant="dialogTitle" sx={{ color: "#000" }}>
          Elimina Media
        </DialogTitle>
        <DialogContent>
          <Typography>Sei sicuro di voler eliminare il media?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogDeleteMedia(false)} sx={{ color: "#000" }}>
            Annulla
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={async () => {
              try {
                await deleteMedia(dialogDeleteMedia.id, tokenAdmin);
              } catch (e) {
                setDialogErr(getAPIErrorMessage(e));
                return;
              } finally {
                setDialogDeleteMedia(false);
              }
              dispatch(getListaMedia());
            }}
          >
            Elimina
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog Error */}
      <Dialog open={!!dialogErr} onClose={() => setDialogErr(false)} maxWidth="sm" fullWidth>
        <DialogTitle variant="dialogTitle" color="error">
          Attenzione
        </DialogTitle>
        <DialogContent>
          <Typography color="error" style={{ whiteSpace: "pre-line" }}>
            {dialogErr}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => setDialogErr(false)}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
