import * as React from "react";
import { Box, Dialog, DialogTitle, DialogContent, DialogActions, FormControl, Typography, Grid, Button, TextField, Card, CardActionArea, CardMedia, CardContent, Stack, CircularProgress, Backdrop } from "@mui/material";
import { useSelector } from "react-redux";
import { formatDate } from "../../../../common/utils";
import MediaAdd from "./MediaAdd";

export function MediaSelectDialog({ slcted, tipologie, acceptedTypes, onSelectCallback, onClose }) {
  const filteredCells = ["codice_media", "oggetto", "nome_file"];

  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const [dialogError, setDialogError] = React.useState("");
  const [slctedMedia, setSlctedMedia] = React.useState(slcted || null);
  const [search, setSearch] = React.useState("");

  const { isLoading, listaMedia } = useSelector((state) => state.dashboardAdmin);

  let filteredMedia = listaMedia
    .filter((media) => {
      let result = false;
      filteredCells.forEach((cell) => {
        if (media[cell] != null) {
          result = result || media[cell].toString().toLowerCase().includes(search.toLowerCase());
        }
      });
      return result;
    })
    .filter((media) => tipologie.includes(media.tipo));

  return (
    <>
      <Dialog open={true} onClose={() => onClose()} maxWidth="lg" fullWidth>
        <DialogTitle variant="dialogTitle">Seleziona un media dalla Libreria</DialogTitle>
        <DialogContent>
          <Box>
            <Grid container spacing={2} alignItems="center" sx={{ mb: 3, pt: 1 }}>
              <Grid item xs={5}>
                <FormControl fullWidth>
                  <TextField size="small" variant="outlined" label="Cerca" value={search} onChange={(e) => setSearch(e.target.value)}></TextField>
                </FormControl>
              </Grid>
              <Grid item xs={5} />
              <Grid item xs={2}>
                <MediaAdd isDialogOpen={isDialogOpen} setIsDialogOpen={setIsDialogOpen} acceptedTypes={acceptedTypes} />
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ height: "500px", overflowY: "auto", pb: 2 }}>
              {filteredMedia.length === 0 && (
                <Typography variant="body" component="div" color="error" gutterBottom sx={{ p: 0, m: 2 }}>
                  Nessun media trovato
                </Typography>
              )}
              {isLoading && (
                <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
                  <CircularProgress />
                </Backdrop>
              )}
              {filteredMedia.map((media, i) => {
                return (
                  <Grid key={i} item xs={3}>
                    <Card variant={slctedMedia?.id === media.id ? "elevation" : "outlined"} sx={{ maxWidth: "100%", height: 280, bgcolor: slctedMedia?.id === media.id ? "primary.light" : "#fff" }}>
                      <CardActionArea onClick={() => setSlctedMedia(media)}>
                        <CardMedia component="img" height={140} image={media.thumbnailUrl} alt={media.oggetto} />
                        <CardContent sx={{ height: 140, display: "flex", flexDirection: "column", justifyContent: "space-evenly" }}>
                          <Stack flexDirection="row" alignItems={"baseline"}>
                            <Typography variant="body2" color="text.secondary">
                              Codice Media:
                            </Typography>
                            <Typography gutterBottom sx={{ m: 0, ml: 1 }}>
                              {media.codice_media || "-"}
                            </Typography>
                          </Stack>
                          <Stack flexDirection="row" alignItems={"baseline"}>
                            <Typography variant="body2" color="text.secondary">
                              Nome File:
                            </Typography>
                            <Typography gutterBottom variant="body2" sx={{ ml: 1 }}>
                              {media.nome_file}
                            </Typography>
                          </Stack>
                          <Stack flexDirection="row" alignItems={"baseline"}>
                            <Typography variant="body2" color="text.secondary">
                              Data Creazione:
                            </Typography>
                            <Typography gutterBottom variant="body2" sx={{ ml: 1 }}>
                              {formatDate(media.created_at)}
                            </Typography>
                          </Stack>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          </Box>

          {!!dialogError && (
            <Typography color="error" style={{ whiteSpace: "pre-line" }} sx={{ mt: 3 }}>
              {dialogError}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} sx={{ color: "#000" }}>
            Annulla
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              if (!slctedMedia) {
                setDialogError("Seleziona un Media");
                return;
              }
              onSelectCallback(slctedMedia);
            }}
          >
            CONFERMA
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
