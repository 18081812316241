import * as React from "react";
import { Box, Typography, Stack, Grid, Button, Divider, Chip, Paper } from "@mui/material";
import { useSelector } from "react-redux";
import DialogSetPrezzi from "./DialogSetPrezzi";
import { ReactComponent as SvgPrivato } from "../../../../assets/svg/SvgImgPrivato.svg";
import { ReactComponent as SvgProfessionista } from "../../../../assets/svg/SvgImgProfessionista.svg";
import { ReactComponent as SvgRivenditore } from "../../../../assets/svg/SvgImgRivenditore.svg";

import { getPrezzi } from "../../dashboardAdminAPI";
import { getAPIErrorMessage } from "../../../../common/utils";

export function PrezziProgramma({ selectedProgramma, setError, setLoading }) {
  const tokenAdmin = useSelector((state) => state.loginadmin.tokenAdmin);

  const tipologie = [
    { value: "P", key: "utente_privato", label: "Privato" },
    { value: "PA", key: "utente_professionista_attivo", label: "Professionista" },
    { value: "PP", key: "utente_professionista_passivo", label: "Rivenditore" },
  ];

  const abbonamenti = [
    { key: "abbonamento_settimanale", label: "Settimanale" },
    { key: "abbonamento_mensile", label: "Mensile" },
    { key: "abbonamento_trimestrale", label: "Trimestrale" },
    { key: "abbonamento_semestrale", label: "Semestrale" },
    { key: "abbonamento_annuale", label: "Annuale" },
    { key: "abbonamento_vita", label: "A vita" },
  ];

  const [prezziProgramma, setPrezziProgramma] = React.useState({ utente_privato: null, utente_professionista_attivo: null, utente_professionista_passivo: null });
  const [openDialogPrezzi, setOpenDialogPrezzi] = React.useState(false);

  React.useEffect(() => {
    load();
  }, []);

  const load = async () => {
    try {
      setLoading(true);
      const def = { utente_privato: { tipologia_utente: "P", id_programma: selectedProgramma.id }, utente_professionista_attivo: { tipologia_utente: "PA", id_programma: selectedProgramma.id }, utente_professionista_passivo: { tipologia_utente: "PP", id_programma: selectedProgramma.id } };

      const response = await getPrezzi(selectedProgramma.id, tokenAdmin);

      if (response.data.length > 0) {
        const utente_privato = response.data.find((el) => el.tipologia_utente === "P") || def.utente_privato;
        const utente_professionista_attivo = response.data.find((el) => el.tipologia_utente === "PA") || def.utente_professionista_attivo;
        const utente_professionista_passivo = response.data.find((el) => el.tipologia_utente === "PP") || def.utente_professionista_passivo;

        setPrezziProgramma({ utente_privato, utente_professionista_attivo, utente_professionista_passivo });
      } else {
        setPrezziProgramma(def);
      }
    } catch (e) {
      setError(getAPIErrorMessage(e));
      return;
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Box sx={{}}>
        <Typography variant="subtitle2" sx={{ ml: 1, mb: 2 }}>
          Prezzi
        </Typography>
        <Stack direction="row" spacing={4}>
          {tipologie.map((tipologia) => {
            const prezzi = prezziProgramma[tipologia.key];
            if (prezzi?.id) {
              return (
                <Box key={tipologia.value} flex={1} component={Paper} sx={{ p: 2 }}>
                  <Stack direction="row" alignItems="center" spacing={2} sx={{ pb: 1 }}>
                    <Box flex={1}>
                      <Typography variant="subtitle2">{tipologia.label}</Typography>
                    </Box>
                    <Button variant="contained" size="small" onClick={() => setOpenDialogPrezzi(prezzi)}>
                      Modifica Prezario
                    </Button>
                  </Stack>
                  <Stack direction="row" spacing={2} textAlign="center" sx={{ mt: 2 }}>
                    <Box flex={1}>
                      <Typography variant="m">Prezzo cadauno:</Typography>
                    </Box>
                    <Box flex={1}>
                      <Typography variant="m">{Intl.NumberFormat('it-IT', { style: 'currency', currency: 'eur' }).format(prezzi?.singolo)}</Typography>
                    </Box>
                  </Stack>
                  <Divider sx={{ my: 2 }}>Prezzi a Range</Divider>
                  <Grid container rowSpacing={1} justifyContent="center">
                    {prezzi?.range?.length > 0 ? (
                      prezzi.range.map((r, i) => (
                        <Grid key={i} item xs={6} textAlign={"center"}>
                          <Chip
                            variant="outlined"
                            sx={{ minWidth: "95%" }}
                            label={
                              <Typography typography="m">
                                {r.quantita} codici a {Intl.NumberFormat('it-IT', { style: 'currency', currency: 'eur' }).format(r.prezzo)} cad.
                              </Typography>
                            }
                          />
                        </Grid>
                      ))
                    ) : (
                      <Grid item textAlign={"center"}>
                        -
                      </Grid>
                    )}
                  </Grid>
                  <Divider sx={{ my: 2 }}>Prezzi Abbonamenti</Divider>
                  {prezzi &&
                    abbonamenti.map((abb) => (
                      <Stack key={abb.key} direction="row" spacing={2} textAlign={"center"} sx={{ mt: 1 }}>
                        <Box flex={1}>
                          <Typography typography="m">{abb.label}</Typography>
                        </Box>
                        <Box flex={1}>
                          <Typography typography={prezzi[abb.key] ? "m" : "inherit"}>{prezzi[abb.key] ? `${Intl.NumberFormat('it-IT', { style: 'currency', currency: 'eur' }).format(prezzi[abb.key].prezzo)}${prezzi[abb.key].limite ? `, limite: ${prezzi[abb.key].limite}` : ""}` : "-"}</Typography>
                        </Box>
                      </Stack>
                    ))}
                </Box>
              );
            } else {
              return (
                <Stack
                  key={tipologia.value}
                  component={Paper}
                  sx={{
                    p: 2,
                    flex: 1,
                    alignItems: "center",
                    justifyContent: "space-evenly",
                    cursor: "pointer",
                    "&:hover": { boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)" },
                  }}
                  onClick={() => !!prezzi && setOpenDialogPrezzi(prezzi)}
                >
                  <Typography variant="subtitle2">{tipologia.label}</Typography>
                  <Box sx={{ p: 2 }}>{tipologia.value == "P" ? <SvgPrivato width={100} height={100} /> : tipologia.value === "PA" ? <SvgProfessionista width={100} height={100} /> : <SvgRivenditore width={100} height={100} />}</Box>
                  <Button disabled={prezzi === null} variant="contained" size="small" onClick={() => !!prezzi && setOpenDialogPrezzi(prezzi)}>
                    Aggiungi Prezzario
                  </Button>
                </Stack>
              );
            }
          })}
        </Stack>
      </Box>

      {openDialogPrezzi !== false && <DialogSetPrezzi open={openDialogPrezzi} setOpen={setOpenDialogPrezzi} data={{ load, abbonamenti }} />}
    </>
  );
}
