import { Search } from "@mui/icons-material";
import { Box, CircularProgress, FormControl, InputAdornment, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, TextField, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";

export default function LocalPaginateTable({ scope, stOrder = "", isLoading = false, cols = [], list = [], filterCols = [] }) {
  const tableRef = useRef();
  const startEmpryListRef = useRef();
  const [orderBy, setOrderBy] = useState(stOrder || cols[0].id);
  const [order, setOrder] = useState("desc");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [filteredList, setFilteredList] = useState(list);
  const [sortedList, setSortedList] = useState(filteredList);
  const [emptyListH, setEmptyListH] = useState(0);

  useEffect(() => {
    setEmptyListH(startEmpryListRef ? window.innerHeight - startEmpryListRef.current?.getBoundingClientRect().top - 68 : 0);
  }, [startEmpryListRef, sortedList]);

  useEffect(() => {
    setFilteredList(
      list.filter((data) => {
        let result = false;
        filterCols.forEach((cell) => (result = result || (data[cell] || "").toString().toLowerCase().includes(search.toLowerCase())));
        return result;
      })
    );
  }, [list, search]);

  useEffect(() => {
    setPage(0);
  }, [search]);

  useEffect(() => {
    setSortedList(
      filteredList.slice().sort((a, b) => {
        const aValue = a[orderBy]?.toString() || "";
        const bValue = b[orderBy]?.toString() || "";

        if (order === "asc") {
          return aValue < bValue ? -1 : 1;
        } else {
          return aValue > bValue ? -1 : 1;
        }
      })
    );
  }, [filteredList, page, rowsPerPage, orderBy, order]);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <Box sx={{ mt: 2 }}>
      {isLoading && (
        <Box sx={{ bgcolor: "rgba(255,255,255,.6)", opacity: 5, zIndex: 4, height: tableRef.current?.getBoundingClientRect().height, width: tableRef.current?.getBoundingClientRect().width, position: "absolute", display: "flex", alignItems: "center", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      )}
      <TableContainer ref={tableRef} component={Paper}>
        <FormControl fullWidth sx={{ p: 2 }}>
          <TextField
            label="Cerca"
            variant="outlined"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
        <Table>
          <TableHead>
            <TableRow>
              {cols.map((col) => (
                <TableCell key={col.id} sortDirection={orderBy === col.id ? order : false}>
                  {(col.sortable === undefined || col.sortable === true) && (
                    <TableSortLabel active={orderBy === col.id} direction={orderBy === col.id ? order : "asc"} onClick={() => handleRequestSort(col.id)}>
                      <Typography variant="m">{col.label || ""}</Typography>
                    </TableSortLabel>
                  )}
                  {col.sortable === false && <span>{col.label}</span>}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => (
              <TableRow key={item.id || index}>
                {cols.map((col) => (
                  <TableCell key={col.id + (item.id || index)} sx={{ ...col.sx }}>
                    {col.format ? col.format(item[col.id], item) : item[col.id]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
            <TableRow ref={startEmpryListRef} sx={{ height: emptyListH }}>
              <TableCell sx={{ verticalAlign: "baseline", fontSize: 18 }} colSpan={cols.length}>
                {sortedList.length === 0 && scope ? `Non sono presenti ${scope}.` : ""}
              </TableCell>
            </TableRow>
            <TableRow />
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          rowsPerPageOptions={[10, 25, 100]}
          count={filteredList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(event, newPage) => setPage(newPage)}
          onRowsPerPageChange={(event) => {
            setRowsPerPage(event.target.value);
            setPage(0);
          }}
          labelRowsPerPage="Righe"
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} di ${count}`}
          sx={{ maxHeight: 56 }}
        />
      </TableContainer>
    </Box>
  );
}
