import * as React from "react";
import { Box, FormControlLabel, Switch, Typography, Button, IconButton, Snackbar, Paper, Alert, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getListaProgrammi } from "../../dashboardAdminSlice";
import {  enableProgramma } from "../../dashboardAdminAPI";
import ProgrammaDetails from "./ProgrammaDetails";
import {  getAPIErrorMessage } from "../../../../common/utils";
import { Add, Edit } from "@mui/icons-material";
import LocalPaginateTable from "../LocalPaginateTable";

export function Programmi() {
  const dispatch = useDispatch();

  const [selectedProgramma, setSelectedProgramma] = React.useState(null);
  const [isEditorOpen, setIsEditorOpen] = React.useState(false);
  const [statoUpdated, setStatoUpdated] = React.useState(false);
  const [errorProgramma, setErrorProgramma] = React.useState(false);

  const { listaProgrammi, isLoading } = useSelector((state) => state.dashboardAdmin);
  const tokenAdmin = useSelector((state) => state.loginadmin.tokenAdmin);

  React.useEffect(() => {
    dispatch(getListaProgrammi());
  }, []);

  const handleChangeActive = async (event, id) => {
    const checked = event.target.checked;
    try {
      await enableProgramma({ flg_active: checked }, tokenAdmin, id);
    } catch (e) {
      setErrorProgramma(getAPIErrorMessage(e));
      return;
    }

    dispatch(getListaProgrammi());
    setStatoUpdated(checked ? "attivato" : "disattivato");
  };

  const openDialog = (programma) => {
    setSelectedProgramma(programma);
    setIsEditorOpen(true);
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setStatoUpdated(false);
  };

  const utentiTypes = [
    {
      'codice': 'P',
      'descrizione': 'Utenti Privati',
    },
    {
      'codice': 'PA',
      'descrizione': 'Professionisti Attivi',
    },
    {
      'codice': 'PP',
      'descrizione': 'Rivenditori',
    }
  ];

  return (
    <>
      {isEditorOpen ? (
        <ProgrammaDetails selectedProgramma={selectedProgramma || false} setIsEditorOpen={setIsEditorOpen} />
      ) : (
        <Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button variant="contained" endIcon={<Add />} onClick={() => openDialog(false)}>
              Aggiungi Programma
            </Button>
          </Box>
          <LocalPaginateTable
            scope="Programmi"
            isLoading={isLoading}
            cols={[
              { id: "nome", label: "Nome" },
              { id: "codice", label: "Codice" },
              { id: "durata", label: "Durata", format: (val, prog) => `${val} min` },
              { id: "flg_genius_machine", label: "Genius Machine", format: (val, prog) => (val ? "Abilitata" : "-") },
              { id: "bozza", label: "Stato", format: (val, prog) => (val ? "In Bozza" : "Pubblicato") },
              { id: "flg_active", label: "Attivo", format: (val, prog) => <FormControlLabel control={<Switch checked={!!val} onChange={(e) => handleChangeActive(e, prog.id)} size="small" />} label={val ? "Attivo" : "Disattivo"} /> },
              {
                id: 'programma_tipologia_utente',
                label: 'Tipologia Utenti',
                format: function(val, cat) {
                  return val.map((item) => {
                    return utentiTypes.find((type) => type.codice === item).descrizione;
                  }).join(', ');
                }
              },
              {
                id: "azioni",
                label: "Azioni",
                sortable: false,
                format: (val, prog) => (
                  <IconButton onClick={() => openDialog(prog)}>
                    <Edit />
                  </IconButton>
                ),
              },
            ]}
            list={listaProgrammi}
            filterCols={["nome", "descrizione"]}
          />

          <Snackbar open={!!statoUpdated} autoHideDuration={3000} onClose={handleCloseSnack} anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
            <Paper>
              <Alert onClose={handleCloseSnack} sx={{ width: "100%", alignItems: "center" }}>
                <Typography sx={{ fontFamily: "Poppins-Medium" }}>Programma {statoUpdated} con successo.</Typography>
              </Alert>
            </Paper>
          </Snackbar>

          <Dialog open={!!errorProgramma} onClose={() => setErrorProgramma(false)} maxWidth="sm" fullWidth>
            <DialogTitle variant="dialogTitle" color="error">
              Attenzione
            </DialogTitle>
            <DialogContent>
              <Typography color="error" style={{ whiteSpace: "pre-line" }}>
                {errorProgramma}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" onClick={() => setErrorProgramma(false)}>
                OK
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      )}
    </>
  );
}
