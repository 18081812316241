import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from './../../config/axiosConfig';

export const getListaProgrammiCodici = createAsyncThunk(
  'homepage/getListaProgrammiCodici',
  async (_, thunkAPI) => {
    let token;
    try {
      const state = thunkAPI.getState();
      token = state.login.token;
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      const response = await api.get('api/codici_servizio?order_by=data_movimento&sort=asc&flg_attivo=1', config);
      return { listaProgrammiCodici: response.data.data, };
    } catch (error) {
      throw error.response.data;
    }
  }
);

export const getListaProgrammiAcquistati = createAsyncThunk(
  'homepage/getListaProgrammiAcquistati',
  async (_, thunkAPI) => {
    let token;
    try {
      const state = thunkAPI.getState();
      token = state.login.token;
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      const response = await api.get('api/movimenti/getCodiciAcquistati?limit=50', config);

      return { listaProgrammiAcquistati: response.data.data.data, };
    } catch (error) {
      throw error.response.data;
    }
  }
);

export const getListaAbbonamenti = createAsyncThunk(
  'homepage/getListaAbbonamenti',
  async (_, thunkAPI) => {
    let token;
    try {
      const state = thunkAPI.getState();
      token = state.login.token;
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      const response = await api.get('api/abbonamenti?flg_attivo=1', config);
      return { listaAbbonamenti: response.data.data, };
    } catch (error) {
      throw error.response.data;
    }
  }
);

export const getListaPrestazioni = createAsyncThunk(
  'homepage/getListaPrestazioni',
  async (_, thunkAPI) => {
    let token;
    try {
      const state = thunkAPI.getState();
      token = state.login.token;
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      const response = await api.get("/api/prestazioni?order_by=data_prestazione&sort=desc&flg_storico=1", config);
      return { listaPrestazioni: response.data.data.data, };
    } catch (error) {
      throw error.response.data;
    }
  }
);

export const getDashboardCounter = createAsyncThunk(
  'homepage/getDashboardCounter',
  async (_, thunkAPI) => {
    let token;
    try {
      const state = thunkAPI.getState();
      token = state.login.token;
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      const response = await api.get('api/movimenti/getDashboardCounter', config);
      return { codiciAttivi: response.data.data.codici_attivi, abbonamentiInScadenza: response.data.data.abbonamenti_in_scadenza, abbonamentiAttivi: response.data.data.abbonamenti_attivi };
    } catch (error) {
      throw error.response.data;
    }
  }
);


const initialState = {
  isLoading: false,
  error: null,
  listaProgrammiCodici: [],
  listaProgrammiAcquistati: [],
  listaAbbonamenti: [],
  listaPrestazioni: [],
  codiciAttivi: 0,
  abbonamentiInScadenza: 0,
  abbonamentiAttivi: 0
};

export const homePageSlice = createSlice({
  name: 'homepage',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(getListaProgrammiCodici.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getListaProgrammiCodici.fulfilled, (state, action) => {
        state.isLoading = false;
        state.listaProgrammiCodici = action.payload.listaProgrammiCodici;
        state.error = null;
      })
      .addCase(getListaProgrammiCodici.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message ? action.error.message : action.error;
      })
      .addCase(getListaProgrammiAcquistati.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getListaProgrammiAcquistati.fulfilled, (state, action) => {
        state.isLoading = false;
        state.listaProgrammiAcquistati = action.payload.listaProgrammiAcquistati;
        state.error = null;
      })
      .addCase(getListaProgrammiAcquistati.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message ? action.error.message : action.error;
      })

      .addCase(getListaAbbonamenti.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getListaAbbonamenti.fulfilled, (state, action) => {
        state.isLoading = false;
        state.listaAbbonamenti = action.payload.listaAbbonamenti;
        state.error = null;
      })
      .addCase(getListaAbbonamenti.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message ? action.error.message : action.error;
      })


      .addCase(getListaPrestazioni.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getListaPrestazioni.fulfilled, (state, action) => {
        state.isLoading = false;
        state.listaPrestazioni = action.payload.listaPrestazioni;
        state.error = null;
      })
      .addCase(getListaPrestazioni.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message ? action.error.message : action.error;
      })


      .addCase(getDashboardCounter.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getDashboardCounter.fulfilled, (state, action) => {
        state.isLoading = false;
        state.codiciAttivi = action.payload.codiciAttivi;
        state.abbonamentiInScadenza = action.payload.abbonamentiInScadenza;
        state.abbonamentiAttivi = action.payload.abbonamentiAttivi;
        state.error = null;
      })
      .addCase(getDashboardCounter.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message ? action.error.message : action.error;
      })
  },
});


export default homePageSlice.reducer;
