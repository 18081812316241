import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DomandeQuestionario from "./DomandeQuestionario";
import { getListaQuestionari, setSnackbarData } from "../../dashboardAdminSlice";
import { Add, ArrowBack, ExpandMore, Save } from "@mui/icons-material";
import { formatDate, getAPIErrorMessage } from "../../../../common/utils";
import { creaQuestionario, modifyQuestionario, getQuestionario, pubblicaBozzaQuestionario } from "../../dashboardAdminAPI";
import { Box, Button, Typography, TextField, CircularProgress, FormControl, Grid, InputLabel, Select, MenuItem, Dialog, DialogTitle, DialogContent, ListItemText, DialogActions, Accordion, AccordionSummary, AccordionDetails, Backdrop, Paper, Divider, Snackbar, Alert, Stack } from "@mui/material";

const QuestionarioDetails = ({ setIsEditorOpen, selectedQuestionario = false }) => {
  const dispatch = useDispatch();
  const tokenAdmin = useSelector((state) => state.loginadmin.tokenAdmin);
  const { listaQuestionari } = useSelector((state) => state.dashboardAdmin);
  const [isLoading, setIsLoading] = useState(false);

  const [questionarioData, setQuestionarioData] = useState({ titolo: "", descrizione: "", domande: [] });
  const [lastPubblicato, setLastPubblicato] = useState(null);
  const [isCreated, setIsCreated] = useState(false);
  const [selectedDomanda, setSelectedDomanda] = useState("");
  const [selectedDomandaPubblicata, setSelectedDomandaPubblicata] = useState("");
  const [errorQuestionario, setErrorQuestionario] = useState("");

  const [dialogImportaVisbile, setDialogImportaVisbile] = useState(false);
  const [selectedImportQuestionario, setSelectedImportQuestionario] = useState(null);
  const [errorImportQuestionario, setErrorImportQuestionario] = useState("");

  const [snackPub, setSnackPub] = useState(false);
  const [snackPubDone, setSnackPubDone] = useState(false);

  useEffect(() => {
    if (selectedQuestionario?.id) loadQuestionario(selectedQuestionario.id);
  }, []);

  const loadQuestionario = async (id) => {
    try {
      setIsLoading(true);
      let data;
      const response = await getQuestionario(id, tokenAdmin);
      data = response.data;
      if (data.bozzaQuestionario != null && data.bozza) {
        setLastPubblicato(data);
        setSelectedDomandaPubblicata(selectedDomandaPubblicata != "" ? selectedDomandaPubblicata : data.domande[0].posizione.toString());

        data = { ...data, ...data.bozzaQuestionario, id: data.id };
      } else setLastPubblicato(null);

      setQuestionarioData(data);
      setSelectedDomanda(selectedDomanda != "" ? selectedDomanda : data.domande[0].posizione.toString());
    } catch (e) {
      dispatch(setSnackbarData({ message: getAPIErrorMessage(e), open: true, error: true }));
      onClose();
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setQuestionarioData({ ...questionarioData, [name]: value });
  };

  const onImportQuestionario = function () {
    if (!selectedImportQuestionario?.trim()) {
      setErrorImportQuestionario("Seleziona un Intervista");
      return;
    }
    const newQuestionario = { ...questionarioData, domande: listaQuestionari.find((questionario) => questionario.id === selectedImportQuestionario).domande };
    setQuestionarioData(newQuestionario);
    setSelectedDomanda(newQuestionario.domande[0].posizione.toString() || "");
    setDialogImportaVisbile(false);
  };

  const handleAddQuestion = () => {
    const lun = questionarioData.domande.length;
    let newNumeroPagina = lun + 1;
    if (questionarioData.domande[lun - 1]?.posizione) {
      newNumeroPagina = questionarioData.domande[lun - 1].posizione + 1;
    }

    setQuestionarioData({
      ...questionarioData,
      domande: [
        ...questionarioData.domande,
        {
          testo: "",
          show_media: false,
          note: "",
          tipologia: "RISP-SING",
          posizione: newNumeroPagina,
          opzioni_domanda: [
            { testo: "", tipologia: "TXT", posizione: 1, show_sotto_opzioni: false, sotto_opzioni: [] },
            // { testo: "", tipologia: "TXT", posizione: 2, show_sotto_opzioni: false, sotto_opzioni: [] },
            // { testo: "", tipologia: "TXT", posizione: 3, show_sotto_opzioni: false, sotto_opzioni: [] },
          ],
        },
      ],
    });
    setSelectedDomanda(newNumeroPagina.toString());
  };

  const handleQuestionarioSubmit = async (pubblica) => {
    let response;

    try {
      setIsLoading(true);
      const data = { titolo: questionarioData.titolo, descrizione: questionarioData.descrizione, domande: questionarioData.domande };
      if (pubblica) {
        response = await pubblicaBozzaQuestionario(data, tokenAdmin, questionarioData.id);
      } else {
        if (selectedQuestionario || isCreated) {
          response = await modifyQuestionario(data, tokenAdmin, questionarioData.id);
        } else {
          response = await creaQuestionario(data, tokenAdmin);
        }
      }
    } catch (e) {
      let err = getAPIErrorMessage(e);
      if (e.response?.data?.message) {
        err = e.response.data.message;
        if (Array.isArray(err)) {
          err = err
            .map((str) => {
              let errStr = str.split("'");
              let field = errStr[1].split(".");
              if (field.length > 1 && field[0] === "domande") {
                if (field.length > 3) field = [`${field[4]} nella domanda n.${parseInt(field[1]) + 1} alla risposta n.${parseInt(field[3]) + 1}`];
                else field = [`${field[2]} nella domanda n.${parseInt(field[1]) + 1}`];
              }
              errStr[1] = field[0];
              errStr[0] = "Il campo ";

              return errStr.join("");
            })
            .join("\n");
        }
      }
      setErrorQuestionario(err);
      return;
    } finally {
      setIsLoading(false);
    }

    await loadQuestionario(response.data.id);
    setIsCreated(true);
    if (!pubblica) setSnackPub(true);
    if (pubblica) setSnackPubDone(true);
  };

  const onClose = () => {
    dispatch(getListaQuestionari());
    setErrorQuestionario("");
    setIsEditorOpen(false);
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackPub(false);
  };

  const handleClosePubbDoneSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackPubDone(false);
  };

  return (
    <>
      {isLoading && (
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Box sx={{ bgcolor: "#fff", p: 2 }} component={Paper}>
        <Grid container columnSpacing={2} rowSpacing={4}>
          <Grid item xs={selectedQuestionario || isCreated ? 6 : 12}>
            <Button startIcon={<ArrowBack />} onClick={onClose}>
              Indietro
            </Button>
          </Grid>
          {(selectedQuestionario || isCreated) && (
            <Grid item xs={6} display="flex" justifyContent="flex-end" alignItems="center">
              {questionarioData.bozza ? (
                <>
                  <Typography variant="m" color="primary.main" sx={{ display: "inline-block" }}>
                    Elaborato in bozza dal {formatDate(questionarioData.inizio_modifiche_bozza)}
                  </Typography>
                  <Button variant="contained" onClick={async () => handleQuestionarioSubmit(true)} sx={{ display: "inline-block", ml: 2 }}>
                    Pubblica
                  </Button>
                </>
              ) : (
                <Typography variant="m" color="primary.main">
                  Intervista pubblicata il {formatDate(questionarioData.ultima_data_pubblicazione)}
                </Typography>
              )}
            </Grid>
          )}
          <Grid item xs={12} sx={{ py: 2 }} display="flex" justifyContent="center">
            <Typography variant="title2">{questionarioData?.id ? "Modifica Intervista" : "Aggiungi Intervista"}</Typography>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <TextField label="Titolo" name="titolo" value={questionarioData.titolo} onChange={handleInputChange} />
              {lastPubblicato != null && (
                <Typography color="text.secondary" sx={{ mt: 2, ml: 2 }}>
                  Pubblicato: {lastPubblicato.titolo}
                </Typography>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={8}>
            <FormControl fullWidth>
              <TextField label="Descrizione Intervista" name="descrizione" value={questionarioData.descrizione} onChange={handleInputChange} multiline maxRows={5} />
              {lastPubblicato != null && (
                <Typography color="text.secondary" sx={{ mt: 2, ml: 2 }}>
                  Pubblicato: {lastPubblicato.descrizione}
                </Typography>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="subtitle2" sx={{ ml: 1 }}>
                Domande e Risposte dell' Intervista
              </Typography>
              <Box sx={{ flex: 1 }} />
              <Button
                variant="contained"
                onClick={() => {
                  setSelectedImportQuestionario("");
                  setErrorImportQuestionario("");
                  setDialogImportaVisbile(true);
                }}
              >
                Importa da Questionatio
              </Button>
              <Button variant="contained" onClick={handleAddQuestion} endIcon={<Add />} sx={{ ml: 2 }}>
                Aggiungi Domanda
              </Button>
            </Box>

            {questionarioData?.domande.length > 0 && <DomandeQuestionario selectedTab={selectedDomanda} setSelectedTab={setSelectedDomanda} questionarioData={questionarioData} setQuestionarioData={setQuestionarioData} />}
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          {questionarioData.bozza == true && lastPubblicato?.domande.length > 0 && (
            <Grid item xs={12}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMore />}>Domande Intervista Pubblicate</AccordionSummary>
                <AccordionDetails>
                  <DomandeQuestionario selectedTab={selectedDomandaPubblicata} setSelectedTab={setSelectedDomandaPubblicata} questionarioData={lastPubblicato} setQuestionarioData={() => {}} readOnly={true} />
                </AccordionDetails>
              </Accordion>
            </Grid>
          )}
          <Grid item container direction="row-reverse" xs={12}>
            <Button variant="contained" onClick={async () => handleQuestionarioSubmit(false)} color="primary" startIcon={<Save />}>
              {selectedQuestionario || isCreated ? "Salva come Bozza" : "Crea Intervista"}
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Snackbar open={snackPub} autoHideDuration={5000} onClose={handleCloseSnack} anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
        <Paper>
          <Alert onClose={handleCloseSnack} sx={{ width: "100%", alignItems: "center" }}>
            <Stack direction="row" alignItems="center">
              <Typography sx={{ fontFamily: "Poppins-Medium" }}>Vuoi pubblicare l' Intervista?</Typography>
              <Button
                variant="contained"
                onClick={async () => {
                  handleQuestionarioSubmit(true);
                  handleCloseSnack();
                }}
                sx={{ display: "inline-block", ml: 2 }}
              >
                Pubblica
              </Button>
            </Stack>
          </Alert>
        </Paper>
      </Snackbar>

      <Snackbar open={snackPubDone} autoHideDuration={5000} onClose={handleClosePubbDoneSnack} anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
        <Paper>
          <Alert onClose={handleClosePubbDoneSnack} sx={{ width: "100%", alignItems: "center" }}>
            <Stack direction="row" alignItems="center">
              <Typography sx={{ fontFamily: "Poppins-Medium" }}>Intervista Pubblicato</Typography>
            </Stack>
          </Alert>
        </Paper>
      </Snackbar>

      <Dialog open={!!errorQuestionario} onClose={() => setErrorQuestionario("")} maxWidth="sm" fullWidth>
        <DialogTitle variant="dialogTitle" color="error">
          Attenzione
        </DialogTitle>
        <DialogContent>
          <Typography color="error" style={{ whiteSpace: "pre-line" }}>
            {errorQuestionario}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => setErrorQuestionario("")}>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={dialogImportaVisbile} onClose={() => setDialogImportaVisbile(false)} maxWidth="sm" fullWidth>
        <DialogTitle variant="dialogTitle">Importa domanda da Questionatio</DialogTitle>
        <DialogContent>
          <FormControl sx={{ mt: 2 }} fullWidth>
            <InputLabel style={{ backgroundColor: "white" }}>Intervista</InputLabel>
            <Select label="Intervista" value={selectedImportQuestionario} onChange={(e) => setSelectedImportQuestionario(e.target.value)}>
              {listaQuestionari
                .filter((questionario) => questionario.id !== questionarioData?.id)
                .map((questionario) => (
                  <MenuItem key={questionario.id} value={questionario.id}>
                    <ListItemText primary={questionario.titolo} />
                  </MenuItem>
                ))}
            </Select>
          </FormControl>

          {errorImportQuestionario !== "" && (
            <Typography color="error" sx={{ whiteSpace: "pre-line", mt: 3 }}>
              {errorImportQuestionario}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogImportaVisbile(false)} sx={{ color: "#000" }}>
            Annulla
          </Button>
          <Button variant="contained" onClick={() => onImportQuestionario()}>
            IMPORTA
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default QuestionarioDetails;
