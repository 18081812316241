import React from "react";
import { Box, Button, Typography, TextField, FormControlLabel, FormControl, Switch, Grid, InputLabel, Select, MenuItem, Tab, IconButton, Autocomplete, Divider, Stack } from "@mui/material";
import { Add, Delete } from "@mui/icons-material";
import { MediaSelect } from "../media/MediaSelect";
import TabPanel from "@mui/lab/TabPanel";
import TabList from "@mui/lab/TabList";
import TabContext from "@mui/lab/TabContext";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import DraggableTab from "../DraggableTabs";

const DomandeQuestionario = ({ selectedTab, setSelectedTab, questionarioData = false, setQuestionarioData, readOnly = false }) => {
  const handleInputQuestionarioChange = (e, questionIndex, answerIndex, field) => {
    let { value, type, checked } = e.target;
    if (type === "checkbox") value = checked;

    setQuestionarioData((prevQuestionario) => {
      let updatedQuestionario = { ...prevQuestionario };
      const slctedDomanda = updatedQuestionario.domande.find((d) => d.posizione === questionIndex);
      if (answerIndex === -1) slctedDomanda[field] = value;
      else {
        const slctedRisposta = slctedDomanda.opzioni_domanda.find((r) => r.posizione === answerIndex);
        if (field === "sotto_opzioni") {
          slctedRisposta[field] = value.map((v, index) => {
            return { testo: v, posizione: index + 1 };
          });
        } else slctedRisposta[field] = value;
      }
      return updatedQuestionario;
    });
  };

  const handleAddAnswer = (questionIndex) => {
    setQuestionarioData((prevQuestionario) => {
      let updatedQuestionario = { ...prevQuestionario };
      const opzioni_domanda = updatedQuestionario.domande.find((q) => q.posizione === questionIndex).opzioni_domanda;
      updatedQuestionario.domande.find((q) => q.posizione === parseInt(selectedTab)).opzioni_domanda.push({ testo: "", tipologia: "TXT", posizione: opzioni_domanda[opzioni_domanda.length - 1]?.posizione + 1 || 1, show_sotto_opzioni: false, sotto_opzioni: [] });
      return updatedQuestionario;
    });
  };

  const handleDeleteAnswer = (questionIndex, answerIndex) => {
    setQuestionarioData((prevQuestionario) => {
      let updatedQuestionario = { ...prevQuestionario };
      let updQuest = updatedQuestionario.domande.find((q) => q.posizione === questionIndex);
      updQuest.opzioni_domanda = updQuest.opzioni_domanda.filter((opt) => opt.posizione !== answerIndex);
      return updatedQuestionario;
    });
  };

  const handleDeleteQuestion = (questionIndex) => {
    const newDomandeList = questionarioData.domande.filter((q) => q.posizione !== questionIndex);
    for (let i = 0; i < newDomandeList.length; i++) {
      newDomandeList[i].posizione = i + 1;
    }

    setQuestionarioData({ ...questionarioData, domande: newDomandeList });
    if (newDomandeList.length > questionIndex) {
      setSelectedTab(questionIndex.toString());
    } else {
      setSelectedTab(newDomandeList[newDomandeList.length - 1]?.posizione.toString() || "");
    }
  };


  const _renderTabList = (droppableProvided) => (
    <TabList
      onChange={(e, newTab) => setSelectedTab(newTab)}
      variant="scrollable"
    >
      {
        questionarioData?.domande.map((domanda, index) => {
          const child = <Tab key={domanda.posizione} label={"Domanda " + domanda.posizione} value={domanda.posizione.toString()} />;

          return (
            <DraggableTab
              label={"Domanda " + domanda.posizione}
              value={domanda.posizione.toString()}
              index={domanda.posizione}
              key={domanda.posizione}
              child={child}
            />
          );
        })
      }
      {droppableProvided ? droppableProvided.placeholder : null}
    </TabList>

  );


  const _renderTabListWrappedInDroppable = () => (
    <DragDropContext onDragEnd={(e) => { e.source && e.destination && onDragEnd(e.source.index, e.destination.index) }}>
      <div style={{ display: "flex", overflow: "auto" }}>
        <Droppable droppableId="1" direction="horizontal">
          {(droppableProvided) => (
            <div
              ref={droppableProvided.innerRef}
              {...droppableProvided.droppableProps}
            >
              {_renderTabList(droppableProvided)}
            </div>
          )}
        </Droppable>
      </div>
    </DragDropContext>
  );

  const onDragEnd = (posizioneSource, posizioneEnd) => {

    const newDomande = [...questionarioData.domande];
    const [removed] = newDomande.splice((posizioneSource - 1), 1);
    newDomande.splice((posizioneEnd - 1), 0, removed);

    for (let i = 0; i < newDomande.length; i++) {
      newDomande[i].posizione = i + 1;
    }

    setQuestionarioData((prevData) => ({ ...prevData, domande: newDomande }));
    setSelectedTab(posizioneEnd.toString());
  }

  return (
    <TabContext value={selectedTab || ""}>
      <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 1, mt: 2 }}>
        <Stack direction="column">{_renderTabListWrappedInDroppable()}</Stack>
      </Box>
      {questionarioData?.domande?.map((domanda) => (
        <TabPanel value={domanda.posizione.toString()} index={domanda.posizione} key={domanda.posizione}>
          <Box sx={{ display: "flex", flex: 1, justifyContent: "end", position: 'relative', top: -20, right: -20 }}>
            {questionarioData && !readOnly && (
              <IconButton color="error" onClick={() => handleDeleteQuestion(parseInt(selectedTab))}>
                <Delete />
              </IconButton>
            )}
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField multiline minRows={2} maxRows={5} label="Testo" value={domanda.testo} disabled={readOnly} onChange={(e) => handleInputQuestionarioChange(e, domanda.posizione, -1, "testo")} />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField multiline maxRows={4} label="Note" value={domanda.note} disabled={readOnly} onChange={(e) => handleInputQuestionarioChange(e, domanda.posizione, -1, "note")} />
              </FormControl>
            </Grid>
            <Grid item container spacing={2} xs={6}>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel>Tipologia Domanda</InputLabel>
                  <Select value={domanda.tipologia} disabled={readOnly} label="Tipologia Domanda" onChange={(e) => handleInputQuestionarioChange(e, domanda.posizione, -1, "tipologia")}>
                    <MenuItem value="RISP-SING">Risposta Singola</MenuItem>
                    <MenuItem value="RISP-MULT">Risposta Multipla</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} display="flex" alignItems="center">
                <FormControlLabel control={<Switch color="primary" checked={domanda.show_media} disabled={readOnly} onChange={(e) => handleInputQuestionarioChange(e, domanda.posizione, -1, "show_media")} />} label="Mostra Media" labelPlacement="start" />
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>{domanda.tipologia === "RISP-MULT" && <TextField type="number" label="Max Numero Risposte" value={domanda.max_risposte} onChange={(e) => handleInputQuestionarioChange(e, domanda.posizione, -1, "max_risposte")} sx={{ mb: 2 }} />}</FormControl>
              </Grid>
              <Grid item xs={6}>
                {domanda.show_media && (
                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <MediaSelect
                      disabled={readOnly}
                      label="Media"
                      supportedTypes={["IMG", "AUD", "VID"]}
                      currentSelectedMediaId={domanda.id_media}
                      onSelectCallback={(media) => {
                        handleInputQuestionarioChange({ target: { value: media?.id } }, domanda.posizione, -1, "id_media");
                      }}
                    />
                  </FormControl>
                )}
              </Grid>
            </Grid>
            <Grid item container xs={12} sx={{ ml: 5 }} spacing={2} rowGap={2}>
              <Grid container item spacing={2} justifyContent={"space-between"} alignItems={"center"}>
                <Grid item xs={"auto"}>
                  <Typography variant="m">Risposte</Typography>
                </Grid>
                <Grid item xs={"auto"}>
                  {!readOnly && (
                    <Button variant="contained" endIcon={<Add />} onClick={() => handleAddAnswer(domanda.posizione)}>
                      Aggiungi Risposta
                    </Button>
                  )}
                </Grid>
              </Grid>
              {domanda.opzioni_domanda
                // ?.slice()
                // .reverse()
                ?.map((opzione_domanda, index) => (
                  <Grid key={opzione_domanda.posizione} container item spacing={2}>
                    <Grid item xs={1} sx={{ textAlign: "center", margin: "auto" }}>
                      # {index + 1}
                    </Grid>
                    <Grid item container spacing={2} xs={10}>
                      <Grid item display="flex" alignItems="center" xs={5}>
                        <FormControl fullWidth>
                          <InputLabel>Tipologia Risposta</InputLabel>
                          <Select label="Tipologia Risposta" disabled={readOnly} value={opzione_domanda.tipologia} onChange={(e) => handleInputQuestionarioChange(e, domanda.posizione, opzione_domanda.posizione, "tipologia")}>
                            <MenuItem value="TXT">Testo</MenuItem>
                            <MenuItem value="TXTIMG">Testo + Immagine</MenuItem>
                            {/* <MenuItem value="IMG">Immagine</MenuItem> */}
                          </Select>
                        </FormControl>
                      </Grid>
                      {(opzione_domanda.tipologia === "TXTIMG" || opzione_domanda.tipologia === "IMG") && (
                        <Grid item display="flex" alignItems="flex-start\" xs={5}>
                          <MediaSelect
                            label="Immagine"
                            small
                            disabled={readOnly}
                            supportedTypes={["IMG"]}
                            currentSelectedMediaId={opzione_domanda.id_media}
                            onSelectCallback={(media) => handleInputQuestionarioChange({ target: { value: media?.id } }, domanda.posizione, opzione_domanda.posizione, "id_media")}
                          />
                        </Grid>
                      )}
                      {domanda.tipologia === "RISP-SING" && (
                        <Grid item display="flex" alignItems="center" xs={2}>
                          <FormControlLabel control={<Switch disabled={readOnly} checked={opzione_domanda.show_sotto_opzioni} onChange={(e) => handleInputQuestionarioChange(e, domanda.posizione, opzione_domanda.posizione, "show_sotto_opzioni")} />} label="Sotto Opzioni" labelPlacement="start" />
                        </Grid>
                      )}

                      <Grid item xs={12}>
                        <FormControl fullWidth>
                          <TextField label="Testo" disabled={readOnly} multiline value={opzione_domanda.testo} onChange={(e) => handleInputQuestionarioChange(e, domanda.posizione, opzione_domanda.posizione, "testo")} />
                        </FormControl>
                      </Grid>

                      {domanda.tipologia === "RISP-SING" && opzione_domanda.show_sotto_opzioni && (
                        <Grid item xs={12}>
                          <FormControl fullWidth>
                            <Autocomplete
                              freeSolo
                              multiple
                              options={[]}
                              disableClearable
                              disabled={readOnly}
                              value={opzione_domanda.sotto_opzioni.map((opzione) => opzione.testo)}
                              onChange={(e, value) => handleInputQuestionarioChange({ target: { value: value } }, domanda.posizione, opzione_domanda.posizione, "sotto_opzioni")}
                              renderInput={(params) => <TextField label="Aggiungi Opzione" {...params} />}
                            />
                          </FormControl>
                        </Grid>
                      )}
                    </Grid>
                    {!readOnly && (
                      <Grid item display="flex" justifyContent="space-around" alignItems="center" xs={1}>
                        <Divider orientation="vertical" />
                        <IconButton onClick={() => handleDeleteAnswer(domanda.posizione, opzione_domanda.posizione)}>
                          <Delete xs={{ color: "red" }} />
                        </IconButton>
                      </Grid>
                    )}
                    {index !== domanda.opzioni_domanda.length - 1 && (
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                    )}
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </TabPanel>
      ))}
    </TabContext>
  );
};

export default DomandeQuestionario;
