import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getAPIErrorMessage } from "../../common/utils";
import { Tab, Dialog, DialogContent, Box, CircularProgress, Backdrop, Button, Typography, DialogActions } from "@mui/material";
import { useTheme } from "@emotion/react";
import TabPanel from "@mui/lab/TabPanel";
import TabList from "@mui/lab/TabList";
import TabContext from "@mui/lab/TabContext";
import { RicercaUtente } from "../prestazione/RicercaUtente";
import { initialCheckVenditaBrani, vendiBrani } from "./absAPI";
import { Info } from "@mui/icons-material";
import { InserisciUtente } from "../prestazione/InserisciUtente";
import { register } from "../login/loginAPI";
export function DialogVendiBrani({ brani, onSuccess, onClose }) {
  const theme = useTheme();
  const c = theme.palette;

  const { user, token } = useSelector((state) => state.login);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isInitialLoading, setIsInitialLoading] = React.useState(true);
  const [initialLoadingError, setInitialLoadingError] = React.useState("");
  const [error, setError] = React.useState("");
  const [selectedTab, setSelectedTab] = useState("S");
  const [selectedUser, setSelectedUser] = useState(null);
  const [braniVendita, setBraniVendita] = useState([]);
  const [datiUtentePrivatoManuale, setDatiUtentePrivatoManuale] = useState(null);

  const init = async () => {
    setIsInitialLoading(true);
    let response;
    try {
      response = await initialCheckVenditaBrani({ brani: brani }, token);
      setBraniVendita(response.data);
    } catch (err) {
      setInitialLoadingError(getAPIErrorMessage(err));
    }
    setIsInitialLoading(false);
  };




  const onConfirm = async () => {
    setIsLoading(true);

    let _id_utente_privato = null;

    if (user.tipologia === "PA" || user.tipologia === "PP") {
      if (selectedTab === "S") {
        if (selectedUser === null) {
          setError("Selezionare un utente");
          setIsLoading(false);
          return;
        }
        _id_utente_privato = selectedUser.id;
      } else if (selectedTab === "R") {
        let response = null;
        try {
          response = await register({ ...datiUtentePrivatoManuale, tipologia: "P" }, token);
        } catch (err) {
          setError(getAPIErrorMessage(err));
          setIsLoading(false);
          return;
        }
        _id_utente_privato = response.data.id;
      } else {
        return;
      }
    }


    const payload = {
      id_utente_destinatario: _id_utente_privato,
      brani: brani,
    };
    try {
      await vendiBrani(payload, token);
    } catch (err) {
      setError(getAPIErrorMessage(err));
      return;
    } finally {
      setIsLoading(false);
    }

    if (onSuccess !== null) {
      onSuccess();
    }
  };

  useEffect(() => {
    init();
  }, []);




  return (
    <>
      <Dialog open={true} onClose={() => onClose()} maxWidth="md" fullWidth={true}>
        {(isInitialLoading || isLoading) && (
          <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isInitialLoading || isLoading}>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}

        <DialogContent sx={{ p: 4, pt: 2, fontSize: "14px", minHeight: 400 }}>
          {!isInitialLoading && initialLoadingError !== "" && (
            <Typography style={{ color: c.text.error, whiteSpace: "pre-line" }} sx={{ mt: 4 }}>
              {initialLoadingError}
            </Typography>
          )}
          {!isInitialLoading && initialLoadingError === "" && (
            <>
              <Box sx={{ mb: 3 }}>
                <Typography style={{ color: c.primary.main, fontSize: "1.5rem", fontWeight: "bold" }}>Cessione Brani</Typography>

                <Typography style={{ display: "inline-block", color: c.text.primary, fontSize: "14px" }}>Per la cessione hai selezionato i brani:</Typography>
                {braniVendita.map((brano, index) => (
                  <Typography key={index} style={{ display: "inline-block", color: "white", fontSize: "0.8rem", backgroundColor: c.primary.main, padding: "4px 8px 4px 8px", fontWeight: "bold", borderRadius: "5px", marginLeft: "10px", marginTop: '8px' }}>
                    {brano.nome}
                  </Typography>
                ))}
              </Box>
              <>
                <Typography sx={{ mb: 0, mt: 1, fontWeight: "bold", fontSize: "14px" }}>Per chi è il programma?</Typography>

                <TabContext value={selectedTab}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <TabList onChange={(e, value) => setSelectedTab(value)} variant="scrollable" scrollButtons allowScrollButtonsMobile>
                      <Tab label={"Utente Salvato"} value={"S"} />
                      {user?.tipologia == 'PA' && <Tab label={"Registra utente"} value={"R"} />}
                    </TabList>
                  </Box>

                  <TabPanel value={"S"} index={0} sx={{py: '2rem', px: '0.5rem'}}>
                    <Box sx={{ display: 'flex' }}>
                      <Info sx={{ color: c.primary.main, fontSize: '20px' }} />
                      <Typography sx={{ ml: '5px', fontSize: '14px' }}>
                        Ricerca l’utente salvato per codice cliente.
                      </Typography>
                    </Box>
                    <RicercaUtente selectedUser={selectedUser} setSelectedUser={setSelectedUser}
                      resultMessage={'Risultati della ricerca. Seleziona un utente e clicca su "Conferma"'} />
                  </TabPanel>
                  <TabPanel value={"R"} index={1} sx={{py: '2rem', px: '0.5rem'}}>
                    <Box sx={{ display: 'flex', mb: 2 }}>
                      <Info sx={{ color: c.primary.main, fontSize: '20px' }} />
                      <Typography sx={{ ml: '5px', fontSize: '14px' }}>
                        Registra il tuo cliente se non è già registrato per trasferire unità audio o programmi.
                      </Typography>
                    </Box>

                    <InserisciUtente isRegistrazione={true} datiUtentePrivatoManuale={datiUtentePrivatoManuale} setDatiUtentePrivatoManuale={setDatiUtentePrivatoManuale} />
                  </TabPanel>

                </TabContext>
              </>
            </>
          )}

          {error !== "" && (
            <Typography style={{ color: c.text.error, whiteSpace: "pre-line" }} sx={{ mt: 4 }}>
              {error}
            </Typography>
          )}
        </DialogContent>

        <DialogActions>
          <Button onClick={() => onClose()} style={{ color: "black" }}>
            Annulla
          </Button>
          {initialLoadingError === "" && (
            <Button variant="contained" onClick={() => onConfirm()}>
              Conferma
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}
