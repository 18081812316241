import api from '../../config/axiosConfig';



export const getOrdine = async (id, token) => {
    const config = {
        headers: {
            Authorization: "Bearer " + token,
        },
    };

    const response = await api.get('/api/ordini/'+id, config);
    return response.data;
}