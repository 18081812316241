import React from "react";
import { Box, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";
import { CheckCircle } from "@mui/icons-material";

export function InfoVendita({ programma = null, codiciServizio = [] }) {
  const theme = useTheme();
  const c = theme.palette;

  return (
    <>
      <Box sx={{ mb: 3 }}>
        <Typography style={{ color: c.primary.main, fontSize: "1.5rem", fontWeight: "bold" }}>Programma {programma?.nome.toUpperCase()}</Typography>

        <Typography style={{ display: "inline-block", color: c.text.primary, fontSize: "14px" }}>Per la cessione hai selezionato i codici:</Typography>
        {codiciServizio.map((codice, index) => (
          <Typography key={index} style={{ display: "inline-block", color: "white", fontSize: "0.8rem", backgroundColor: c.primary.main, padding: "4px 8px 4px 8px", fontWeight: "bold", borderRadius: "5px", marginLeft: "10px", marginTop:'8px' }}>
            {codice}
          </Typography>
        ))}
      </Box>
      <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>Descrizione programma:</Typography>
      <Typography sx={{ mb: 4 }} variant="body2">
        {programma?.descrizione}
      </Typography>

      <Typography sx={{ mb: 1, fontWeight: 'bold', fontSize: '14px' }}>Cosa prevede:</Typography>

      <Box sx={{ mb: 4 }}>
        <Box sx={{ display: 'inline-block', mr: 2 }} >
          <CheckCircle sx={{ color: c.primary.dark, verticalAlign: 'bottom', mr: 1, height: '20px', width: '20px' }} />
          <Typography sx={{ display: 'inline-block', fontWeight: 'semi-bold', fontSize: '14px', }}>Breve intervista</Typography>

        </Box>
        {!!programma?.flg_genius_machine && (
          <Box sx={{ display: "inline-block" }}>
            <CheckCircle sx={{ color: c.primary.dark, verticalAlign: "bottom", mr: 1, height: "20px", width: "20px" }} />
            <Typography sx={{ display: "inline-block", fontWeight: "semi-bold", fontSize: "14px" }}>Genius Machine</Typography>
          </Box>
        )}
      </Box>
    </>
  );
}
