import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  TextField,
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
  Grid,
  Alert,
  Tooltip,
  Backdrop,
  Paper,
  tooltipClasses,
  styled,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  InputAdornment,
  Stack,
} from "@mui/material";
import { creaProgramma, modifyProgramma, getProgramma, pubblicaBozzaProgramma, scaricaRefertoProgrammaPreview } from "../../dashboardAdminAPI";
import { getListaProgrammi, getListaCategorie, getListaQuestionari, getListaReferti, setSnackbarData } from "../../dashboardAdminSlice";
import { useDispatch, useSelector } from "react-redux";
import { PrezziProgramma } from "./PrezziProgramma";
import { MediaSelect } from "../media/MediaSelect";
import { formatDate, getAPIErrorMessage } from "../../../../common/utils";
import { ArrowBack, Download, ExpandMore, Info, Save } from "@mui/icons-material";

const CustomTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: { color: theme.palette.primary.light },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.primary.light,
    fontFamily: "Poppins-Regular",
    color: "#000",
    boxShadow: theme.shadows[1],
    fontSize: 18,
    textAlign: "center",
  },
}));

const GridData = ({ data, setData, liste, readOnly = false } = {}) => {
  const { listaCategorie, listaQuestionari, listaReferti } = liste;
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setData((prevData) => ({ ...prevData, [name]: type === "checkbox" ? checked : value }));
  };

  const utentiTypes = [
    {
      'codice': 'P',
      'descrizione': 'Utenti Privati',
    },
    {
      'codice': 'PA',
      'descrizione': 'Professionisti Attivi',
    },
    {
      'codice': 'PP',
      'descrizione': 'Rivenditori',
    }
  ];

  return (
    <>
      <Grid item xs={4}>
        <FormControl fullWidth>
          <TextField disabled={readOnly} label="Nome" name="nome" value={data.nome} onChange={handleInputChange} />
        </FormControl>
      </Grid>
      <Grid item xs={4} />
      <Grid item xs={2} display="flex" justifyContent="flex-end">
        <FormControlLabel control={<Checkbox disabled={readOnly} checked={!!data.flg_genius_machine} onChange={handleInputChange} name="flg_genius_machine" />} label="Genius Machine" />
      </Grid>
      <Grid item xs={2} display="flex" justifyContent="flex-end">
        <FormControlLabel control={<Checkbox disabled={readOnly} checked={!!data.flg_gdpr} onChange={handleInputChange} name="flg_gdpr" />} label="Informativa GDPR" />
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <TextField disabled={readOnly} multiline minRows={3} maxRows={5} label="Descrizione" name="descrizione" value={data.descrizione} onChange={handleInputChange} />
        </FormControl>
      </Grid>
      <Grid item xs={2}>
        <FormControl fullWidth>
          <TextField disabled={readOnly} label="Nome foglio excel" name="nome_foglio_excel" value={data.nome_foglio_excel} onChange={handleInputChange} InputLabelProps={{ shrink: !!data.nome_foglio_excel }} />
        </FormControl>
      </Grid>

      {!readOnly && (
      <Grid item xs={2}>
        <FormControl fullWidth>
          <TextField disabled={readOnly} label="Codice Programma" name="codice" value={data.codice} onChange={handleInputChange} InputLabelProps={{ shrink: !!data.codice }} />
        </FormControl>
      </Grid>
      )}
     
      <Grid item xs={2}>
        <FormControl fullWidth>
          <TextField disabled={readOnly} type="number" label="Durata" name="durata" inputProps={{ style: { textAlign: "right" } }} InputProps={{ endAdornment: <InputAdornment position="end">min</InputAdornment> }} value={data.durata} onChange={(e) => e.target.value >= 0 && handleInputChange(e)} />
        </FormControl>
      </Grid>
      {!readOnly && (
        <Grid item xs={3}>
          <MediaSelect disabled={readOnly} label="Avvertenze" supportedTypes={["PDF"]} currentSelectedMediaId={data.id_media_avvertenze} onSelectCallback={(media) => setData((prevData) => ({ ...prevData, id_media_avvertenze: media?.id }))} />
        </Grid>
      )}
      {!readOnly && (
        <Grid item xs={3}>
          <MediaSelect disabled={readOnly} label="Informativa" supportedTypes={["PDF"]} currentSelectedMediaId={data.id_media_informativa} onSelectCallback={(media) => setData((prevData) => ({ ...prevData, id_media_informativa: media?.id }))} />
        </Grid>
      )}
      {!readOnly && (
        <Grid item xs={4}>
          <MediaSelect disabled={readOnly} label="Immagine" supportedTypes={["IMG"]} currentSelectedMediaId={data.id_media} onSelectCallback={(media) => setData((prevData) => ({ ...prevData, id_media: media?.id }))} />
        </Grid>
      )}
      <Grid item xs={4}>
        <FormControl fullWidth>
          <InputLabel>Intervista</InputLabel>
          <Select disabled={readOnly} label="Intervista" name="id_questionario" value={data.id_questionario} onChange={handleInputChange}>
            {listaQuestionari?.map((questionario, i) => (
              <MenuItem key={i} value={questionario.id}>
                {questionario.titolo}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <FormControl fullWidth>
          <InputLabel>Elaborato</InputLabel>
          <Select disabled={readOnly} label="Elaborato" name="id_referto" value={data.id_referto} onChange={handleInputChange}>
            {listaReferti?.map((referto, i) => (
              <MenuItem key={i} value={referto.id}>
                {referto.nome}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      {!readOnly && (
        <Grid item xs={4}>
          <FormControl fullWidth>
            <InputLabel>Categorie</InputLabel>
            <Select label="Categoria" name="categorie" multiple value={data.categorie} onChange={handleInputChange}>
              {listaCategorie?.map((categoria, i) => (
                <MenuItem key={i} value={categoria.id}>
                  {categoria.nome}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      )}
      {!readOnly && (
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel>Tipologie di Utenti Abilitati alla visione</InputLabel>
            <Select label="Tipologie di Utenti Abilitati alla visione" name="programma_tipologia_utente" multiple value={data.programma_tipologia_utente} onChange={handleInputChange}>
              {utentiTypes?.map((type, i) => (
                <MenuItem key={i} value={type.codice}>
                  {type.descrizione}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      )}
    </>
  );
};

const ProgrammaDetails = ({ setIsEditorOpen, selectedProgramma = false }) => {
  const dispatch = useDispatch();
  const tokenAdmin = useSelector((state) => state.loginadmin.tokenAdmin);
  const { listaCategorie, listaQuestionari, listaReferti } = useSelector((state) => state.dashboardAdmin);
  const [programData, setProgramData] = useState({ nome: "", descrizione: "", durata: "0", flg_genius_machine: false, flg_gdpr: false, categorie: [], id_questionario: "", id_referto: "", id_media: "", id_media_avvertenze: "", id_media_informativa: "", programma_tipologia_utente: [] });
  const [lastPubblicato, setLastPubblicato] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [alertPrezziVis, setAlertPrezziVis] = useState(false);
  const [isCreated, setIsCreated] = useState(false);
  const [errorProgramma, setErrorProgramma] = useState("");

  const [snackPub, setSnackPub] = useState(false);
  const [snackPubDone, setSnackPubDone] = useState(false);

  useEffect(() => {
    if (selectedProgramma?.id) loadProgramma(selectedProgramma.id);

    dispatch(getListaCategorie());
    dispatch(getListaQuestionari());
    dispatch(getListaReferti());
  }, []);

  const loadProgramma = async (id) => {
    try {
      setIsLoading(true);
      let data;
      let categorie = [];
      const response = await getProgramma(id, tokenAdmin);
      data = response.data;
      categorie = data.categorie;
      if (data.bozzaProgramma != null && data.bozza) {
        setLastPubblicato(data);
        data = { ...data, ...data.bozzaProgramma, id: data.id };
      } else setLastPubblicato(null);

      data.categorie = categorie.map((categoria) => categoria.id);
      setProgramData(data);
    } catch (e) {
      dispatch(setSnackbarData({ message: getAPIErrorMessage(e), severity: "error" }));
      onClose()
    } finally {
      setIsLoading(false);
    }
  };

  const handleProgramSubmit = async (pubblica) => {
    let response;
    try {
      setIsLoading(true);
      const data = {
        nome: programData.nome,
        descrizione: programData.descrizione,
        durata: programData.durata,
        flg_genius_machine: programData.flg_genius_machine,
        flg_gdpr: programData.flg_gdpr,
        categorie: programData.categorie,
        programma_tipologia_utente: programData.programma_tipologia_utente,
        id_questionario: programData.id_questionario,
        id_referto: programData.id_referto,
        nome_foglio_excel: programData.nome_foglio_excel,
        codice: programData.codice,
        id_media: programData.id_media,
        id_media_avvertenze: programData.id_media_avvertenze,
        id_media_informativa: programData.id_media_informativa,
        dati_questionario_preview: programData.dati_questionario_preview,
      };
      if (pubblica) {
        response = await pubblicaBozzaProgramma(data, tokenAdmin, programData.id);
      } else {
        if (selectedProgramma || isCreated) {
          response = await modifyProgramma(data, tokenAdmin, programData.id);
        } else {
          response = await creaProgramma(data, tokenAdmin);
          setAlertPrezziVis(true);
        }
      }
    } catch (e) {
      setErrorProgramma(getAPIErrorMessage(e));
      return;
    } finally {
      setIsLoading(false);
    }
    await loadProgramma(response.data.id);
    setIsCreated(true);
    if (!pubblica && (selectedProgramma || isCreated)) setSnackPub(true);
    if (pubblica) setSnackPubDone(true);
  };

  const onScaricaPreview = async (pubblicato) => {
    try {
      setIsLoading(true);
      const response = await scaricaRefertoProgrammaPreview(programData.id, { pubblicato: pubblicato }, tokenAdmin);
      const url = window.URL.createObjectURL(new Blob([response], { type: "application/pdf" }));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "PreviewElaboratoProgramma.pdf");
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      setErrorProgramma(getAPIErrorMessage(e));
      return;
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertPrezziVis(false);
  };

  const handleClosePubbSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackPub(false);
  };

  const handleClosePubbDoneSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackPubDone(false);
  };

  const onClose = () => {
    dispatch(getListaProgrammi());
    setErrorProgramma("");
    setIsEditorOpen(false);
  };

  return (
    <>
      {isLoading && (
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Box sx={{ bgcolor: "#fff", p: 2 }} component={Paper}>
        <Grid container columnSpacing={2} rowSpacing={4} alignItems="center">
          <Grid item xs={selectedProgramma || isCreated ? 6 : 12}>
            <Button startIcon={<ArrowBack />} onClick={onClose}>
              Indietro
            </Button>
          </Grid>
          {(selectedProgramma || isCreated) && (
            <Grid item xs={6} display="flex" justifyContent="flex-end" alignItems="center">
              <CustomTooltip title="Il sistema di bozza/pubblicato è limitato ai campi: Nome, Descrizione, Genius Machine, Nome Foglio Excel, Durata, Intervista e Elaborato" arrow>
                <Info color="primary" sx={{ mr: 1 }} />
              </CustomTooltip>
              {programData.bozza ? (
                <>
                  <Typography variant="m" color="primary.main" sx={{ display: "inline-block" }}>
                    Programma in bozza dal {formatDate(programData.inizio_modifiche_bozza)}
                  </Typography>
                  <Button variant="contained" onClick={async () => handleProgramSubmit(true)} sx={{ display: "inline-block", ml: 2 }}>
                    Pubblica
                  </Button>
                </>
              ) : (
                <Typography variant="m" color="primary.main">
                  Programma pubblicato il {formatDate(programData.ultima_data_pubblicazione)}
                </Typography>
              )}
            </Grid>
          )}
          <Grid item xs={12} sx={{ py: 2 }} display="flex" justifyContent="center">
            <Typography variant="title2">{programData?.id ? "Modifica Programma" : "Aggiungi Programma"}</Typography>
          </Grid>
          <Grid item container columnSpacing={2} rowSpacing={4} xs={12}>
            <GridData data={programData} setData={setProgramData} liste={{ listaCategorie, listaQuestionari, listaReferti }} />
          </Grid>
          {programData?.id && (
            <>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <PrezziProgramma selectedProgramma={programData} setError={setErrorProgramma} setLoading={setIsLoading} />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <Divider />
          </Grid>
          {lastPubblicato && (
            <>
              <Grid item xs={12}>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMore />}>Ultima modifica pubblicata</AccordionSummary>
                  <AccordionDetails>
                    <Grid container columnSpacing={2} rowSpacing={4}>
                      <GridData data={lastPubblicato} readOnly liste={{ listaCategorie, listaQuestionari, listaReferti }} />
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                multiline
                minRows={5}
                maxRows={10}
                label="Dati Questionaro per Preview"
                value={programData.dati_questionario_preview}
                onChange={(e) => {
                  setProgramData((prevData) => ({ ...prevData, dati_questionario_preview: e.target.value }));
                }}
                InputLabelProps={{ shrink: !!programData.dati_questionario_preview }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ display: "flex", mb: 2 }}>
              {(selectedProgramma || isCreated) && (
                <>
                  <Button variant="contained" onClick={() => onScaricaPreview(1)} startIcon={<Download />}>
                    Scarica Elaborato Pubblicato
                  </Button>

                  <Button variant="contained" sx={{ ml: 1 }} onClick={() => onScaricaPreview(0)} startIcon={<Download />}>
                    Scarica Elaborato Bozza
                  </Button>
                </>
              )}
              <Box sx={{ flex: 1 }}></Box>
              <Button variant="contained" onClick={() => handleProgramSubmit(false)} color="primary" sx={{ ml: 2 }} startIcon={<Save />}>
                {selectedProgramma || isCreated ? "Salva come Bozza" : "Crea Programma"}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Snackbar open={alertPrezziVis} autoHideDuration={15000} onClose={handleCloseSnack} anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
        <Paper>
          <Alert onClose={handleCloseSnack} sx={{ width: "100%", alignItems: "center" }}>
            <Typography sx={{ fontFamily: "Poppins-Medium" }}>Programma inserito correttamente, ora puoi aggiungere i prezzi.</Typography>
          </Alert>
        </Paper>
      </Snackbar>

      <Snackbar open={snackPub} autoHideDuration={5000} onClose={handleClosePubbSnack} anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
        <Paper>
          <Alert onClose={handleClosePubbSnack} sx={{ width: "100%", alignItems: "center" }}>
            <Stack direction="row" alignItems="center">
              <Typography sx={{ fontFamily: "Poppins-Medium" }}>Vuoi pubblicare il Programma?</Typography>
              <Button
                variant="contained"
                onClick={async () => {
                  handleProgramSubmit(true);
                  handleClosePubbSnack();
                }}
                sx={{ display: "inline-block", ml: 2 }}
              >
                Pubblica
              </Button>
            </Stack>
          </Alert>
        </Paper>
      </Snackbar>

      <Snackbar open={snackPubDone} autoHideDuration={5000} onClose={handleClosePubbDoneSnack} anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
        <Paper>
          <Alert onClose={handleClosePubbDoneSnack} sx={{ width: "100%", alignItems: "center" }}>
            <Stack direction="row" alignItems="center">
              <Typography sx={{ fontFamily: "Poppins-Medium" }}>Programma Pubblicato</Typography>
            </Stack>
          </Alert>
        </Paper>
      </Snackbar>

      <Dialog open={!!errorProgramma} onClose={() => setErrorProgramma("")} maxWidth="sm" fullWidth>
        <DialogTitle variant="dialogTitle" color="error">
          Attenzione
        </DialogTitle>
        <DialogContent>
          <Typography color="error" style={{ whiteSpace: "pre-line" }}>
            {errorProgramma}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => setErrorProgramma("")}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ProgrammaDetails;
