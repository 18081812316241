import React, { useEffect } from "react";
import { Grid, Box, Typography, Button, useTheme, Table, TableBody, TableCell, TableHead, TableRow, Tabs, Tab, Link, useMediaQuery } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { getDashboardCounter, getListaAbbonamenti, getListaProgrammiAcquistati, getListaProgrammiCodici, getListaPrestazioni } from "./HomepageSlice";
import { useNavigate } from "react-router-dom";
import { Check, Close } from "@mui/icons-material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { setCreaVendiPrestazioneData } from "../programmi/programmiSlice";


export function HomepageProfessionista() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { listaProgrammiAcquistati, listaAbbonamenti, codiciAttivi, abbonamentiInScadenza, abbonamentiAttivi } = useSelector((state) => state.homepage);
  const c = theme.palette;
  const [value, setValue] = React.useState("1");
  const matchMd = useMediaQuery(theme.breakpoints.up('md'));



  function formatDate(inputDateString) {
    const dateObject = new Date(inputDateString);

    const day = dateObject.getDate();
    const month = dateObject.getMonth() + 1; // Mese inizia da 0, quindi aggiungi 1
    const year = dateObject.getFullYear();

    // Aggiungi zeri se necessario
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;

    // Formatta la data nel formato desiderato
    const formattedDate = `${formattedDay}/${formattedMonth}/${year}`;

    return formattedDate;
  }

  function formatDateYYYYMMGG(inputDateString) {
    // Creazione di un oggetto Date dalla stringa
    var data = new Date(inputDateString);

    // Ottenere il giorno, il mese e l'anno dalla data
    var giorno = data.getDate();
    var mese = data.getMonth() + 1; // Mese inizia da zero, quindi aggiungi 1
    var anno = data.getFullYear();

    // Aggiungere zero davanti a giorno e mese se sono inferiori a 10
    giorno = giorno < 10 ? "0" + giorno : giorno;
    mese = mese < 10 ? "0" + mese : mese;

    // Creare la stringa nel formato desiderato
    return giorno + "/" + mese + "/" + anno;
  }

  useEffect(() => {
    dispatch(getListaProgrammiCodici());
    dispatch(getListaProgrammiAcquistati());
    dispatch(getListaAbbonamenti());
    dispatch(getListaPrestazioni());
    dispatch(getDashboardCounter());
  }, []);


  const avviaProgrammaTableStyle = {
    backgroundColor: c.secondary.main,
    color: c.primary.light,
    "&:hover": {
      backgroundColor: c.secondary.light,
      color: c.primary.dark,
    },
  };

  const acquistaProgrammaTableStyle = {
    marginRight: "5%",
    backgroundColor: c.secondary.light,
    color: c.primary.dark,
    "&:hover": {
      backgroundColor: c.secondary.main,
      color: c.primary.light,
    },
  };


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onAvviaClickedProgramma = (programma) => {
    dispatch(setCreaVendiPrestazioneData({ isVendita: false, tipoAttivazione: "C", codiciServizio: [programma.codice_servizio], idProgramma: programma.programma.id }));
  };

  const onAvviaClickedProgrammaAbbonamento = (programma) => {
    dispatch(setCreaVendiPrestazioneData({ isVendita: false, tipoAttivazione: "A", codiciServizio: [], idProgramma: programma.id }));
  };


  const headCells = [
    { id: "programma", label: "Programma" },
    { id: "codice", label: "Codice" },
    { id: "data_acquisto", label: "Data Acquisto" },
    { id: "attivo", label: "Attivo" },
    { id: "venduto", label: "Ceduto" },
    { id: "azioni", label: "" },
  ];



  const headCellsAbbonamenti = [
    { id: "nome", label: "Abbonamento" },
    { id: "tipo_abbonamento", label: "Tipo" },
    { id: "data_inizio", label: "Inizio" },
    { id: "data_fine", label: "Fine" },
    { id: "limite", label: "Limite" },
    { id: "numero_prestazioni_effettuate", label: "Prestazioni Effettuate" },
    { id: "azioni", label: "" },
  ];


  return (
    <Grid container sx={paginaStyle}>
      {/* PARTE SINISTRA */}
      <Grid item xs={12} md={6}>
        <Box sx={{ ...boxStyle, display: 'flex', flexDirection: 'column' }} >
          <Typography sx={{ ...titoloStyle, fontSize: '1.2rem' }}>Qui puoi avviare o cedere un programma.</Typography>

          <Typography sx={{ ...testoInferioreStyle, fontSize: '1.1rem', py: 2 }}>Scegli il programma che vuoi avviare. Premi il pulsante “AVVIA”. </Typography>

          <Typography sx={{ ...testoInferioreStyle, fontSize: '1.1rem', py: 2 }}>
            All'apertura del form inserisci i dati del tuo cliente.<br />
            Se il tuo cliente è già registrato ricercalo con il codice utente.<br />
            I programmi possono essere avviati anche dalla pagina <Link sx={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={() => { navigate('/tommy/programmi', { state: { tabTarget: "0" } }) }}>i miei programmi</Link>.
          </Typography>

          <Typography sx={{ ...testoInferioreStyle, fontSize: '1.1rem', py: 2 }}>
            Per trasferire un programma a un tuo cliente premi il pulsante “CEDI”. <br />
            NB. Per <b>cedere</b> un programma o delle unità audio il cliente deve essere precedentemente registrato nell'area utente privato.
          </Typography>

        </Box>
      </Grid>
      {/* PARTE DESTRA */}
      <Grid item xs={12} md={6}>
        <Box sx={{ ...boxStyle, height: 'auto' }}>
          <Tabs value={value} onChange={handleChange}>
            <Tab value="1" label="Programmi" />
            <Tab value="2" label="Abbonamenti" />
          </Tabs>
          {value === "1" && (
            <>
              <br></br>
              <Typography sx={titoloStyle}>I tuoi ultimi programmi Acquistati</Typography>
              <Typography sx={sottotitoloStyle}>N. Programmi Attivi</Typography>
              <Typography sx={programmiAttiviStyle}>{codiciAttivi}</Typography>
              {/* <Typography sx={programmiScadenzaStyle}>
                  ci sono <span style={{ fontWeight: 'bold' }}>{abbonamentiInScadenza}</span> programmi in scadenza
                </Typography> */}
              <Typography sx={testoInferioreBoldStyle} style={{ marginTop: "2%" }}>
                Storico Programmi:
              </Typography>

              <div className="scrollDashboard" style={{ maxHeight: "65%", overflowY: "auto" }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {headCells.map((headCell) => (
                        <TableCell key={headCell.id} style={{ textAlign: headCell.id === "programma" ? "left" : "center", width: "auto", color: "rgb(124,124,124)", fontSize: "0.8rem" }}>
                          {headCell.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {listaProgrammiAcquistati &&
                      listaProgrammiAcquistati.map((prog, index) => (
                        <TableRow key={prog.id} style={{ backgroundColor: index % 2 === 0 ? "white" : "rgb(244,242,242)" }}>
                          <TableCell style={{ textAlign: "left", width: "auto" }}>
                            <span style={{ fontWeight: "bold" }}>{prog.programma.nome}</span>
                          </TableCell>
                          <TableCell style={{ textAlign: "center", width: "auto" }}>
                            <span style={{ fontWeight: "bold" }}>{prog.codice_servizio}</span>
                          </TableCell>
                          <TableCell style={{ textAlign: "center", width: "auto" }}>{formatDate(prog.created_at)}</TableCell>
                          <TableCell style={{ textAlign: "center", width: "auto" }}>{prog.venduto ? "-" : prog.attivo ? <Check sx={{ color: "#255300" }} /> : <Close sx={{ color: "#D32F2F" }} />}</TableCell>
                          <TableCell style={{ textAlign: "center", width: "auto" }}>{prog.venduto ? <Check sx={{ color: "#255300" }} /> : "-"}</TableCell>
                          <TableCell style={{ textAlign: "left", width: "auto" }}>
                            <div style={{ display: "flex", alignItems: "center" }}>
                              <Button variant="outlined" sx={acquistaProgrammaTableStyle} onClick={() => navigate("/tommy/ecommerce", { state: { categoria: prog.programma.categorie[0] } })}>
                                ACQUISTA
                              </Button>
                              {prog.attivo === 1 && !prog.venduto ? (
                                <Button variant="contained" sx={avviaProgrammaTableStyle} onClick={() => onAvviaClickedProgramma(prog)}>
                                  AVVIA
                                </Button>
                              ) : (
                                ""
                              )}
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </div>
            </>
          )}

          {value === "2" && (
            <>
              <br></br>
              <Typography sx={titoloStyle}>I tuoi ultimi abbonamenti Acquistati</Typography>
              <Typography sx={sottotitoloStyle}>N. Abbonamenti Attivi</Typography>
              <Typography sx={programmiAttiviStyle}>{abbonamentiAttivi}</Typography>
              {abbonamentiInScadenza > 0 && (
                <Typography sx={programmiScadenzaStyle}>
                  ci sono <span style={{ fontWeight: "bold" }}>{abbonamentiInScadenza}</span> abbonamenti in scadenza
                </Typography>
              )}

              <Typography sx={testoInferioreBoldStyle} style={{ marginTop: "2%" }}>
                Storico Abbonamenti:
              </Typography>
              <div className="scrollDashboard" style={{ maxHeight: "40%", overflowY: "auto" }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {headCellsAbbonamenti.map((headCell) => (
                        <TableCell key={headCell.id} style={{ textAlign: headCell.id === "abbonamento" ? "left" : "center", width: "auto", color: "rgb(124,124,124)", fontSize: "0.8rem" }}>
                          {headCell.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {listaAbbonamenti &&
                      listaAbbonamenti.map((abb, index) => (
                        <TableRow key={abb.id} style={{ backgroundColor: index % 2 === 0 ? "white" : "rgb(244,242,242)" }}>
                          <TableCell style={{ textAlign: "left", width: "auto" }}>
                            <span style={{ fontWeight: "bold" }}>{abb.programma.nome}</span>
                          </TableCell>
                          <TableCell style={{ textAlign: "center", width: "auto" }}>{abb.tipo_abbonamento}</TableCell>
                          <TableCell style={{ textAlign: "center", width: "auto" }}>{formatDateYYYYMMGG(abb.data_inizio)}</TableCell>
                          <TableCell style={{ textAlign: "center", width: "auto" }}>{formatDateYYYYMMGG(abb.data_fine)}</TableCell>
                          <TableCell style={{ textAlign: "center", width: "auto" }}>{abb.limite}</TableCell>
                          <TableCell style={{ textAlign: "center", width: "auto" }}>{abb.numero_prestazioni_effettuate}</TableCell>
                          <TableCell style={{ textAlign: "left", width: "auto" }}>
                            <div style={{ display: "flex", alignItems: "center" }}>
                              {abb.programma.flg_active === 1 ? (
                                <Button
                                  variant="contained"
                                  sx={avviaProgrammaTableStyle}
                                  onClick={() => onAvviaClickedProgrammaAbbonamento(abb.programma)}
                                >
                                  AVVIA
                                </Button>
                              ) : (
                                ""
                              )}
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </div>
            </>
          )}
        </Box>
      </Grid>
    </Grid>
  );
}

const boxStyle = {
  marginTop: "5%",
  marginLeft: "2%",
  marginRight: "2%",
  padding: "2%",
  width: "96%",
  backgroundColor: "white",
  position: "relative",
};


const titoloStyle = {
  color: "#255300",
  fontSize: "1.1rem",
  fontWeight: "bold",
};

const sottotitoloStyle = {
  color: "#255300",
  fontSize: "1rem",
  fontWeight: "bold",
  marginTop: "2%",
};

const programmiAttiviStyle = {
  color: "black",
  fontSize: "1.7rem",
  fontWeight: "bold",
  marginTop: "1%",
};

const programmiScadenzaStyle = {
  color: "black",
  fontSize: "0.8rem",
  fontWeight: "medium",
};


const testoInferioreStyle = {
  color: "rgb(124,124,124)",
  fontSize: "1rem",
  marginTop: "1%",
};

const testoInferioreBoldStyle = {
  color: "black",
  fontSize: "0.9rem",
  fontWeight: "bold",
  marginTop: "8%",
};

const paginaStyle = {
  backgroundColor: "rgb(248, 248, 248)",
};

