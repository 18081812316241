import React, { useEffect } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, Grid, Stack, TextField, Typography, DialogTitle, Divider, FormControl, InputAdornment, IconButton } from "@mui/material";
import { Add, Clear, Euro } from "@mui/icons-material";
import { creaPrezzi, modifyPrezzi as modifyPrezziApi } from "../../dashboardAdminAPI";
import { useSelector } from "react-redux";
import { getAPIErrorMessage } from "../../../../common/utils";

const initialPrezzi = {
  singolo: null,
  range: [],
  abbonamento_settimanale: {
    prezzo: null,
    limite: null,
  },
  abbonamento_mensile: {
    prezzo: null,
    limite: null,
  },
  abbonamento_trimestrale: {
    prezzo: null,
    limite: null,
  },
  abbonamento_semestrale: {
    prezzo: null,
    limite: null,
  },
  abbonamento_annuale: {
    prezzo: null,
    limite: null,
  },
  abbonamento_vita: {
    prezzo: null,
  },
};

const abbonamenti_keys = [
  'abbonamento_settimanale', 'abbonamento_mensile', 'abbonamento_trimestrale', 'abbonamento_semestrale', 'abbonamento_annuale', 'abbonamento_vita'
]

const DialogSetPrezzi = ({ open, setOpen, data }) => {
  const { load, abbonamenti } = data;

  const tokenAdmin = useSelector((state) => state.loginadmin.tokenAdmin);
  const [prezzi, setPrezzi] = React.useState(initialPrezzi);
  const [errorPrezzi, setErrorPrezzi] = React.useState("");

  useEffect(() => {
    setPrezzi(open?.id ? open : JSON.parse(JSON.stringify(initialPrezzi)));
  }, []);

  const handleClose = () => {
    setPrezzi(initialPrezzi);
    setErrorPrezzi("");
    setOpen(false);
  };

  function clearNullKeys(obj) {
    if (typeof obj !== "object" || obj === null) {
      return obj;
    }
    const cleanedObj = {};

    for (const [key, value] of Object.entries(obj)) {
      if (typeof value === "object" && value !== null) {
        const cleanedValue = clearNullKeys(value);
        if (cleanedValue !== null && !(Array.isArray(cleanedValue) && cleanedValue.length === 0) && Object.keys(cleanedValue).length !== 0) {
          cleanedObj[key] = cleanedValue;
        }
      } else if (value !== null) {
        cleanedObj[key] = value;
      }
    }

    return cleanedObj;
  }

  const handleSubmitPrezzi = async () => {
    const _prezzi = {...prezzi};//rimuovere prezzi a null o vuoti
    for (const key of abbonamenti_keys) {
      if (_prezzi[key] && (!_prezzi[key].prezzo || _prezzi[key].prezzo == '')) {
        delete _prezzi[key];
      }
    }
    try {
      if (prezzi.id) {
        await modifyPrezziApi(open.id_programma, prezzi.id, clearNullKeys({ ..._prezzi, tipologia_utente: open.tipologia_utente }), tokenAdmin);
      } else {
        await creaPrezzi(open.id_programma, clearNullKeys({ ...prezzi, tipologia_utente: open.tipologia_utente }), tokenAdmin);
      }
    } catch (e) {
      let err = getAPIErrorMessage(e);
      if (e.response?.data?.message) {
        err = e.response.data.message;
        if (Array.isArray(err)) {
          err = err
            .map((str) => {
              let errStr = str.split("'");
              let field = errStr[1].split(".");
              if (field.length > 1 && field[0] === 'range'){
                field = [` ${field[2]} del ${field[0]} n.${parseInt(field[1]) + 1}`];
              }else if (field.length > 1) {
                field = [`${field[1]} del ${field[0]}`];
              }
              
              errStr[1] = field[0];
              errStr[0] = "Il campo ";

              return errStr.join("");
            })
            .join("\n");
        }
      }
      setErrorPrezzi(err);

      return;
    }
    load();
    handleClose();
  };

  const handleInputChange = ({ event, i = -1 } = {}) => {
    const { value, name } = event.target;

    if (i === -1) setPrezzi({ ...prezzi, [name]: value });
    else if (typeof i === "string") setPrezzi({ ...prezzi, [i]: { ...prezzi[i], [name]: value } });
    else {
      setPrezzi((prev) => {
        let updPrezzi = { ...prev };
        updPrezzi.range[i][name] = value;
        return updPrezzi;
      });
    }
  };

  const handleAddRange = () => {
    setPrezzi((prev) => {
      const newPrezzi = { ...prev };
      if (!newPrezzi.range) newPrezzi.range = [];
      newPrezzi.range.push({ quantita: "", prezzo: "" });
      return newPrezzi;
    });
  };

  const handleRemoveRange = (i) => {
    setPrezzi((prev) => {
      const newPrezzi = { ...prev };
      newPrezzi.range.splice(i, 1);
      return newPrezzi;
    });
  };

  return (
    <Dialog open={prezzi !== false} maxWidth="sm" fullWidth>
      <DialogTitle variant="dialogTitle">Imposta i prezzi per {prezzi.tipologia_utente === "P" ? "gli Utenti Privati" : prezzi.tipologia_utente === "PA" ? "i Professionisti Attivi" : "i Professionisti Passivi"}</DialogTitle>
      <DialogContent>
        <Box sx={{ my: 2 }}>
          <FormControl>
            <TextField
              type="number"
              label="Prezzo Cadauno"
              name="singolo"
              value={prezzi.singolo || ""}
              onChange={(event) => handleInputChange({ event })}
              InputProps={{
                inputProps: { style: { textAlign: "right" }, min: 0 },
                endAdornment: (
                  <InputAdornment position="end">
                    <Euro />
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
          <Divider sx={{ my: 2 }} textAlign="left">
            Prezzi a Range
          </Divider>
          {prezzi?.range?.map((r, i) => {
            const inputChange = (event) => handleInputChange({ event, i });

            return (
              <Stack key={i} direction="row" alignItems="center" sx={{ my: 1, mb: i === prezzi.range.length - 1 ? 2 : 1 }}>
                <Box flex={1}>
                  <TextField type="number" label="Quantità Minima" name="quantita" value={r.quantita || ""} onChange={inputChange} InputProps={{ inputProps: { min: 0 } }} />
                </Box>
                <Box flex={1}>
                  <TextField
                    type="number"
                    label="Prezzo Cadauno"
                    name="prezzo"
                    value={r.prezzo || ""}
                    onChange={inputChange}
                    InputProps={{
                      inputProps: { style: { textAlign: "right" }, min: 0 },
                      endAdornment: (
                        <InputAdornment position="end">
                          <Euro />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <IconButton onClick={() => handleRemoveRange(i)} sx={{ ml: 1 }}>
                  <Clear />
                </IconButton>
              </Stack>
            );
          })}
          <Stack alignItems="flex-end">
            <Button variant="outlined" endIcon={<Add />} onClick={handleAddRange}>
              Aggiungi Range
            </Button>
          </Stack>
          <Divider sx={{ my: 2 }} textAlign="left">
            Prezzi Abbonamenti
          </Divider>

          {abbonamenti.map((abb, i) => {
            const inputChange = (event) => handleInputChange({ event, i: abb.key });
            return (
              <Grid container key={i} sx={{ my: 1 }} alignItems={"center"} spacing={1}>
                <Grid item xs={4}>
                  <Typography variant="m">{abb.label}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    type="number"
                    label="Prezzo"
                    name="prezzo"
                    value={prezzi[abb.key]?.prezzo || ""}
                    onChange={inputChange}
                    InputProps={{
                      inputProps: { style: { textAlign: "right" }, min: 0 },
                      endAdornment: (
                        <InputAdornment position="end">
                          <Euro />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  {initialPrezzi[abb.key]?.hasOwnProperty("limite") && prezzi[abb.key]?.prezzo > 0 && <TextField type="number" label="Limite Utilizzo" name="limite" value={prezzi[abb.key]?.limite || ""} onChange={inputChange} InputProps={{ inputProps: { min: 0 } }} />}
                </Grid>
              </Grid>
            );
          })}
        </Box>
        <Stack direction="row" spacing={2}></Stack>

        {
          <Typography color="error" align="center" style={{ whiteSpace: "pre-line" }}>
            {errorPrezzi}
          </Typography>
        }
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} sx={{ color: "#000" }}>
          Annulla
        </Button>
        <Button variant="contained" disabled={!prezzi} onClick={handleSubmitPrezzi}>
          CONFERMA
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogSetPrezzi;
