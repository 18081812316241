import api from '../../config/axiosConfig';



export const creaConfigurazioneExcel = async (formData, token) => {
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: "Bearer " + token,
        },
        timeout: 180000, // 3 minutes
    };
    const response = await api.post('adm/configurazione-excel', formData, config);
    return response.data;
}

export const creaCodiciRivenditori = async (formData, token) => {
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: "Bearer " + token,
        },
        timeout: 180000, // 3 minutes
    };
    const response = await api.post('adm/codici-rivenditori', formData, config);
    return response.data;
}

export const creaAutorizzazioniAcquisto = async (formData, token) => {
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: "Bearer " + token,
        },
        timeout: 180000, // 3 minutes
    };
    const response = await api.post('adm/autorizzazioni-acquisto', formData, config);
    return response.data;
}

export const pubblicaExcel = async (id, token) => {
    const config = {
        headers: {
            Authorization: "Bearer " + token,
        },
    };
    const response = await api.post('adm/configurazione-excel/' + id + '/pubblica', {}, config);
    return response.data;
}

export const pubblicaTutto = async (token) => {
    const config = {
        headers: {
            Authorization: "Bearer " + token,
        },
    };
    const response = await api.post('adm/pubblicaTutto', {}, config);
    return response.data;
}


export const scaricaConfigurazioneExcel = async (id, token) => {
    const config = {
        headers: {
            Authorization: "Bearer " + token,
        },
        responseType: 'arraybuffer'
    };
    const response = await api.get('adm/configurazione-excel/' + id + '/scarica', config);
    return response.data;
}


export const creaMedia = async (formData, token) => {
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: "Bearer " + token,
        },
    };
    const response = await api.post('adm/media', formData, config);
    return response.data;
}

export const aggiornaMedia = async (id, formData, token) => {
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: "Bearer " + token,
        },
    };
    const response = await api.post('adm/media/' + id + '/update', formData, config);
    return response.data;
}


export const deleteMedia = async (id, token) => {
    const config = {
        headers: {
            Authorization: "Bearer " + token,
        },
    };
    const response = await api.delete('adm/media/' + id, config);
    return response.data;
}

export const deleteProgramma = async (id, token) => {
    const config = {
        headers: {
            Authorization: "Bearer " + token,
        },
    };
    const response = await api.delete('/adm/programmi/' + id, config);
    return response.data;
}

export const creaProgramma = async (data, token) => {
    const config = {
        headers: {
            Authorization: "Bearer " + token,
        },
    };

    const response = await api.post('/adm/programmi', data, config);
    return response.data;
}

export const modifyProgramma = async (data, token, id) => {
    const config = {
        headers: {
            Authorization: "Bearer " + token,
        },
    };

    const response = await api.put('/adm/programmi/' + id, data, config);
    return response.data;
}

export const pubblicaBozzaProgramma = async (data, token, id) => {
    const config = {
        headers: {
            Authorization: "Bearer " + token,
        },
    };

    const response = await api.post('/adm/programmi/' + id + '/pubblicaBozza', data, config);
    return response.data;
}

export const pubblicaBozzaQuestionario = async (data, token, id) => {
    const config = {
        headers: {
            Authorization: "Bearer " + token,
        },
    };

    const response = await api.post('/adm/questionari/' + id + '/pubblicaBozza', data, config);
    return response.data;
}

export const pubblicaBozzaReferto = async (data, token, id) => {
    const config = {
        headers: {
            Authorization: "Bearer " + token,
        },
    };

    const response = await api.post('/adm/referti/' + id + '/pubblicaBozza', data, config);
    return response.data;
}

export const scaricaRefertoPreview = async (id, data, token) => {
    const config = {
        headers: {
            Authorization: "Bearer " + token,
        },
        responseType: 'arraybuffer'
    };
    const queryParams = new URLSearchParams(data);
    const response = await api.get('/adm/referti/' + id + '/previewReferto?' + queryParams, config);
    return response.data;
}

export const scaricaRefertoProgrammaPreview = async (id, data, token) => {
    const config = {
        headers: {
            Authorization: "Bearer " + token,
        },
        responseType: 'arraybuffer'
    };
    const queryParams = new URLSearchParams(data);
    const response = await api.get('/adm/programmi/' + id + '/previewRefertoProgramma?' + queryParams, config);
    return response.data;
}



export const creaCategoria = async (data, token) => {
    const config = {
        headers: {
            Authorization: "Bearer " + token,
        },
    };

    const response = await api.post('/adm/categorie', data, config);
    return response.data;
}

export const creaCategoriaAudio = async (data, token) => {
    const config = {
        headers: {
            Authorization: "Bearer " + token,
        },
    };

    const response = await api.post('/adm/categorie-audio', data, config);
    return response.data;
}


export const deleteCategoria = async (id, token) => {
    const config = {
        headers: {
            Authorization: "Bearer " + token,
        },
    };
    const response = await api.delete('/adm/categorie/' + id, config);
    return response.data;
}

export const getPrezzi = async (id, token) => {
    const config = {
        headers: {
            Authorization: "Bearer " + token,
        },
    };
    const response = await api.get('/adm/programmi/' + id + '/prezzi', config);
    return response.data;
}

export const creaPrezzi = async (id_programma, data, token) => {
    const config = {
        headers: {
            Authorization: "Bearer " + token,
        },
    };
    const response = await api.post('/adm/programmi/' + id_programma + '/prezzi', data, config);
    return response.data;
}

export const modifyPrezzi = async (id_programma, id, data, token) => {
    const config = {
        headers: {
            Authorization: "Bearer " + token,
        },
    };
    const response = await api.put('/adm/programmi/' + id_programma + '/prezzi/' + id, data, config);
    return response;
}

export const enableProgramma = async (data, token, id) => {
    const config = {
        headers: {
            Authorization: "Bearer " + token,
        },
    };

    const response = await api.post('/adm/programmi/' + id + '/enable', data, config);
    return response.data;
}


export const creaQuestionario = async (data, token) => {
    const config = {
        headers: {
            Authorization: "Bearer " + token,
        },
    };
    const response = await api.post('/adm/questionari', data, config);
    return response.data;

}


export const getQuestionario = async (id, token) => {
    const config = {
        headers: {
            Authorization: "Bearer " + token,
        },
    };
    const response = await api.get('/adm/questionari/' + id, config);
    return response.data;
}

export const getAudio = async (id, token) => {
    const config = {
        headers: {
            Authorization: "Bearer " + token,
        },
    };
    const response = await api.get('/adm/audio/' + id, config);
    return response.data;
}

export const creaAudio = async (data, token) => {
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: "Bearer " + token,
        },
    };
    const response = await api.post('/adm/audio', data, config);
    return response.data;
}

export const modifyAudio = async (data, token, id) => {
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: "Bearer " + token,
        },
    };
    const response = await api.post('/adm/audio/' + id + '/update', data, config);
    return response.data;
}



export const getProgramma = async (id, token) => {
    const config = {
        headers: {
            Authorization: "Bearer " + token,
        },
    };
    const response = await api.get('/adm/programmi/' + id, config);
    return response.data;
}

export const modifyQuestionario = async (data, token, id) => {
    const config = {
        headers: {
            Authorization: "Bearer " + token,
        },
    };
    const response = await api.put('/adm/questionari/' + id, data, config);
    return response.data;

}

export const deleteQuestionario = async (id, token) => {
    const config = {
        headers: {
            Authorization: "Bearer " + token,
        },
    };
    const response = await api.delete('/adm/questionari/' + id, config);
    return response.data;
}

export const modifyCategoria = async (data, token, id) => {
    const config = {
        headers: {
            Authorization: "Bearer " + token,
        },
    };

    const response = await api.put('/adm/categorie/' + id, data, config);
    return response.data;
}

export const modifyCategoriaAudio = async (data, token, id) => {
    const config = {
        headers: {
            Authorization: "Bearer " + token,
        },
    };

    const response = await api.put('/adm/categorie-audio/' + id, data, config);
    return response.data;
}

export const creaReferto = async (data, token) => {
    const config = {
        headers: {
            Authorization: "Bearer " + token,
        },
    };
    const response = await api.post('adm/referti', data, config);
    return response.data;
}

export const aggiornaReferto = async (data, token, id) => {
    const config = {
        headers: {
            Authorization: "Bearer " + token,
        },
    };
    const response = await api.put('/adm/referti/' + id, data, config);
    return response.data;
}


export const deleteReferto = async (id, token) => {
    const config = {
        headers: {
            Authorization: "Bearer " + token,
        },
    };
    const response = await api.delete('adm/referti/' + id, config);
    return response.data;
}

export const getReferto = async (id, token) => {
    const config = {
        headers: {
            Authorization: "Bearer " + token,
        },
    };
    const response = await api.get('/adm/referti/' + id, config);
    return response.data;
}

export const exportOrdini = async (data, token) => {
    const config = {
        headers: {
            Authorization: "Bearer " + token,
        },
        responseType: 'arraybuffer'
    };
    const response = await api.post('/adm/ordine/exportOrdini', data, config);
    return response.data;
}

export const exportTecnica = async (id, token) => {
    const config = {
        headers: {
            Authorization: "Bearer " + token,
        },
        responseType: 'arraybuffer'
    };
    const response = await api.post('/adm/tecnicaabs/' + id + "/export", {}, config);
    return response.data;
}

export const updateUtente = async (id, data, token) => {
    const config = {
        headers: {
            Authorization: "Bearer " + token,
        },
    };
    const response = await api.put('/adm/utenti/' + id, data, config);
    return response.data;
}


export const regalaCodici = async (data, token) => {
    const config = {
        headers: {
            Authorization: "Bearer " + token,
        },
    };
    const response = await api.post('/adm/programma/regalaCodici', data, config);
    return response.data;
}

export const regalaBrani = async (data, token) => {
    const config = {
        headers: {
            Authorization: "Bearer " + token,
        },
    };
    const response = await api.post('/adm/brani/regalaBrani', data, config);
    return response.data;
}

export const getConfigurazione = async (token) => {
    const config = {
        headers: {
            Authorization: "Bearer " + token,
        },
    };
    const response = await api.get('/adm/configurazione', config);
    return response.data;
}

export const saveConfigurazione = async (data, token) => {
    const config = {
        headers: {
            Authorization: "Bearer " + token,
        },
    };
    const response = await api.post('/adm/configurazione', data, config);
    return response.data;
}

export const getTecnica = async (id, token) => {
    const config = {
        headers: {
            Authorization: "Bearer " + token,
        },
    };
    const response = await api.get('/adm/abs/' + id, config);
    return response.data;
}

export const modifyTecnica = async (id, data, token) => {
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: "Bearer " + token,
        },
    };

    const response = await api.post('/adm/tecnicaabs/' + id + '/salva', data, config);
    return response.data;
}

export const deleteUtente = async (id, token) => {
    const config = {
        headers: {
            Authorization: "Bearer " + token,
        },
    };
    const response = await api.delete('/adm/utenti/' + id, config);
    return response.data;
}