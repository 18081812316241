import { Search } from "@mui/icons-material";
import { TextField, InputAdornment, TableSortLabel, Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography, useTheme, useMediaQuery } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

export default function PaginateTable(props) {
  const { pageInfo, data, cols, f, emptyTable, height } = props;
  const dispatch = useDispatch();
  const divRef = useRef(null);
  const divPagRef = useRef(null);
  const [gridH, setGridH] = useState(null);
  const [orderBy, setOrderBy] = useState(null);
  const [order, setOrder] = useState("asc");
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const theme = useTheme();
  const matchSm = useMediaQuery(theme.breakpoints.up("sm"));

  useEffect(() => {
    const calculateDistance = () => {
      const element = divRef.current;
      if (element) setGridH(window.innerHeight - element.getBoundingClientRect().top);
    };
    calculateDistance();

    window.addEventListener("resize", calculateDistance);
    return () => {
      window.removeEventListener("resize", calculateDistance);
    };
  }, []);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    const newSort = isAsc ? "desc" : "asc";
    setOrder(newSort);
    setOrderBy(property);

    dispatch(f({ params: { page: page + 1, sort: newSort, order_by: property, search: search } }));
  };

  const handleRequestSearch = (event) => {
    setPage(0);
    dispatch(f({ params: { sort: order, order_by: orderBy, search: event.target.value } }));
  };

  return (
    <Paper ref={divRef} sx={{ width: "100%", overflow: "hidden", maxHeight: height ? height : gridH - 16 || (window.innerHeight / 3) * 1.95 }}>
      <TableContainer sx={{ maxHeight: height ? height : (gridH - 16 || (window.innerHeight / 3) * 1.95) - (divPagRef.current?.getBoundingClientRect().height || 50) }}>
        <TextField
          sx={{ m: 2 }}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          // value={search[scope]}
          // onChange={(e) => setSearch({ ...search, [scope]: e.target.value })}
          label="Cerca"
          size="small"
          onKeyDown={(e) => e.key === "Enter" && handleRequestSearch(e)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            ),
          }}
        />
        <Table stickyHeader size={matchSm ? 'medium' : 'small'}>
          <TableHead>
            <TableRow>
              {cols.map((col, index) => (
                <TableCell key={index} sortDirection={orderBy === col.id ? order : false}>
                  {(col.sortable === undefined || col.sortable === true) && (
                    <TableSortLabel active={orderBy === col.id} direction={orderBy === col.id ? order : "asc"} onClick={() => handleRequestSort(col.id)}>
                      {col.label}
                    </TableSortLabel>
                  )}
                  {col.sortable === false && <span>{col.label}</span>}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.length > 0 &&
              data.map((row) => (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                  {cols.map((col) => (
                    <TableCell key={col.id + col.label} align={col.align}>
                      {col.format ? col.format(row[col.id], row) : row[col.id]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {(!data || (data && data.length === 0)) && (
        <Box sx={{ m: 2 }}>
          <Typography>{emptyTable}</Typography>
        </Box>
      )}
      {
        <TablePagination
          ref={divPagRef}
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={pageInfo?.total || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={async (_, newPage) => {
            dispatch(f({ params: { page: newPage + 1, sort: order, order_by: orderBy, search: search } }));
            setPage(newPage);
          }}
          onRowsPerPageChange={(event) => {
            dispatch(f({ params: { per_page: +event.target.value, sort: order, order_by: orderBy, search: search } }));
            setRowsPerPage(+event.target.value);
            setPage(0);
          }}
          labelRowsPerPage="Righe"
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} di ${count}`}
        />
      }
    </Paper>
  );
}
