import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getStoricoAbbonamenti } from "./programmiSlice";
import { Box, Stack, Typography } from "@mui/material";
import PaginateTable from "./components/PaginateTable";
import { formatDate } from "../../common/utils";
import Loading from "../Loading";
import { Footer } from "../homepage/Footer";

export function StoricoAbbonamenti() {
  const dispatch = useDispatch();
  const listaStoricoAbbonamenti = useSelector((state) => state.programmi.listaStoricoAbbonamenti);
  const pageInfoStoricoAbbonamenti = useSelector((state) => state.programmi.pageInfoStoricoAbbonamenti);
  const loadingStoricoAbbonamenti = useSelector((state) => state.programmi.isLoadingStoricoAbbonamenti);
  const [screenH, setScreenH] = useState(0);
  const screenRef = React.useRef();

  const handleResize = () => {
    if (screenRef.current) {
      setScreenH(window.innerHeight - screenRef.current.getBoundingClientRect().top);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [screenRef]);

  useEffect(() => {
    dispatch(getStoricoAbbonamenti());
  }, []);

  const abb_types = { SETT: "Settimanale", MENS: "Mensile", TRIM: "Trimestrale", SEM: "Semestrale", ANNUAL: "Annuale", VITA: "A Vita", CUST: 'Personalizzato' };



  return (
    <Box style={{ display: "flex", height: screenH, flexDirection: "column", overflowY:'auto' }} ref={screenRef}>
      <Box sx={{ px: 2, py: 2, flex: 1 }}>
        <Stack sx={{ flexDirection: "row", alignItems: "center", mb: 2 }}>
          <Typography variant="title" color="secondary">
            Storico Abbonamenti
          </Typography>
        </Stack>
        <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 2 }}>
          {loadingStoricoAbbonamenti && <Loading />}
          <PaginateTable
            height='auto'
            index={3}
            emptyTable="Nessun abbonamento presente"
            data={listaStoricoAbbonamenti}
            pageInfo={pageInfoStoricoAbbonamenti}
            f={getStoricoAbbonamenti}
            cols={[
              { id: "programma", label: "Programma", format: (value) => value.nome },
              { id: 'tipo_abbonamento', label: "Tipo Abbonamento", format: (value) => abb_types[value] },
              { id: "data_inizio", label: "Data Inizio", format: (value) => formatDate(value) },
              { id: "data_fine", label: "Data Fine", format: (value) => value != null ? formatDate(value) : '-' },
              { id: "numero_prestazioni_effettuate", label: "Prestazioni Effettuate" },
            ]}
          />
        </Box>
      </Box>

      <Footer />


    </Box>
  );
}
