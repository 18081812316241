import axios from "axios";
import { CONFIG } from "./envConfig";

let dispatch;

var instance = axios.create({
  baseURL: CONFIG.TOMMY_API_URL,
  timeout: 120000, // 2 minutes
});


instance.interceptors.response.use(
  (response) => response,
  (error) => {
    // if (error.response?.status === 401) {
    //   console.log("USER_LOGOUT");
    //   dispatch({ type: "USER_LOGOUT" });
    // }
    return Promise.reject(error);
  }
);

instance.setDispatch = (dispatchFunc) => {
  dispatch = dispatchFunc;
};

export default instance;
