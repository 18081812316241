import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  TextField,
  FormControl,
  CircularProgress,
  Grid,
  Backdrop,
  Paper,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { exportTecnica, getTecnica, modifyTecnica } from "../../dashboardAdminAPI";
import { setSnackbarData, getListaTecniche, getConteggioTecnicheDaSvolgere } from "../../dashboardAdminSlice";
import { useDispatch, useSelector } from "react-redux";
import { formatDate, getAPIErrorMessage } from "../../../../common/utils";
import { ArrowBack, CloudUploadOutlined, Download, MusicNote, Save } from "@mui/icons-material";
import { useTheme } from "@emotion/react";



const TecnicaDetails = ({ setIsEditorOpen, selectedTecnica = false }) => {
  const theme = useTheme();
  const c = theme.palette;
  const dispatch = useDispatch();
  const tokenAdmin = useSelector((state) => state.loginadmin.tokenAdmin);
  const { listaTecnicheLastProps } = useSelector((state) => state.dashboardAdmin);
  const [tecnicaData, setTecnicaData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isCreated, setIsCreated] = useState(false);
  const [error, setError] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    if (selectedTecnica?.id) loadTecnica(selectedTecnica.id);
  }, []);

  const loadTecnica = async (id) => {
    try {
      setIsLoading(true);
      let data;
      const response = await getTecnica(id, tokenAdmin);
      data = response.data;
      setTecnicaData(data);
    } catch (e) {
      dispatch(setSnackbarData({ message: getAPIErrorMessage(e), severity: "error" }));
      onClose()
    } finally {
      setIsLoading(false);
    }
  };

  const exportExcel = async () => {
    let response;
    try {
      response = await exportTecnica(tecnicaData?.id, tokenAdmin);
    } catch (e) {
      dispatch(setSnackbarData({ message: getAPIErrorMessage(e), severity: "error" }));
      return;
    }

    const url = window.URL.createObjectURL(new Blob([response], { type: "application/vnd.ms-excel" }));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "TecnicaABSn." + tecnicaData?.numero_tecnica + ".xlsx");
    document.body.appendChild(link);
    link.click();
  };

  const handleSave = async (pubblica) => {
    let response;
    try {
      setIsLoading(true);
      const data = new FormData();
      data.append("file", selectedFile);


      response = await modifyTecnica(selectedTecnica.id, data, tokenAdmin);

    } catch (e) {
      setError(getAPIErrorMessage(e));
      return;
    } finally {
      setIsLoading(false);
    }
    dispatch(getConteggioTecnicheDaSvolgere());
    dispatch(setSnackbarData({ message: "Tecnica ABS salvata con successo", severity: "success" }));
    await loadTecnica(response.data.id);
    setIsCreated(true);
  };



  const onClose = () => {
    dispatch(getListaTecniche(listaTecnicheLastProps));
    setError("");
    setIsEditorOpen(false);
  };

  const onFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };


  return (
    <>
      {isLoading && (
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Box sx={{ bgcolor: "#fff", p: 2 }} component={Paper}>
        <Grid container columnSpacing={2} rowSpacing={4} alignItems="center">
          <Grid item xs={selectedTecnica || isCreated ? 6 : 12}>
            <Button startIcon={<ArrowBack />} onClick={onClose}>
              Indietro
            </Button>
          </Grid>

          <Grid item xs={12} sx={{ pt: 2 }} display="flex" justifyContent="center">
            <Typography variant="title2">Tecnica ABS n. {tecnicaData?.numero_tecnica}</Typography>
          </Grid>
          <Grid item xs={12} sx={{p:0}} display="flex" justifyContent="end">
            <Button variant="contained" onClick={exportExcel} sx={{}} startIcon={<Download />}>
              Esporta Excel
            </Button>
          </Grid>



          <Grid item xs={12}>
            <Box sx={{ mb: 4, display: 'flex', justifyContent: 'space-around' }}>
              <Box>
                <Typography variant="h6" sx={{ color: c.primary.main }}>Richiedente</Typography>
                <Typography variant="body2">{tecnicaData?.utente_privato?.nome} {tecnicaData?.utente_privato?.cognome}</Typography>
                {
                  tecnicaData?.utente_professionista ?
                    <Box>
                      <Typography variant="h6" sx={{ color: c.primary.main }}>Professionista</Typography>
                      <Typography variant="body2">{tecnicaData?.utente_professionista?.nome} {tecnicaData?.utente_professionista?.cognome}</Typography>
                    </Box>
                    : <></>
                }
              </Box>
              <Box>
                <Typography variant="h6" sx={{ color: c.primary.main }}>Data Richiesta</Typography>
                <Typography variant="body2">{formatDate(tecnicaData?.created_at)}</Typography>
                <Typography variant="h6" sx={{ color: c.primary.main }}>Stato</Typography>
                <Typography variant="body2">{tecnicaData?.stato == 'COMP' ? 'Consegnato' : 'Da Svolgere'}</Typography>
              </Box>

            </Box>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Box sx={{ mt: 4 }}>


              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <Box>
                    <Typography variant="h6" sx={{ color: c.primary.main }}>Brani</Typography>
                    <Grid container spacing={2}>
                      {
                        tecnicaData?.brani?.map((b, i) => {
                          return (
                            <Grid item key={i} xs={3} sx={{ display: 'flex' }}>
                              <MusicNote sx={{ fontSize: '18px' }} />
                              <Typography variant="body2" sx={{ ml: 1 }}>
                                {b.nome}
                              </Typography>
                            </Grid>
                          );
                        })
                      }
                    </Grid>
                  </Box>

                </Grid>
                <Grid item xs={12} sm={12}>
                  <Box>
                    <Typography variant="h6" sx={{ color: c.primary.main }}>Frasi Negative</Typography>

                    <ol>
                      {
                        tecnicaData?.frasi_negative?.map((f, i) => {
                          return <Typography variant="body2"><li key={i}> {f.testo_frase}</li></Typography>
                        })
                      }
                    </ol>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Box>
                    <Typography variant="h6" sx={{ color: c.primary.main }}>Frasi Positive</Typography>

                    <ol>
                      {
                        tecnicaData?.frasi_positive?.map((f, i) => {
                          return <Typography variant="body2"><li key={i}> {f.testo_frase}</li></Typography>
                        })
                      }
                    </ol>
                  </Box>
                </Grid>
            
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h6" sx={{ color: c.primary.main }}>Elaborato Multimediale</Typography>
            <FormControl fullWidth>
              {!!selectedFile && <TextField InputProps={{ readOnly: true }} value={selectedFile.name} label="Nome File" />}
              <Button component="label" variant="contained" sx={{ my: 2 }} startIcon={<CloudUploadOutlined />}>
                Scegli un file audio/video
                <input type="file" onChange={onFileChange} style={{ display: "none" }} accept="video/*,audio/*" />
              </Button>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
          </Grid>
          {
            tecnicaData?.media_url ?
              <Grid item xs={4} key={tecnicaData?.media_url + "?" + tecnicaData?.updated_at}>
                <FormControl fullWidth>
                  <Typography variant="subtitle1">Ultimo file multimediale salvato</Typography>
                  {
                    tecnicaData?.estensione == 'mp4' ?
                      <video controls style={{ maxWidth: "500px", maxHeight: "400px" }}>
                        <source src={tecnicaData?.media_url + "?" + tecnicaData?.updated_at} type="video/mp4" />
                      </video>
                      :
                      <audio controls style={{ maxWidth: "500px", maxHeight: "200px" }}>
                        <source src={tecnicaData?.media_url + "?" + tecnicaData?.updated_at} type="audio/mp3" />
                      </audio>
                  }

                </FormControl>
              </Grid>
              : <></>
          }


          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ display: "flex", mb: 2 }}>
              <Box sx={{ flex: 1 }}></Box>
              <Button variant="contained" onClick={() => handleSave(false)} color="primary" sx={{ ml: 2 }} startIcon={<Save />}>
                Salva
              </Button>
            </Box>
          </Grid>

        </Grid>
      </Box>

      <Dialog open={!!error} onClose={() => setError("")} maxWidth="sm" fullWidth>
        <DialogTitle variant="dialogTitle" color="error">
          Attenzione
        </DialogTitle>
        <DialogContent>
          <Typography color="error" style={{ whiteSpace: "pre-line" }}>
            {error}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => setError("")}>
            OK
          </Button>
        </DialogActions>
      </Dialog>


    </>
  );
};

export default TecnicaDetails;
