import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../config/axiosConfig';

export const getListaCategorie = createAsyncThunk(
  'ecommerce/getListaCategorie',
  async (_, thunkAPI) => {
    let token;
    try {
      const state = thunkAPI.getState();
      token = state.login.token;
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      const response = await api.get('api/categorie', config);
      return { listaCategorie: response.data.data, };
    } catch (error) {
      throw error.response.data;
    }
  }
);

export const getListaProgrammi = createAsyncThunk(
  'ecommerce/getListaProgrammi',
  async (id, thunkAPI) => {
    let token;
    try {
      const state = thunkAPI.getState();
      token = state.login.token;
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      const response = await api.get('api/programmi?id_categoria=' + id, config);
      return { listaProgrammi: response.data.data, };
    } catch (error) {
      throw error.response.data;
    }
  }
);

export const getCarrello = createAsyncThunk(
  'ecommerce/getCarrello',
  async (_, thunkAPI) => {
    let token;
    try {
      const state = thunkAPI.getState();
      token = state.login.token;
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      const response = await api.get('api/carrello', config);
      return { carrello: response.data.data, };
    } catch (error) {
      throw error.response.data;
    }
  }
);

const initialState = {
  isLoading: false,
  isProgrammiLoading: false,
  error: null,
  listaCategorie: [],
  listaProgrammi: [],
  carrello: [],
  infoTecnicaABS: null,
};

export const EcommerceSlice = createSlice({
  name: 'ecommerce',
  initialState,
  reducers: {
    setCarrello: (state, action) => {
      state.carrello = action.payload;
    },
    setInfoTecnicaABS: (state, action) => {
      state.infoTecnicaABS = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getListaCategorie.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getListaCategorie.fulfilled, (state, action) => {
        state.isLoading = false;
        state.listaCategorie = action.payload.listaCategorie;
        state.error = null;
      })
      .addCase(getListaCategorie.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message ? action.error.message : action.error;
      })

      .addCase(getListaProgrammi.pending, (state) => {
        state.isProgrammiLoading = true;
        state.error = null;
      })
      .addCase(getListaProgrammi.fulfilled, (state, action) => {
        state.isProgrammiLoading = false;
        state.listaProgrammi = action.payload.listaProgrammi;
        state.error = null;
      })
      .addCase(getListaProgrammi.rejected, (state, action) => {
        state.isProgrammiLoading = false;
        state.error = action.error.message ? action.error.message : action.error;
      })

      .addCase(getCarrello.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getCarrello.fulfilled, (state, action) => {
        state.isLoading = false;
        state.carrello = action.payload.carrello;
        state.error = null;
      })
      .addCase(getCarrello.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message ? action.error.message : action.error;
      })

  },
});

export const {
  setCarrello,
  setInfoTecnicaABS
} = EcommerceSlice.actions;
export default EcommerceSlice.reducer;
