import React, { useEffect } from "react";
import { Grid, Box, Typography, Button, useTheme, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { getDashboardCounter, getListaAbbonamenti, getListaProgrammiAcquistati, getListaProgrammiCodici, getListaPrestazioni } from "./HomepageSlice";
import { Check } from "@mui/icons-material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { setCreaVendiPrestazioneData } from "../programmi/programmiSlice";

export function HomepageRivenditore() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { listaProgrammiAcquistati, codiciAttivi } = useSelector((state) => state.homepage);
  const c = theme.palette;



  function formatDate(inputDateString) {
    const dateObject = new Date(inputDateString);

    const day = dateObject.getDate();
    const month = dateObject.getMonth() + 1; // Mese inizia da 0, quindi aggiungi 1
    const year = dateObject.getFullYear();

    // Aggiungi zeri se necessario
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;

    // Formatta la data nel formato desiderato
    const formattedDate = `${formattedDay}/${formattedMonth}/${year}`;

    return formattedDate;
  }



  useEffect(() => {
    dispatch(getListaProgrammiCodici());
    dispatch(getListaProgrammiAcquistati());
    dispatch(getListaAbbonamenti());
    dispatch(getListaPrestazioni());
    dispatch(getDashboardCounter());
  }, []);

  const acquistaProgrammaTableStyle = {
    marginRight: "5%",
    backgroundColor: c.secondary.light,
    color: c.primary.dark,
    "&:hover": {
      backgroundColor: c.secondary.main,
      color: c.primary.light,
    },
  };

  const onVendiClickedProgramma = (programma) => {
    dispatch(setCreaVendiPrestazioneData({ isVendita: true, tipoAttivazione: "C", codiciServizio: [programma.codice_servizio], idProgramma: programma.programma.id }));
  };


  const headCellsRivenditore = [
    { id: "programma", label: "Programma" },
    { id: "codice", label: "Codice" },
    { id: "data_acquisto", label: "Data Acquisto" },
    { id: "venduto", label: "Ceduto" },
    { id: "azioni", label: "" },
  ];



  return (
    <Grid container sx={paginaStyle}>
      {/* PARTE SINISTRA */}
      <Grid item xs={12} md={6}>
        <Box sx={boxStyle}>
          <Typography sx={titoloStyle}>I tuoi ultimi programmi Acquistati</Typography>
          <Typography sx={sottotitoloStyle}>N. Programmi Attivi</Typography>
          <Typography sx={programmiAttiviStyle}>{codiciAttivi}</Typography>
          {/* <Typography sx={programmiScadenzaStyle}>
          ci sono <span style={{ fontWeight: 'bold' }}>{abbonamentiInScadenza}</span> programmi in scadenza
        </Typography> */}
          <Typography sx={testoInferioreBoldStyle} style={{ marginTop: "2%" }}>
            Storico Programmi:
          </Typography>
          <div className="scrollDashboard" style={{ overflowY: "auto" }}>
            <Table>
              <TableHead>
                <TableRow>
                  {headCellsRivenditore.map((headCell) => (
                    <TableCell key={headCell.id} style={{ textAlign: headCell.id === "programma" ? "left" : "center", width: "auto", color: "rgb(124,124,124)", fontSize: "0.8rem" }}>
                      {headCell.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {listaProgrammiAcquistati &&
                  listaProgrammiAcquistati.map((prog, index) => (
                    <TableRow key={prog.id} style={{ backgroundColor: index % 2 === 0 ? "white" : "rgb(244,242,242)" }}>
                      <TableCell style={{ textAlign: "left", width: "auto" }}>
                        <span style={{ fontWeight: "bold" }}>{prog.programma.nome}</span>
                      </TableCell>
                      <TableCell style={{ textAlign: "center", width: "auto" }}>
                        <span style={{ fontWeight: "bold" }}>{prog.codice_servizio}</span>
                      </TableCell>
                      <TableCell style={{ textAlign: "center", width: "auto" }}>{formatDate(prog.created_at)}</TableCell>
                      <TableCell style={{ textAlign: "center", width: "auto" }}>{prog.venduto ? <Check sx={{ color: "#255300" }} /> : "-"}</TableCell>
                      <TableCell style={{ textAlign: "left", width: "auto" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {!prog.venduto && (
                            <Button variant="outlined" sx={acquistaProgrammaTableStyle} onClick={() => onVendiClickedProgramma(prog)}>
                              CEDI
                            </Button>
                          )}
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </div>
        </Box>
      </Grid>
    </Grid>
  );
}

const boxStyle = {
  marginTop: "5%",
  marginLeft: "2%",
  marginRight: "2%",
  padding: "2%",
  width: "96%",
  backgroundColor: "white",
  position: "relative",
};


const titoloStyle = {
  color: "#255300",
  fontSize: "1.1rem",
  fontWeight: "bold",
};

const sottotitoloStyle = {
  color: "#255300",
  fontSize: "1rem",
  fontWeight: "bold",
  marginTop: "2%",
};

const programmiAttiviStyle = {
  color: "black",
  fontSize: "1.7rem",
  fontWeight: "bold",
  marginTop: "1%",
};

const testoInferioreBoldStyle = {
  color: "black",
  fontSize: "0.9rem",
  fontWeight: "bold",
  marginTop: "8%",
};

const paginaStyle = {
  backgroundColor: "rgb(248, 248, 248)",
};