import * as React from "react";
import { Box, Dialog, DialogTitle, DialogContent, DialogActions, FormControl, Typography, Grid, CircularProgress, Button, TextField } from "@mui/material";
import { Add, CloudUploadOutlined} from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { getListaCodiciRivenditori } from "../../dashboardAdminSlice";
import { creaCodiciRivenditori,  } from "../../dashboardAdminAPI";
import {  getAPIErrorMessage } from "../../../../common/utils";
import LocalPaginateTable from "../LocalPaginateTable";

export function CodiciRivenditori() {
  const dispatch = useDispatch();

  const { listaCodiciRivenditori, isLoading } = useSelector((state) => state.dashboardAdmin);
  const tokenAdmin = useSelector((state) => state.loginadmin.tokenAdmin);

  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [isSaving, setIsSaving] = React.useState(false);
  const [dialogError, setDialogError] = React.useState("");
  const [errAction, setErrAction] = React.useState("");

  React.useEffect(() => {
    dispatch(getListaCodiciRivenditori());
  }, []);

  const saveConfigurazione = async () => {
    if (selectedFile == null) {
      setDialogError("Devi selezionare un file");
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);

    setIsSaving(true);

    try {
      await creaCodiciRivenditori(formData, tokenAdmin);
    } catch (error) {
      setDialogError(getAPIErrorMessage(error));
      return;
    } finally {
      setIsSaving(false);
    }

    dispatch(getListaCodiciRivenditori());
    setIsDialogOpen(false);
  };

  const openDialog = () => {
    setDialogError("");
    setSelectedFile(null);
    setIsDialogOpen(true);
  };



  const onFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  return (
    <>
      <Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button variant="contained" endIcon={<Add />} onClick={openDialog}>
            Carica File Excel Codici Rivenditori
          </Button>
        </Box>
        <LocalPaginateTable
          scope="Codici Rivenditori"
          isLoading={isLoading}
          cols={[
            { id: "nome", label: "Nome" },
            { id: "codice_rivenditore", label: "Codice Rivenditore" },
            { id: "codice_professionista", label: "Codice Professionista", format: (val, conf) => val?? "-" },
          ]}
          list={listaCodiciRivenditori}
          filterCols={["nome", "codice_rivenditore", "codice_professionista"]}
          stOrder="nome"
        />
      </Box>

      <Dialog open={!!errAction} maxWidth="sm" fullWidth>
        <DialogTitle variant="dialogTitle" color="error">
          Attenzione
        </DialogTitle>
        <DialogContent>
          <Typography color="error" style={{ whiteSpace: "pre-line" }}>
            {errAction}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => setErrAction("")}>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog per l'edit */}
      <Dialog open={isDialogOpen} maxWidth="sm" fullWidth>
        <DialogTitle variant="dialogTitle">Carica File Excel Codici Rivenditori</DialogTitle>
        {isSaving && (
          <Box sx={{ bgcolor: "rgba(255,255,255,.6)", zIndex: 4, height: "100%", width: "100%", position: "absolute", display: "flex", alignItems: "center", justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        )}
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                {!!selectedFile && <TextField InputProps={{ readOnly: true }} value={selectedFile.name} label="Nome File" />}
                <Button component="label" variant="contained" sx={{ my: 2 }} startIcon={<CloudUploadOutlined />}>
                  Scegli un file excel
                  <input type="file" onChange={onFileChange} style={{ display: "none" }} accept="application/vnd.ms-excel, application/msexcel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
                </Button>
              </FormControl>
            </Grid>
          </Grid>

          <Typography color="error" style={{ whiteSpace: "pre-line" }}>
            {dialogError}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDialogOpen(false)} sx={{ color: "#000" }}>
            Annulla
          </Button>
          <Button variant="contained" onClick={() => saveConfigurazione()}>
            SALVA
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
