import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import DialogInfoProgramma from "../../common/DialogInfoProgramma";
import { HomepagePrivato } from "./HomepagePrivato";
import { HomepageProfessionista } from "./HomepageProfessionista";
import { HomepageRivenditore } from "./HomepageRivenditore";
import { Footer } from "./Footer";

export function Homepage() {
  const { user } = useSelector((state) => state.login);
  const [infoProgramma, setInfoProgramma] = useState(false);
  const screenRef = React.useRef();
  const [screenH, setScreenH] = React.useState(null);

  const handleResize = () => {
    if (screenRef.current) {
      setScreenH((window.innerHeight - screenRef.current.getBoundingClientRect().top));
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [screenRef]);

  return (
    <Box sx={{ backgroundColor: "rgb(248, 248, 248)", overflowY: 'auto', display: 'flex', flexDirection: "column" }} ref={screenRef} height={screenH}>
      <Box sx={{flex: 1}}>
        <Typography sx={titoloGeneraleStyle}>
          <span style={{ fontWeight: "bold" }}>Ciao {user?.nome}</span>, cosa vuoi fare oggi?
        </Typography>

        {/* DASHBOARD OPERATORE */}
        {user?.tipologia === "PA" && (
          <HomepageProfessionista />
        )
        }

        {infoProgramma && <DialogInfoProgramma id_programma={infoProgramma} isOpen={setInfoProgramma} />}

        {/* DASHBOARD RIVENDITORE */}
        {
          user?.tipologia === "PP" && (
            <HomepageRivenditore />
          )
        }

        {/* DASHBOARD PRIVATO */}
        {
          user?.tipologia === "P" && (
            <HomepagePrivato />
          )
        }
      </Box>
      <Footer />
    </Box >
  );
}
const titoloGeneraleStyle = {
  color: "#255300",
  fontSize: "1.3rem",
  fontWeight: "medium",
  marginLeft: "1%",
  paddingTop: "1%",
};
