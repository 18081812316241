import api from "../../config/axiosConfig";

export const getInfoProgramma = async ({ token, id_programma }) => {
  const config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  const response = await api.get("/api/programmi/"+id_programma, config);
  return response.data;
};
