import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "./../../config/axiosConfig";
import { PURGE } from "redux-persist";
import { getAPIErrorMessage } from "../../common/utils";

export const login = createAsyncThunk("loginadmin/login", async ({ username, password }, thunkAPI) => {
  try {
    const response = await api.post("/adm/login", { username, password });
    if (response.data.data.user.flg_reset_password) {
      return { user: response.data.data.user, tempToken: response.data.data.token, resetPassword: true };
    } else {
      return { user: response.data.data.user, token: response.data.data.token, loginSuccess: true };
    }
  } catch (error) {
    throw error.data ? error.data : error.message ? error.message : error;
  }
});

export const sendForgotPassword = createAsyncThunk("loginadmin/sendForgotPassword", async ({ email }, thunkAPI) => {
  try {
    await api.post("/adm/resetPassword", { email });
    return { resetSuccess: true };
  } catch (error) {
    return thunkAPI.rejectWithValue({ message: getAPIErrorMessage(error) });
  }
});

export const setPasswordAsync = createAsyncThunk("loginadmin/setPassword", async ({ newPassword, confirmPassword }, thunkAPI) => {
  try {
    const state = thunkAPI.getState();
    const tempToken = state.login.tempToken;
    const config = {
      headers: {
        Authorization: "Bearer " + tempToken,
      },
    };
    await api.post("/adm/password", { password: newPassword, password_confirm: confirmPassword }, config);
    return { resetPassword: false, loginSuccess: true, tempToken: null, token: tempToken };
  } catch (error) {
    return thunkAPI.rejectWithValue({ message: getAPIErrorMessage(error) });
  }
});

const initialState = {
  user: null,
  isLoading: false,
  isLoadingForgot: false,
  isLoadingSetPsw: false,
  error: null,
  errorForgot: null,
  errorSetPsw: null,
  resetPassword: false,
  loginSuccess: false,
  resetSuccess: false,
  tokenAdmin: null,
  tempToken: null,
};

export const loginAdminSlice = createSlice({
  name: "loginadmin",
  initialState,
  reducers: {
    setLoginSuccess: (state, action) => {
      state.loginSuccess = action.payload;
    },
    setForgotSuccess: (state, action) => {
      state.resetSuccess = action.payload;
    },
    setDataCheck: (state, action) => {
      state.user = action.payload;
    },
    setToken: (state, action) => {
      state.tokenAdmin = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload.user;
        if (action.payload.loginSuccess) {
          state.tokenAdmin = action.payload.token;
          state.loginSuccess = action.payload.loginSuccess;
        } else if (action.payload.resetPassword) {
          state.tempToken = action.payload.tempToken;
          state.resetPassword = action.payload.resetPassword;
        }
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message ? action.error.message : action.error;
      })
      .addCase(sendForgotPassword.pending, (state) => {
        state.isLoadingForgot = true;
        state.errorForgot = null;
      })
      .addCase(sendForgotPassword.fulfilled, (state, action) => {
        state.isLoadingForgot = false;
        state.resetSuccess = true;
      })
      .addCase(sendForgotPassword.rejected, (state, action) => {
        state.isLoadingForgot = false;
        state.errorForgot = action.payload.message;
      })
      .addCase(setPasswordAsync.pending, (state) => {
        state.isLoadingSetPsw = true;
        state.errorSetPsw = null;
      })
      .addCase(setPasswordAsync.fulfilled, (state, action) => {
        state.isLoadingSetPsw = false;
        state.resetPassword = action.payload.resetPassword;
        state.loginSuccess = action.payload.loginSuccess;
        state.tokenAdmin = action.payload.token;
        state.tempToken = action.payload.tempToken;
      })
      .addCase(setPasswordAsync.rejected, (state, action) => {
        state.isLoadingSetPsw = false;
        state.errorSetPsw = action.payload.message;
      })

      .addCase(PURGE, (state) => {
        // localStorage.clear();
        return initialState;
      });
  },
});

export const { setLoginSuccess, setForgotSuccess, setDataCheck, setToken } = loginAdminSlice.actions;

export default loginAdminSlice.reducer;
