import { Box, Button, Card, CardActions, CardContent, Stack, Typography, useTheme } from "@mui/material";
import { ReactComponent as SvgPrgGeniusM } from "../../../assets/svg/SvgPrgGeniusM.svg";
import { ReactComponent as SvgPrg } from "../../../assets/svg/SvgPrg.svg";
import { ReactComponent as SvgOrologio } from "../../../assets/svg/SvgOrologio.svg";
import { ReactComponent as SvgChk } from "../../../assets/svg/SvgChk.svg";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSelezionaCodiceData, setCreaVendiPrestazioneData } from "../programmiSlice";
import { Info } from "@mui/icons-material";
import DialogInfoProgramma from "../../../common/DialogInfoProgramma";

export default function CardCodiciProgrammaGrouped(props) {
  const { tipoAttivazione, codiceServizio, ...other } = props;
  const c = useTheme().palette;
  const { user } = useSelector((state) => state.login);
  const dispatch = useDispatch();
  const [infoProgramma, setInfoProgramma] = React.useState(false);
  const descriptionLenght = 200;

  const onApriDialogCreaVendiCodice = (props) => {
    dispatch(setCreaVendiPrestazioneData({ ...props, onVendiSuccess: null, onCreaSuccess: null }));
  };

  const onAttiva = () => {
    dispatch(setSelezionaCodiceData({ idProgramma: codiceServizio?.programma?.id, isVendita: false, tipoAttivazione: "C", onSuccess: onApriDialogCreaVendiCodice }));
  };

  const onVendi = () => {
    dispatch(setSelezionaCodiceData({ idProgramma: codiceServizio?.programma?.id, isVendita: true, tipoAttivazione: "C", onSuccess: onApriDialogCreaVendiCodice }));
  };

  return (
    <>
      <Card sx={{ bgcolor: codiceServizio?.programma?.colore, height: '250px' }} {...other}>
        <CardContent sx={{ m: -1 }}>
          <Stack direction="row">
            <div style={{ width: 35, height: "50px" }}>{codiceServizio?.programma?.flg_genius_machine ? <SvgPrgGeniusM /> : <SvgPrg />}</div>
            <Stack sx={{ ml: 1, width: "100%" }} direction="column">
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="s">{codiceServizio?.programma?.nome}</Typography>
                <Info sx={{ color: "#255300", cursor: "pointer", alignSelf: "flex-end" }} onClick={() => setInfoProgramma(codiceServizio.programma?.id)} />
              </Stack>
              <Typography variant="l" sx={{ height: "55px", overflow: "auto" }}>
                {codiceServizio.programma?.descrizione}
              </Typography>
            </Stack>
          </Stack>
          <Stack sx={{ ml: 5, mt: 2 }}>
            <Stack sx={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
              <Typography variant="s" sx={{ fontSize: 12 }}>
                Cosa prevede:
              </Typography>
              <Stack sx={{ flexDirection: "row", alignItems: "center", mr: 1 }}>
                <SvgOrologio />
                <Typography sx={{ ml: 1, fontSize: 12 }}>{codiceServizio.programma?.durata} min</Typography>
              </Stack>
            </Stack>
            <Stack sx={{ flexDirection: "row", alignItems: "center", mt: 1 }}>
              <SvgChk />
              <Typography sx={{ ml: 1, fontSize: 12 }}>Breve intervista</Typography>
            </Stack>
            <Stack sx={{ flexDirection: "row", alignItems: "center", mt: 1, visibility: codiceServizio.programma?.flg_genius_machine ? "display" : "hidden" }}>
              <SvgChk />
              <Typography sx={{ ml: 1, fontSize: 12 }}>Genius Machine</Typography>
            </Stack>
          </Stack>
        </CardContent>
        <CardActions sx={{ justifyContent: "space-between", flex: 1, flexDirection: "row" }}>
          {tipoAttivazione === "C" && <Typography style={{ color: c.secondary.main, fontSize: 12 }}>Codici Disponibili: {codiceServizio?.codici?.length}</Typography>}
          <Box sx={{display:'flex'}}>
            {user?.tipologia !== "PP" && (
              <Button variant="contained" size="small" color="secondary" sx={{}} onClick={onAttiva}>
                ATTIVA
              </Button>
            )}
            {(user?.tipologia === "PP" || user?.tipologia === "PA") && tipoAttivazione === "C" && (
              <Button variant="contained" size="small" color="secondary" sx={{ ml: 1 }} onClick={onVendi}>
                CEDI
              </Button>
            )}
          </Box>
        </CardActions>
        {infoProgramma && <DialogInfoProgramma id_programma={infoProgramma} isOpen={setInfoProgramma} />}
      </Card>
    </>
  );
}
