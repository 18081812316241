import React, { useEffect, useState } from "react";
import { Button, Typography, TextField, Dialog, DialogTitle, DialogContent, Grid, FormControl, DialogActions, InputLabel, Select, MenuItem } from "@mui/material";
import { creaCategoria, modifyCategoria } from "../../dashboardAdminAPI";
import { getListaCategorie } from "../../dashboardAdminSlice";
import { useDispatch, useSelector } from "react-redux";
import { getAPIErrorMessage } from "../../../../common/utils";
import { MediaSelect } from "../media/MediaSelect";

const CategoriaDetails = ({ isDialogOpen, setIsDialogOpen, selectedCategoria = null }) => {
  const dispatch = useDispatch();
  const tokenAdmin = useSelector((state) => state.loginadmin.tokenAdmin);

  const [categoriaData, setCategoriaData] = useState({ nome: null, id_media: null, flg_active: true, categoria_tipologia_utente: [] });
  const [error, setError] = useState("");

  useEffect(() => {
    if (selectedCategoria?.id) setCategoriaData({ ...selectedCategoria, flg_active: true });
    else setCategoriaData({ nome: null, id_media: null, flg_active: true, categoria_tipologia_utente: [] });
  }, [isDialogOpen]);

  const saveCategoria = async () => {
    if (!categoriaData.nome?.trim()) {
      setError("Inserire il Nome della Categoria");
      return;
    }

    const data = { nome: categoriaData.nome, id_media: categoriaData.id_media, categoria_tipologia_utente: categoriaData.categoria_tipologia_utente };
    try {
      if (selectedCategoria?.id) {
        await modifyCategoria(data, tokenAdmin, selectedCategoria.id);
      } else {
        await creaCategoria(data, tokenAdmin);
      }
      onClose();
    } catch (e) {
      setError(getAPIErrorMessage(e));
      return;
    }
  };

  const onClose = () => {
    dispatch(getListaCategorie());
    setError("");
    setIsDialogOpen(false);
  };


  const utentiTypes = [
    {
      'codice': 'P',
      'descrizione': 'Utenti Privati',
    },
    {
      'codice': 'PA',
      'descrizione': 'Professionisti Attivi',
    },
    {
      'codice': 'PP',
      'descrizione': 'Rivenditori',
    }
  ];


  return (
    <>
      {/* Dialog per l'edit */}
      <Dialog open={isDialogOpen || false} maxWidth="sm" fullWidth>
        <DialogTitle variant="dialogTitle">{selectedCategoria?.id ? "Modifica Categoria" : "Aggiungi Categoria"}</DialogTitle>
        <DialogContent>
          <form>
            <Grid container spacing={2} sx={{ pt: 1 }}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <TextField label="Nome" name="nome" value={categoriaData.nome ?? ""} onChange={(e) => setCategoriaData({ ...categoriaData, nome: e.target.value })} />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <MediaSelect label="Immagine" supportedTypes={["IMG"]} currentSelectedMediaId={categoriaData.id_media} onSelectCallback={(media) => setCategoriaData({ ...categoriaData, id_media: media?.id })} />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Tipologie di Utenti Abilitati alla visione</InputLabel>
                  <Select label="Tipologie di Utenti Abilitati alla visione" name="categoria_tipologia_utente" multiple value={categoriaData.categoria_tipologia_utente} onChange={(e) => setCategoriaData({ ...categoriaData, categoria_tipologia_utente: e.target.value })}>
                    {utentiTypes?.map((type, i) => (
                      <MenuItem key={i} value={type.codice}>
                        {type.descrizione}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {/* <Grid item xs={12} sx={{ my: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Typography sx={{ ml: 1 }}>Stato</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <FormControlLabel control={<Switch checked={categoriaData.flg_active} onChange={(e) => setCategoriaData({ ...categoriaData, flg_active: e.target.checked })} size="small" />} label={categoriaData.flg_active ? "Attivo" : "Disattivo"} />
                  </Grid>
                </Grid>
              </Grid> */}
            </Grid>
          </form>

          <Typography color="error" style={{ whiteSpace: "pre-line" }} sx={{ mt: 3 }}>
            {error}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} sx={{ color: "#000" }}>
            Annulla
          </Button>
          <Button variant="contained" onClick={saveCategoria}>
            SALVA
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CategoriaDetails;
