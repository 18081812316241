import api from '../../config/axiosConfig';

export const initialCheckVenditaBrani = async (brani, token) => {
    const config = {
        headers: {
            Authorization: "Bearer " + token,
        },
    };

    const response = await api.post('/api/abs/initialCheckVenditaBrani', brani, config);
    return response.data;
}


export const vendiBrani = async (brani, token) => {
    const config = {
        headers: {
            Authorization: "Bearer " + token,
        },
    };

    const response = await api.post('/api/abs/vendiBrani', brani, config);
    return response.data;
}

export const getBraniUtente = async (data, token) => {
    const config = {
        headers: {
            Authorization: "Bearer " + token,
        },
    };
    const queryParams = new URLSearchParams(data);
    const response = await api.get("api/abs/getBraniUtente?" + queryParams, config);
    return response.data;
}

export const getBraniAcquistati = async (data, token) => {
    const config = {
        headers: {
            Authorization: "Bearer " + token,
        },
    };
    const queryParams = new URLSearchParams(data);
    const response = await api.get("api/abs/getBraniAcquistati?" + queryParams, config);
    return response.data;
}

export const getMediaIstruzioni = async (token) => {
    const config = {
        headers: {
            Authorization: "Bearer " + token,
        },
    };

    const response = await api.get('/api/abs/mediaIstruzioni', config);
    return response.data;
}

export const attivaBrano = async (id_brano_utente, token) => {
    const config = {
        headers: {
            Authorization: "Bearer " + token,
        },
    };

    const response = await api.post('/api/abs/branoUtente/' + id_brano_utente + '/attiva',{}, config);
    return response.data;
}