import { combineReducers, configureStore } from "@reduxjs/toolkit";

import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import loginReducer from "../pages/login/loginSlice";
import loginAdminReducer from "../pages/loginadmin/loginAdminSlice";
import dashboardAdminReducer from "../pages/dashboardadmin/dashboardAdminSlice";
import ordiniReducer from "../pages/ordine/ordineSlice";
import programmiReducer from "../pages/programmi/programmiSlice";
import homePageReducer from "../pages/homepage/HomepageSlice";
import prestazioniReducer from "../pages/prestazione/prestazioneSlice";
import profiloReducer from "../pages/profilo/profiloSlice";
import ecommerceReducer from "../pages/ecommerce/EcommerceSlice";
import absReducer from "../pages/abs/absSlice";

import { encryptTransform } from "redux-persist-transform-encrypt";

const appReducer = combineReducers({
  login: loginReducer,
  loginadmin: loginAdminReducer,
  dashboardAdmin: dashboardAdminReducer,
  ordini: ordiniReducer,
  homepage: homePageReducer,
  programmi: programmiReducer,
  prestazioni: prestazioniReducer,
  profilo: profiloReducer,
  ecommerce: ecommerceReducer,
  abs: absReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") {
    localStorage.removeItem("persist:login");
    localStorage.removeItem("persist:loginadmin");
    localStorage.removeItem("persist:root");
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  transforms: [
    encryptTransform({
      secretKey: "chiave",
      onError: (error) => {
        console.log(error);
      },
    }),
  ],
  // blacklist: ["login", "loginadmin", "profilo"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);
