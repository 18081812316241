import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { Box, Button, Dialog, DialogContent, DialogTitle, Grid, Hidden, IconButton, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { login, sendForgotPassword, setForgotSuccess, setInitState, setPasswordAsync } from "./loginSlice";
import { useTheme } from "@emotion/react";
import LogoPrivato from "./../../assets/img/privato.png";
import LogoProfessionista from "./../../assets/img/professionista.png";
import { ReactComponent as LogoRivenditore } from "./../../assets/svg/SvgImgRivenditore.svg";
import { ReactComponent as ImgBackground } from "./../../assets/svg/SvgImgBackgroundLogin.svg";
import Tommy from "../../common/Tommy";
import { Footer } from "../homepage/Footer";

export function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const c = theme.palette;
  const { isLoading, isLoadingForgot, isLoadingSetPsw, error, errorForgot, errorSetPsw, resetPassword, loginSuccess, resetSuccess, token } = useSelector((state) => state.login);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [psw, setPsw] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const tokenAdmin = useSelector((state) => state.loginadmin.tokenAdmin);

  const selectedSection = useSelector((state) => state.login.selectedSection);

  const [errorForm, setErrorForm] = useState(false);
  const [errorForgotForm, setErrorForgotForm] = useState(false);
  const [errorSetPswForm, setErrorSetPswForm] = useState(false);

  const [disabled, setDisabled] = useState(true);
  const [disabledForgot, setDisabledForgot] = useState(true);
  const [disabledNewPsw, setDisabledNewPsw] = useState(true);

  const [forgotPassword, setForgotPassword] = useState(false);
  const [openModalForgot, setOpenModalForgot] = useState(false);

  const [pswVis, setPswVis] = useState(false);
  const [newPswVis, setNewPswVis] = useState(false);
  const [repeatPswVis, setRepeatPswVis] = useState(false);

  const gridRef = useRef();
  const [gridW, setGridW] = useState(0);

  useEffect(() => {
    if (token == null && tokenAdmin == null) {
      dispatch(setInitState());
    }
    // const checkSession = async () => {
    //   if (token) {
    //     const checked = await check(token);
    //     if (checked) {
    //       const path = selectedSection;
    //       //const path = localStorage.getItem("selectedSection");
    //       if (path && !path.includes("admin")) {
    //         navigate(path);
    //       } else {
    //         navigate("/tommy/homepage");
    //       }
    //     }
    //   }
    // };
    // checkSession();
  }, []);

  useEffect(() => {
    if (username !== "" && psw !== "" && !errorForm) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [username, psw, errorForm]);

  useEffect(() => {
    if (email !== "" && !errorForgotForm) {
      setDisabledForgot(false);
    } else {
      setDisabledForgot(true);
    }
  }, [email]);

  useEffect(() => {
    if (newPassword !== "" && confirmPassword !== "" && !errorSetPswForm) {
      setDisabledNewPsw(false);
    } else {
      setDisabledNewPsw(true);
    }
  }, [newPassword, confirmPassword]);

  useEffect(() => {
    if (loginSuccess) {
      const path = selectedSection;
      if (path && !path.includes("admin")) {
        navigate(path);
      } else {
        navigate("/tommy/homepage");
      }
    }
  }, [loginSuccess]);

  useEffect(() => {
    if (resetSuccess) {
      setForgotPassword(false);
      setOpenModalForgot(true);
      dispatch(setForgotSuccess(false));
    }
  }, [resetSuccess]);

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(login({ email: username, password: psw }));
  };

  const handleUserReset = (event) => {
    event.preventDefault();
    dispatch(sendForgotPassword({ email }));
  };

  const handleSetPassword = (event) => {
    event.preventDefault();
    dispatch(setPasswordAsync({ newPassword, confirmPassword }));
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [gridRef]);

  const handleResize = () => {
    if (gridRef.current) {
      setGridW(gridRef.current.getBoundingClientRect().width);
    }
  };

  return (
    <Grid container component="main" sx={{ height: "100vh", overflowY: "auto", width: '100%' }}>
      <Grid ref={gridRef} container item sm={12} md={6} justifyContent="center" alignItems="center" flexDirection="column">
        <Hidden mdDown>
          <Tommy
            tipPos="top"
            boxStyle={{ position: "relative" }}
            tommyHeight={gridW / 4}
            tipStyle={{ marginBottom: '20px' }}
            content={["Benvenuto in Expert Service, sono Tommy!", "Hai già un account?\nEffettua il Login.", "Non hai un account?\nScegli la categoria ed iscriviti."].map((item, idx) => (
              <Stack key={idx} sx={{ width: gridW / 2, minHeight: 0, justifyContent: "center", alignItems: "center" }}>
                <Typography variant="h6" sx={{ whiteSpace: "pre-line" }}>
                  {item}
                </Typography>
              </Stack>
            ))}
            autoplay
          />
          <ImgBackground
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              overflow: "hidden",
            }}
          />
        </Hidden>
        {
          //<CarouselProgrammi />
        }
      </Grid>

      <Grid container item md={6} sm={12} justifyContent="space-around" flexDirection="column" sx={{ zIndex: 200, px: 4, mt: 2 }}>
        {/* Login */}
        {!resetPassword && !forgotPassword && (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
              }}
            >
              <Typography component="h1" variant="h4" color="secondary.main" fontFamily="Poppins-Medium" sx={{ marginBottom: "1rem" }}>
                Benvenuto,
              </Typography>
              <Typography align="center" color="text.sub" component="p" textAlign="left">
                Inserisci le tue credenziali per accedere
              </Typography>
              <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                <TextField
                  error={errorForm}
                  margin="normal"
                  fullWidth
                  id="username"
                  label="Email"
                  name="username"
                  autoComplete="username"
                  autoFocus
                  value={username}
                  onChange={(event) => {
                    setUsername(event.target.value);
                    if (event.target.value.length > 0) {
                      setErrorForm(false);
                    } else {
                      setErrorForm(true);
                    }
                  }}
                  sx={{
                    backgroundColor: "text.background",
                  }}
                />
                <TextField
                  margin="normal"
                  // required
                  fullWidth
                  name="password"
                  label="Password"
                  type={pswVis ? "text" : "password"}
                  id="password"
                  autoComplete="current-password"
                  value={psw}
                  onChange={(event) => {
                    setPsw(event.target.value);
                  }}
                  sx={{ backgroundColor: "text.background" }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton aria-label="toggle password visibility" onClick={() => setPswVis(!pswVis)} edge="end">
                          {pswVis ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Button type="submit" fullWidth variant="contained" disabled={disabled || isLoading} sx={{ mt: 3, mb: 2, backgroundColor: "primary.dark", height: "50px" }}>
                  <Typography>{isLoading ? "Loading..." : "ACCEDI"}</Typography>
                </Button>
                <Grid container>
                  <Grid item xs>
                    <Link variant="body2" onClick={() => setForgotPassword(true)} sx={{ cursor: "pointer" }}>
                      Password dimenticata?
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            {error && (
              <Typography color="text.sub" component="p" sx={{ color: (t) => t.palette.text.error, ml: 2 }}>
                {error.message ? error.message : "Login Error"}
              </Typography>
            )}
          </>
        )}

        {/* email to reset password */}
        {forgotPassword && (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
              }}
            >
              <Typography component="h1" variant="h4" color="secondary.main" fontFamily="Poppins-Medium" sx={{ marginBottom: "1rem" }}>
                Password dimenticata
              </Typography>
              <Typography align="center" color="text.sub" component="p" textAlign="left">
                Inserisci la tua email per resettare la password
              </Typography>
              <Box component="form" noValidate onSubmit={handleUserReset} sx={{ mt: 1 }}>
                <TextField
                  error={errorForgotForm}
                  margin="normal"
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  autoComplete="email"
                  value={email}
                  onChange={(event) => {
                    setEmail(event.target.value);
                    if (event.target.value) {
                      setErrorForgotForm(false);
                    } else {
                      setErrorForgotForm(true);
                    }
                  }}
                  sx={{
                    backgroundColor: "text.background",
                  }}
                />
                <Button type="submit" fullWidth variant="contained" disabled={disabledForgot || isLoadingForgot} sx={{ mt: 3, mb: 2, backgroundColor: "primary.dark", height: "50px" }}>
                  {isLoadingForgot ? "Loading..." : "Invia Email"}
                </Button>
                <Grid container>
                  <Grid item xs>
                    <Link variant="body2" onClick={() => setForgotPassword(false)} sx={{ cursor: "pointer" }}>
                      Indietro
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </Box>

            {errorForgot && (
              <Typography align="center" color="text.sub" component="p" sx={{ color: (t) => t.palette.text.error }}>
                {errorForgot}
              </Typography>
            )}
          </>
        )}

        {/* set new password */}
        {resetPassword && (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
              }}
            >
              <Typography component="h1" variant="h4" color="secondary.main" fontFamily="Poppins-Medium" sx={{ marginBottom: "1rem" }}>
                Inserisci una nuova password
              </Typography>
              <Typography align="center" color="text.sub" component="p" textAlign="left">
                Inserisci la tua nuova password e conferma
              </Typography>
              <Box component="form" noValidate onSubmit={handleSetPassword} sx={{ mt: 2 }}>
                <TextField
                  autoFocus
                  error={errorSetPswForm}
                  fullWidth
                  margin="dense"
                  label="Password"
                  autoComplete="current-password"
                  type={newPswVis ? "text" : "password"}
                  value={newPassword}
                  onChange={(event) => setNewPassword(event.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton aria-label="toggle password visibility" onClick={() => setNewPswVis(!newPswVis)} edge="end">
                          {newPswVis ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  error={errorSetPswForm}
                  fullWidth
                  margin="dense"
                  label="Repeat Password"
                  autoComplete="current-password"
                  type={repeatPswVis ? "text" : "password"}
                  value={confirmPassword}
                  onChange={(event) => {
                    setConfirmPassword(event.target.value);
                    if (event.target.value !== newPassword) {
                      setErrorSetPswForm(true);
                    } else {
                      setErrorSetPswForm(false);
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton aria-label="toggle password visibility" onClick={() => setRepeatPswVis(!repeatPswVis)} edge="end">
                          {repeatPswVis ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Button type="submit" fullWidth variant="contained" disabled={disabledNewPsw || isLoadingSetPsw} sx={{ mt: 3, mb: 2, backgroundColor: "primary.dark", height: "50px" }}>
                  {isLoadingSetPsw ? "Loading..." : "Confirm"}
                </Button>
              </Box>
            </Box>
            {errorSetPswForm && (
              <Typography align="center" color="text.sub" component="p" sx={{ color: (t) => t.palette.text.error }}>
                The passwords entered do not match
              </Typography>
            )}
            {errorSetPsw && (
              <Typography align="center" color="text.sub" component="p" sx={{ color: (t) => t.palette.text.error }}>
                {errorSetPsw}
              </Typography>
            )}
          </>
        )}

        <Dialog open={openModalForgot} onClose={() => setOpenModalForgot(false)} maxWidth="sm" fullWidth>
          <DialogTitle variant="dialogTitle">Email inviata</DialogTitle>
          <DialogContent>
            <Typography style={{ whiteSpace: "pre-line" }}>Un email è stata inviata con le istruzioni per resettare la tua password.</Typography>
          </DialogContent>
        </Dialog>

        {/* registrazione */}
        <Box sx={{  mt: 4, maxWidth:'1000px'}}>
          <Typography
            sx={{
              fontFamily: "Poppins-SemiBold",
              fontSize: "23px",
              mb: 1,
            }}
          >
            Non hai ancora un account?
          </Typography>
          <Typography
            sx={{
              fontFamily: "Poppins-SemiBold",
              fontSize: "18px",
              mb: 1,
            }}
          >
            Seleziona una categoria e <span style={{ color: c.primary.main }}> registrati</span>
          </Typography>
          <Typography sx={{ color: c.primary.background, mb: 3 }}>Expert Service è un'innovativa piattaforma di L.S. Genius che offre specifici programmi benessere per
            professionisti del settore e privati</Typography>

          <Box
            onClick={() => navigate("/tommy/registrazione", { state: { type: "professionista" } })}
            sx={{ bgcolor: c.primary.light, borderRadius: 3, border: "1px solid " + c.primary.main, p: 2, mb: 3, display: "flex", flexDirection: "row", cursor: "pointer", justifyContent: "left", alignItems: "center" }}
          >
            <Box sx={{ pr: 2, maxWidth: "100px" }}>
              <img src={LogoProfessionista} style={{}} alt="logo" />
            </Box>
            <Box>
              <Typography sx={{ color: c.primary.dark, fontFamily: "Poppins-Medium", fontSize: 18 }} gutterBottom>
                Sono un professionista
              </Typography>
              <Typography sx={{ color: c.primary.dark }}>Vuoi acquistare i nostri servizi?</Typography>
              <Typography sx={{ color: c.primary.background }}>Registrandoti potrai acquistare i programmi benessere della L.S. Genius per le tue consulenze
                professionali.</Typography>
            </Box>
          </Box>
          <Box sx={{ zIndex: 99, bgcolor: "#fcf" }}></Box>

          <Box
            onClick={() => navigate("/tommy/registrazione", { state: { type: "privato" } })}
            sx={{ bgcolor: c.primary.light, borderRadius: 3, border: "1px solid " + c.primary.main, p: 2, mb: 3, display: "flex", flexDirection: "row", cursor: "pointer", justifyContent: "left", alignItems: "center" }}
          >
            <Box sx={{ pr: 2, maxWidth: "100px" }}>
              <img src={LogoPrivato} style={{}} alt="logo" />
            </Box>
            <Box>
              <Typography sx={{ color: c.primary.dark, fontFamily: "Poppins-Medium", fontSize: 18 }} gutterBottom>
                Sono un privato
              </Typography>
              <Typography sx={{ color: c.primary.dark }}>Vuoi usufruire dei nostri Servizi?</Typography>
              <Typography sx={{ color: c.primary.background }}>Registrati! Potrai scegliere il programma di benessere che fa per te e ricevere immediatamente l'elaborato
                nella tua area riservata. </Typography>
            </Box>
          </Box>

          <Box
            onClick={() => navigate("/tommy/registrazione", { state: { type: "rivenditore" } })}
            sx={{ bgcolor: c.primary.light, borderRadius: 3, border: "1px solid " + c.primary.main, p: 2, mb: 3, display: "flex", flexDirection: "row", cursor: "pointer", justifyContent: "left", alignItems: "center" }}
          >
            <Box sx={{ pr: 2, maxWidth: "100px" }}>
              <LogoRivenditore style={{}} alt="logo" />
            </Box>
            <Box>
              <Typography sx={{ color: c.primary.dark, fontFamily: "Poppins-Medium", fontSize: 18 }} gutterBottom>
                Sono un rivenditore
              </Typography>
              <Typography sx={{ color: c.primary.dark }}>Vuoi rivendere i nostri servizi?</Typography>
              <Typography sx={{ color: c.primary.background }}>Registrandoti potrai acquistare e rivendere i nostri servizi ai tuoi Clienti.</Typography>
            </Box>
          </Box>
        </Box>
      </Grid>
      <Box sx={{width: '100%'}}>
      <Footer/>
      </Box>
    
    </Grid>
  );
}
