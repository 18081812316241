import { useEffect, useState } from "react";
import { Search } from "@mui/icons-material";
import { Button, InputAdornment, Stack, TextField } from "@mui/material";
import { useDispatch } from "react-redux";

export default function SearchOrderStack(props) {
  const { index, f, orderList, ...other } = props;
  const dispatch = useDispatch();
  const [srcString, setSrc] = useState("");
  const [sortIndex, setSortIndex] = useState(0);

  const updateList = () => {
    if (orderList) {
      dispatch(f({ params: { search: srcString || "", order_by: orderList[sortIndex].order_by, sort: orderList[sortIndex].sort } }));
    } else {
      dispatch(f({ params: { search: srcString || "" } }));
    }
  };

  const onSortChanged = () => {
    setSortIndex((sortIndex + 1) % orderList.length);
  };

  useEffect(() => {
    updateList();
  }, [sortIndex]);

  return (
    <Stack direction="row" spacing={2} sx={{ mb: 3 }} key={`src-filt-${index}`} {...other}>
      <TextField
        key={`src-bar-${index}`}
        value={srcString}
        onChange={(e) => setSrc(e.target.value)}
        label="cerca"
        size="small"
        onKeyDown={(e) => e.key === "Enter" && updateList()}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" onClick={updateList}>
              <Search />
            </InputAdornment>
          ),
        }}
      />
      {orderList && (
        <Button key={`sort-btn-${index}`} variant="contained" startIcon={orderList[sortIndex]?.svg} size="small" style={{ backgroundColor: "#fff", color: "#000" }} onClick={onSortChanged}>
          {orderList[sortIndex]?.label}
        </Button>
      )}
    </Stack>
  );
}
