import { AccountCircle, History, Inventory, Logout, MusicNote, PersonOutlineTwoTone, Sell, ShoppingCart, Store } from "@mui/icons-material";
import { Alert, AppBar, Badge, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Drawer, Grid, Hidden, IconButton, ListItemIcon, Menu, MenuItem, Snackbar, Toolbar, Typography, styled, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import NavbarItem from "./components/NavbarItem";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as SvgDashboard } from "./../../assets/svg/SvgDashboard.svg";
import { ReactComponent as SvgProgramma } from "./../../assets/svg/SvgProgramma.svg";
import { ReactComponent as SvgABS } from "./../../assets/svg/SvgABS.svg";
import { getPrestazioneAttiva } from "../prestazione/prestazioneAPI";
import { setPrestazioneAttiva } from "../prestazione/prestazioneSlice";
import SvgReferto from "../../assets/svg/SvgReferto";
import DialogSelectCodice from "../programmi/components/DialogSelectCodice";
import { DialogCreaVendiPrestazione } from "../prestazione/DialogCreaVendiPrestazione";
import { setSelezionaCodiceData, setCreaVendiPrestazioneData, setSnackbarData } from "../programmi/programmiSlice";
import { Cart } from "./Cart";
import { setInfoTecnicaABS } from "../ecommerce/EcommerceSlice";

export function Navbar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const c = theme.palette;
  const { user, token, selectedSection } = useSelector((state) => state.login);
  const prestazioneAttiva = useSelector((state) => state.prestazioni.prestazioneAttiva);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { creaVendiPrestazioneData, selezionaCodiceData, snackbarData } = useSelector((state) => state.programmi);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { carrello, infoTecnicaABS } = useSelector((state) => state.ecommerce);

  const handleLogout = () => {
    dispatch({ type: "USER_LOGOUT" });
    navigate("/tommy/login");
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const loadPrestazioneAttiva = async () => {
    if (user?.tipologia === "P" || user?.tipologia === "PA") {
      let response;
      try {
        response = await getPrestazioneAttiva(token);
      } catch (err) {
        return;
      }
      if (response.data !== false) {
        dispatch(setPrestazioneAttiva(response.data));
      } else {
        dispatch(setPrestazioneAttiva(null));
      }
    }
  };

  const onApriDialogCreaVendiCodice = (props) => {
    dispatch(setCreaVendiPrestazioneData({ ...props }));
  };

  useEffect(() => {
    dispatch(setSelezionaCodiceData(null));
    dispatch(setCreaVendiPrestazioneData(null));
    dispatch(setSnackbarData(null));
    loadPrestazioneAttiva();

    return () => {
      dispatch(setSelezionaCodiceData(null));
      dispatch(setCreaVendiPrestazioneData(null));
    };
  }, []);

  const sellProgrammi = () => {
    dispatch(setSelezionaCodiceData({ idProgramma: null, codiciServizio: null, isVendita: true, tipoAttivazione: "C", onSuccess: onApriDialogCreaVendiCodice }));
  };

  const closeSellProgrammi = () => {
    dispatch(setSelezionaCodiceData(null));
  };

  const closeCreaVendiPrestazione = () => {
    dispatch(setCreaVendiPrestazioneData(null));
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    dispatch(setSnackbarData(null));
  };

  const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      right: 1,
      top: 3,
      padding: '3px 0px 0px 0px',
    },
  }));


  return (
    <Box>
      <AppBar position="static" sx={{ backgroundColor: c.secondary.main }}>
        <Toolbar sx={{ m: 0, p: 0 }}>


          {prestazioneAttiva?.id && (
            <Box sx={{ width: '50%' }}>
              <NavbarItem height={'auto'} glow={true} shortVarian={false} to="/tommy/esecuzione-prestazione" params={{ id_prestazione: prestazioneAttiva.id }} icon={<Hidden smDown><SvgProgramma style={{ width: '1.25rem' }} /></Hidden>} title={"Programma in corso: " + prestazioneAttiva.programma.nome + ""} selected={selectedSection === "/tommy/esecuzione-prestazione" ? true : false} />
            </Box>
          )}


          <Box sx={{ display: "flex", justifyContent: "end", alignItems: "center", width: prestazioneAttiva?.id ? '50%' : '100%' }}>
            <Box textAlign="right">
              <Box>
                {user?.tipologia === "PA" && <Typography sx={{ display: "inline-block", fontSize: "0.8rem" }}>Operatore</Typography>}
                {user?.tipologia === "PP" && <Typography sx={{ display: "inline-block", fontSize: "0.8rem" }}>Rivenditore</Typography>}
                {(user?.nome || user?.cognome) && (
                  <Typography sx={{ display: "inline-block", fontWeight: "bold", ml: 1, fontSize: '1rem' }}>
                    {user?.nome} {user?.cognome}
                  </Typography>
                )}
              </Box>
              <Box>
                <Hidden xsDown smDown>
                  <Typography sx={{ display: "inline-block", mr: "5px", fontSize: "0.8rem" }}>Codice Utente: </Typography>
                </Hidden>
                <Typography sx={{ display: "inline-block", fontWeight: "bold", fontSize: '1rem' }}>{user?.codice_utente}</Typography>
              </Box>
            </Box>

            <Divider orientation="vertical" sx={{ borderColor: "lightgray", height: "30px", p: '0.5rem' }} />

            <IconButton onClick={handleClick} size="small" sx={{ ml: '1rem' }} aria-controls={open ? "account-menu" : undefined} aria-haspopup="true" aria-expanded={open ? "true" : undefined}>
              <AccountCircle sx={{ width: '2rem', height: '2rem', color: "white" }}></AccountCircle>
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              slotProps={{
                paper: {
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": { width: 32, height: 32, ml: -0.5, mr: 1 },
                    "&:before": { content: '""', position: "absolute", top: 0, right: 14, width: 10, height: 10, bgcolor: "background.paper", transform: "translateY(-50%) rotate(45deg)", zIndex: 0 },
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem onClick={() => navigate("/tommy/profilo")}>
                <ListItemIcon>
                  <PersonOutlineTwoTone fontSize="small" />
                </ListItemIcon>
                Profilo
              </MenuItem>
              {
                user?.tipologia === "P" || user?.tipologia === "PA" ?
                  <MenuItem onClick={() => navigate("/tommy/storico-abbonamenti")}>
                    <ListItemIcon>
                      <History fontSize="small" />
                    </ListItemIcon>
                    Storico Abbonamenti
                  </MenuItem> : <></>
              }

              <MenuItem onClick={() => navigate("/tommy/ordini")}>
                <ListItemIcon>
                  <Inventory fontSize="small" />
                </ListItemIcon>
                Cronologia Ordini
              </MenuItem>
              <MenuItem onClick={handleLogout}>
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                Logout
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      <AppBar position="static" sx={{ backgroundColor: c.primary.main }}>
        <Toolbar sx={{}}>
          <Grid container alignItems="center" columnSpacing={{ xs: 2, sm: 3, md: 3 }}>
            <NavbarItem to="/tommy/homepage" icon={<SvgDashboard style={{ width: '1.25rem' }} />} title="Dashboard" selected={selectedSection === "/tommy/homepage" ? true : false} />
            <Divider orientation="vertical" sx={{ borderColor: c.text.unselected, height: "30px" }} />
            <NavbarItem to="/tommy/programmi" icon={<SvgProgramma style={{ width: '1.25rem' }} />} title="I miei programmi" selected={selectedSection === "/tommy/programmi" ? true : false} />
            {user?.tipologia !== "PP" && (
              <>
                <Divider orientation="vertical" sx={{ borderColor: c.text.unselected, height: "30px" }} />
                <NavbarItem to="/tommy/elaborati" icon={<SvgReferto style={{ width: '1.25rem' }} />} title="I miei elaborati" selected={selectedSection === "/tommy/elaborati" ? true : false} />
              </>
            )}
            {/* {user?.tipologia !== "P" && (  */}
            {user?.tipologia !== "PP" ? (
              <>
                <Divider orientation="vertical" sx={{ borderColor: c.text.unselected, height: "30px" }} />
                <NavbarItem to="/tommy/abs" icon={<SvgABS style={{ width: '1.25rem' }} />} title="Audio Brain Stimulation" selected={selectedSection === "/tommy/abs" ? true : false} />
              </>
            ) : (
              <></>
            )}
            {/* )} */}
          </Grid>

          <Grid item sx={{ display: 'flex', mr: 1 }}>

            {(user?.tipologia === "PP" || user?.tipologia === "PA") && (
              <Grid item>
                <Box display="flex">
                  <Hidden mdUp>
                    <IconButton
                      aria-label="Add"
                      onClick={sellProgrammi}
                      sx={{}}
                    >
                      <Sell sx={{ color: c.secondary.light, fontSize: '1.25em', mt: 0.5 }} />
                    </IconButton>
                  </Hidden>
                  <Hidden mdDown>
                    <Button
                      variant="contained"
                      startIcon={<Sell />}
                      sx={{
                        backgroundColor: c.secondary.light,
                        color: c.primary.dark,
                        "&:hover": {
                          backgroundColor: c.secondary.light,
                        },
                        mr: 2
                      }}
                      onClick={sellProgrammi}
                    >
                      <Box sx={{ whiteSpace: "nowrap" }}>CEDI CODICI</Box>
                    </Button>
                  </Hidden>
                </Box>
              </Grid>
            )}

            <Grid item>
              <Box display="flex" sx={{}}>
                <Hidden mdUp>
                  <IconButton
                  sx={{ mr: 1}}
                    aria-label="Add"
                    onClick={() => navigate("/tommy/ecommerce")}
                  >
                    <Store sx={{ color: c.secondary.light, fontSize: '1.5em', }} />
                  </IconButton>
                </Hidden>
                <Hidden mdDown>
                  <Button
                    variant="contained"
                    startIcon={<Store />}
                    sx={{
                      backgroundColor: c.secondary.light,
                      color: c.primary.dark,
                      "&:hover": {
                        backgroundColor: c.secondary.light,
                      },
                      mr: 2
                    }}
                    onClick={() => navigate("/tommy/ecommerce")}
                  >
                    <Box sx={{ whiteSpace: "nowrap" }}>ACQUISTO PROGRAMMI</Box>
                  </Button>
                </Hidden>
              </Box>
            </Grid>

            <Grid item sx={{ display: 'flex' }}>
              <Box display="flex" sx={{ margin: 'auto' }}>
                <StyledBadge badgeContent={carrello?.voci?.length} color="secondary" >
                  <IconButton
                    sx={{
                      backgroundColor: 'white', "&:hover": {
                        backgroundColor: c.secondary.light,
                      },
                    }}
                    size="small"
                    aria-label="Add"
                    onClick={() => setDrawerOpen(true)}
                  >
                    <ShoppingCart sx={{ color: c.primary.main }} />
                  </IconButton>
                </StyledBadge>
              </Box>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>

      {selezionaCodiceData !== null && selezionaCodiceData !== undefined && <DialogSelectCodice onClose={closeSellProgrammi} props={selezionaCodiceData} />}

      {creaVendiPrestazioneData !== null && creaVendiPrestazioneData !== undefined && <DialogCreaVendiPrestazione props={creaVendiPrestazioneData} onClose={closeCreaVendiPrestazione} />}

      <Snackbar open={snackbarData !== null} anchorOrigin={{ vertical: snackbarData?.vertical ?? 'top', horizontal: "center" }} autoHideDuration={4000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarData?.severity} variant="filled" sx={{ width: "100%" }}>
          {snackbarData?.message}
        </Alert>
      </Snackbar>

      <Drawer
        anchor={'right'}
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        <Hidden mdUp>
          <Box sx={{ width: '250px' }}>
            <Cart setDrawerOpen={setDrawerOpen} />
          </Box>
        </Hidden>
        <Hidden mdDown>
          <Box sx={{ width: '400px' }}>
            <Cart setDrawerOpen={setDrawerOpen} />
          </Box>
        </Hidden>

      </Drawer>


      <Dialog open={infoTecnicaABS != null} onClose={() => dispatch(setInfoTecnicaABS(null))} maxWidth="md" fullWidth>
        <DialogTitle variant="dialogTitle">
          Informazioni Tecnica ABS
        </DialogTitle>
        <DialogContent>
          {
            user?.tipologia == 'PA' ?
              <Box>
                <Typography variant="body1" sx={{ mb: 2 }}>
                  <b>Utente:</b> {infoTecnicaABS?.utente_privato?.nome} {infoTecnicaABS?.utente_privato?.cognome}
                </Typography>
              </Box>
              : <>  </>
          }



          {
            infoTecnicaABS?.showFrasi == undefined || infoTecnicaABS?.showFrasi == true ?
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Box>
                    <Typography variant="body1" sx={{ mb: 2 }}>
                      <b>  Frasi Negative</b>
                    </Typography>
                    <ol>
                      {
                        infoTecnicaABS?.frasi_negative?.map((f, i) => {
                          return <li key={i}>{f.testo_frase}</li>
                        })
                      }
                    </ol>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box>
                    <Typography variant="body1" sx={{ mb: 2 }}>
                      <b> Frasi Positive</b>
                    </Typography>
                    <ol>
                      {
                        infoTecnicaABS?.frasi_positive?.map((f, i) => {
                          return <li key={i}>{f.testo_frase}</li>
                        })
                      }
                    </ol>
                  </Box>
                </Grid>


              </Grid>
              :
              <>
                <Typography variant="body1" sx={{ mb: 2 }}>
                  <b>  N. Frasi Negative</b>: {infoTecnicaABS?.frasi_negative?.length}
                </Typography>
                <Typography variant="body1" sx={{ mb: 2 }}>
                  <b> N. Frasi Positive</b>: {infoTecnicaABS?.frasi_positive?.length}
                </Typography>
              </>
          }

          <Box sx={{ mt: 2 }}>
            <Typography variant="body1" sx={{ mb: 2 }}>
              <b> Brani</b>
            </Typography>
            <Grid container spacing={2}>
              {
                infoTecnicaABS?.brani?.map((b, i) => {
                  return (
                    <Grid item key={i} xs={3} sx={{ display: 'flex' }}>
                      <MusicNote />
                      <Typography variant="body" sx={{ ml: 1 }}>
                        {b.nome}
                      </Typography>
                    </Grid>
                  );
                })
              }
            </Grid>

          </Box>

        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => dispatch(setInfoTecnicaABS(null))}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
