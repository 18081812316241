import { AddCircle, Close } from "@mui/icons-material";
import { Box, FormControl, IconButton, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setTecnica } from "./absSlice";

export function Frasi(props) {
  const { isPositive } = props;

  const dispatch = useDispatch();
  const boxRef = useRef(null);
  const { tecnica } = useSelector((state) => state.abs);

  const key = isPositive ? 'frasi_positive' : 'frasi_negative';


  useEffect(() => {
    if (boxRef.current) {
      boxRef.current.scrollTop = boxRef.current.scrollHeight;
    }
  }, [tecnica[key]]);


  const onChangeFrase = (e, type, index) => {
    const _tecnica = JSON.parse(JSON.stringify(tecnica));
    _tecnica[type][index].testo_frase = e.target.value;
    dispatch(setTecnica(_tecnica));
  };

  const onRemoveFrase = (type, index) => {
    const _tecnica = JSON.parse(JSON.stringify(tecnica));
    _tecnica[type].splice(index, 1);
    dispatch(setTecnica(_tecnica));
  };

  const onAddFrase = (type) => {
    const _tecnica = JSON.parse(JSON.stringify(tecnica));
    _tecnica[type].push({ testo_frase: '' });
    dispatch(setTecnica(_tecnica));
  };




  return <Box sx={{ p: 2, bgcolor: "#fff", height: '100%', display: 'flex', flexDirection: 'column' }} >
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Typography variant="subtitle2">Frasi {isPositive ? 'Positive' : "Negative"}</Typography>
      <IconButton onClick={() => onAddFrase(key)}>
        <AddCircle color="primary" />
      </IconButton>
    </Stack>
    <Box ref={boxRef} sx={{ overflowY: "auto", flex: 1 }}>
      {tecnica[key]?.map((f, i) => (
        <Stack key={i} direction="row" alignItems="center" sx={{ my: 1 }}>
          <FormControl fullWidth>
            <TextField
              value={f.testo_frase}
              onChange={(e) =>
                onChangeFrase(e, key, i)
              }
              InputProps={{
                maxRows: 4,
                multiline: true,
                inputComponent: 'textarea'
              }}
              size="small"
              placeholder="Inserisci una frase negativa"
            />
          </FormControl>
          <IconButton
            onClick={() =>
              onRemoveFrase(key, i)
            }
          >
            <Close />
          </IconButton>
        </Stack>
      ))}
    </Box>
  </Box>;

}
