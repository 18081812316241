import { useTheme } from "@emotion/react";
import { Backdrop, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, FormControl, InputLabel, MenuItem, OutlinedInput, Paper, Select, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProgrammiConCodiceAttivo } from "../../prestazione/prestazioneAPI";
import { setSelezionaCodiceData } from "../programmiSlice";

export default function DialogSelectCodice({ onClose, props }) {
  const { isVendita, idProgramma, tipoAttivazione, onSuccess } = props;
  const dispatch = useDispatch();
  const c = useTheme().palette;
  const { token } = useSelector((state) => state.login);
  const [programmaSlcted, setProgrammaSlcted] = useState(null);
  const [codiceSlcted, setCodiceSlcted] = useState([]);
  const [dialogError, setDialogError] = useState(null);
  const [listaProgrammi, setListaProgrammi] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const caricaCodici = async () => {
    try {
      const response = await getProgrammiConCodiceAttivo(token);
      setListaProgrammi(response.data);
      if (idProgramma != null) {
        setProgrammaSlcted(idProgramma);
      }
      return response.data;
    } catch (err) {
      console.error(err);
    }
    return [];
  }

  const selezioneAutomatica = async () => {
    const _listaProgrammi = await caricaCodici();
    if (_listaProgrammi.length > 0 && idProgramma != null) {
      const codiciProgrammaSelezionato = _listaProgrammi.find((el) => el.id === idProgramma)?.codici;
      if (codiciProgrammaSelezionato.length > 0) {
        onSuccess({ tipoAttivazione: tipoAttivazione, codiciServizio: [codiciProgrammaSelezionato[0]], idProgramma: idProgramma, isVendita: isVendita });
        dispatch(setSelezionaCodiceData(null));
      }
    }
  };

  const asyncInit = async () => {
    if (isVendita) {
      await caricaCodici();
    } else {
      //lo porto direttamente alla pagina di riepilogo attivazione 
      await selezioneAutomatica();
    }

    setIsLoading(false);
  }

  useEffect(() => {
    asyncInit();
  }, []);


  const onAttivaCodice = async () => {
    let error = "";
    if (programmaSlcted == null) error += "Seleziona il Programma\n";
    if (codiceSlcted.length === 0) error += "Seleziona il Codice\n";

    setDialogError(error);
    if (error.length > 0) {
      return;
    }

    onSuccess({ tipoAttivazione: tipoAttivazione, codiciServizio: codiceSlcted, idProgramma: programmaSlcted, isVendita: isVendita });
  };

  const closeDialog = () => {
    setDialogError(null);
    setCodiceSlcted([]);
    onClose();
  };

  useEffect(() => {
    setCodiceSlcted([]);
  }, [programmaSlcted]);

  return (
    <Dialog open={true} onClose={closeDialog} maxWidth="sm" fullWidth>

      {
        isLoading ?
          <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
            <CircularProgress color="inherit" />
          </Backdrop>
          : <>  </>
      }

      <DialogContent sx={{ p: 4, pt: 2, fontSize: "14px", minHeight: 200 }}>
        {
          !isLoading ?
            <Stack direction="column" sx={{ justifyContent: "center", alignItems: "center" }}>
              <Typography variant="h5" sx={{ fontFamily: "Poppins-SemiBold", color: c.primary.main, p: 2 }}>
                Seleziona {isVendita ? "uno o più codici" : "un codice"}
              </Typography>
              {idProgramma == null && (
                <FormControl fullWidth sx={{ my: 2 }}>
                  <InputLabel>Programma</InputLabel>
                  <Select
                    input={<OutlinedInput label="Programma" />}
                    value={programmaSlcted || ""}
                    onChange={(e) => {
                      setProgrammaSlcted(e.target.value);
                    }}
                  >
                    {listaProgrammi?.map((programma) => (
                      <MenuItem key={programma.id} value={programma.id}>
                        {programma.nome}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              <FormControl fullWidth sx={{ my: 2 }}>
                <InputLabel>Codice</InputLabel>
                <Select
                  disabled={programmaSlcted == null}
                  input={<OutlinedInput label="Codice" />}
                  multiple={isVendita}
                  value={codiceSlcted}
                  renderValue={(selected) => {
                    return (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => (
                          <Box key={value} sx={{ p: 0.5, bgcolor: c.primary.main, color: "#fff", borderRadius: '5px' }} >
                            {value}
                          </Box>
                        ))}
                      </Box>
                    );
                  }}
                  onChange={(e) => {
                    const value = e.target.value;
                    setCodiceSlcted(typeof value === "string" ? value.split(",") : value);
                  }}
                >
                  {listaProgrammi.find((el) => el.id === programmaSlcted)?.codici?.map((codice) => (
                    <MenuItem key={codice} value={codice}>
                      {codice}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {dialogError && (
                <Typography variant="p" sx={{ fontFamily: "Poppins-SemiBold", color: c.error.main, whiteSpace: "pre-line", textAlign: "center" }}>
                  {dialogError}
                </Typography>
              )}
            </Stack>
            :
            <>  </>
        }

      </DialogContent>

      <DialogActions>
        <Button onClick={closeDialog} color="primary">
          Annulla
        </Button>
        <Button onClick={onAttivaCodice} color="primary" variant="contained">
          Continua
        </Button>
      </DialogActions>

    </Dialog>
  );
}
