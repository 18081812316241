import React, { useEffect, useState } from "react";
import { Button, Typography, TextField, Dialog, DialogTitle, DialogContent, Grid, FormControl, DialogActions, InputLabel, MenuItem, Select } from "@mui/material";
import { creaCategoriaAudio, modifyCategoriaAudio } from "../../dashboardAdminAPI";
import { getListaCategorieAudio } from "../../dashboardAdminSlice";
import { useDispatch, useSelector } from "react-redux";
import { getAPIErrorMessage } from "../../../../common/utils";

const CategoriaAudioDetails = ({ isDialogOpen, setIsDialogOpen, selectedCategoria = null }) => {
  const dispatch = useDispatch();
  const tokenAdmin = useSelector((state) => state.loginadmin.tokenAdmin);

  const [categoriaData, setCategoriaData] = useState({ nome: null, categoria_tipologia_utente: [] });
  const [error, setError] = useState("");

  useEffect(() => {
    if (selectedCategoria?.id) setCategoriaData({ ...selectedCategoria });
    else setCategoriaData({ nome: null, categoria_tipologia_utente: [] });
  }, [isDialogOpen]);

  const saveCategoria = async () => {
    if (!categoriaData.nome?.trim()) {
      setError("Inserire il Nome della Categoria");
      return;
    }

    const data = { nome: categoriaData.nome, categoria_tipologia_utente: categoriaData.categoria_tipologia_utente };
    try {
      if (selectedCategoria?.id) {
        await modifyCategoriaAudio(data, tokenAdmin, selectedCategoria.id);
      } else {
        await creaCategoriaAudio(data, tokenAdmin);
      }
      onClose();
    } catch (e) {
      setError(getAPIErrorMessage(e));
      return;
    }
  };

  const onClose = () => {
    dispatch(getListaCategorieAudio());
    setError("");
    setIsDialogOpen(false);
  };


  const utentiTypes = [
    {
      'codice': 'P',
      'descrizione': 'Utenti Privati',
    },
    {
      'codice': 'PA',
      'descrizione': 'Professionisti Attivi',
    },
   
  ];



  return (
    <>
      {/* Dialog per l'edit */}
      <Dialog open={isDialogOpen || false} maxWidth="sm" fullWidth>
        <DialogTitle variant="dialogTitle">{selectedCategoria?.id ? "Modifica Categoria Audio" : "Aggiungi Categoria Audio"}</DialogTitle>
        <DialogContent>
          <form>
            <Grid container spacing={2} sx={{ pt: 1 }}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <TextField label="Nome" name="nome" value={categoriaData.nome ?? ""} onChange={(e) => setCategoriaData({ ...categoriaData, nome: e.target.value })} />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Tipologie di Utenti Abilitati alla visione</InputLabel>
                <Select label="Tipologie di Utenti Abilitati alla visione" name="categoria_tipologia_utente" multiple value={categoriaData.categoria_tipologia_utente} onChange={(e) => setCategoriaData({ ...categoriaData, categoria_tipologia_utente: e.target.value })}>
                  {utentiTypes?.map((type, i) => (
                    <MenuItem key={i} value={type.codice}>
                      {type.descrizione}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            </Grid>
            
          </form>

          <Typography color="error" style={{ whiteSpace: "pre-line" }} sx={{ mt: 3 }}>
            {error}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} sx={{ color: "#000" }}>
            Annulla
          </Button>
          <Button variant="contained" onClick={saveCategoria}>
            SALVA
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CategoriaAudioDetails;
