import { useTheme } from "@emotion/react";
import { Backdrop, Box, CircularProgress, Grid, MenuItem, Select, Stack, TextField, Tooltip, Typography, styled, tooltipClasses, useMediaQuery } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getListaAudio, getListaCategorieAudio, setSelectedCategoriaAudioStore } from "./absSlice";
import { CardBrano } from "./CardBrano";
import { CONFIG } from "../../config/envConfig";
import { AccessAlarm, Info } from "@mui/icons-material";

export function StoreAudio(props) {
  const msg = (<div>Acquista l'utilizzo dei brani di tuo interesse.
    <br/>
    <AccessAlarm sx={{ fontSize: '16px' }} />: Vedi il tempo di utilizzo del brano.
    <br/>
    Attiva il brano per iniziare l'ascolto.</div>
  );
  const { showPrice = true, cardSize = 4, isCardIconChecked, onCardAddClick, fullHeight = false, storeMsg = msg } = props;
  const screenRef = useRef();
  const dispatch = useDispatch();
  const gg = useRef();
  const [srcAudio, setSrcAudio] = useState("");
  const theme = useTheme();
  const c = theme.palette;
  const { listaAudio, listaCategorieAudio, isLoadingAudio, selectedCategoriaAudioStore } = useSelector((state) => state.abs);
  const matchSm = useMediaQuery(theme.breakpoints.up("sm"));

  const [screenH, setScreenH] = useState(null);

  const handleResize = () => {
    if (screenRef.current) {
      setScreenH(window.innerHeight - screenRef.current.getBoundingClientRect().top - CONFIG.FOOTER_HEIGHT);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [screenRef]);

  useEffect(() => {
    dispatch(getListaAudio({ id_categoria: selectedCategoriaAudioStore }));
  }, [selectedCategoriaAudioStore]);


  useEffect(() => {
    dispatch(getListaCategorieAudio());
    dispatch(getListaAudio({ id_categoria: selectedCategoriaAudioStore }));
  }, []);


  var audioFiltered = (!!srcAudio ? listaAudio.filter((a) => a.nome?.toLowerCase().includes(srcAudio.toLowerCase()) || a.descrizione?.toLowerCase().includes(srcAudio.toLowerCase())) : listaAudio);


  const CustomTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: { color: theme.palette.primary.light },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.primary.light,
      fontFamily: "Poppins-Regular",
      color: "#000",
      boxShadow: theme.shadows[1],
      fontSize: 12,
      textAlign: "left",
    },
  }));

  return (

    <Box ref={screenRef} height={fullHeight ? 'auto' : screenH} sx={{ display: 'flex', bgcolor: "#fff", flexDirection: 'column', overflowY: fullHeight ? 'hidden' : 'auto', p: 1.5, mt: 1 }}>
      <Typography variant="h6" align="left">
        Sfoglia la libreria sonora
      </Typography>
      <Stack spacing={1} direction="row" sx={{ alignItems: 'top' }}>
        <Typography variant="body2" align="left" sx={{ alignContent: 'center' }}>
          {
            storeMsg
          }

        </Typography>
        <CustomTooltip
          sx={{ ml: 1 }}
          title={
            <div style={{ whiteSpace: 'pre-line' }}>
              Tutte le unità sonore di Audio Brain Stimulation sono state progettate come sottofondi di ascolto da utilizzare nel corso di specifiche tecniche di benessere personale; esse non sono acquistabili come tali. Ciò che viene concesso, al termine della procedura di acquisto, è il loro utilizzo esclusivo nella propria area riservata per un tempo limitato specificato per ogni singolo brano.
              <br />
              Audio Brain Stimulation è un marchio registrato. Tutti i suoi contenuti sono soggetti a copyright.
            </div>
          } arrow>
          <Info color="primary" sx={{ mr: 1 }} />
        </CustomTooltip>
      </Stack>

      <Box sx={{ display: 'flex', flexDirection: matchSm ? 'row' : 'column', justifyContent: 'space-between', mt: 4 }}>
        <Box sx={{}}>
          <Typography variant="body" sx={{ color: c.primary.main, display: 'inline-block' }} align="left">
            Di cosa hai bisogno?
          </Typography>
          <Select
            value={selectedCategoriaAudioStore}
            size="small"
            sx={{ ml: 2 }}
            onChange={(event) => {
              dispatch(setSelectedCategoriaAudioStore(event.target.value));
            }}
          >
            <MenuItem key={'0'} value={'0'}>
              TUTTI
            </MenuItem>
            {listaCategorieAudio.map((f) => (
              <MenuItem key={f.id} value={f.id}>
                {f.nome.toUpperCase()}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <TextField size="small" sx={{ width: '300px', maxWidth: '100%', mt: matchSm ? 0 : 1 }} label="Cerca brano" value={srcAudio} onChange={(e) => setSrcAudio(e.target.value)} />
      </Box>
      <Box>
        <Grid ref={gg} container spacing={4} sx={{ marginTop: '0px', maxHeight: fullHeight ? 'auto' : window.innerHeight - gg.current?.getBoundingClientRect().top, pb: 1 }}>
          {
            isLoadingAudio && <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
              <CircularProgress color="inherit" />
            </Backdrop>
          }
          {
            !isLoadingAudio && audioFiltered.map((a, i) => (
              <Grid item xs={12} sm={6} md={cardSize} key={i} style={{ paddingTop: '0px' }}>
                <CardBrano audio={a} showPrice={showPrice} isCardIconChecked={isCardIconChecked} onCardAddClick={onCardAddClick} />
              </Grid>
            ))
          }

        </Grid>
      </Box>

      {
        audioFiltered.length == 0 && <Typography variant="body2" align="center" sx={{ mt: 2 }}>Nessun brano trovato</Typography>
      }


    </Box>
  );
}
