import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../config/axiosConfig";

export const getCodici = createAsyncThunk("programmi/getCodici", async (props, thunkAPI) => {
  try {
    const state = thunkAPI.getState();
    const token = state.login.token;
    const config = {
      params: { flg_attivo: 1, ...props?.params },
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    const response = await api.get("/api/codici_servizio", config);
    return { listaCodici: response.data.data };
  } catch (error) {
    console.error("getCodici=>", error);
    throw error.response.data;
  }
});

export const getAbbonamenti = createAsyncThunk("programmi/getAbbonamenti", async (props, thunkAPI) => {
  try {
    const state = thunkAPI.getState();
    const token = state.login.token;
    const config = {
      params: { flg_attivo: 1, ...props?.params },
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    const response = await api.get("/api/abbonamenti", config);
    return { listaAbbonamenti: response.data.data };
  } catch (error) {
    console.error("getAbbonamenti=>", error);
    throw error.response.data;
  }
});

export const getPrestazioni = createAsyncThunk("programmi/getPrestazioni", async (props, thunkAPI) => {
  try {
    const state = thunkAPI.getState();
    const token = state.login.token;
    const config = {
      params: { ...props?.params },
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    const response = await api.get("/api/prestazioni", config);
    return { listaPrestazioni: response.data.data.data, current_page: response.data.data.current_page, per_page: response.data.data.per_page, total: response.data.data.total };
  } catch (error) {
    console.error("getPrestazioni=>", error);
    throw error.response.data;
  }
});

export const getStoricoAbbonamenti = createAsyncThunk("programmi/getStoricoAbbonamenti", async (props, thunkAPI) => {
  try {
    const state = thunkAPI.getState();
    const token = state.login.token;
    const config = {
      params: { flg_storico: 1, ...props?.params },
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    const response = await api.get("/api/abbonamenti", config);
    return { listaStoricoAbbonamenti: response.data.data.data, current_page: response.data.data.current_page, per_page: response.data.data.per_page, total: response.data.data.total };
  } catch (error) {
    console.error("getStoricoAbbonamenti=>", error);
    throw error.response.data;
  }
});

export const getMovimentiCodici = createAsyncThunk("programmi/getMovimentiCodici", async (props, thunkAPI) => {
  try {
    const state = thunkAPI.getState();
    const token = state.login.token;
    const config = {
      params: { ...props?.params },
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    const response = await api.get("/api/movimenti/getCodiciAcquistati", config);
    return { listaMovimentiCodici: response.data.data.data, current_page: response.data.data.current_page, per_page: response.data.data.per_page, total: response.data.data.total };
  } catch (error) {
    console.error("getMovimentiCodici=>", error);
    throw error.response.data;
  }
});

export const initialState = {
  listaCodici: [],
  isLoadingCodici: false,
  listaAbbonamenti: [],
  isLoadingAbbonamenti: false,
  listaPrestazioni: [],
  pageInfoPrestazioni: {},
  isLoadingPrestazioni: false,
  listaStoricoAbbonamenti: [],
  pageInfoStoricoAbbonamenti: {},
  isLoadingStoricoAbbonamenti: false,
  listaMovimentiCodici: [],
  pageInfoMovimentiCodici: {},
  isLoadingMovimentiCodici: false,
  selectedTab: 0,

  selezionaCodiceData: null,
  creaVendiPrestazioneData: null,

  snackbarData: null,

  error: null,
};

export const programmiSlice = createSlice({
  name: "programmi",
  initialState,
  reducers: {
    setSelectedTab: (state, action) => {
      state.selectedTab = action.payload;
    },
    setSelezionaCodiceData: (state, action) => {
      state.selezionaCodiceData = action.payload;
    },
    setCreaVendiPrestazioneData: (state, action) => {
      state.creaVendiPrestazioneData = action.payload;
    },
    setSnackbarData: (state, action) => {
      state.snackbarData = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      //#region codici
      .addCase(getCodici.pending, (state, action) => {
        state.isLoadingCodici = true;
      })
      .addCase(getCodici.fulfilled, (state, action) => {
        state.listaCodici = action.payload.listaCodici;
        state.isLoadingCodici = false;
      })
      .addCase(getCodici.rejected, (state, action) => {
        state.isLoadingCodici = false;
        state.error = action.error.message || action.error;
      })
      // #endregion
      //#region abbonamenti
      .addCase(getAbbonamenti.pending, (state, action) => {
        state.isLoadingAbbonamenti = true;
      })
      .addCase(getAbbonamenti.fulfilled, (state, action) => {
        state.listaAbbonamenti = action.payload.listaAbbonamenti;
        state.isLoadingAbbonamenti = false;
      })
      .addCase(getAbbonamenti.rejected, (state, action) => {
        state.isLoadingAbbonamenti = false;
        state.error = action.error.message || action.error;
      })
      // #endregion
      //#region prestazioni
      .addCase(getPrestazioni.pending, (state, action) => {
        state.isLoadingPrestazioni = true;
      })
      .addCase(getPrestazioni.fulfilled, (state, action) => {
        const { listaPrestazioni, ...pageInfo } = action.payload;
        state.listaPrestazioni = listaPrestazioni;
        state.pageInfoPrestazioni = pageInfo;
        state.isLoadingPrestazioni = false;
      })
      .addCase(getPrestazioni.rejected, (state, action) => {
        state.isLoadingPrestazioni = false;
        state.error = action.error.message || action.error;
      })
      // #endregion
      //#region storico abbonamenti
      .addCase(getStoricoAbbonamenti.pending, (state, action) => {
        state.isLoadingStoricoAbbonamenti = true;
      })
      .addCase(getStoricoAbbonamenti.fulfilled, (state, action) => {
        const { listaStoricoAbbonamenti, ...pageInfo } = action.payload;
        state.listaStoricoAbbonamenti = listaStoricoAbbonamenti;
        state.pageInfoStoricoAbbonamenti = pageInfo;
        state.isLoadingStoricoAbbonamenti = false;
      })
      .addCase(getStoricoAbbonamenti.rejected, (state, action) => {
        state.isLoadingStoricoAbbonamenti = false;
        state.error = action.error.message || action.error;
      })
      // #endregion
      //#region storico abbonamenti
      .addCase(getMovimentiCodici.pending, (state, action) => {
        state.isLoadingMovimentiCodici = true;
      })
      .addCase(getMovimentiCodici.fulfilled, (state, action) => {
        const { listaMovimentiCodici, ...pageInfo } = action.payload;
        state.listaMovimentiCodici = listaMovimentiCodici;
        state.pageInfoMovimentiCodici = pageInfo;
        state.isLoadingMovimentiCodici = false;
      })
      .addCase(getMovimentiCodici.rejected, (state, action) => {
        state.isLoadingMovimentiCodici = false;
        state.error = action.error.message || action.error;
      });
    // #endregion
  },
});

export const {
  setSelectedTab, setSelezionaCodiceData, setCreaVendiPrestazioneData, setSnackbarData
} = programmiSlice.actions;

export default programmiSlice.reducer;
