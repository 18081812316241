import * as React from "react";
import { Box, Button, IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getListaReferti, getListaTipoStrutturaReferti } from "../../dashboardAdminSlice";
import RefertiDetails from "./RefertiDetails";
import { Add, Edit } from "@mui/icons-material";
import LocalPaginateTable from "../LocalPaginateTable";

export function Referti() {
  const dispatch = useDispatch();
  const [selectedReferto, setSelectedReferto] = React.useState(null);
  const [isEditorOpen, setIsEditorOpen] = React.useState(false);
  const { listaReferti, isLoading } = useSelector((state) => state.dashboardAdmin);

  React.useEffect(() => {
    dispatch(getListaReferti());
    dispatch(getListaTipoStrutturaReferti());
  }, []);

  const openDialog = (referto) => {
    setSelectedReferto(referto);
    setIsEditorOpen(true);
  };

  return (
    <>
      {isEditorOpen ? (
        <RefertiDetails setIsEditorOpen={setIsEditorOpen} selectedReferto={selectedReferto || false} />
      ) : (
        <Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button variant="contained" endIcon={<Add />} onClick={() => openDialog(null)}>
              Aggiungi Elaborato
            </Button>
          </Box>
          <LocalPaginateTable
            scope="Elaborati"
            isLoading={isLoading}
            cols={[
              { id: "nome", label: "Nome" },
              { id: "titolo_intestazione", label: "Titolo Intestazione" },
              { id: "bozza", label: "Stato", format: (val, referto) => val ? "In Bozza": "Pubblicato" },
              { id: "pagine", label: "N. Pagine", format: (val, referto) => val.length },
              {
                id: "azioni",
                label: "Azioni",
                sortable: false,
                format: (val, referto) => (
                  <IconButton onClick={() => openDialog(referto)}>
                    <Edit />
                  </IconButton>
                ),
              },
            ]}
            list={listaReferti}
            filterCols={["nome", "titolo_intestazione"]}
          />
        </Box>
      )}
    </>
  );
}
