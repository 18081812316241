import * as React from "react";
import { Box, Dialog, DialogTitle, DialogContent, DialogActions, FormControl, Typography, Grid, CircularProgress, Button, TextField, Backdrop } from "@mui/material";
import { Save } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { getConfigurazione, saveConfigurazione } from "../dashboardAdminAPI";
import { getAPIErrorMessage } from "../../../common/utils";
import { LoadingButton } from "@mui/lab";
import { setSnackbarData } from "../dashboardAdminSlice";

export function Configurazione() {
  const dispatch = useDispatch();

  const tokenAdmin = useSelector((state) => state.loginadmin.tokenAdmin);

  const [isSaving, setIsSaving] = React.useState(false);
  const [errAction, setErrAction] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [configurazione, setConfigurazione] = React.useState(null);

  const init = async () => {
    setIsLoading(true);
    let data;
    try {
      const response = await getConfigurazione(tokenAdmin);
      data = response.data;
    } catch (error) {
      setErrAction(getAPIErrorMessage(error));
      return;
    } finally {
      setIsLoading(false);
    }

    setConfigurazione(data);
  };

  const salva = async () => {
    setIsSaving(true);
    const data = {
      prezzo_tecnica_privato: configurazione.prezzo_tecnica_privato,
      prezzo_tecnica_professionista: configurazione.prezzo_tecnica_professionista
    }
    try {
      await saveConfigurazione(data, tokenAdmin);
    } catch (error) {
      setErrAction(getAPIErrorMessage(error));
      return;
    } finally {
      setIsSaving(false);
    }

    dispatch(setSnackbarData({ message: "Configurazione salvata", severity: "success" }));
  }

  React.useEffect(() => {
    init();
  }, []);

  const handleInputChange = (e) => {
    setConfigurazione((prevData) => {
      return { ...prevData, [e.target.name]: e.target.value };
    });
  }


  return (
    <>
      {
        isLoading && <Backdrop open={isLoading} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
          <CircularProgress color="inherit" />
        </Backdrop>
      }
      <Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <LoadingButton variant="contained" endIcon={<Save />} onClick={salva} loading={isSaving}>
            Salva
          </LoadingButton>
        </Box>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <TextField type="number" InputLabelProps={{ shrink: !!configurazione?.prezzo_tecnica_privato }} name="prezzo_tecnica_privato" label="Prezzo Tecnica Privato" inputProps={{ min: 0 }} value={configurazione?.prezzo_tecnica_privato} onChange={(e) => handleInputChange(e)} />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <TextField type="number" InputLabelProps={{ shrink: !!configurazione?.prezzo_tecnica_professionista }}  name="prezzo_tecnica_professionista" label="Prezzo Tecnica Professionista" inputProps={{ min: 0 }} value={configurazione?.prezzo_tecnica_professionista} onChange={(e) => handleInputChange(e)} />
            </FormControl>
          </Grid>
        </Grid>
      </Box>

      <Dialog open={!!errAction} onClose={() => setErrAction("")} maxWidth="sm" fullWidth>
        <DialogTitle variant="dialogTitle" color="error">
          Attenzione
        </DialogTitle>
        <DialogContent>
          <Typography color="error" style={{ whiteSpace: "pre-line" }}>
            {errAction}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => setErrAction("")}>
            OK
          </Button>
        </DialogActions>
      </Dialog>


    </>
  );
}
